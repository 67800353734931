@import './variables';
@import '~bootstrap/scss/bootstrap';

header {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    transition: 300ms;
    .header-container {
        // padding: 6vw 0;
        &.active {
            .open-search {
                fill: white !important;
                path {
                    fill: white !important;
                }
            }
        }
    }
    .fa-search {
        width: 20px;
    }

    .select {
        position: relative;
        background: transparent;
        select {
            font-size: 14px;
            border: none;
            box-shadow: none;
            border-radius: 0;
            background: transparent;
            height: 100%;
            width: 100%;
            cursor: pointer;
            outline: none;
            padding-right: 24px;
            padding-left: 15px;
            -moz-appearance: none;
            -webkit-appearance: none;
            color: white;

            &:-moz-focusring {
                color: transparent;
            }
            &::-ms-expand {
                display: none;
            }
            option {
                color: white;
            }
        }
        /* .fa-chevron-down {
            position: absolute;
            right: 6px;
            top: 4px;
            font-size: 14px;
            z-index: -1;
        } */
    }

    // IE 9 only
    @media all and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
        .select {
            select {
                padding-right: 0;
            }

            &:after,
            &:before {
                display: none;
            }
        }
    }

    ///TOGGLE
    .toggle {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        cursor: pointer;
        .line {
            transition: 200ms;
            background-color: white;
            height: 2px;
            &.line1 {
                width: 42px;
            }
            &.line2 {
                width: 52px;
                margin: 0.5rem 0;
            }
            &.line3 {
                width: 30px;
            }
        }
        &:hover {
            .line {
                width: 52px;
            }
        }
        &.on-off {
            .line {
                width: 40px;
            }
            .line1 {
                transform: rotate(45deg) translate(7.5px, 7.5px);
            }
            .line2 {
                transform: rotate(90deg) translateX(10px) translateY(8.5px);
                opacity: 0;
            }
            .line3 {
                transform: rotate(-45deg) translate(6.5px, -8px);
            }
        }
    }
    .menu-language {
        min-width: 2rem;
        background: transparent;
        li {
            &:first-child {
                display: none;
            }
        }
    }
}
.header-logo {
    width: calc(10vw + 15px);
}
.header-logo-mobile {
    img {
        width: 100%;
        width: calc(10vw + 15px);
    }
}

///NAVBARRR
.header {
    .select {
        min-width: 52px;
    }
    &.active {
        .menu-btn,
        .fa-search {
            color: $white !important;
        }
        .toggle .line {
            background: $white !important;
        }
        .select {
            .fa-chevron-down,
            select {
                color: $white !important;
            }
        }
        .language-link {
            color: white !important;
        }
    }
}
.dropdown {
    .language-ul-list {
        will-change: none;
    }
}
.navbar-logo {
    visibility: hidden;

    &.active {
        visibility: visible;
    }
}
.close-navbar {
    &.active {
        visibility: hidden;
        display: none;
    }
}
//NAVBAR
.navbar-screen {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: $secondary;
    opacity: 0;
    overflow: hidden;
    z-index: 15;
    pointer-events: none;
    transform: translateY(-100%);
    transition: 300ms;
    &.active {
        pointer-events: visible;
        opacity: 1;
        transform: translateY(0);
    }

    .container {
        padding-top: calc(7vw + 15px);
    }

    .nav-list {
        li {
            position: relative;
            a {
                font-weight: normal;
                font-size: 32px;
                line-height: 2;
                letter-spacing: -0.02em;
                color: #ffffff;
                transition: 400ms;
                border-right: 3px solid transparent;

                .hover {
                    font-weight: 300;
                    font-size: 72px;
                    line-height: 100%;
                    letter-spacing: 0.2em;
                    color: #ffffff15;
                    position: absolute;
                    top: 55%;
                    transform: translateY(-50%) scale(0.9);
                    text-transform: uppercase;
                    left: -13%;
                    opacity: 0;
                    transition: 500ms;
                    z-index: -1;
                }

                &:hover {
                    border-right: 3px solid white;
                    font-weight: bold;

                    .hover {
                        opacity: 1;
                        transform: translateY(-50%) scale(1);
                    }
                }
            }
        }
    }

    //social
    .social-media {
        li {
            a {
                i {
                    font-size: 24px;
                }
            }
        }
    }
}

//SEARCH
.search-screen {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: $secondary;
    opacity: 0;
    overflow: hidden;
    z-index: 999;
    pointer-events: none;
    transform: translateY(-100%);
    transition: 300ms;

    &.active {
        pointer-events: visible;
        opacity: 1;
        transform: translateY(0);
    }
    .search-wrapper {
        max-width: 720px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        input {
            font-size: 24px;
            border: none;
            padding: 0.75rem 4rem 0.75rem 1rem;
            border-bottom: 3px solid white;
            outline: none !important;
            box-shadow: none !important;
            border-radius: 0 !important;
            outline-width: 0 !important;
            -webkit-box-shadow: none;
            -webkit-tap-highlight-color: transparent;
            outline-style: none;
            &:focus-visible {
                outline: none;
                border-bottom: 3px solid white !important;
            }
            &::placeholder {
                font-size: 24px;
                color: white;
            }
        }
        .fa-search {
            font-size: 32px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 99;
            right: 16px;
        }
    }
    /*   .close-search {
        position: absolute;
        top: 16px;
        right: 16px;
        font-size: 32px;
        width: 64px;
        height: 64px;
        background: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $secondary;
    } */

    .close-search {
        position: absolute;
        right: 32px;
        top: 32px;
        width: 40px;
        height: 40px;
        opacity: 0.8;
    }
    .close-search:hover {
        opacity: 1;
    }
    .close-search:before,
    .close-search:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 40px;
        width: 2px;
        background-color: rgb(255, 255, 255);
    }
    .close-search:before {
        transform: rotate(45deg);
    }
    .close-search:after {
        transform: rotate(-45deg);
    }

    .search-logo {
        width: calc(10vw + 15px);
    }

    .fa-search {
        max-width: 24px;
    }
}

@media (min-width: 576px) {
}
@media (min-width: 992px) {
    .navbar-screen {
        .container {
            &::after {
                content: '';
                position: absolute;
                height: 95vw;
                top: 0vw;
                left: calc(10vw + 14px);
                width: 2px;
                opacity: 0.2;
                background: white;
            }
            &::before {
                content: '';
                position: absolute;
                height: 8px;
                width: 8px;
                top: 50%;
                left: calc(10vw + 14px);

                background: white;
            }
        }
    }
    .line-custom {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            height: 78vw;
            top: -50vw;
            right: 15px;
            width: 2px;
            opacity: 0.2;
            background: white;
        }
    }
}
.general-search-input {
    &:focus {
        outline-width: 0 !important;
        box-shadow: none !important;
        border-color: transparent !important;
    }
}

[contenteditable='true']:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: transparent !important;
}
header {
    .general-search-input {
        &:focus {
            outline: none !important;
            box-shadow: none !important;
            border-color: transparent !important;
        }
    }
}
//MEDIA
@media (max-width: 1680px) {
    .navbar-screen {
        .nav-list {
            li {
                a {
                    font-size: 18px;

                    .hover {
                        font-size: 48px;
                    }
                }
            }
        }
    }
}
@media (max-width: 1440px) {
}
@media (max-width: 1366px) {
}
@media (max-width: 1199.98px) {
}
@media (max-width: 991.98px) {
}
@media (max-width: 767.98px) {
    .select {
        .fa-chevron-down {
            font-size: 12px !important;
            top: 5px !important;
        }
        select {
            padding-left: 0 !important;
            padding-right: 20px !important;
            font-size: 12px !important;
        }
        &::before {
            width: 20px !important;
        }
    }
}
@media (max-width: 575.98px) {
    .navbar-logo {
        visibility: hidden;
        max-width: 0 !important;
        min-width: 0 !important;
        &.active {
            visibility: visible;
            max-width: 40vw !important;
            min-width: 40vw !important;
        }
    }

    .header {
        .menu-btn {
            font-size: 14px;
        }

        .toggle {
            &.on-off {
                .line {
                    width: 35px;
                }
            }
        }
    }
    .header-logo-mobile {
        img {
            max-width: 40vw;
            min-width: 40vw;
            position: relative;
            left: -30px;
        }
    }
    .search-logo {
        width: 24vw !important;
    }
    .select,
    .open-search {
        display: none;
    }
    .close-search {
        display: block !important;
    }
    .open-search {
        max-width: 25px;
        width: 100%;
    }
    .header-container {
        &.active {
            .select,
            .open-search {
                display: block;
            }
            .open-search {
                fill: white !important;
                path {
                    fill: white !important;
                }
            }

            .menu-btn {
                display: none !important;
            }
        }
    }

    .navbar-screen {
        .nav-list {
            margin-top: 28vw;
            margin-left: calc(40vw - 30px);
            border-bottom: 1px solid white;
            li {
                a {
                    font-size: 18px;
                    line-height: 2.2;
                    .hover {
                        font-size: 28px !important;
                        left: -3%;
                    }
                }
            }
        }
        .wrapper-social {
            margin-left: calc(40vw - 30px);
        }
    }
    .search-screen {
        .search-wrapper {
            input {
                padding: 1.2rem 4rem 1.2rem 1rem;
                font-size: 16px;
                &::placeholder {
                    font-size: 16px;
                }
            }
            .fa-search {
                right: 16px;
                font-size: 24px;
            }
        }
    }

    .social-media {
        li {
            a {
                i {
                    font-size: 16px !important;
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .header-logo-mobile {
        opacity: 0;
        appearance: none;
        pointer-events: none;
    }
}

.header {
    &.header-height-style {
        background: rgba(255, 255, 255, 0.95);
        .menu-btn,
        .fa-search {
            color: $secondary !important;
            fill: $secondary !important;
            path {
                fill: $secondary !important;
            }
        }
        .toggle .line {
            background: $secondary !important;
        }
        .select {
            .fa-chevron-down,
            select {
                color: $secondary !important;
            }
        }
        .selected-language {
            color: $secondary;
        }
    }
    &.active {
        background: none;
        .menu-btn,
        .fa-search {
            color: white !important;
            fill: white !important;
            path {
                fill: white !important;
            }
        }
        .toggle .line {
            background: white !important;
        }
        .select {
            .fa-chevron-down,
            select {
                color: white !important;
            }
        }
        .selected-language {
            color: white !important;
        }
    }
}



@media (max-height: 630px) {
    .navbar-screen {
        .nav-list{
            li {
                a{
                    line-height: 1.5;
                }
            }
        }
    }
}


@media (max-height: 578px) {
    .navbar-screen {
        .nav-list{
            li {
                a{
                    line-height: 1.3;
                }
            }
        }
    }
}
