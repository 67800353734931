.manufacture-mobile-wrapper{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.manufacture-mobile-gr{
    background-color: #00000060;
    padding: 158px 32px 64px 32px;
    height: 100%;
    width: 100%;
}
.manufacture-next{
    color: #EF353D !important;
}
.manufacture-prev{
    color: #EF353D !important;
}
@media(max-width: 992px){
    .manufacture-next{
        color: white !important;
        right: 64px !important;
        top: 13% !important;
    }
    .manufacture-prev{
        color: white !important;
        top: 13% !important;
    }
    .manufacture-mobile-gr{
        .secondary-bold-title{
            font-size: 2.25rem;
        }
        h2{
            font-size: 1.5rem;
        }

    }
}



#manifacturing-detail-section {
    .content-wrapper {
        h1 {
            margin-bottom: 2rem;
        }
        h2 {
        }
        p {
            margin-bottom: 4rem;
        }
        .outline-btn {
        }
    }
}
.manifacturing-detail-section-mobile {
    padding: 0 12px;

    .manifacturing-mobile-card {
        margin-bottom: 12px;

        .card-img-overlayy {
            position: relative;
            padding: 158px 32px 64px 32px;
            width: 100%;
            background-size: cover;
            height: 100%;
            background-repeat: no-repeat;
            border-radius: 8px;

            .content-wrapper {
                h1 {
                    line-height: 1.1;
                    letter-spacing: -0.02em;
                }
                h2 {
                    font-weight: 800;
                    line-height: 1.2;
                    letter-spacing: -0.02em;
                }
                p {
                }
                .outline-btn {
                    &::after {
                        background: white;
                    }
                }
            }

            &.one {
                height: 620px;
                background-image: url(../../public/assets/manufacturing-section-img/sec1.jpg);
                padding: 158px 32px 64px 32px;
                background-position-x: 100%;
            }
            &.two {
                height: 620px;
                background-image: url(../../public/assets/manufacturing-section-img/sec2.jpg);
                padding: 136px 32px 64px 32px;
                background-position-x: 70%;
            }
            &.three {
                height: 620px;
                background-image: url(../../public/assets/manufacturing-section-img/sec3.jpg);
                padding: 158px 32px 64px 32px;
                background-position-x: 64%;
            }
            &.four {
                height: 620px;
                background-image: url(../../public/assets/manufacturing-section-img/sec4.jpg);
                padding: 158px 32px 64px 32px;
                background-position-x: 10%;
            }

            .overlay {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
                opacity: 0.8;
                border-radius: 8px;
            }
        }
    }
}

.manifacturing-quality-systems {
    .for-card {
        position: relative;

        figure {
            max-width: 100px;
        }
    }
}

.quality-systems-mobile-slider {
    .swiper-button-prev,
    .swiper-button-next {
        top: 40%;
        &::after {
            color: $secondary;
            font-size: 1rem;
            font-weight: bolder;
        }
    }
    .swiper-button-next {
        right: 32px;
    }
    .swiper-button-prev {
        left: 32px;
    }
}

.manifacturing-documents-and-certificates,
.manifacturing-documents-and-certificates-mobile {
    .card {
        max-width: 252px;
        min-width: 252px;
    }
    .wrapper-content {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 150vw;
            height: 100%;
            background: #f2f2f2;
            z-index: -1;
        }
    }

    .certificates {
        width: 47px;
        height: 80px;
        background: $secondary;
        .certificates-svg {
            bottom: 15px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

.manifacturing-documents-and-certificates-mobile {
    .certificates-mobile-slider {
        transform: translateX(-40px);
        padding-left: 40px;
    }
    .swiper-button-next,
    .swiper-button-prev {
        &::after {
            font-size: 1rem;
            color: $secondary;
            font-weight: bolder;
        }
    }
}

.manufacturing-slider {
    .swiper-button-next,
    .swiper-button-prev {
        color: white;
        &::after {
            font-size: 28px;
        }
    }

    .swiper-button-prev {
        left: 64px;
    }
    .swiper-button-next {
        right: 64px;
    }
}

.min-secondary-title{
    font-size: 1.5rem;
    font-weight: 700;
}

.quality-card-desc{
    font-size: 1.3rem;
}

//MEDIA
@media (max-width: 1680px) {
    .swiper-slide{
        .swiper-text-animation{
            h2{
                font-size: 1.875rem;
                word-break: break-word;
            }
        }
    }
    .manifacturing {
        h2 {
            font-size: 1.5rem;
        }
    }

    #manifacturing-detail-section {
        .content-wrapper {
            h1 {
                font-size: 40px;
                margin-bottom: 1.5rem;
            }
            h2 {
                font-size: 1.5rem;
            }
            p {
                font-size: 0.875rem;
                margin-bottom: 2.5rem;
            }
            .outline-btn {
                font-size: 0.875rem;
            }
        }
    }
}
@media (max-width: 1440px) {
    #manifacturing-detail-section {
        .content-wrapper {
            h1 {
                font-size: 32px;
                margin-bottom: 1.2rem;
            }
            h2 {
                font-size: 1.2rem;
            }
            p {
                font-size: 0.725rem;
                margin-bottom: 2rem;
            }
            .outline-btn {
                font-size: 0.725rem;
            }
        }
    }
}
@media (max-width: 1280px) {
    #manifacturing-detail-section {
        .content-wrapper {
            h1 {
                font-size: 24px;
                margin-bottom: 1rem;
            }
            h2 {
                font-size: 1rem;
            }
            p {
                font-size: 0.7rem;
                margin-bottom: 1.865rem;
            }
            .outline-btn {
                font-size: 0.7em;
            }
        }
    }

    
}
@media (max-width: 991.98px) {
    #manifacturing-detail-section {
        .col-lg-7 {
            img {
                max-height: 320px;
            }
        }
    }

    .manifacturing-quality-systems {
        h2 {
            font-size: 1.6rem;
        }
    }

    .manifacturing-documents-and-certificates {
        .wrapper-content {
            &::after {
                left: -10%;
            }
        }
    }
}
@media (max-width: 767.98px) {
    #manifacturing-detail-section {
        .col-lg-7 {
            img {
                max-height: 240px;
            }
        }
        .content-wrapper {
            h1 {
                font-size: 24px;
                margin-bottom: 1rem;
            }
            h2 {
                font-size: 1rem;
            }
            p {
                font-size: 0.7rem;
                margin-bottom: 1.865rem;
            }
            .outline-btn {
                font-size: 0.7em;
            }
        }
    }
    .manifacturing-quality-systems {
        h2 {
            font-size: 1.5rem;
        }
    }
}
@media (max-width: 575.98px) {
    .manufacturing-page-hero {
        img {
            object-position: initial !important;
        }
    }

    .manifacturing-quality-systems {
        position: relative;
        &::before {
            content: '';
            position: absolute;
            height: 358px;
            width: 1px;
            left: 30px;
            top: 0;
            opacity: 0.2;
            border: 1px solid #ef353d;
        }
        .manifacturing-quality-systems-description {
            font-size: 1rem;
        }
    }

    .manifacturing {
        .max-w-1228px {
            font-size: 1rem;
        }
    }
    .manifacturing-documents-and-certificates {
        .card {
            max-width: 100% !important;
            width: 100%;
        }
        .section-secondary-title-v2 {
            border-color: transparent;
            position: relative;
            &::after {
                content: '';
                position: absolute;
                left: 0;
                width: 3px;
                height: 86px;
                top: -8px;
                background: $secondary;
            }

            &::before {
                content: '';
                position: absolute;
                height: 155px;
                width: 1px;
                left: 0px;
                top: -3rem;
                opacity: 0.2;
                border: 1px solid #ef353d;
            }
        }
    }

    .manifacturing-documents-and-certificates {
        .wrapper-content {
            position: relative;
            &::after {
                display: none;
            }
        }
    }
}
