//FOOTER
footer {
    margin-top: 68px;
    .footer-logo {
        position: absolute;
        width: calc(10vw + 15px);
        top: -68px;
        left: 0;
    }

    .footer-list-content {
        border-bottom: 1px solid #dddddd;
    }
}
.footer-link{
    transition: 300ms;
    &:hover{
        transform: scale(1.1);
    }
}
//MEDIA
@media (max-width: 1680px) {
}
@media (max-width: 1440px) {
}
@media (max-width: 1366px) {
}
@media (max-width: 1199.98px) {
}
@media (max-width: 991.98px) {
    footer {
        margin-top: 80px;
        .footer-logo {
            position: relative !important;
            top: -6rem;
            left: 0;
        }
    }
}
@media (max-width: 767.98px) {
    footer {
        margin-top: 40px;

        .footer-logo {
            top: -3rem;
        }
    }
}
@media (max-width: 575.98px) {
    footer {
        .footer-logo {
            width: calc(40vw + 15px);
            margin-top: -40px;
        }

        .footer-list-content {
            padding-left: 24px;
            margin-top: -1rem;
            border-bottom: none;
        }
        .footer-bottom-row {
            margin-left: 24px;
            .copyright {
                max-width: 180px;
                padding-left: 0 !important;
                margin-top: 32px;
            }
            .col-lg-5 {
                padding-left: 0 !important;
                border-bottom: 1px solid #dddddd;
                ul {
                    margin-bottom: 32px;
                }
            }
        }
    }
}
