 .accordion-team-section {
    .accordion-item {
       position: relative;
       .person-name{
            font-size: 1.5rem;
            font-weight: 600;
            color: #000;
            letter-spacing: 0.2px;
            text-align: start;
       }
       .person-title{
            font-size: 1rem;
            color: rgb(66, 66, 66);
            margin-bottom: 0.5rem;
            letter-spacing: 0.2px;
            font-weight: 400;
            text-align: start;
       }
    }
    .accordion-header{
        transform: translateX(24px);
    }
    .accordion-btn-img {
       width: 120px;
       height: 120px;
       margin-right: 1.5rem;
       transition: 300ms;
       opacity: 1;
    }
    .accordion-button {
       &::after {
          background-image: url(/assets/our-management-team/plus.svg);
          margin-left: 0;
          margin-right: 40px;
       }
    }
    .accordion-button:not(.collapsed)::after {
       background-image: url(/assets/our-management-team/plus.svg);
       transform: rotate(135deg);
    }

    .accordion-button:not(.collapsed) {
       background: transparent;
    }
    .accordion-col-custom {
       transition: 300ms;
       min-width: 50%;
       &.wide {
          min-width: 100% !important;
       }
    }

    //ANIM{
    .accordion-col-custom {
       transition-delay: 350ms;
       &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          right: 0;
          width: 1740px;
          min-width: 1740px;
          height: 100%;
          background: transparent;
          margin: 0 auto;
          transition: 1500ms;
          transition-delay: 550ms;
          z-index: -1;
          opacity: 0;
       }

       .card-body {
            img {
                opacity: 0;
                transform: scale(0.7);
                transition-delay: 600s !important;
                transition: 500ms !important;
            }
            .content {
                opacity: 0;
                transform: translateY(70px);
                transition-delay: 700s !important;
                transition: 500ms !important;
            }
       }
       &.wide {
            position: relative;

            .card-body {
                img {
                    opacity: 1;
                    transform: scale(1);
                }
                .content {
                    opacity: 1;
                    transform: translateY(0);
                }
            }

            &.accordion-col-custom {
                &::before {
                    height: 100%;
                    background: #f7f7f7;
                    margin: 0 auto;
                    opacity: 1;
                }
            }
            .person-accordion-icon{
                transform: rotate(90deg);
            }
            .accordion-item {
                padding-bottom: 6.5rem;
            }
            .accordion-btn-img {
                width: 0;
                margin-right: 0;
                opacity: 0;
            }

        }
    }
 }

 //RESPONSIVE
 @include media-breakpoint-down(md) {
    .accordion-team-section {
       .accordion-item {
          &.border-bottom-line {
             &::before {
                bottom: -24px;
                right: 1.5rem;
             }
          }
       }
    }
 }

