@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Nunito");
/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #ef353d;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #3a3a3a;
  --primary: #007bff;
  --secondary: #ef353d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #ef353d;
  --light: #f2f2f2;
  --dark: #4d4d4d;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Nexa";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Nexa";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 900;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: none;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.5;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.5;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.5;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.5;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.5;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: normal;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.75rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: bold;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 820px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 80vw;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 0 solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 0 solid #dee2e6;
}
.table tbody + tbody {
  border-top: 0 solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 0 solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 0 solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 0;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #fbc6c9;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #f7969a;
}

.table-hover .table-secondary:hover {
  background-color: #f9aeb2;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #f9aeb2;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fbc6c9;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f7969a;
}

.table-hover .table-danger:hover {
  background-color: #f9aeb2;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f9aeb2;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fbfbfb;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f8f8f8;
}

.table-hover .table-light:hover {
  background-color: #eeeeee;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #eeeeee;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #cdcdcd;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #a2a2a2;
}

.table-hover .table-dark:hover {
  background-color: silver;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: silver;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #3a3a3a;
  border-color: #4d4d4d;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #3a3a3a;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #4d4d4d;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.75rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 1rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 5px;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%233a3a3a' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #ef353d;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(239, 53, 61, 0.9);
  border-radius: 5px;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ef353d;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ef353d' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ef353d' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #ef353d;
  box-shadow: 0 0 0 0.2rem rgba(239, 53, 61, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #ef353d;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%233a3a3a' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ef353d' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ef353d' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #ef353d;
  box-shadow: 0 0 0 0.2rem rgba(239, 53, 61, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ef353d;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #ef353d;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #ef353d;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f3646a;
  background-color: #f3646a;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(239, 53, 61, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ef353d;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ef353d;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #ef353d;
  box-shadow: 0 0 0 0.2rem rgba(239, 53, 61, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: normal;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #ef353d;
  border-color: #ef353d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #eb131c;
  border-color: #df121b;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #eb131c;
  border-color: #df121b;
  box-shadow: 0 0 0 0.2rem rgba(241, 83, 90, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #ef353d;
  border-color: #ef353d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #df121b;
  border-color: #d31119;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 83, 90, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #ef353d;
  border-color: #ef353d;
}
.btn-danger:hover {
  color: #fff;
  background-color: #eb131c;
  border-color: #df121b;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #eb131c;
  border-color: #df121b;
  box-shadow: 0 0 0 0.2rem rgba(241, 83, 90, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #ef353d;
  border-color: #ef353d;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #df121b;
  border-color: #d31119;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 83, 90, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-light:hover {
  color: #212529;
  background-color: #dfdfdf;
  border-color: #d9d9d9;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #dfdfdf;
  border-color: #d9d9d9;
  box-shadow: 0 0 0 0.2rem rgba(211, 211, 212, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #d9d9d9;
  border-color: #d2d2d2;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 211, 212, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #4d4d4d;
  border-color: #4d4d4d;
}
.btn-dark:hover {
  color: #fff;
  background-color: #3a3a3a;
  border-color: #343434;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #3a3a3a;
  border-color: #343434;
  box-shadow: 0 0 0 0.2rem rgba(104, 104, 104, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #4d4d4d;
  border-color: #4d4d4d;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #343434;
  border-color: #2d2d2d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(104, 104, 104, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #ef353d;
  border-color: #ef353d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #ef353d;
  border-color: #ef353d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 53, 61, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #ef353d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #ef353d;
  border-color: #ef353d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 53, 61, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #ef353d;
  border-color: #ef353d;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #ef353d;
  border-color: #ef353d;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 53, 61, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #ef353d;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #ef353d;
  border-color: #ef353d;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 53, 61, 0.5);
}

.btn-outline-light {
  color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 242, 242, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f2f2f2;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 242, 242, 0.5);
}

.btn-outline-dark {
  color: #4d4d4d;
  border-color: #4d4d4d;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #4d4d4d;
  border-color: #4d4d4d;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 77, 77, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #4d4d4d;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #4d4d4d;
  border-color: #4d4d4d;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 77, 77, 0.5);
}

.btn-link {
  font-weight: normal;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: none;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 1rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.75rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: normal;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 5px;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 1rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.75rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 5px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%233a3a3a' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 5px;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 5px;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 5px 5px 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 5px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 5px;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: 4px 4px 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 4px 4px;
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 4px;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 5px;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 5px;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.page-item:last-child .page-link {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #ef353d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #df121b;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(239, 53, 61, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #ef353d;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #df121b;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(239, 53, 61, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f2f2f2;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #d9d9d9;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(242, 242, 242, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #4d4d4d;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #343434;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(77, 77, 77, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 1rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 5px;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: bold;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #7c1c20;
  background-color: #fcd7d8;
  border-color: #fbc6c9;
}
.alert-secondary hr {
  border-top-color: #f9aeb2;
}
.alert-secondary .alert-link {
  color: #521315;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #7c1c20;
  background-color: #fcd7d8;
  border-color: #fbc6c9;
}
.alert-danger hr {
  border-top-color: #f9aeb2;
}
.alert-danger .alert-link {
  color: #521315;
}

.alert-light {
  color: #7e7e7e;
  background-color: #fcfcfc;
  border-color: #fbfbfb;
}
.alert-light hr {
  border-top-color: #eeeeee;
}
.alert-light .alert-link {
  color: #656565;
}

.alert-dark {
  color: #282828;
  background-color: #dbdbdb;
  border-color: #cdcdcd;
}
.alert-dark hr {
  border-top-color: silver;
}
.alert-dark .alert-link {
  color: #0f0f0f;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 5px;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 5px;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f5f5f5;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 5px;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #7c1c20;
  background-color: #fbc6c9;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #7c1c20;
  background-color: #f9aeb2;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #7c1c20;
  border-color: #7c1c20;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #7c1c20;
  background-color: #fbc6c9;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #7c1c20;
  background-color: #f9aeb2;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #7c1c20;
  border-color: #7c1c20;
}

.list-group-item-light {
  color: #7e7e7e;
  background-color: #fbfbfb;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #7e7e7e;
  background-color: #eeeeee;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #7e7e7e;
  border-color: #7e7e7e;
}

.list-group-item-dark {
  color: #282828;
  background-color: #cdcdcd;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #282828;
  background-color: silver;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #282828;
  border-color: #282828;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(1rem - 1px);
  border-top-right-radius: calc(1rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(1rem - 1px);
  border-bottom-left-radius: calc(1rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Nexa";
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 5px;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Nexa";
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 1rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 1rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 1rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(1rem - 1px);
  border-top-right-radius: calc(1rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #ef353d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #df121b !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #ef353d !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #df121b !important;
}

.bg-light {
  background-color: #f2f2f2 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #d9d9d9 !important;
}

.bg-dark {
  background-color: #4d4d4d !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #343434 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #ef353d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #ef353d !important;
}

.border-light {
  border-color: #f2f2f2 !important;
}

.border-dark {
  border-color: #4d4d4d !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.75rem !important;
}

.rounded {
  border-radius: 5px !important;
}

.rounded-top {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.rounded-right {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.rounded-bottom {
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.rounded-left {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.rounded-lg {
  border-radius: 1rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.mt-5,
.my-5 {
  margin-top: 2rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 2rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 2rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 2rem !important;
}

.m-6 {
  margin: 2.5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 2.5rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 2.5rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 2.5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 2.5rem !important;
}

.m-7 {
  margin: 3rem !important;
}

.mt-7,
.my-7 {
  margin-top: 3rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 3rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 3rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 3rem !important;
}

.m-8 {
  margin: 3.5rem !important;
}

.mt-8,
.my-8 {
  margin-top: 3.5rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 3.5rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 3.5rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 3.5rem !important;
}

.m-9 {
  margin: 4rem !important;
}

.mt-9,
.my-9 {
  margin-top: 4rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 4rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 4rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 4rem !important;
}

.m-10 {
  margin: 4.5rem !important;
}

.mt-10,
.my-10 {
  margin-top: 4.5rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 4.5rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 4.5rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 4.5rem !important;
}

.m-11 {
  margin: 5rem !important;
}

.mt-11,
.my-11 {
  margin-top: 5rem !important;
}

.mr-11,
.mx-11 {
  margin-right: 5rem !important;
}

.mb-11,
.my-11 {
  margin-bottom: 5rem !important;
}

.ml-11,
.mx-11 {
  margin-left: 5rem !important;
}

.m-12 {
  margin: 5.5rem !important;
}

.mt-12,
.my-12 {
  margin-top: 5.5rem !important;
}

.mr-12,
.mx-12 {
  margin-right: 5.5rem !important;
}

.mb-12,
.my-12 {
  margin-bottom: 5.5rem !important;
}

.ml-12,
.mx-12 {
  margin-left: 5.5rem !important;
}

.m-13 {
  margin: 6rem !important;
}

.mt-13,
.my-13 {
  margin-top: 6rem !important;
}

.mr-13,
.mx-13 {
  margin-right: 6rem !important;
}

.mb-13,
.my-13 {
  margin-bottom: 6rem !important;
}

.ml-13,
.mx-13 {
  margin-left: 6rem !important;
}

.m-14 {
  margin: 6.5rem !important;
}

.mt-14,
.my-14 {
  margin-top: 6.5rem !important;
}

.mr-14,
.mx-14 {
  margin-right: 6.5rem !important;
}

.mb-14,
.my-14 {
  margin-bottom: 6.5rem !important;
}

.ml-14,
.mx-14 {
  margin-left: 6.5rem !important;
}

.m-15 {
  margin: 7rem !important;
}

.mt-15,
.my-15 {
  margin-top: 7rem !important;
}

.mr-15,
.mx-15 {
  margin-right: 7rem !important;
}

.mb-15,
.my-15 {
  margin-bottom: 7rem !important;
}

.ml-15,
.mx-15 {
  margin-left: 7rem !important;
}

.m-16 {
  margin: 7.5rem !important;
}

.mt-16,
.my-16 {
  margin-top: 7.5rem !important;
}

.mr-16,
.mx-16 {
  margin-right: 7.5rem !important;
}

.mb-16,
.my-16 {
  margin-bottom: 7.5rem !important;
}

.ml-16,
.mx-16 {
  margin-left: 7.5rem !important;
}

.m-17 {
  margin: 8rem !important;
}

.mt-17,
.my-17 {
  margin-top: 8rem !important;
}

.mr-17,
.mx-17 {
  margin-right: 8rem !important;
}

.mb-17,
.my-17 {
  margin-bottom: 8rem !important;
}

.ml-17,
.mx-17 {
  margin-left: 8rem !important;
}

.m-18 {
  margin: 8.5rem !important;
}

.mt-18,
.my-18 {
  margin-top: 8.5rem !important;
}

.mr-18,
.mx-18 {
  margin-right: 8.5rem !important;
}

.mb-18,
.my-18 {
  margin-bottom: 8.5rem !important;
}

.ml-18,
.mx-18 {
  margin-left: 8.5rem !important;
}

.m-19 {
  margin: 9rem !important;
}

.mt-19,
.my-19 {
  margin-top: 9rem !important;
}

.mr-19,
.mx-19 {
  margin-right: 9rem !important;
}

.mb-19,
.my-19 {
  margin-bottom: 9rem !important;
}

.ml-19,
.mx-19 {
  margin-left: 9rem !important;
}

.m-20 {
  margin: 9.5rem !important;
}

.mt-20,
.my-20 {
  margin-top: 9.5rem !important;
}

.mr-20,
.mx-20 {
  margin-right: 9.5rem !important;
}

.mb-20,
.my-20 {
  margin-bottom: 9.5rem !important;
}

.ml-20,
.mx-20 {
  margin-left: 9.5rem !important;
}

.m-21 {
  margin: 10rem !important;
}

.mt-21,
.my-21 {
  margin-top: 10rem !important;
}

.mr-21,
.mx-21 {
  margin-right: 10rem !important;
}

.mb-21,
.my-21 {
  margin-bottom: 10rem !important;
}

.ml-21,
.mx-21 {
  margin-left: 10rem !important;
}

.m-22 {
  margin: 11rem !important;
}

.mt-22,
.my-22 {
  margin-top: 11rem !important;
}

.mr-22,
.mx-22 {
  margin-right: 11rem !important;
}

.mb-22,
.my-22 {
  margin-bottom: 11rem !important;
}

.ml-22,
.mx-22 {
  margin-left: 11rem !important;
}

.m-23 {
  margin: 12rem !important;
}

.mt-23,
.my-23 {
  margin-top: 12rem !important;
}

.mr-23,
.mx-23 {
  margin-right: 12rem !important;
}

.mb-23,
.my-23 {
  margin-bottom: 12rem !important;
}

.ml-23,
.mx-23 {
  margin-left: 12rem !important;
}

.m-24 {
  margin: 13rem !important;
}

.mt-24,
.my-24 {
  margin-top: 13rem !important;
}

.mr-24,
.mx-24 {
  margin-right: 13rem !important;
}

.mb-24,
.my-24 {
  margin-bottom: 13rem !important;
}

.ml-24,
.mx-24 {
  margin-left: 13rem !important;
}

.m-25 {
  margin: 13.5rem !important;
}

.mt-25,
.my-25 {
  margin-top: 13.5rem !important;
}

.mr-25,
.mx-25 {
  margin-right: 13.5rem !important;
}

.mb-25,
.my-25 {
  margin-bottom: 13.5rem !important;
}

.ml-25,
.mx-25 {
  margin-left: 13.5rem !important;
}

.m-26 {
  margin: 14rem !important;
}

.mt-26,
.my-26 {
  margin-top: 14rem !important;
}

.mr-26,
.mx-26 {
  margin-right: 14rem !important;
}

.mb-26,
.my-26 {
  margin-bottom: 14rem !important;
}

.ml-26,
.mx-26 {
  margin-left: 14rem !important;
}

.m-27 {
  margin: 15rem !important;
}

.mt-27,
.my-27 {
  margin-top: 15rem !important;
}

.mr-27,
.mx-27 {
  margin-right: 15rem !important;
}

.mb-27,
.my-27 {
  margin-bottom: 15rem !important;
}

.ml-27,
.mx-27 {
  margin-left: 15rem !important;
}

.m-28 {
  margin: 16rem !important;
}

.mt-28,
.my-28 {
  margin-top: 16rem !important;
}

.mr-28,
.mx-28 {
  margin-right: 16rem !important;
}

.mb-28,
.my-28 {
  margin-bottom: 16rem !important;
}

.ml-28,
.mx-28 {
  margin-left: 16rem !important;
}

.m-29 {
  margin: 17rem !important;
}

.mt-29,
.my-29 {
  margin-top: 17rem !important;
}

.mr-29,
.mx-29 {
  margin-right: 17rem !important;
}

.mb-29,
.my-29 {
  margin-bottom: 17rem !important;
}

.ml-29,
.mx-29 {
  margin-left: 17rem !important;
}

.m-30 {
  margin: 18rem !important;
}

.mt-30,
.my-30 {
  margin-top: 18rem !important;
}

.mr-30,
.mx-30 {
  margin-right: 18rem !important;
}

.mb-30,
.my-30 {
  margin-bottom: 18rem !important;
}

.ml-30,
.mx-30 {
  margin-left: 18rem !important;
}

.m-31 {
  margin: 19rem !important;
}

.mt-31,
.my-31 {
  margin-top: 19rem !important;
}

.mr-31,
.mx-31 {
  margin-right: 19rem !important;
}

.mb-31,
.my-31 {
  margin-bottom: 19rem !important;
}

.ml-31,
.mx-31 {
  margin-left: 19rem !important;
}

.m-32 {
  margin: 20rem !important;
}

.mt-32,
.my-32 {
  margin-top: 20rem !important;
}

.mr-32,
.mx-32 {
  margin-right: 20rem !important;
}

.mb-32,
.my-32 {
  margin-bottom: 20rem !important;
}

.ml-32,
.mx-32 {
  margin-left: 20rem !important;
}

.m-37 {
  margin: 22.5rem !important;
}

.mt-37,
.my-37 {
  margin-top: 22.5rem !important;
}

.mr-37,
.mx-37 {
  margin-right: 22.5rem !important;
}

.mb-37,
.my-37 {
  margin-bottom: 22.5rem !important;
}

.ml-37,
.mx-37 {
  margin-left: 22.5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.pt-5,
.py-5 {
  padding-top: 2rem !important;
}

.pr-5,
.px-5 {
  padding-right: 2rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 2rem !important;
}

.pl-5,
.px-5 {
  padding-left: 2rem !important;
}

.p-6 {
  padding: 2.5rem !important;
}

.pt-6,
.py-6 {
  padding-top: 2.5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 2.5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 2.5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 2.5rem !important;
}

.p-7 {
  padding: 3rem !important;
}

.pt-7,
.py-7 {
  padding-top: 3rem !important;
}

.pr-7,
.px-7 {
  padding-right: 3rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 3rem !important;
}

.pl-7,
.px-7 {
  padding-left: 3rem !important;
}

.p-8 {
  padding: 3.5rem !important;
}

.pt-8,
.py-8 {
  padding-top: 3.5rem !important;
}

.pr-8,
.px-8 {
  padding-right: 3.5rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 3.5rem !important;
}

.pl-8,
.px-8 {
  padding-left: 3.5rem !important;
}

.p-9 {
  padding: 4rem !important;
}

.pt-9,
.py-9 {
  padding-top: 4rem !important;
}

.pr-9,
.px-9 {
  padding-right: 4rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 4rem !important;
}

.pl-9,
.px-9 {
  padding-left: 4rem !important;
}

.p-10 {
  padding: 4.5rem !important;
}

.pt-10,
.py-10 {
  padding-top: 4.5rem !important;
}

.pr-10,
.px-10 {
  padding-right: 4.5rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 4.5rem !important;
}

.pl-10,
.px-10 {
  padding-left: 4.5rem !important;
}

.p-11 {
  padding: 5rem !important;
}

.pt-11,
.py-11 {
  padding-top: 5rem !important;
}

.pr-11,
.px-11 {
  padding-right: 5rem !important;
}

.pb-11,
.py-11 {
  padding-bottom: 5rem !important;
}

.pl-11,
.px-11 {
  padding-left: 5rem !important;
}

.p-12 {
  padding: 5.5rem !important;
}

.pt-12,
.py-12 {
  padding-top: 5.5rem !important;
}

.pr-12,
.px-12 {
  padding-right: 5.5rem !important;
}

.pb-12,
.py-12 {
  padding-bottom: 5.5rem !important;
}

.pl-12,
.px-12 {
  padding-left: 5.5rem !important;
}

.p-13 {
  padding: 6rem !important;
}

.pt-13,
.py-13 {
  padding-top: 6rem !important;
}

.pr-13,
.px-13 {
  padding-right: 6rem !important;
}

.pb-13,
.py-13 {
  padding-bottom: 6rem !important;
}

.pl-13,
.px-13 {
  padding-left: 6rem !important;
}

.p-14 {
  padding: 6.5rem !important;
}

.pt-14,
.py-14 {
  padding-top: 6.5rem !important;
}

.pr-14,
.px-14 {
  padding-right: 6.5rem !important;
}

.pb-14,
.py-14 {
  padding-bottom: 6.5rem !important;
}

.pl-14,
.px-14 {
  padding-left: 6.5rem !important;
}

.p-15 {
  padding: 7rem !important;
}

.pt-15,
.py-15 {
  padding-top: 7rem !important;
}

.pr-15,
.px-15 {
  padding-right: 7rem !important;
}

.pb-15,
.py-15 {
  padding-bottom: 7rem !important;
}

.pl-15,
.px-15 {
  padding-left: 7rem !important;
}

.p-16 {
  padding: 7.5rem !important;
}

.pt-16,
.py-16 {
  padding-top: 7.5rem !important;
}

.pr-16,
.px-16 {
  padding-right: 7.5rem !important;
}

.pb-16,
.py-16 {
  padding-bottom: 7.5rem !important;
}

.pl-16,
.px-16 {
  padding-left: 7.5rem !important;
}

.p-17 {
  padding: 8rem !important;
}

.pt-17,
.py-17 {
  padding-top: 8rem !important;
}

.pr-17,
.px-17 {
  padding-right: 8rem !important;
}

.pb-17,
.py-17 {
  padding-bottom: 8rem !important;
}

.pl-17,
.px-17 {
  padding-left: 8rem !important;
}

.p-18 {
  padding: 8.5rem !important;
}

.pt-18,
.py-18 {
  padding-top: 8.5rem !important;
}

.pr-18,
.px-18 {
  padding-right: 8.5rem !important;
}

.pb-18,
.py-18 {
  padding-bottom: 8.5rem !important;
}

.pl-18,
.px-18 {
  padding-left: 8.5rem !important;
}

.p-19 {
  padding: 9rem !important;
}

.pt-19,
.py-19 {
  padding-top: 9rem !important;
}

.pr-19,
.px-19 {
  padding-right: 9rem !important;
}

.pb-19,
.py-19 {
  padding-bottom: 9rem !important;
}

.pl-19,
.px-19 {
  padding-left: 9rem !important;
}

.p-20 {
  padding: 9.5rem !important;
}

.pt-20,
.py-20 {
  padding-top: 9.5rem !important;
}

.pr-20,
.px-20 {
  padding-right: 9.5rem !important;
}

.pb-20,
.py-20 {
  padding-bottom: 9.5rem !important;
}

.pl-20,
.px-20 {
  padding-left: 9.5rem !important;
}

.p-21 {
  padding: 10rem !important;
}

.pt-21,
.py-21 {
  padding-top: 10rem !important;
}

.pr-21,
.px-21 {
  padding-right: 10rem !important;
}

.pb-21,
.py-21 {
  padding-bottom: 10rem !important;
}

.pl-21,
.px-21 {
  padding-left: 10rem !important;
}

.p-22 {
  padding: 11rem !important;
}

.pt-22,
.py-22 {
  padding-top: 11rem !important;
}

.pr-22,
.px-22 {
  padding-right: 11rem !important;
}

.pb-22,
.py-22 {
  padding-bottom: 11rem !important;
}

.pl-22,
.px-22 {
  padding-left: 11rem !important;
}

.p-23 {
  padding: 12rem !important;
}

.pt-23,
.py-23 {
  padding-top: 12rem !important;
}

.pr-23,
.px-23 {
  padding-right: 12rem !important;
}

.pb-23,
.py-23 {
  padding-bottom: 12rem !important;
}

.pl-23,
.px-23 {
  padding-left: 12rem !important;
}

.p-24 {
  padding: 13rem !important;
}

.pt-24,
.py-24 {
  padding-top: 13rem !important;
}

.pr-24,
.px-24 {
  padding-right: 13rem !important;
}

.pb-24,
.py-24 {
  padding-bottom: 13rem !important;
}

.pl-24,
.px-24 {
  padding-left: 13rem !important;
}

.p-25 {
  padding: 13.5rem !important;
}

.pt-25,
.py-25 {
  padding-top: 13.5rem !important;
}

.pr-25,
.px-25 {
  padding-right: 13.5rem !important;
}

.pb-25,
.py-25 {
  padding-bottom: 13.5rem !important;
}

.pl-25,
.px-25 {
  padding-left: 13.5rem !important;
}

.p-26 {
  padding: 14rem !important;
}

.pt-26,
.py-26 {
  padding-top: 14rem !important;
}

.pr-26,
.px-26 {
  padding-right: 14rem !important;
}

.pb-26,
.py-26 {
  padding-bottom: 14rem !important;
}

.pl-26,
.px-26 {
  padding-left: 14rem !important;
}

.p-27 {
  padding: 15rem !important;
}

.pt-27,
.py-27 {
  padding-top: 15rem !important;
}

.pr-27,
.px-27 {
  padding-right: 15rem !important;
}

.pb-27,
.py-27 {
  padding-bottom: 15rem !important;
}

.pl-27,
.px-27 {
  padding-left: 15rem !important;
}

.p-28 {
  padding: 16rem !important;
}

.pt-28,
.py-28 {
  padding-top: 16rem !important;
}

.pr-28,
.px-28 {
  padding-right: 16rem !important;
}

.pb-28,
.py-28 {
  padding-bottom: 16rem !important;
}

.pl-28,
.px-28 {
  padding-left: 16rem !important;
}

.p-29 {
  padding: 17rem !important;
}

.pt-29,
.py-29 {
  padding-top: 17rem !important;
}

.pr-29,
.px-29 {
  padding-right: 17rem !important;
}

.pb-29,
.py-29 {
  padding-bottom: 17rem !important;
}

.pl-29,
.px-29 {
  padding-left: 17rem !important;
}

.p-30 {
  padding: 18rem !important;
}

.pt-30,
.py-30 {
  padding-top: 18rem !important;
}

.pr-30,
.px-30 {
  padding-right: 18rem !important;
}

.pb-30,
.py-30 {
  padding-bottom: 18rem !important;
}

.pl-30,
.px-30 {
  padding-left: 18rem !important;
}

.p-31 {
  padding: 19rem !important;
}

.pt-31,
.py-31 {
  padding-top: 19rem !important;
}

.pr-31,
.px-31 {
  padding-right: 19rem !important;
}

.pb-31,
.py-31 {
  padding-bottom: 19rem !important;
}

.pl-31,
.px-31 {
  padding-left: 19rem !important;
}

.p-32 {
  padding: 20rem !important;
}

.pt-32,
.py-32 {
  padding-top: 20rem !important;
}

.pr-32,
.px-32 {
  padding-right: 20rem !important;
}

.pb-32,
.py-32 {
  padding-bottom: 20rem !important;
}

.pl-32,
.px-32 {
  padding-left: 20rem !important;
}

.p-37 {
  padding: 22.5rem !important;
}

.pt-37,
.py-37 {
  padding-top: 22.5rem !important;
}

.pr-37,
.px-37 {
  padding-right: 22.5rem !important;
}

.pb-37,
.py-37 {
  padding-bottom: 22.5rem !important;
}

.pl-37,
.px-37 {
  padding-left: 22.5rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -2rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -2rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -2rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -2rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -2rem !important;
}

.m-n6 {
  margin: -2.5rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -2.5rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -2.5rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -2.5rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -2.5rem !important;
}

.m-n7 {
  margin: -3rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -3rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -3rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -3rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -3rem !important;
}

.m-n8 {
  margin: -3.5rem !important;
}

.mt-n8,
.my-n8 {
  margin-top: -3.5rem !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -3.5rem !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -3.5rem !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -3.5rem !important;
}

.m-n9 {
  margin: -4rem !important;
}

.mt-n9,
.my-n9 {
  margin-top: -4rem !important;
}

.mr-n9,
.mx-n9 {
  margin-right: -4rem !important;
}

.mb-n9,
.my-n9 {
  margin-bottom: -4rem !important;
}

.ml-n9,
.mx-n9 {
  margin-left: -4rem !important;
}

.m-n10 {
  margin: -4.5rem !important;
}

.mt-n10,
.my-n10 {
  margin-top: -4.5rem !important;
}

.mr-n10,
.mx-n10 {
  margin-right: -4.5rem !important;
}

.mb-n10,
.my-n10 {
  margin-bottom: -4.5rem !important;
}

.ml-n10,
.mx-n10 {
  margin-left: -4.5rem !important;
}

.m-n11 {
  margin: -5rem !important;
}

.mt-n11,
.my-n11 {
  margin-top: -5rem !important;
}

.mr-n11,
.mx-n11 {
  margin-right: -5rem !important;
}

.mb-n11,
.my-n11 {
  margin-bottom: -5rem !important;
}

.ml-n11,
.mx-n11 {
  margin-left: -5rem !important;
}

.m-n12 {
  margin: -5.5rem !important;
}

.mt-n12,
.my-n12 {
  margin-top: -5.5rem !important;
}

.mr-n12,
.mx-n12 {
  margin-right: -5.5rem !important;
}

.mb-n12,
.my-n12 {
  margin-bottom: -5.5rem !important;
}

.ml-n12,
.mx-n12 {
  margin-left: -5.5rem !important;
}

.m-n13 {
  margin: -6rem !important;
}

.mt-n13,
.my-n13 {
  margin-top: -6rem !important;
}

.mr-n13,
.mx-n13 {
  margin-right: -6rem !important;
}

.mb-n13,
.my-n13 {
  margin-bottom: -6rem !important;
}

.ml-n13,
.mx-n13 {
  margin-left: -6rem !important;
}

.m-n14 {
  margin: -6.5rem !important;
}

.mt-n14,
.my-n14 {
  margin-top: -6.5rem !important;
}

.mr-n14,
.mx-n14 {
  margin-right: -6.5rem !important;
}

.mb-n14,
.my-n14 {
  margin-bottom: -6.5rem !important;
}

.ml-n14,
.mx-n14 {
  margin-left: -6.5rem !important;
}

.m-n15 {
  margin: -7rem !important;
}

.mt-n15,
.my-n15 {
  margin-top: -7rem !important;
}

.mr-n15,
.mx-n15 {
  margin-right: -7rem !important;
}

.mb-n15,
.my-n15 {
  margin-bottom: -7rem !important;
}

.ml-n15,
.mx-n15 {
  margin-left: -7rem !important;
}

.m-n16 {
  margin: -7.5rem !important;
}

.mt-n16,
.my-n16 {
  margin-top: -7.5rem !important;
}

.mr-n16,
.mx-n16 {
  margin-right: -7.5rem !important;
}

.mb-n16,
.my-n16 {
  margin-bottom: -7.5rem !important;
}

.ml-n16,
.mx-n16 {
  margin-left: -7.5rem !important;
}

.m-n17 {
  margin: -8rem !important;
}

.mt-n17,
.my-n17 {
  margin-top: -8rem !important;
}

.mr-n17,
.mx-n17 {
  margin-right: -8rem !important;
}

.mb-n17,
.my-n17 {
  margin-bottom: -8rem !important;
}

.ml-n17,
.mx-n17 {
  margin-left: -8rem !important;
}

.m-n18 {
  margin: -8.5rem !important;
}

.mt-n18,
.my-n18 {
  margin-top: -8.5rem !important;
}

.mr-n18,
.mx-n18 {
  margin-right: -8.5rem !important;
}

.mb-n18,
.my-n18 {
  margin-bottom: -8.5rem !important;
}

.ml-n18,
.mx-n18 {
  margin-left: -8.5rem !important;
}

.m-n19 {
  margin: -9rem !important;
}

.mt-n19,
.my-n19 {
  margin-top: -9rem !important;
}

.mr-n19,
.mx-n19 {
  margin-right: -9rem !important;
}

.mb-n19,
.my-n19 {
  margin-bottom: -9rem !important;
}

.ml-n19,
.mx-n19 {
  margin-left: -9rem !important;
}

.m-n20 {
  margin: -9.5rem !important;
}

.mt-n20,
.my-n20 {
  margin-top: -9.5rem !important;
}

.mr-n20,
.mx-n20 {
  margin-right: -9.5rem !important;
}

.mb-n20,
.my-n20 {
  margin-bottom: -9.5rem !important;
}

.ml-n20,
.mx-n20 {
  margin-left: -9.5rem !important;
}

.m-n21 {
  margin: -10rem !important;
}

.mt-n21,
.my-n21 {
  margin-top: -10rem !important;
}

.mr-n21,
.mx-n21 {
  margin-right: -10rem !important;
}

.mb-n21,
.my-n21 {
  margin-bottom: -10rem !important;
}

.ml-n21,
.mx-n21 {
  margin-left: -10rem !important;
}

.m-n22 {
  margin: -11rem !important;
}

.mt-n22,
.my-n22 {
  margin-top: -11rem !important;
}

.mr-n22,
.mx-n22 {
  margin-right: -11rem !important;
}

.mb-n22,
.my-n22 {
  margin-bottom: -11rem !important;
}

.ml-n22,
.mx-n22 {
  margin-left: -11rem !important;
}

.m-n23 {
  margin: -12rem !important;
}

.mt-n23,
.my-n23 {
  margin-top: -12rem !important;
}

.mr-n23,
.mx-n23 {
  margin-right: -12rem !important;
}

.mb-n23,
.my-n23 {
  margin-bottom: -12rem !important;
}

.ml-n23,
.mx-n23 {
  margin-left: -12rem !important;
}

.m-n24 {
  margin: -13rem !important;
}

.mt-n24,
.my-n24 {
  margin-top: -13rem !important;
}

.mr-n24,
.mx-n24 {
  margin-right: -13rem !important;
}

.mb-n24,
.my-n24 {
  margin-bottom: -13rem !important;
}

.ml-n24,
.mx-n24 {
  margin-left: -13rem !important;
}

.m-n25 {
  margin: -13.5rem !important;
}

.mt-n25,
.my-n25 {
  margin-top: -13.5rem !important;
}

.mr-n25,
.mx-n25 {
  margin-right: -13.5rem !important;
}

.mb-n25,
.my-n25 {
  margin-bottom: -13.5rem !important;
}

.ml-n25,
.mx-n25 {
  margin-left: -13.5rem !important;
}

.m-n26 {
  margin: -14rem !important;
}

.mt-n26,
.my-n26 {
  margin-top: -14rem !important;
}

.mr-n26,
.mx-n26 {
  margin-right: -14rem !important;
}

.mb-n26,
.my-n26 {
  margin-bottom: -14rem !important;
}

.ml-n26,
.mx-n26 {
  margin-left: -14rem !important;
}

.m-n27 {
  margin: -15rem !important;
}

.mt-n27,
.my-n27 {
  margin-top: -15rem !important;
}

.mr-n27,
.mx-n27 {
  margin-right: -15rem !important;
}

.mb-n27,
.my-n27 {
  margin-bottom: -15rem !important;
}

.ml-n27,
.mx-n27 {
  margin-left: -15rem !important;
}

.m-n28 {
  margin: -16rem !important;
}

.mt-n28,
.my-n28 {
  margin-top: -16rem !important;
}

.mr-n28,
.mx-n28 {
  margin-right: -16rem !important;
}

.mb-n28,
.my-n28 {
  margin-bottom: -16rem !important;
}

.ml-n28,
.mx-n28 {
  margin-left: -16rem !important;
}

.m-n29 {
  margin: -17rem !important;
}

.mt-n29,
.my-n29 {
  margin-top: -17rem !important;
}

.mr-n29,
.mx-n29 {
  margin-right: -17rem !important;
}

.mb-n29,
.my-n29 {
  margin-bottom: -17rem !important;
}

.ml-n29,
.mx-n29 {
  margin-left: -17rem !important;
}

.m-n30 {
  margin: -18rem !important;
}

.mt-n30,
.my-n30 {
  margin-top: -18rem !important;
}

.mr-n30,
.mx-n30 {
  margin-right: -18rem !important;
}

.mb-n30,
.my-n30 {
  margin-bottom: -18rem !important;
}

.ml-n30,
.mx-n30 {
  margin-left: -18rem !important;
}

.m-n31 {
  margin: -19rem !important;
}

.mt-n31,
.my-n31 {
  margin-top: -19rem !important;
}

.mr-n31,
.mx-n31 {
  margin-right: -19rem !important;
}

.mb-n31,
.my-n31 {
  margin-bottom: -19rem !important;
}

.ml-n31,
.mx-n31 {
  margin-left: -19rem !important;
}

.m-n32 {
  margin: -20rem !important;
}

.mt-n32,
.my-n32 {
  margin-top: -20rem !important;
}

.mr-n32,
.mx-n32 {
  margin-right: -20rem !important;
}

.mb-n32,
.my-n32 {
  margin-bottom: -20rem !important;
}

.ml-n32,
.mx-n32 {
  margin-left: -20rem !important;
}

.m-n37 {
  margin: -22.5rem !important;
}

.mt-n37,
.my-n37 {
  margin-top: -22.5rem !important;
}

.mr-n37,
.mx-n37 {
  margin-right: -22.5rem !important;
}

.mb-n37,
.my-n37 {
  margin-bottom: -22.5rem !important;
}

.ml-n37,
.mx-n37 {
  margin-left: -22.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 2rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 2rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 2rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 2rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 2rem !important;
  }
  .m-sm-6 {
    margin: 2.5rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 2.5rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 2.5rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 2.5rem !important;
  }
  .m-sm-7 {
    margin: 3rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 3rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 3rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 3rem !important;
  }
  .m-sm-8 {
    margin: 3.5rem !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 3.5rem !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 3.5rem !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 3.5rem !important;
  }
  .m-sm-9 {
    margin: 4rem !important;
  }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 4rem !important;
  }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 4rem !important;
  }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 4rem !important;
  }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 4rem !important;
  }
  .m-sm-10 {
    margin: 4.5rem !important;
  }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 4.5rem !important;
  }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 4.5rem !important;
  }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 4.5rem !important;
  }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 4.5rem !important;
  }
  .m-sm-11 {
    margin: 5rem !important;
  }
  .mt-sm-11,
  .my-sm-11 {
    margin-top: 5rem !important;
  }
  .mr-sm-11,
  .mx-sm-11 {
    margin-right: 5rem !important;
  }
  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 5rem !important;
  }
  .ml-sm-11,
  .mx-sm-11 {
    margin-left: 5rem !important;
  }
  .m-sm-12 {
    margin: 5.5rem !important;
  }
  .mt-sm-12,
  .my-sm-12 {
    margin-top: 5.5rem !important;
  }
  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 5.5rem !important;
  }
  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 5.5rem !important;
  }
  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 5.5rem !important;
  }
  .m-sm-13 {
    margin: 6rem !important;
  }
  .mt-sm-13,
  .my-sm-13 {
    margin-top: 6rem !important;
  }
  .mr-sm-13,
  .mx-sm-13 {
    margin-right: 6rem !important;
  }
  .mb-sm-13,
  .my-sm-13 {
    margin-bottom: 6rem !important;
  }
  .ml-sm-13,
  .mx-sm-13 {
    margin-left: 6rem !important;
  }
  .m-sm-14 {
    margin: 6.5rem !important;
  }
  .mt-sm-14,
  .my-sm-14 {
    margin-top: 6.5rem !important;
  }
  .mr-sm-14,
  .mx-sm-14 {
    margin-right: 6.5rem !important;
  }
  .mb-sm-14,
  .my-sm-14 {
    margin-bottom: 6.5rem !important;
  }
  .ml-sm-14,
  .mx-sm-14 {
    margin-left: 6.5rem !important;
  }
  .m-sm-15 {
    margin: 7rem !important;
  }
  .mt-sm-15,
  .my-sm-15 {
    margin-top: 7rem !important;
  }
  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 7rem !important;
  }
  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 7rem !important;
  }
  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 7rem !important;
  }
  .m-sm-16 {
    margin: 7.5rem !important;
  }
  .mt-sm-16,
  .my-sm-16 {
    margin-top: 7.5rem !important;
  }
  .mr-sm-16,
  .mx-sm-16 {
    margin-right: 7.5rem !important;
  }
  .mb-sm-16,
  .my-sm-16 {
    margin-bottom: 7.5rem !important;
  }
  .ml-sm-16,
  .mx-sm-16 {
    margin-left: 7.5rem !important;
  }
  .m-sm-17 {
    margin: 8rem !important;
  }
  .mt-sm-17,
  .my-sm-17 {
    margin-top: 8rem !important;
  }
  .mr-sm-17,
  .mx-sm-17 {
    margin-right: 8rem !important;
  }
  .mb-sm-17,
  .my-sm-17 {
    margin-bottom: 8rem !important;
  }
  .ml-sm-17,
  .mx-sm-17 {
    margin-left: 8rem !important;
  }
  .m-sm-18 {
    margin: 8.5rem !important;
  }
  .mt-sm-18,
  .my-sm-18 {
    margin-top: 8.5rem !important;
  }
  .mr-sm-18,
  .mx-sm-18 {
    margin-right: 8.5rem !important;
  }
  .mb-sm-18,
  .my-sm-18 {
    margin-bottom: 8.5rem !important;
  }
  .ml-sm-18,
  .mx-sm-18 {
    margin-left: 8.5rem !important;
  }
  .m-sm-19 {
    margin: 9rem !important;
  }
  .mt-sm-19,
  .my-sm-19 {
    margin-top: 9rem !important;
  }
  .mr-sm-19,
  .mx-sm-19 {
    margin-right: 9rem !important;
  }
  .mb-sm-19,
  .my-sm-19 {
    margin-bottom: 9rem !important;
  }
  .ml-sm-19,
  .mx-sm-19 {
    margin-left: 9rem !important;
  }
  .m-sm-20 {
    margin: 9.5rem !important;
  }
  .mt-sm-20,
  .my-sm-20 {
    margin-top: 9.5rem !important;
  }
  .mr-sm-20,
  .mx-sm-20 {
    margin-right: 9.5rem !important;
  }
  .mb-sm-20,
  .my-sm-20 {
    margin-bottom: 9.5rem !important;
  }
  .ml-sm-20,
  .mx-sm-20 {
    margin-left: 9.5rem !important;
  }
  .m-sm-21 {
    margin: 10rem !important;
  }
  .mt-sm-21,
  .my-sm-21 {
    margin-top: 10rem !important;
  }
  .mr-sm-21,
  .mx-sm-21 {
    margin-right: 10rem !important;
  }
  .mb-sm-21,
  .my-sm-21 {
    margin-bottom: 10rem !important;
  }
  .ml-sm-21,
  .mx-sm-21 {
    margin-left: 10rem !important;
  }
  .m-sm-22 {
    margin: 11rem !important;
  }
  .mt-sm-22,
  .my-sm-22 {
    margin-top: 11rem !important;
  }
  .mr-sm-22,
  .mx-sm-22 {
    margin-right: 11rem !important;
  }
  .mb-sm-22,
  .my-sm-22 {
    margin-bottom: 11rem !important;
  }
  .ml-sm-22,
  .mx-sm-22 {
    margin-left: 11rem !important;
  }
  .m-sm-23 {
    margin: 12rem !important;
  }
  .mt-sm-23,
  .my-sm-23 {
    margin-top: 12rem !important;
  }
  .mr-sm-23,
  .mx-sm-23 {
    margin-right: 12rem !important;
  }
  .mb-sm-23,
  .my-sm-23 {
    margin-bottom: 12rem !important;
  }
  .ml-sm-23,
  .mx-sm-23 {
    margin-left: 12rem !important;
  }
  .m-sm-24 {
    margin: 13rem !important;
  }
  .mt-sm-24,
  .my-sm-24 {
    margin-top: 13rem !important;
  }
  .mr-sm-24,
  .mx-sm-24 {
    margin-right: 13rem !important;
  }
  .mb-sm-24,
  .my-sm-24 {
    margin-bottom: 13rem !important;
  }
  .ml-sm-24,
  .mx-sm-24 {
    margin-left: 13rem !important;
  }
  .m-sm-25 {
    margin: 13.5rem !important;
  }
  .mt-sm-25,
  .my-sm-25 {
    margin-top: 13.5rem !important;
  }
  .mr-sm-25,
  .mx-sm-25 {
    margin-right: 13.5rem !important;
  }
  .mb-sm-25,
  .my-sm-25 {
    margin-bottom: 13.5rem !important;
  }
  .ml-sm-25,
  .mx-sm-25 {
    margin-left: 13.5rem !important;
  }
  .m-sm-26 {
    margin: 14rem !important;
  }
  .mt-sm-26,
  .my-sm-26 {
    margin-top: 14rem !important;
  }
  .mr-sm-26,
  .mx-sm-26 {
    margin-right: 14rem !important;
  }
  .mb-sm-26,
  .my-sm-26 {
    margin-bottom: 14rem !important;
  }
  .ml-sm-26,
  .mx-sm-26 {
    margin-left: 14rem !important;
  }
  .m-sm-27 {
    margin: 15rem !important;
  }
  .mt-sm-27,
  .my-sm-27 {
    margin-top: 15rem !important;
  }
  .mr-sm-27,
  .mx-sm-27 {
    margin-right: 15rem !important;
  }
  .mb-sm-27,
  .my-sm-27 {
    margin-bottom: 15rem !important;
  }
  .ml-sm-27,
  .mx-sm-27 {
    margin-left: 15rem !important;
  }
  .m-sm-28 {
    margin: 16rem !important;
  }
  .mt-sm-28,
  .my-sm-28 {
    margin-top: 16rem !important;
  }
  .mr-sm-28,
  .mx-sm-28 {
    margin-right: 16rem !important;
  }
  .mb-sm-28,
  .my-sm-28 {
    margin-bottom: 16rem !important;
  }
  .ml-sm-28,
  .mx-sm-28 {
    margin-left: 16rem !important;
  }
  .m-sm-29 {
    margin: 17rem !important;
  }
  .mt-sm-29,
  .my-sm-29 {
    margin-top: 17rem !important;
  }
  .mr-sm-29,
  .mx-sm-29 {
    margin-right: 17rem !important;
  }
  .mb-sm-29,
  .my-sm-29 {
    margin-bottom: 17rem !important;
  }
  .ml-sm-29,
  .mx-sm-29 {
    margin-left: 17rem !important;
  }
  .m-sm-30 {
    margin: 18rem !important;
  }
  .mt-sm-30,
  .my-sm-30 {
    margin-top: 18rem !important;
  }
  .mr-sm-30,
  .mx-sm-30 {
    margin-right: 18rem !important;
  }
  .mb-sm-30,
  .my-sm-30 {
    margin-bottom: 18rem !important;
  }
  .ml-sm-30,
  .mx-sm-30 {
    margin-left: 18rem !important;
  }
  .m-sm-31 {
    margin: 19rem !important;
  }
  .mt-sm-31,
  .my-sm-31 {
    margin-top: 19rem !important;
  }
  .mr-sm-31,
  .mx-sm-31 {
    margin-right: 19rem !important;
  }
  .mb-sm-31,
  .my-sm-31 {
    margin-bottom: 19rem !important;
  }
  .ml-sm-31,
  .mx-sm-31 {
    margin-left: 19rem !important;
  }
  .m-sm-32 {
    margin: 20rem !important;
  }
  .mt-sm-32,
  .my-sm-32 {
    margin-top: 20rem !important;
  }
  .mr-sm-32,
  .mx-sm-32 {
    margin-right: 20rem !important;
  }
  .mb-sm-32,
  .my-sm-32 {
    margin-bottom: 20rem !important;
  }
  .ml-sm-32,
  .mx-sm-32 {
    margin-left: 20rem !important;
  }
  .m-sm-37 {
    margin: 22.5rem !important;
  }
  .mt-sm-37,
  .my-sm-37 {
    margin-top: 22.5rem !important;
  }
  .mr-sm-37,
  .mx-sm-37 {
    margin-right: 22.5rem !important;
  }
  .mb-sm-37,
  .my-sm-37 {
    margin-bottom: 22.5rem !important;
  }
  .ml-sm-37,
  .mx-sm-37 {
    margin-left: 22.5rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 2rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 2rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 2rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 2rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 2rem !important;
  }
  .p-sm-6 {
    padding: 2.5rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 2.5rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 2.5rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 2.5rem !important;
  }
  .p-sm-7 {
    padding: 3rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 3rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 3rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 3rem !important;
  }
  .p-sm-8 {
    padding: 3.5rem !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 3.5rem !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 3.5rem !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 3.5rem !important;
  }
  .p-sm-9 {
    padding: 4rem !important;
  }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 4rem !important;
  }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 4rem !important;
  }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 4rem !important;
  }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 4rem !important;
  }
  .p-sm-10 {
    padding: 4.5rem !important;
  }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 4.5rem !important;
  }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 4.5rem !important;
  }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 4.5rem !important;
  }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 4.5rem !important;
  }
  .p-sm-11 {
    padding: 5rem !important;
  }
  .pt-sm-11,
  .py-sm-11 {
    padding-top: 5rem !important;
  }
  .pr-sm-11,
  .px-sm-11 {
    padding-right: 5rem !important;
  }
  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 5rem !important;
  }
  .pl-sm-11,
  .px-sm-11 {
    padding-left: 5rem !important;
  }
  .p-sm-12 {
    padding: 5.5rem !important;
  }
  .pt-sm-12,
  .py-sm-12 {
    padding-top: 5.5rem !important;
  }
  .pr-sm-12,
  .px-sm-12 {
    padding-right: 5.5rem !important;
  }
  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 5.5rem !important;
  }
  .pl-sm-12,
  .px-sm-12 {
    padding-left: 5.5rem !important;
  }
  .p-sm-13 {
    padding: 6rem !important;
  }
  .pt-sm-13,
  .py-sm-13 {
    padding-top: 6rem !important;
  }
  .pr-sm-13,
  .px-sm-13 {
    padding-right: 6rem !important;
  }
  .pb-sm-13,
  .py-sm-13 {
    padding-bottom: 6rem !important;
  }
  .pl-sm-13,
  .px-sm-13 {
    padding-left: 6rem !important;
  }
  .p-sm-14 {
    padding: 6.5rem !important;
  }
  .pt-sm-14,
  .py-sm-14 {
    padding-top: 6.5rem !important;
  }
  .pr-sm-14,
  .px-sm-14 {
    padding-right: 6.5rem !important;
  }
  .pb-sm-14,
  .py-sm-14 {
    padding-bottom: 6.5rem !important;
  }
  .pl-sm-14,
  .px-sm-14 {
    padding-left: 6.5rem !important;
  }
  .p-sm-15 {
    padding: 7rem !important;
  }
  .pt-sm-15,
  .py-sm-15 {
    padding-top: 7rem !important;
  }
  .pr-sm-15,
  .px-sm-15 {
    padding-right: 7rem !important;
  }
  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 7rem !important;
  }
  .pl-sm-15,
  .px-sm-15 {
    padding-left: 7rem !important;
  }
  .p-sm-16 {
    padding: 7.5rem !important;
  }
  .pt-sm-16,
  .py-sm-16 {
    padding-top: 7.5rem !important;
  }
  .pr-sm-16,
  .px-sm-16 {
    padding-right: 7.5rem !important;
  }
  .pb-sm-16,
  .py-sm-16 {
    padding-bottom: 7.5rem !important;
  }
  .pl-sm-16,
  .px-sm-16 {
    padding-left: 7.5rem !important;
  }
  .p-sm-17 {
    padding: 8rem !important;
  }
  .pt-sm-17,
  .py-sm-17 {
    padding-top: 8rem !important;
  }
  .pr-sm-17,
  .px-sm-17 {
    padding-right: 8rem !important;
  }
  .pb-sm-17,
  .py-sm-17 {
    padding-bottom: 8rem !important;
  }
  .pl-sm-17,
  .px-sm-17 {
    padding-left: 8rem !important;
  }
  .p-sm-18 {
    padding: 8.5rem !important;
  }
  .pt-sm-18,
  .py-sm-18 {
    padding-top: 8.5rem !important;
  }
  .pr-sm-18,
  .px-sm-18 {
    padding-right: 8.5rem !important;
  }
  .pb-sm-18,
  .py-sm-18 {
    padding-bottom: 8.5rem !important;
  }
  .pl-sm-18,
  .px-sm-18 {
    padding-left: 8.5rem !important;
  }
  .p-sm-19 {
    padding: 9rem !important;
  }
  .pt-sm-19,
  .py-sm-19 {
    padding-top: 9rem !important;
  }
  .pr-sm-19,
  .px-sm-19 {
    padding-right: 9rem !important;
  }
  .pb-sm-19,
  .py-sm-19 {
    padding-bottom: 9rem !important;
  }
  .pl-sm-19,
  .px-sm-19 {
    padding-left: 9rem !important;
  }
  .p-sm-20 {
    padding: 9.5rem !important;
  }
  .pt-sm-20,
  .py-sm-20 {
    padding-top: 9.5rem !important;
  }
  .pr-sm-20,
  .px-sm-20 {
    padding-right: 9.5rem !important;
  }
  .pb-sm-20,
  .py-sm-20 {
    padding-bottom: 9.5rem !important;
  }
  .pl-sm-20,
  .px-sm-20 {
    padding-left: 9.5rem !important;
  }
  .p-sm-21 {
    padding: 10rem !important;
  }
  .pt-sm-21,
  .py-sm-21 {
    padding-top: 10rem !important;
  }
  .pr-sm-21,
  .px-sm-21 {
    padding-right: 10rem !important;
  }
  .pb-sm-21,
  .py-sm-21 {
    padding-bottom: 10rem !important;
  }
  .pl-sm-21,
  .px-sm-21 {
    padding-left: 10rem !important;
  }
  .p-sm-22 {
    padding: 11rem !important;
  }
  .pt-sm-22,
  .py-sm-22 {
    padding-top: 11rem !important;
  }
  .pr-sm-22,
  .px-sm-22 {
    padding-right: 11rem !important;
  }
  .pb-sm-22,
  .py-sm-22 {
    padding-bottom: 11rem !important;
  }
  .pl-sm-22,
  .px-sm-22 {
    padding-left: 11rem !important;
  }
  .p-sm-23 {
    padding: 12rem !important;
  }
  .pt-sm-23,
  .py-sm-23 {
    padding-top: 12rem !important;
  }
  .pr-sm-23,
  .px-sm-23 {
    padding-right: 12rem !important;
  }
  .pb-sm-23,
  .py-sm-23 {
    padding-bottom: 12rem !important;
  }
  .pl-sm-23,
  .px-sm-23 {
    padding-left: 12rem !important;
  }
  .p-sm-24 {
    padding: 13rem !important;
  }
  .pt-sm-24,
  .py-sm-24 {
    padding-top: 13rem !important;
  }
  .pr-sm-24,
  .px-sm-24 {
    padding-right: 13rem !important;
  }
  .pb-sm-24,
  .py-sm-24 {
    padding-bottom: 13rem !important;
  }
  .pl-sm-24,
  .px-sm-24 {
    padding-left: 13rem !important;
  }
  .p-sm-25 {
    padding: 13.5rem !important;
  }
  .pt-sm-25,
  .py-sm-25 {
    padding-top: 13.5rem !important;
  }
  .pr-sm-25,
  .px-sm-25 {
    padding-right: 13.5rem !important;
  }
  .pb-sm-25,
  .py-sm-25 {
    padding-bottom: 13.5rem !important;
  }
  .pl-sm-25,
  .px-sm-25 {
    padding-left: 13.5rem !important;
  }
  .p-sm-26 {
    padding: 14rem !important;
  }
  .pt-sm-26,
  .py-sm-26 {
    padding-top: 14rem !important;
  }
  .pr-sm-26,
  .px-sm-26 {
    padding-right: 14rem !important;
  }
  .pb-sm-26,
  .py-sm-26 {
    padding-bottom: 14rem !important;
  }
  .pl-sm-26,
  .px-sm-26 {
    padding-left: 14rem !important;
  }
  .p-sm-27 {
    padding: 15rem !important;
  }
  .pt-sm-27,
  .py-sm-27 {
    padding-top: 15rem !important;
  }
  .pr-sm-27,
  .px-sm-27 {
    padding-right: 15rem !important;
  }
  .pb-sm-27,
  .py-sm-27 {
    padding-bottom: 15rem !important;
  }
  .pl-sm-27,
  .px-sm-27 {
    padding-left: 15rem !important;
  }
  .p-sm-28 {
    padding: 16rem !important;
  }
  .pt-sm-28,
  .py-sm-28 {
    padding-top: 16rem !important;
  }
  .pr-sm-28,
  .px-sm-28 {
    padding-right: 16rem !important;
  }
  .pb-sm-28,
  .py-sm-28 {
    padding-bottom: 16rem !important;
  }
  .pl-sm-28,
  .px-sm-28 {
    padding-left: 16rem !important;
  }
  .p-sm-29 {
    padding: 17rem !important;
  }
  .pt-sm-29,
  .py-sm-29 {
    padding-top: 17rem !important;
  }
  .pr-sm-29,
  .px-sm-29 {
    padding-right: 17rem !important;
  }
  .pb-sm-29,
  .py-sm-29 {
    padding-bottom: 17rem !important;
  }
  .pl-sm-29,
  .px-sm-29 {
    padding-left: 17rem !important;
  }
  .p-sm-30 {
    padding: 18rem !important;
  }
  .pt-sm-30,
  .py-sm-30 {
    padding-top: 18rem !important;
  }
  .pr-sm-30,
  .px-sm-30 {
    padding-right: 18rem !important;
  }
  .pb-sm-30,
  .py-sm-30 {
    padding-bottom: 18rem !important;
  }
  .pl-sm-30,
  .px-sm-30 {
    padding-left: 18rem !important;
  }
  .p-sm-31 {
    padding: 19rem !important;
  }
  .pt-sm-31,
  .py-sm-31 {
    padding-top: 19rem !important;
  }
  .pr-sm-31,
  .px-sm-31 {
    padding-right: 19rem !important;
  }
  .pb-sm-31,
  .py-sm-31 {
    padding-bottom: 19rem !important;
  }
  .pl-sm-31,
  .px-sm-31 {
    padding-left: 19rem !important;
  }
  .p-sm-32 {
    padding: 20rem !important;
  }
  .pt-sm-32,
  .py-sm-32 {
    padding-top: 20rem !important;
  }
  .pr-sm-32,
  .px-sm-32 {
    padding-right: 20rem !important;
  }
  .pb-sm-32,
  .py-sm-32 {
    padding-bottom: 20rem !important;
  }
  .pl-sm-32,
  .px-sm-32 {
    padding-left: 20rem !important;
  }
  .p-sm-37 {
    padding: 22.5rem !important;
  }
  .pt-sm-37,
  .py-sm-37 {
    padding-top: 22.5rem !important;
  }
  .pr-sm-37,
  .px-sm-37 {
    padding-right: 22.5rem !important;
  }
  .pb-sm-37,
  .py-sm-37 {
    padding-bottom: 22.5rem !important;
  }
  .pl-sm-37,
  .px-sm-37 {
    padding-left: 22.5rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -2rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -2rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -2rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -2rem !important;
  }
  .m-sm-n6 {
    margin: -2.5rem !important;
  }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -2.5rem !important;
  }
  .m-sm-n7 {
    margin: -3rem !important;
  }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -3rem !important;
  }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -3rem !important;
  }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -3rem !important;
  }
  .m-sm-n8 {
    margin: -3.5rem !important;
  }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -3.5rem !important;
  }
  .m-sm-n9 {
    margin: -4rem !important;
  }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -4rem !important;
  }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -4rem !important;
  }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -4rem !important;
  }
  .m-sm-n10 {
    margin: -4.5rem !important;
  }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -4.5rem !important;
  }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -4.5rem !important;
  }
  .m-sm-n11 {
    margin: -5rem !important;
  }
  .mt-sm-n11,
  .my-sm-n11 {
    margin-top: -5rem !important;
  }
  .mr-sm-n11,
  .mx-sm-n11 {
    margin-right: -5rem !important;
  }
  .mb-sm-n11,
  .my-sm-n11 {
    margin-bottom: -5rem !important;
  }
  .ml-sm-n11,
  .mx-sm-n11 {
    margin-left: -5rem !important;
  }
  .m-sm-n12 {
    margin: -5.5rem !important;
  }
  .mt-sm-n12,
  .my-sm-n12 {
    margin-top: -5.5rem !important;
  }
  .mr-sm-n12,
  .mx-sm-n12 {
    margin-right: -5.5rem !important;
  }
  .mb-sm-n12,
  .my-sm-n12 {
    margin-bottom: -5.5rem !important;
  }
  .ml-sm-n12,
  .mx-sm-n12 {
    margin-left: -5.5rem !important;
  }
  .m-sm-n13 {
    margin: -6rem !important;
  }
  .mt-sm-n13,
  .my-sm-n13 {
    margin-top: -6rem !important;
  }
  .mr-sm-n13,
  .mx-sm-n13 {
    margin-right: -6rem !important;
  }
  .mb-sm-n13,
  .my-sm-n13 {
    margin-bottom: -6rem !important;
  }
  .ml-sm-n13,
  .mx-sm-n13 {
    margin-left: -6rem !important;
  }
  .m-sm-n14 {
    margin: -6.5rem !important;
  }
  .mt-sm-n14,
  .my-sm-n14 {
    margin-top: -6.5rem !important;
  }
  .mr-sm-n14,
  .mx-sm-n14 {
    margin-right: -6.5rem !important;
  }
  .mb-sm-n14,
  .my-sm-n14 {
    margin-bottom: -6.5rem !important;
  }
  .ml-sm-n14,
  .mx-sm-n14 {
    margin-left: -6.5rem !important;
  }
  .m-sm-n15 {
    margin: -7rem !important;
  }
  .mt-sm-n15,
  .my-sm-n15 {
    margin-top: -7rem !important;
  }
  .mr-sm-n15,
  .mx-sm-n15 {
    margin-right: -7rem !important;
  }
  .mb-sm-n15,
  .my-sm-n15 {
    margin-bottom: -7rem !important;
  }
  .ml-sm-n15,
  .mx-sm-n15 {
    margin-left: -7rem !important;
  }
  .m-sm-n16 {
    margin: -7.5rem !important;
  }
  .mt-sm-n16,
  .my-sm-n16 {
    margin-top: -7.5rem !important;
  }
  .mr-sm-n16,
  .mx-sm-n16 {
    margin-right: -7.5rem !important;
  }
  .mb-sm-n16,
  .my-sm-n16 {
    margin-bottom: -7.5rem !important;
  }
  .ml-sm-n16,
  .mx-sm-n16 {
    margin-left: -7.5rem !important;
  }
  .m-sm-n17 {
    margin: -8rem !important;
  }
  .mt-sm-n17,
  .my-sm-n17 {
    margin-top: -8rem !important;
  }
  .mr-sm-n17,
  .mx-sm-n17 {
    margin-right: -8rem !important;
  }
  .mb-sm-n17,
  .my-sm-n17 {
    margin-bottom: -8rem !important;
  }
  .ml-sm-n17,
  .mx-sm-n17 {
    margin-left: -8rem !important;
  }
  .m-sm-n18 {
    margin: -8.5rem !important;
  }
  .mt-sm-n18,
  .my-sm-n18 {
    margin-top: -8.5rem !important;
  }
  .mr-sm-n18,
  .mx-sm-n18 {
    margin-right: -8.5rem !important;
  }
  .mb-sm-n18,
  .my-sm-n18 {
    margin-bottom: -8.5rem !important;
  }
  .ml-sm-n18,
  .mx-sm-n18 {
    margin-left: -8.5rem !important;
  }
  .m-sm-n19 {
    margin: -9rem !important;
  }
  .mt-sm-n19,
  .my-sm-n19 {
    margin-top: -9rem !important;
  }
  .mr-sm-n19,
  .mx-sm-n19 {
    margin-right: -9rem !important;
  }
  .mb-sm-n19,
  .my-sm-n19 {
    margin-bottom: -9rem !important;
  }
  .ml-sm-n19,
  .mx-sm-n19 {
    margin-left: -9rem !important;
  }
  .m-sm-n20 {
    margin: -9.5rem !important;
  }
  .mt-sm-n20,
  .my-sm-n20 {
    margin-top: -9.5rem !important;
  }
  .mr-sm-n20,
  .mx-sm-n20 {
    margin-right: -9.5rem !important;
  }
  .mb-sm-n20,
  .my-sm-n20 {
    margin-bottom: -9.5rem !important;
  }
  .ml-sm-n20,
  .mx-sm-n20 {
    margin-left: -9.5rem !important;
  }
  .m-sm-n21 {
    margin: -10rem !important;
  }
  .mt-sm-n21,
  .my-sm-n21 {
    margin-top: -10rem !important;
  }
  .mr-sm-n21,
  .mx-sm-n21 {
    margin-right: -10rem !important;
  }
  .mb-sm-n21,
  .my-sm-n21 {
    margin-bottom: -10rem !important;
  }
  .ml-sm-n21,
  .mx-sm-n21 {
    margin-left: -10rem !important;
  }
  .m-sm-n22 {
    margin: -11rem !important;
  }
  .mt-sm-n22,
  .my-sm-n22 {
    margin-top: -11rem !important;
  }
  .mr-sm-n22,
  .mx-sm-n22 {
    margin-right: -11rem !important;
  }
  .mb-sm-n22,
  .my-sm-n22 {
    margin-bottom: -11rem !important;
  }
  .ml-sm-n22,
  .mx-sm-n22 {
    margin-left: -11rem !important;
  }
  .m-sm-n23 {
    margin: -12rem !important;
  }
  .mt-sm-n23,
  .my-sm-n23 {
    margin-top: -12rem !important;
  }
  .mr-sm-n23,
  .mx-sm-n23 {
    margin-right: -12rem !important;
  }
  .mb-sm-n23,
  .my-sm-n23 {
    margin-bottom: -12rem !important;
  }
  .ml-sm-n23,
  .mx-sm-n23 {
    margin-left: -12rem !important;
  }
  .m-sm-n24 {
    margin: -13rem !important;
  }
  .mt-sm-n24,
  .my-sm-n24 {
    margin-top: -13rem !important;
  }
  .mr-sm-n24,
  .mx-sm-n24 {
    margin-right: -13rem !important;
  }
  .mb-sm-n24,
  .my-sm-n24 {
    margin-bottom: -13rem !important;
  }
  .ml-sm-n24,
  .mx-sm-n24 {
    margin-left: -13rem !important;
  }
  .m-sm-n25 {
    margin: -13.5rem !important;
  }
  .mt-sm-n25,
  .my-sm-n25 {
    margin-top: -13.5rem !important;
  }
  .mr-sm-n25,
  .mx-sm-n25 {
    margin-right: -13.5rem !important;
  }
  .mb-sm-n25,
  .my-sm-n25 {
    margin-bottom: -13.5rem !important;
  }
  .ml-sm-n25,
  .mx-sm-n25 {
    margin-left: -13.5rem !important;
  }
  .m-sm-n26 {
    margin: -14rem !important;
  }
  .mt-sm-n26,
  .my-sm-n26 {
    margin-top: -14rem !important;
  }
  .mr-sm-n26,
  .mx-sm-n26 {
    margin-right: -14rem !important;
  }
  .mb-sm-n26,
  .my-sm-n26 {
    margin-bottom: -14rem !important;
  }
  .ml-sm-n26,
  .mx-sm-n26 {
    margin-left: -14rem !important;
  }
  .m-sm-n27 {
    margin: -15rem !important;
  }
  .mt-sm-n27,
  .my-sm-n27 {
    margin-top: -15rem !important;
  }
  .mr-sm-n27,
  .mx-sm-n27 {
    margin-right: -15rem !important;
  }
  .mb-sm-n27,
  .my-sm-n27 {
    margin-bottom: -15rem !important;
  }
  .ml-sm-n27,
  .mx-sm-n27 {
    margin-left: -15rem !important;
  }
  .m-sm-n28 {
    margin: -16rem !important;
  }
  .mt-sm-n28,
  .my-sm-n28 {
    margin-top: -16rem !important;
  }
  .mr-sm-n28,
  .mx-sm-n28 {
    margin-right: -16rem !important;
  }
  .mb-sm-n28,
  .my-sm-n28 {
    margin-bottom: -16rem !important;
  }
  .ml-sm-n28,
  .mx-sm-n28 {
    margin-left: -16rem !important;
  }
  .m-sm-n29 {
    margin: -17rem !important;
  }
  .mt-sm-n29,
  .my-sm-n29 {
    margin-top: -17rem !important;
  }
  .mr-sm-n29,
  .mx-sm-n29 {
    margin-right: -17rem !important;
  }
  .mb-sm-n29,
  .my-sm-n29 {
    margin-bottom: -17rem !important;
  }
  .ml-sm-n29,
  .mx-sm-n29 {
    margin-left: -17rem !important;
  }
  .m-sm-n30 {
    margin: -18rem !important;
  }
  .mt-sm-n30,
  .my-sm-n30 {
    margin-top: -18rem !important;
  }
  .mr-sm-n30,
  .mx-sm-n30 {
    margin-right: -18rem !important;
  }
  .mb-sm-n30,
  .my-sm-n30 {
    margin-bottom: -18rem !important;
  }
  .ml-sm-n30,
  .mx-sm-n30 {
    margin-left: -18rem !important;
  }
  .m-sm-n31 {
    margin: -19rem !important;
  }
  .mt-sm-n31,
  .my-sm-n31 {
    margin-top: -19rem !important;
  }
  .mr-sm-n31,
  .mx-sm-n31 {
    margin-right: -19rem !important;
  }
  .mb-sm-n31,
  .my-sm-n31 {
    margin-bottom: -19rem !important;
  }
  .ml-sm-n31,
  .mx-sm-n31 {
    margin-left: -19rem !important;
  }
  .m-sm-n32 {
    margin: -20rem !important;
  }
  .mt-sm-n32,
  .my-sm-n32 {
    margin-top: -20rem !important;
  }
  .mr-sm-n32,
  .mx-sm-n32 {
    margin-right: -20rem !important;
  }
  .mb-sm-n32,
  .my-sm-n32 {
    margin-bottom: -20rem !important;
  }
  .ml-sm-n32,
  .mx-sm-n32 {
    margin-left: -20rem !important;
  }
  .m-sm-n37 {
    margin: -22.5rem !important;
  }
  .mt-sm-n37,
  .my-sm-n37 {
    margin-top: -22.5rem !important;
  }
  .mr-sm-n37,
  .mx-sm-n37 {
    margin-right: -22.5rem !important;
  }
  .mb-sm-n37,
  .my-sm-n37 {
    margin-bottom: -22.5rem !important;
  }
  .ml-sm-n37,
  .mx-sm-n37 {
    margin-left: -22.5rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 2rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 2rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 2rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 2rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 2rem !important;
  }
  .m-md-6 {
    margin: 2.5rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 2.5rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 2.5rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 2.5rem !important;
  }
  .m-md-7 {
    margin: 3rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 3rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 3rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 3rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 3rem !important;
  }
  .m-md-8 {
    margin: 3.5rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 3.5rem !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 3.5rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 3.5rem !important;
  }
  .m-md-9 {
    margin: 4rem !important;
  }
  .mt-md-9,
  .my-md-9 {
    margin-top: 4rem !important;
  }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 4rem !important;
  }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 4rem !important;
  }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 4rem !important;
  }
  .m-md-10 {
    margin: 4.5rem !important;
  }
  .mt-md-10,
  .my-md-10 {
    margin-top: 4.5rem !important;
  }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 4.5rem !important;
  }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 4.5rem !important;
  }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 4.5rem !important;
  }
  .m-md-11 {
    margin: 5rem !important;
  }
  .mt-md-11,
  .my-md-11 {
    margin-top: 5rem !important;
  }
  .mr-md-11,
  .mx-md-11 {
    margin-right: 5rem !important;
  }
  .mb-md-11,
  .my-md-11 {
    margin-bottom: 5rem !important;
  }
  .ml-md-11,
  .mx-md-11 {
    margin-left: 5rem !important;
  }
  .m-md-12 {
    margin: 5.5rem !important;
  }
  .mt-md-12,
  .my-md-12 {
    margin-top: 5.5rem !important;
  }
  .mr-md-12,
  .mx-md-12 {
    margin-right: 5.5rem !important;
  }
  .mb-md-12,
  .my-md-12 {
    margin-bottom: 5.5rem !important;
  }
  .ml-md-12,
  .mx-md-12 {
    margin-left: 5.5rem !important;
  }
  .m-md-13 {
    margin: 6rem !important;
  }
  .mt-md-13,
  .my-md-13 {
    margin-top: 6rem !important;
  }
  .mr-md-13,
  .mx-md-13 {
    margin-right: 6rem !important;
  }
  .mb-md-13,
  .my-md-13 {
    margin-bottom: 6rem !important;
  }
  .ml-md-13,
  .mx-md-13 {
    margin-left: 6rem !important;
  }
  .m-md-14 {
    margin: 6.5rem !important;
  }
  .mt-md-14,
  .my-md-14 {
    margin-top: 6.5rem !important;
  }
  .mr-md-14,
  .mx-md-14 {
    margin-right: 6.5rem !important;
  }
  .mb-md-14,
  .my-md-14 {
    margin-bottom: 6.5rem !important;
  }
  .ml-md-14,
  .mx-md-14 {
    margin-left: 6.5rem !important;
  }
  .m-md-15 {
    margin: 7rem !important;
  }
  .mt-md-15,
  .my-md-15 {
    margin-top: 7rem !important;
  }
  .mr-md-15,
  .mx-md-15 {
    margin-right: 7rem !important;
  }
  .mb-md-15,
  .my-md-15 {
    margin-bottom: 7rem !important;
  }
  .ml-md-15,
  .mx-md-15 {
    margin-left: 7rem !important;
  }
  .m-md-16 {
    margin: 7.5rem !important;
  }
  .mt-md-16,
  .my-md-16 {
    margin-top: 7.5rem !important;
  }
  .mr-md-16,
  .mx-md-16 {
    margin-right: 7.5rem !important;
  }
  .mb-md-16,
  .my-md-16 {
    margin-bottom: 7.5rem !important;
  }
  .ml-md-16,
  .mx-md-16 {
    margin-left: 7.5rem !important;
  }
  .m-md-17 {
    margin: 8rem !important;
  }
  .mt-md-17,
  .my-md-17 {
    margin-top: 8rem !important;
  }
  .mr-md-17,
  .mx-md-17 {
    margin-right: 8rem !important;
  }
  .mb-md-17,
  .my-md-17 {
    margin-bottom: 8rem !important;
  }
  .ml-md-17,
  .mx-md-17 {
    margin-left: 8rem !important;
  }
  .m-md-18 {
    margin: 8.5rem !important;
  }
  .mt-md-18,
  .my-md-18 {
    margin-top: 8.5rem !important;
  }
  .mr-md-18,
  .mx-md-18 {
    margin-right: 8.5rem !important;
  }
  .mb-md-18,
  .my-md-18 {
    margin-bottom: 8.5rem !important;
  }
  .ml-md-18,
  .mx-md-18 {
    margin-left: 8.5rem !important;
  }
  .m-md-19 {
    margin: 9rem !important;
  }
  .mt-md-19,
  .my-md-19 {
    margin-top: 9rem !important;
  }
  .mr-md-19,
  .mx-md-19 {
    margin-right: 9rem !important;
  }
  .mb-md-19,
  .my-md-19 {
    margin-bottom: 9rem !important;
  }
  .ml-md-19,
  .mx-md-19 {
    margin-left: 9rem !important;
  }
  .m-md-20 {
    margin: 9.5rem !important;
  }
  .mt-md-20,
  .my-md-20 {
    margin-top: 9.5rem !important;
  }
  .mr-md-20,
  .mx-md-20 {
    margin-right: 9.5rem !important;
  }
  .mb-md-20,
  .my-md-20 {
    margin-bottom: 9.5rem !important;
  }
  .ml-md-20,
  .mx-md-20 {
    margin-left: 9.5rem !important;
  }
  .m-md-21 {
    margin: 10rem !important;
  }
  .mt-md-21,
  .my-md-21 {
    margin-top: 10rem !important;
  }
  .mr-md-21,
  .mx-md-21 {
    margin-right: 10rem !important;
  }
  .mb-md-21,
  .my-md-21 {
    margin-bottom: 10rem !important;
  }
  .ml-md-21,
  .mx-md-21 {
    margin-left: 10rem !important;
  }
  .m-md-22 {
    margin: 11rem !important;
  }
  .mt-md-22,
  .my-md-22 {
    margin-top: 11rem !important;
  }
  .mr-md-22,
  .mx-md-22 {
    margin-right: 11rem !important;
  }
  .mb-md-22,
  .my-md-22 {
    margin-bottom: 11rem !important;
  }
  .ml-md-22,
  .mx-md-22 {
    margin-left: 11rem !important;
  }
  .m-md-23 {
    margin: 12rem !important;
  }
  .mt-md-23,
  .my-md-23 {
    margin-top: 12rem !important;
  }
  .mr-md-23,
  .mx-md-23 {
    margin-right: 12rem !important;
  }
  .mb-md-23,
  .my-md-23 {
    margin-bottom: 12rem !important;
  }
  .ml-md-23,
  .mx-md-23 {
    margin-left: 12rem !important;
  }
  .m-md-24 {
    margin: 13rem !important;
  }
  .mt-md-24,
  .my-md-24 {
    margin-top: 13rem !important;
  }
  .mr-md-24,
  .mx-md-24 {
    margin-right: 13rem !important;
  }
  .mb-md-24,
  .my-md-24 {
    margin-bottom: 13rem !important;
  }
  .ml-md-24,
  .mx-md-24 {
    margin-left: 13rem !important;
  }
  .m-md-25 {
    margin: 13.5rem !important;
  }
  .mt-md-25,
  .my-md-25 {
    margin-top: 13.5rem !important;
  }
  .mr-md-25,
  .mx-md-25 {
    margin-right: 13.5rem !important;
  }
  .mb-md-25,
  .my-md-25 {
    margin-bottom: 13.5rem !important;
  }
  .ml-md-25,
  .mx-md-25 {
    margin-left: 13.5rem !important;
  }
  .m-md-26 {
    margin: 14rem !important;
  }
  .mt-md-26,
  .my-md-26 {
    margin-top: 14rem !important;
  }
  .mr-md-26,
  .mx-md-26 {
    margin-right: 14rem !important;
  }
  .mb-md-26,
  .my-md-26 {
    margin-bottom: 14rem !important;
  }
  .ml-md-26,
  .mx-md-26 {
    margin-left: 14rem !important;
  }
  .m-md-27 {
    margin: 15rem !important;
  }
  .mt-md-27,
  .my-md-27 {
    margin-top: 15rem !important;
  }
  .mr-md-27,
  .mx-md-27 {
    margin-right: 15rem !important;
  }
  .mb-md-27,
  .my-md-27 {
    margin-bottom: 15rem !important;
  }
  .ml-md-27,
  .mx-md-27 {
    margin-left: 15rem !important;
  }
  .m-md-28 {
    margin: 16rem !important;
  }
  .mt-md-28,
  .my-md-28 {
    margin-top: 16rem !important;
  }
  .mr-md-28,
  .mx-md-28 {
    margin-right: 16rem !important;
  }
  .mb-md-28,
  .my-md-28 {
    margin-bottom: 16rem !important;
  }
  .ml-md-28,
  .mx-md-28 {
    margin-left: 16rem !important;
  }
  .m-md-29 {
    margin: 17rem !important;
  }
  .mt-md-29,
  .my-md-29 {
    margin-top: 17rem !important;
  }
  .mr-md-29,
  .mx-md-29 {
    margin-right: 17rem !important;
  }
  .mb-md-29,
  .my-md-29 {
    margin-bottom: 17rem !important;
  }
  .ml-md-29,
  .mx-md-29 {
    margin-left: 17rem !important;
  }
  .m-md-30 {
    margin: 18rem !important;
  }
  .mt-md-30,
  .my-md-30 {
    margin-top: 18rem !important;
  }
  .mr-md-30,
  .mx-md-30 {
    margin-right: 18rem !important;
  }
  .mb-md-30,
  .my-md-30 {
    margin-bottom: 18rem !important;
  }
  .ml-md-30,
  .mx-md-30 {
    margin-left: 18rem !important;
  }
  .m-md-31 {
    margin: 19rem !important;
  }
  .mt-md-31,
  .my-md-31 {
    margin-top: 19rem !important;
  }
  .mr-md-31,
  .mx-md-31 {
    margin-right: 19rem !important;
  }
  .mb-md-31,
  .my-md-31 {
    margin-bottom: 19rem !important;
  }
  .ml-md-31,
  .mx-md-31 {
    margin-left: 19rem !important;
  }
  .m-md-32 {
    margin: 20rem !important;
  }
  .mt-md-32,
  .my-md-32 {
    margin-top: 20rem !important;
  }
  .mr-md-32,
  .mx-md-32 {
    margin-right: 20rem !important;
  }
  .mb-md-32,
  .my-md-32 {
    margin-bottom: 20rem !important;
  }
  .ml-md-32,
  .mx-md-32 {
    margin-left: 20rem !important;
  }
  .m-md-37 {
    margin: 22.5rem !important;
  }
  .mt-md-37,
  .my-md-37 {
    margin-top: 22.5rem !important;
  }
  .mr-md-37,
  .mx-md-37 {
    margin-right: 22.5rem !important;
  }
  .mb-md-37,
  .my-md-37 {
    margin-bottom: 22.5rem !important;
  }
  .ml-md-37,
  .mx-md-37 {
    margin-left: 22.5rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 2rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 2rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 2rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 2rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 2rem !important;
  }
  .p-md-6 {
    padding: 2.5rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 2.5rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 2.5rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 2.5rem !important;
  }
  .p-md-7 {
    padding: 3rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 3rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 3rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 3rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 3rem !important;
  }
  .p-md-8 {
    padding: 3.5rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 3.5rem !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 3.5rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 3.5rem !important;
  }
  .p-md-9 {
    padding: 4rem !important;
  }
  .pt-md-9,
  .py-md-9 {
    padding-top: 4rem !important;
  }
  .pr-md-9,
  .px-md-9 {
    padding-right: 4rem !important;
  }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 4rem !important;
  }
  .pl-md-9,
  .px-md-9 {
    padding-left: 4rem !important;
  }
  .p-md-10 {
    padding: 4.5rem !important;
  }
  .pt-md-10,
  .py-md-10 {
    padding-top: 4.5rem !important;
  }
  .pr-md-10,
  .px-md-10 {
    padding-right: 4.5rem !important;
  }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 4.5rem !important;
  }
  .pl-md-10,
  .px-md-10 {
    padding-left: 4.5rem !important;
  }
  .p-md-11 {
    padding: 5rem !important;
  }
  .pt-md-11,
  .py-md-11 {
    padding-top: 5rem !important;
  }
  .pr-md-11,
  .px-md-11 {
    padding-right: 5rem !important;
  }
  .pb-md-11,
  .py-md-11 {
    padding-bottom: 5rem !important;
  }
  .pl-md-11,
  .px-md-11 {
    padding-left: 5rem !important;
  }
  .p-md-12 {
    padding: 5.5rem !important;
  }
  .pt-md-12,
  .py-md-12 {
    padding-top: 5.5rem !important;
  }
  .pr-md-12,
  .px-md-12 {
    padding-right: 5.5rem !important;
  }
  .pb-md-12,
  .py-md-12 {
    padding-bottom: 5.5rem !important;
  }
  .pl-md-12,
  .px-md-12 {
    padding-left: 5.5rem !important;
  }
  .p-md-13 {
    padding: 6rem !important;
  }
  .pt-md-13,
  .py-md-13 {
    padding-top: 6rem !important;
  }
  .pr-md-13,
  .px-md-13 {
    padding-right: 6rem !important;
  }
  .pb-md-13,
  .py-md-13 {
    padding-bottom: 6rem !important;
  }
  .pl-md-13,
  .px-md-13 {
    padding-left: 6rem !important;
  }
  .p-md-14 {
    padding: 6.5rem !important;
  }
  .pt-md-14,
  .py-md-14 {
    padding-top: 6.5rem !important;
  }
  .pr-md-14,
  .px-md-14 {
    padding-right: 6.5rem !important;
  }
  .pb-md-14,
  .py-md-14 {
    padding-bottom: 6.5rem !important;
  }
  .pl-md-14,
  .px-md-14 {
    padding-left: 6.5rem !important;
  }
  .p-md-15 {
    padding: 7rem !important;
  }
  .pt-md-15,
  .py-md-15 {
    padding-top: 7rem !important;
  }
  .pr-md-15,
  .px-md-15 {
    padding-right: 7rem !important;
  }
  .pb-md-15,
  .py-md-15 {
    padding-bottom: 7rem !important;
  }
  .pl-md-15,
  .px-md-15 {
    padding-left: 7rem !important;
  }
  .p-md-16 {
    padding: 7.5rem !important;
  }
  .pt-md-16,
  .py-md-16 {
    padding-top: 7.5rem !important;
  }
  .pr-md-16,
  .px-md-16 {
    padding-right: 7.5rem !important;
  }
  .pb-md-16,
  .py-md-16 {
    padding-bottom: 7.5rem !important;
  }
  .pl-md-16,
  .px-md-16 {
    padding-left: 7.5rem !important;
  }
  .p-md-17 {
    padding: 8rem !important;
  }
  .pt-md-17,
  .py-md-17 {
    padding-top: 8rem !important;
  }
  .pr-md-17,
  .px-md-17 {
    padding-right: 8rem !important;
  }
  .pb-md-17,
  .py-md-17 {
    padding-bottom: 8rem !important;
  }
  .pl-md-17,
  .px-md-17 {
    padding-left: 8rem !important;
  }
  .p-md-18 {
    padding: 8.5rem !important;
  }
  .pt-md-18,
  .py-md-18 {
    padding-top: 8.5rem !important;
  }
  .pr-md-18,
  .px-md-18 {
    padding-right: 8.5rem !important;
  }
  .pb-md-18,
  .py-md-18 {
    padding-bottom: 8.5rem !important;
  }
  .pl-md-18,
  .px-md-18 {
    padding-left: 8.5rem !important;
  }
  .p-md-19 {
    padding: 9rem !important;
  }
  .pt-md-19,
  .py-md-19 {
    padding-top: 9rem !important;
  }
  .pr-md-19,
  .px-md-19 {
    padding-right: 9rem !important;
  }
  .pb-md-19,
  .py-md-19 {
    padding-bottom: 9rem !important;
  }
  .pl-md-19,
  .px-md-19 {
    padding-left: 9rem !important;
  }
  .p-md-20 {
    padding: 9.5rem !important;
  }
  .pt-md-20,
  .py-md-20 {
    padding-top: 9.5rem !important;
  }
  .pr-md-20,
  .px-md-20 {
    padding-right: 9.5rem !important;
  }
  .pb-md-20,
  .py-md-20 {
    padding-bottom: 9.5rem !important;
  }
  .pl-md-20,
  .px-md-20 {
    padding-left: 9.5rem !important;
  }
  .p-md-21 {
    padding: 10rem !important;
  }
  .pt-md-21,
  .py-md-21 {
    padding-top: 10rem !important;
  }
  .pr-md-21,
  .px-md-21 {
    padding-right: 10rem !important;
  }
  .pb-md-21,
  .py-md-21 {
    padding-bottom: 10rem !important;
  }
  .pl-md-21,
  .px-md-21 {
    padding-left: 10rem !important;
  }
  .p-md-22 {
    padding: 11rem !important;
  }
  .pt-md-22,
  .py-md-22 {
    padding-top: 11rem !important;
  }
  .pr-md-22,
  .px-md-22 {
    padding-right: 11rem !important;
  }
  .pb-md-22,
  .py-md-22 {
    padding-bottom: 11rem !important;
  }
  .pl-md-22,
  .px-md-22 {
    padding-left: 11rem !important;
  }
  .p-md-23 {
    padding: 12rem !important;
  }
  .pt-md-23,
  .py-md-23 {
    padding-top: 12rem !important;
  }
  .pr-md-23,
  .px-md-23 {
    padding-right: 12rem !important;
  }
  .pb-md-23,
  .py-md-23 {
    padding-bottom: 12rem !important;
  }
  .pl-md-23,
  .px-md-23 {
    padding-left: 12rem !important;
  }
  .p-md-24 {
    padding: 13rem !important;
  }
  .pt-md-24,
  .py-md-24 {
    padding-top: 13rem !important;
  }
  .pr-md-24,
  .px-md-24 {
    padding-right: 13rem !important;
  }
  .pb-md-24,
  .py-md-24 {
    padding-bottom: 13rem !important;
  }
  .pl-md-24,
  .px-md-24 {
    padding-left: 13rem !important;
  }
  .p-md-25 {
    padding: 13.5rem !important;
  }
  .pt-md-25,
  .py-md-25 {
    padding-top: 13.5rem !important;
  }
  .pr-md-25,
  .px-md-25 {
    padding-right: 13.5rem !important;
  }
  .pb-md-25,
  .py-md-25 {
    padding-bottom: 13.5rem !important;
  }
  .pl-md-25,
  .px-md-25 {
    padding-left: 13.5rem !important;
  }
  .p-md-26 {
    padding: 14rem !important;
  }
  .pt-md-26,
  .py-md-26 {
    padding-top: 14rem !important;
  }
  .pr-md-26,
  .px-md-26 {
    padding-right: 14rem !important;
  }
  .pb-md-26,
  .py-md-26 {
    padding-bottom: 14rem !important;
  }
  .pl-md-26,
  .px-md-26 {
    padding-left: 14rem !important;
  }
  .p-md-27 {
    padding: 15rem !important;
  }
  .pt-md-27,
  .py-md-27 {
    padding-top: 15rem !important;
  }
  .pr-md-27,
  .px-md-27 {
    padding-right: 15rem !important;
  }
  .pb-md-27,
  .py-md-27 {
    padding-bottom: 15rem !important;
  }
  .pl-md-27,
  .px-md-27 {
    padding-left: 15rem !important;
  }
  .p-md-28 {
    padding: 16rem !important;
  }
  .pt-md-28,
  .py-md-28 {
    padding-top: 16rem !important;
  }
  .pr-md-28,
  .px-md-28 {
    padding-right: 16rem !important;
  }
  .pb-md-28,
  .py-md-28 {
    padding-bottom: 16rem !important;
  }
  .pl-md-28,
  .px-md-28 {
    padding-left: 16rem !important;
  }
  .p-md-29 {
    padding: 17rem !important;
  }
  .pt-md-29,
  .py-md-29 {
    padding-top: 17rem !important;
  }
  .pr-md-29,
  .px-md-29 {
    padding-right: 17rem !important;
  }
  .pb-md-29,
  .py-md-29 {
    padding-bottom: 17rem !important;
  }
  .pl-md-29,
  .px-md-29 {
    padding-left: 17rem !important;
  }
  .p-md-30 {
    padding: 18rem !important;
  }
  .pt-md-30,
  .py-md-30 {
    padding-top: 18rem !important;
  }
  .pr-md-30,
  .px-md-30 {
    padding-right: 18rem !important;
  }
  .pb-md-30,
  .py-md-30 {
    padding-bottom: 18rem !important;
  }
  .pl-md-30,
  .px-md-30 {
    padding-left: 18rem !important;
  }
  .p-md-31 {
    padding: 19rem !important;
  }
  .pt-md-31,
  .py-md-31 {
    padding-top: 19rem !important;
  }
  .pr-md-31,
  .px-md-31 {
    padding-right: 19rem !important;
  }
  .pb-md-31,
  .py-md-31 {
    padding-bottom: 19rem !important;
  }
  .pl-md-31,
  .px-md-31 {
    padding-left: 19rem !important;
  }
  .p-md-32 {
    padding: 20rem !important;
  }
  .pt-md-32,
  .py-md-32 {
    padding-top: 20rem !important;
  }
  .pr-md-32,
  .px-md-32 {
    padding-right: 20rem !important;
  }
  .pb-md-32,
  .py-md-32 {
    padding-bottom: 20rem !important;
  }
  .pl-md-32,
  .px-md-32 {
    padding-left: 20rem !important;
  }
  .p-md-37 {
    padding: 22.5rem !important;
  }
  .pt-md-37,
  .py-md-37 {
    padding-top: 22.5rem !important;
  }
  .pr-md-37,
  .px-md-37 {
    padding-right: 22.5rem !important;
  }
  .pb-md-37,
  .py-md-37 {
    padding-bottom: 22.5rem !important;
  }
  .pl-md-37,
  .px-md-37 {
    padding-left: 22.5rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -2rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -2rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -2rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -2rem !important;
  }
  .m-md-n6 {
    margin: -2.5rem !important;
  }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -2.5rem !important;
  }
  .m-md-n7 {
    margin: -3rem !important;
  }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -3rem !important;
  }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -3rem !important;
  }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -3rem !important;
  }
  .m-md-n8 {
    margin: -3.5rem !important;
  }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -3.5rem !important;
  }
  .m-md-n9 {
    margin: -4rem !important;
  }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -4rem !important;
  }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -4rem !important;
  }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -4rem !important;
  }
  .m-md-n10 {
    margin: -4.5rem !important;
  }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -4.5rem !important;
  }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -4.5rem !important;
  }
  .m-md-n11 {
    margin: -5rem !important;
  }
  .mt-md-n11,
  .my-md-n11 {
    margin-top: -5rem !important;
  }
  .mr-md-n11,
  .mx-md-n11 {
    margin-right: -5rem !important;
  }
  .mb-md-n11,
  .my-md-n11 {
    margin-bottom: -5rem !important;
  }
  .ml-md-n11,
  .mx-md-n11 {
    margin-left: -5rem !important;
  }
  .m-md-n12 {
    margin: -5.5rem !important;
  }
  .mt-md-n12,
  .my-md-n12 {
    margin-top: -5.5rem !important;
  }
  .mr-md-n12,
  .mx-md-n12 {
    margin-right: -5.5rem !important;
  }
  .mb-md-n12,
  .my-md-n12 {
    margin-bottom: -5.5rem !important;
  }
  .ml-md-n12,
  .mx-md-n12 {
    margin-left: -5.5rem !important;
  }
  .m-md-n13 {
    margin: -6rem !important;
  }
  .mt-md-n13,
  .my-md-n13 {
    margin-top: -6rem !important;
  }
  .mr-md-n13,
  .mx-md-n13 {
    margin-right: -6rem !important;
  }
  .mb-md-n13,
  .my-md-n13 {
    margin-bottom: -6rem !important;
  }
  .ml-md-n13,
  .mx-md-n13 {
    margin-left: -6rem !important;
  }
  .m-md-n14 {
    margin: -6.5rem !important;
  }
  .mt-md-n14,
  .my-md-n14 {
    margin-top: -6.5rem !important;
  }
  .mr-md-n14,
  .mx-md-n14 {
    margin-right: -6.5rem !important;
  }
  .mb-md-n14,
  .my-md-n14 {
    margin-bottom: -6.5rem !important;
  }
  .ml-md-n14,
  .mx-md-n14 {
    margin-left: -6.5rem !important;
  }
  .m-md-n15 {
    margin: -7rem !important;
  }
  .mt-md-n15,
  .my-md-n15 {
    margin-top: -7rem !important;
  }
  .mr-md-n15,
  .mx-md-n15 {
    margin-right: -7rem !important;
  }
  .mb-md-n15,
  .my-md-n15 {
    margin-bottom: -7rem !important;
  }
  .ml-md-n15,
  .mx-md-n15 {
    margin-left: -7rem !important;
  }
  .m-md-n16 {
    margin: -7.5rem !important;
  }
  .mt-md-n16,
  .my-md-n16 {
    margin-top: -7.5rem !important;
  }
  .mr-md-n16,
  .mx-md-n16 {
    margin-right: -7.5rem !important;
  }
  .mb-md-n16,
  .my-md-n16 {
    margin-bottom: -7.5rem !important;
  }
  .ml-md-n16,
  .mx-md-n16 {
    margin-left: -7.5rem !important;
  }
  .m-md-n17 {
    margin: -8rem !important;
  }
  .mt-md-n17,
  .my-md-n17 {
    margin-top: -8rem !important;
  }
  .mr-md-n17,
  .mx-md-n17 {
    margin-right: -8rem !important;
  }
  .mb-md-n17,
  .my-md-n17 {
    margin-bottom: -8rem !important;
  }
  .ml-md-n17,
  .mx-md-n17 {
    margin-left: -8rem !important;
  }
  .m-md-n18 {
    margin: -8.5rem !important;
  }
  .mt-md-n18,
  .my-md-n18 {
    margin-top: -8.5rem !important;
  }
  .mr-md-n18,
  .mx-md-n18 {
    margin-right: -8.5rem !important;
  }
  .mb-md-n18,
  .my-md-n18 {
    margin-bottom: -8.5rem !important;
  }
  .ml-md-n18,
  .mx-md-n18 {
    margin-left: -8.5rem !important;
  }
  .m-md-n19 {
    margin: -9rem !important;
  }
  .mt-md-n19,
  .my-md-n19 {
    margin-top: -9rem !important;
  }
  .mr-md-n19,
  .mx-md-n19 {
    margin-right: -9rem !important;
  }
  .mb-md-n19,
  .my-md-n19 {
    margin-bottom: -9rem !important;
  }
  .ml-md-n19,
  .mx-md-n19 {
    margin-left: -9rem !important;
  }
  .m-md-n20 {
    margin: -9.5rem !important;
  }
  .mt-md-n20,
  .my-md-n20 {
    margin-top: -9.5rem !important;
  }
  .mr-md-n20,
  .mx-md-n20 {
    margin-right: -9.5rem !important;
  }
  .mb-md-n20,
  .my-md-n20 {
    margin-bottom: -9.5rem !important;
  }
  .ml-md-n20,
  .mx-md-n20 {
    margin-left: -9.5rem !important;
  }
  .m-md-n21 {
    margin: -10rem !important;
  }
  .mt-md-n21,
  .my-md-n21 {
    margin-top: -10rem !important;
  }
  .mr-md-n21,
  .mx-md-n21 {
    margin-right: -10rem !important;
  }
  .mb-md-n21,
  .my-md-n21 {
    margin-bottom: -10rem !important;
  }
  .ml-md-n21,
  .mx-md-n21 {
    margin-left: -10rem !important;
  }
  .m-md-n22 {
    margin: -11rem !important;
  }
  .mt-md-n22,
  .my-md-n22 {
    margin-top: -11rem !important;
  }
  .mr-md-n22,
  .mx-md-n22 {
    margin-right: -11rem !important;
  }
  .mb-md-n22,
  .my-md-n22 {
    margin-bottom: -11rem !important;
  }
  .ml-md-n22,
  .mx-md-n22 {
    margin-left: -11rem !important;
  }
  .m-md-n23 {
    margin: -12rem !important;
  }
  .mt-md-n23,
  .my-md-n23 {
    margin-top: -12rem !important;
  }
  .mr-md-n23,
  .mx-md-n23 {
    margin-right: -12rem !important;
  }
  .mb-md-n23,
  .my-md-n23 {
    margin-bottom: -12rem !important;
  }
  .ml-md-n23,
  .mx-md-n23 {
    margin-left: -12rem !important;
  }
  .m-md-n24 {
    margin: -13rem !important;
  }
  .mt-md-n24,
  .my-md-n24 {
    margin-top: -13rem !important;
  }
  .mr-md-n24,
  .mx-md-n24 {
    margin-right: -13rem !important;
  }
  .mb-md-n24,
  .my-md-n24 {
    margin-bottom: -13rem !important;
  }
  .ml-md-n24,
  .mx-md-n24 {
    margin-left: -13rem !important;
  }
  .m-md-n25 {
    margin: -13.5rem !important;
  }
  .mt-md-n25,
  .my-md-n25 {
    margin-top: -13.5rem !important;
  }
  .mr-md-n25,
  .mx-md-n25 {
    margin-right: -13.5rem !important;
  }
  .mb-md-n25,
  .my-md-n25 {
    margin-bottom: -13.5rem !important;
  }
  .ml-md-n25,
  .mx-md-n25 {
    margin-left: -13.5rem !important;
  }
  .m-md-n26 {
    margin: -14rem !important;
  }
  .mt-md-n26,
  .my-md-n26 {
    margin-top: -14rem !important;
  }
  .mr-md-n26,
  .mx-md-n26 {
    margin-right: -14rem !important;
  }
  .mb-md-n26,
  .my-md-n26 {
    margin-bottom: -14rem !important;
  }
  .ml-md-n26,
  .mx-md-n26 {
    margin-left: -14rem !important;
  }
  .m-md-n27 {
    margin: -15rem !important;
  }
  .mt-md-n27,
  .my-md-n27 {
    margin-top: -15rem !important;
  }
  .mr-md-n27,
  .mx-md-n27 {
    margin-right: -15rem !important;
  }
  .mb-md-n27,
  .my-md-n27 {
    margin-bottom: -15rem !important;
  }
  .ml-md-n27,
  .mx-md-n27 {
    margin-left: -15rem !important;
  }
  .m-md-n28 {
    margin: -16rem !important;
  }
  .mt-md-n28,
  .my-md-n28 {
    margin-top: -16rem !important;
  }
  .mr-md-n28,
  .mx-md-n28 {
    margin-right: -16rem !important;
  }
  .mb-md-n28,
  .my-md-n28 {
    margin-bottom: -16rem !important;
  }
  .ml-md-n28,
  .mx-md-n28 {
    margin-left: -16rem !important;
  }
  .m-md-n29 {
    margin: -17rem !important;
  }
  .mt-md-n29,
  .my-md-n29 {
    margin-top: -17rem !important;
  }
  .mr-md-n29,
  .mx-md-n29 {
    margin-right: -17rem !important;
  }
  .mb-md-n29,
  .my-md-n29 {
    margin-bottom: -17rem !important;
  }
  .ml-md-n29,
  .mx-md-n29 {
    margin-left: -17rem !important;
  }
  .m-md-n30 {
    margin: -18rem !important;
  }
  .mt-md-n30,
  .my-md-n30 {
    margin-top: -18rem !important;
  }
  .mr-md-n30,
  .mx-md-n30 {
    margin-right: -18rem !important;
  }
  .mb-md-n30,
  .my-md-n30 {
    margin-bottom: -18rem !important;
  }
  .ml-md-n30,
  .mx-md-n30 {
    margin-left: -18rem !important;
  }
  .m-md-n31 {
    margin: -19rem !important;
  }
  .mt-md-n31,
  .my-md-n31 {
    margin-top: -19rem !important;
  }
  .mr-md-n31,
  .mx-md-n31 {
    margin-right: -19rem !important;
  }
  .mb-md-n31,
  .my-md-n31 {
    margin-bottom: -19rem !important;
  }
  .ml-md-n31,
  .mx-md-n31 {
    margin-left: -19rem !important;
  }
  .m-md-n32 {
    margin: -20rem !important;
  }
  .mt-md-n32,
  .my-md-n32 {
    margin-top: -20rem !important;
  }
  .mr-md-n32,
  .mx-md-n32 {
    margin-right: -20rem !important;
  }
  .mb-md-n32,
  .my-md-n32 {
    margin-bottom: -20rem !important;
  }
  .ml-md-n32,
  .mx-md-n32 {
    margin-left: -20rem !important;
  }
  .m-md-n37 {
    margin: -22.5rem !important;
  }
  .mt-md-n37,
  .my-md-n37 {
    margin-top: -22.5rem !important;
  }
  .mr-md-n37,
  .mx-md-n37 {
    margin-right: -22.5rem !important;
  }
  .mb-md-n37,
  .my-md-n37 {
    margin-bottom: -22.5rem !important;
  }
  .ml-md-n37,
  .mx-md-n37 {
    margin-left: -22.5rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 2rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 2rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 2rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 2rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 2rem !important;
  }
  .m-lg-6 {
    margin: 2.5rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 2.5rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 2.5rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 2.5rem !important;
  }
  .m-lg-7 {
    margin: 3rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 3rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 3rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 3rem !important;
  }
  .m-lg-8 {
    margin: 3.5rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 3.5rem !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 3.5rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 3.5rem !important;
  }
  .m-lg-9 {
    margin: 4rem !important;
  }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 4rem !important;
  }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 4rem !important;
  }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 4rem !important;
  }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 4rem !important;
  }
  .m-lg-10 {
    margin: 4.5rem !important;
  }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 4.5rem !important;
  }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 4.5rem !important;
  }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 4.5rem !important;
  }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 4.5rem !important;
  }
  .m-lg-11 {
    margin: 5rem !important;
  }
  .mt-lg-11,
  .my-lg-11 {
    margin-top: 5rem !important;
  }
  .mr-lg-11,
  .mx-lg-11 {
    margin-right: 5rem !important;
  }
  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 5rem !important;
  }
  .ml-lg-11,
  .mx-lg-11 {
    margin-left: 5rem !important;
  }
  .m-lg-12 {
    margin: 5.5rem !important;
  }
  .mt-lg-12,
  .my-lg-12 {
    margin-top: 5.5rem !important;
  }
  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 5.5rem !important;
  }
  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 5.5rem !important;
  }
  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 5.5rem !important;
  }
  .m-lg-13 {
    margin: 6rem !important;
  }
  .mt-lg-13,
  .my-lg-13 {
    margin-top: 6rem !important;
  }
  .mr-lg-13,
  .mx-lg-13 {
    margin-right: 6rem !important;
  }
  .mb-lg-13,
  .my-lg-13 {
    margin-bottom: 6rem !important;
  }
  .ml-lg-13,
  .mx-lg-13 {
    margin-left: 6rem !important;
  }
  .m-lg-14 {
    margin: 6.5rem !important;
  }
  .mt-lg-14,
  .my-lg-14 {
    margin-top: 6.5rem !important;
  }
  .mr-lg-14,
  .mx-lg-14 {
    margin-right: 6.5rem !important;
  }
  .mb-lg-14,
  .my-lg-14 {
    margin-bottom: 6.5rem !important;
  }
  .ml-lg-14,
  .mx-lg-14 {
    margin-left: 6.5rem !important;
  }
  .m-lg-15 {
    margin: 7rem !important;
  }
  .mt-lg-15,
  .my-lg-15 {
    margin-top: 7rem !important;
  }
  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 7rem !important;
  }
  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 7rem !important;
  }
  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 7rem !important;
  }
  .m-lg-16 {
    margin: 7.5rem !important;
  }
  .mt-lg-16,
  .my-lg-16 {
    margin-top: 7.5rem !important;
  }
  .mr-lg-16,
  .mx-lg-16 {
    margin-right: 7.5rem !important;
  }
  .mb-lg-16,
  .my-lg-16 {
    margin-bottom: 7.5rem !important;
  }
  .ml-lg-16,
  .mx-lg-16 {
    margin-left: 7.5rem !important;
  }
  .m-lg-17 {
    margin: 8rem !important;
  }
  .mt-lg-17,
  .my-lg-17 {
    margin-top: 8rem !important;
  }
  .mr-lg-17,
  .mx-lg-17 {
    margin-right: 8rem !important;
  }
  .mb-lg-17,
  .my-lg-17 {
    margin-bottom: 8rem !important;
  }
  .ml-lg-17,
  .mx-lg-17 {
    margin-left: 8rem !important;
  }
  .m-lg-18 {
    margin: 8.5rem !important;
  }
  .mt-lg-18,
  .my-lg-18 {
    margin-top: 8.5rem !important;
  }
  .mr-lg-18,
  .mx-lg-18 {
    margin-right: 8.5rem !important;
  }
  .mb-lg-18,
  .my-lg-18 {
    margin-bottom: 8.5rem !important;
  }
  .ml-lg-18,
  .mx-lg-18 {
    margin-left: 8.5rem !important;
  }
  .m-lg-19 {
    margin: 9rem !important;
  }
  .mt-lg-19,
  .my-lg-19 {
    margin-top: 9rem !important;
  }
  .mr-lg-19,
  .mx-lg-19 {
    margin-right: 9rem !important;
  }
  .mb-lg-19,
  .my-lg-19 {
    margin-bottom: 9rem !important;
  }
  .ml-lg-19,
  .mx-lg-19 {
    margin-left: 9rem !important;
  }
  .m-lg-20 {
    margin: 9.5rem !important;
  }
  .mt-lg-20,
  .my-lg-20 {
    margin-top: 9.5rem !important;
  }
  .mr-lg-20,
  .mx-lg-20 {
    margin-right: 9.5rem !important;
  }
  .mb-lg-20,
  .my-lg-20 {
    margin-bottom: 9.5rem !important;
  }
  .ml-lg-20,
  .mx-lg-20 {
    margin-left: 9.5rem !important;
  }
  .m-lg-21 {
    margin: 10rem !important;
  }
  .mt-lg-21,
  .my-lg-21 {
    margin-top: 10rem !important;
  }
  .mr-lg-21,
  .mx-lg-21 {
    margin-right: 10rem !important;
  }
  .mb-lg-21,
  .my-lg-21 {
    margin-bottom: 10rem !important;
  }
  .ml-lg-21,
  .mx-lg-21 {
    margin-left: 10rem !important;
  }
  .m-lg-22 {
    margin: 11rem !important;
  }
  .mt-lg-22,
  .my-lg-22 {
    margin-top: 11rem !important;
  }
  .mr-lg-22,
  .mx-lg-22 {
    margin-right: 11rem !important;
  }
  .mb-lg-22,
  .my-lg-22 {
    margin-bottom: 11rem !important;
  }
  .ml-lg-22,
  .mx-lg-22 {
    margin-left: 11rem !important;
  }
  .m-lg-23 {
    margin: 12rem !important;
  }
  .mt-lg-23,
  .my-lg-23 {
    margin-top: 12rem !important;
  }
  .mr-lg-23,
  .mx-lg-23 {
    margin-right: 12rem !important;
  }
  .mb-lg-23,
  .my-lg-23 {
    margin-bottom: 12rem !important;
  }
  .ml-lg-23,
  .mx-lg-23 {
    margin-left: 12rem !important;
  }
  .m-lg-24 {
    margin: 13rem !important;
  }
  .mt-lg-24,
  .my-lg-24 {
    margin-top: 13rem !important;
  }
  .mr-lg-24,
  .mx-lg-24 {
    margin-right: 13rem !important;
  }
  .mb-lg-24,
  .my-lg-24 {
    margin-bottom: 13rem !important;
  }
  .ml-lg-24,
  .mx-lg-24 {
    margin-left: 13rem !important;
  }
  .m-lg-25 {
    margin: 13.5rem !important;
  }
  .mt-lg-25,
  .my-lg-25 {
    margin-top: 13.5rem !important;
  }
  .mr-lg-25,
  .mx-lg-25 {
    margin-right: 13.5rem !important;
  }
  .mb-lg-25,
  .my-lg-25 {
    margin-bottom: 13.5rem !important;
  }
  .ml-lg-25,
  .mx-lg-25 {
    margin-left: 13.5rem !important;
  }
  .m-lg-26 {
    margin: 14rem !important;
  }
  .mt-lg-26,
  .my-lg-26 {
    margin-top: 14rem !important;
  }
  .mr-lg-26,
  .mx-lg-26 {
    margin-right: 14rem !important;
  }
  .mb-lg-26,
  .my-lg-26 {
    margin-bottom: 14rem !important;
  }
  .ml-lg-26,
  .mx-lg-26 {
    margin-left: 14rem !important;
  }
  .m-lg-27 {
    margin: 15rem !important;
  }
  .mt-lg-27,
  .my-lg-27 {
    margin-top: 15rem !important;
  }
  .mr-lg-27,
  .mx-lg-27 {
    margin-right: 15rem !important;
  }
  .mb-lg-27,
  .my-lg-27 {
    margin-bottom: 15rem !important;
  }
  .ml-lg-27,
  .mx-lg-27 {
    margin-left: 15rem !important;
  }
  .m-lg-28 {
    margin: 16rem !important;
  }
  .mt-lg-28,
  .my-lg-28 {
    margin-top: 16rem !important;
  }
  .mr-lg-28,
  .mx-lg-28 {
    margin-right: 16rem !important;
  }
  .mb-lg-28,
  .my-lg-28 {
    margin-bottom: 16rem !important;
  }
  .ml-lg-28,
  .mx-lg-28 {
    margin-left: 16rem !important;
  }
  .m-lg-29 {
    margin: 17rem !important;
  }
  .mt-lg-29,
  .my-lg-29 {
    margin-top: 17rem !important;
  }
  .mr-lg-29,
  .mx-lg-29 {
    margin-right: 17rem !important;
  }
  .mb-lg-29,
  .my-lg-29 {
    margin-bottom: 17rem !important;
  }
  .ml-lg-29,
  .mx-lg-29 {
    margin-left: 17rem !important;
  }
  .m-lg-30 {
    margin: 18rem !important;
  }
  .mt-lg-30,
  .my-lg-30 {
    margin-top: 18rem !important;
  }
  .mr-lg-30,
  .mx-lg-30 {
    margin-right: 18rem !important;
  }
  .mb-lg-30,
  .my-lg-30 {
    margin-bottom: 18rem !important;
  }
  .ml-lg-30,
  .mx-lg-30 {
    margin-left: 18rem !important;
  }
  .m-lg-31 {
    margin: 19rem !important;
  }
  .mt-lg-31,
  .my-lg-31 {
    margin-top: 19rem !important;
  }
  .mr-lg-31,
  .mx-lg-31 {
    margin-right: 19rem !important;
  }
  .mb-lg-31,
  .my-lg-31 {
    margin-bottom: 19rem !important;
  }
  .ml-lg-31,
  .mx-lg-31 {
    margin-left: 19rem !important;
  }
  .m-lg-32 {
    margin: 20rem !important;
  }
  .mt-lg-32,
  .my-lg-32 {
    margin-top: 20rem !important;
  }
  .mr-lg-32,
  .mx-lg-32 {
    margin-right: 20rem !important;
  }
  .mb-lg-32,
  .my-lg-32 {
    margin-bottom: 20rem !important;
  }
  .ml-lg-32,
  .mx-lg-32 {
    margin-left: 20rem !important;
  }
  .m-lg-37 {
    margin: 22.5rem !important;
  }
  .mt-lg-37,
  .my-lg-37 {
    margin-top: 22.5rem !important;
  }
  .mr-lg-37,
  .mx-lg-37 {
    margin-right: 22.5rem !important;
  }
  .mb-lg-37,
  .my-lg-37 {
    margin-bottom: 22.5rem !important;
  }
  .ml-lg-37,
  .mx-lg-37 {
    margin-left: 22.5rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 2rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 2rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 2rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 2rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 2rem !important;
  }
  .p-lg-6 {
    padding: 2.5rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 2.5rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 2.5rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 2.5rem !important;
  }
  .p-lg-7 {
    padding: 3rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 3rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 3rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 3rem !important;
  }
  .p-lg-8 {
    padding: 3.5rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 3.5rem !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 3.5rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 3.5rem !important;
  }
  .p-lg-9 {
    padding: 4rem !important;
  }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 4rem !important;
  }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 4rem !important;
  }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 4rem !important;
  }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 4rem !important;
  }
  .p-lg-10 {
    padding: 4.5rem !important;
  }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 4.5rem !important;
  }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 4.5rem !important;
  }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 4.5rem !important;
  }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 4.5rem !important;
  }
  .p-lg-11 {
    padding: 5rem !important;
  }
  .pt-lg-11,
  .py-lg-11 {
    padding-top: 5rem !important;
  }
  .pr-lg-11,
  .px-lg-11 {
    padding-right: 5rem !important;
  }
  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 5rem !important;
  }
  .pl-lg-11,
  .px-lg-11 {
    padding-left: 5rem !important;
  }
  .p-lg-12 {
    padding: 5.5rem !important;
  }
  .pt-lg-12,
  .py-lg-12 {
    padding-top: 5.5rem !important;
  }
  .pr-lg-12,
  .px-lg-12 {
    padding-right: 5.5rem !important;
  }
  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 5.5rem !important;
  }
  .pl-lg-12,
  .px-lg-12 {
    padding-left: 5.5rem !important;
  }
  .p-lg-13 {
    padding: 6rem !important;
  }
  .pt-lg-13,
  .py-lg-13 {
    padding-top: 6rem !important;
  }
  .pr-lg-13,
  .px-lg-13 {
    padding-right: 6rem !important;
  }
  .pb-lg-13,
  .py-lg-13 {
    padding-bottom: 6rem !important;
  }
  .pl-lg-13,
  .px-lg-13 {
    padding-left: 6rem !important;
  }
  .p-lg-14 {
    padding: 6.5rem !important;
  }
  .pt-lg-14,
  .py-lg-14 {
    padding-top: 6.5rem !important;
  }
  .pr-lg-14,
  .px-lg-14 {
    padding-right: 6.5rem !important;
  }
  .pb-lg-14,
  .py-lg-14 {
    padding-bottom: 6.5rem !important;
  }
  .pl-lg-14,
  .px-lg-14 {
    padding-left: 6.5rem !important;
  }
  .p-lg-15 {
    padding: 7rem !important;
  }
  .pt-lg-15,
  .py-lg-15 {
    padding-top: 7rem !important;
  }
  .pr-lg-15,
  .px-lg-15 {
    padding-right: 7rem !important;
  }
  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 7rem !important;
  }
  .pl-lg-15,
  .px-lg-15 {
    padding-left: 7rem !important;
  }
  .p-lg-16 {
    padding: 7.5rem !important;
  }
  .pt-lg-16,
  .py-lg-16 {
    padding-top: 7.5rem !important;
  }
  .pr-lg-16,
  .px-lg-16 {
    padding-right: 7.5rem !important;
  }
  .pb-lg-16,
  .py-lg-16 {
    padding-bottom: 7.5rem !important;
  }
  .pl-lg-16,
  .px-lg-16 {
    padding-left: 7.5rem !important;
  }
  .p-lg-17 {
    padding: 8rem !important;
  }
  .pt-lg-17,
  .py-lg-17 {
    padding-top: 8rem !important;
  }
  .pr-lg-17,
  .px-lg-17 {
    padding-right: 8rem !important;
  }
  .pb-lg-17,
  .py-lg-17 {
    padding-bottom: 8rem !important;
  }
  .pl-lg-17,
  .px-lg-17 {
    padding-left: 8rem !important;
  }
  .p-lg-18 {
    padding: 8.5rem !important;
  }
  .pt-lg-18,
  .py-lg-18 {
    padding-top: 8.5rem !important;
  }
  .pr-lg-18,
  .px-lg-18 {
    padding-right: 8.5rem !important;
  }
  .pb-lg-18,
  .py-lg-18 {
    padding-bottom: 8.5rem !important;
  }
  .pl-lg-18,
  .px-lg-18 {
    padding-left: 8.5rem !important;
  }
  .p-lg-19 {
    padding: 9rem !important;
  }
  .pt-lg-19,
  .py-lg-19 {
    padding-top: 9rem !important;
  }
  .pr-lg-19,
  .px-lg-19 {
    padding-right: 9rem !important;
  }
  .pb-lg-19,
  .py-lg-19 {
    padding-bottom: 9rem !important;
  }
  .pl-lg-19,
  .px-lg-19 {
    padding-left: 9rem !important;
  }
  .p-lg-20 {
    padding: 9.5rem !important;
  }
  .pt-lg-20,
  .py-lg-20 {
    padding-top: 9.5rem !important;
  }
  .pr-lg-20,
  .px-lg-20 {
    padding-right: 9.5rem !important;
  }
  .pb-lg-20,
  .py-lg-20 {
    padding-bottom: 9.5rem !important;
  }
  .pl-lg-20,
  .px-lg-20 {
    padding-left: 9.5rem !important;
  }
  .p-lg-21 {
    padding: 10rem !important;
  }
  .pt-lg-21,
  .py-lg-21 {
    padding-top: 10rem !important;
  }
  .pr-lg-21,
  .px-lg-21 {
    padding-right: 10rem !important;
  }
  .pb-lg-21,
  .py-lg-21 {
    padding-bottom: 10rem !important;
  }
  .pl-lg-21,
  .px-lg-21 {
    padding-left: 10rem !important;
  }
  .p-lg-22 {
    padding: 11rem !important;
  }
  .pt-lg-22,
  .py-lg-22 {
    padding-top: 11rem !important;
  }
  .pr-lg-22,
  .px-lg-22 {
    padding-right: 11rem !important;
  }
  .pb-lg-22,
  .py-lg-22 {
    padding-bottom: 11rem !important;
  }
  .pl-lg-22,
  .px-lg-22 {
    padding-left: 11rem !important;
  }
  .p-lg-23 {
    padding: 12rem !important;
  }
  .pt-lg-23,
  .py-lg-23 {
    padding-top: 12rem !important;
  }
  .pr-lg-23,
  .px-lg-23 {
    padding-right: 12rem !important;
  }
  .pb-lg-23,
  .py-lg-23 {
    padding-bottom: 12rem !important;
  }
  .pl-lg-23,
  .px-lg-23 {
    padding-left: 12rem !important;
  }
  .p-lg-24 {
    padding: 13rem !important;
  }
  .pt-lg-24,
  .py-lg-24 {
    padding-top: 13rem !important;
  }
  .pr-lg-24,
  .px-lg-24 {
    padding-right: 13rem !important;
  }
  .pb-lg-24,
  .py-lg-24 {
    padding-bottom: 13rem !important;
  }
  .pl-lg-24,
  .px-lg-24 {
    padding-left: 13rem !important;
  }
  .p-lg-25 {
    padding: 13.5rem !important;
  }
  .pt-lg-25,
  .py-lg-25 {
    padding-top: 13.5rem !important;
  }
  .pr-lg-25,
  .px-lg-25 {
    padding-right: 13.5rem !important;
  }
  .pb-lg-25,
  .py-lg-25 {
    padding-bottom: 13.5rem !important;
  }
  .pl-lg-25,
  .px-lg-25 {
    padding-left: 13.5rem !important;
  }
  .p-lg-26 {
    padding: 14rem !important;
  }
  .pt-lg-26,
  .py-lg-26 {
    padding-top: 14rem !important;
  }
  .pr-lg-26,
  .px-lg-26 {
    padding-right: 14rem !important;
  }
  .pb-lg-26,
  .py-lg-26 {
    padding-bottom: 14rem !important;
  }
  .pl-lg-26,
  .px-lg-26 {
    padding-left: 14rem !important;
  }
  .p-lg-27 {
    padding: 15rem !important;
  }
  .pt-lg-27,
  .py-lg-27 {
    padding-top: 15rem !important;
  }
  .pr-lg-27,
  .px-lg-27 {
    padding-right: 15rem !important;
  }
  .pb-lg-27,
  .py-lg-27 {
    padding-bottom: 15rem !important;
  }
  .pl-lg-27,
  .px-lg-27 {
    padding-left: 15rem !important;
  }
  .p-lg-28 {
    padding: 16rem !important;
  }
  .pt-lg-28,
  .py-lg-28 {
    padding-top: 16rem !important;
  }
  .pr-lg-28,
  .px-lg-28 {
    padding-right: 16rem !important;
  }
  .pb-lg-28,
  .py-lg-28 {
    padding-bottom: 16rem !important;
  }
  .pl-lg-28,
  .px-lg-28 {
    padding-left: 16rem !important;
  }
  .p-lg-29 {
    padding: 17rem !important;
  }
  .pt-lg-29,
  .py-lg-29 {
    padding-top: 17rem !important;
  }
  .pr-lg-29,
  .px-lg-29 {
    padding-right: 17rem !important;
  }
  .pb-lg-29,
  .py-lg-29 {
    padding-bottom: 17rem !important;
  }
  .pl-lg-29,
  .px-lg-29 {
    padding-left: 17rem !important;
  }
  .p-lg-30 {
    padding: 18rem !important;
  }
  .pt-lg-30,
  .py-lg-30 {
    padding-top: 18rem !important;
  }
  .pr-lg-30,
  .px-lg-30 {
    padding-right: 18rem !important;
  }
  .pb-lg-30,
  .py-lg-30 {
    padding-bottom: 18rem !important;
  }
  .pl-lg-30,
  .px-lg-30 {
    padding-left: 18rem !important;
  }
  .p-lg-31 {
    padding: 19rem !important;
  }
  .pt-lg-31,
  .py-lg-31 {
    padding-top: 19rem !important;
  }
  .pr-lg-31,
  .px-lg-31 {
    padding-right: 19rem !important;
  }
  .pb-lg-31,
  .py-lg-31 {
    padding-bottom: 19rem !important;
  }
  .pl-lg-31,
  .px-lg-31 {
    padding-left: 19rem !important;
  }
  .p-lg-32 {
    padding: 20rem !important;
  }
  .pt-lg-32,
  .py-lg-32 {
    padding-top: 20rem !important;
  }
  .pr-lg-32,
  .px-lg-32 {
    padding-right: 20rem !important;
  }
  .pb-lg-32,
  .py-lg-32 {
    padding-bottom: 20rem !important;
  }
  .pl-lg-32,
  .px-lg-32 {
    padding-left: 20rem !important;
  }
  .p-lg-37 {
    padding: 22.5rem !important;
  }
  .pt-lg-37,
  .py-lg-37 {
    padding-top: 22.5rem !important;
  }
  .pr-lg-37,
  .px-lg-37 {
    padding-right: 22.5rem !important;
  }
  .pb-lg-37,
  .py-lg-37 {
    padding-bottom: 22.5rem !important;
  }
  .pl-lg-37,
  .px-lg-37 {
    padding-left: 22.5rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -2rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -2rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -2rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -2rem !important;
  }
  .m-lg-n6 {
    margin: -2.5rem !important;
  }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -2.5rem !important;
  }
  .m-lg-n7 {
    margin: -3rem !important;
  }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -3rem !important;
  }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -3rem !important;
  }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -3rem !important;
  }
  .m-lg-n8 {
    margin: -3.5rem !important;
  }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -3.5rem !important;
  }
  .m-lg-n9 {
    margin: -4rem !important;
  }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -4rem !important;
  }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -4rem !important;
  }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -4rem !important;
  }
  .m-lg-n10 {
    margin: -4.5rem !important;
  }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -4.5rem !important;
  }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -4.5rem !important;
  }
  .m-lg-n11 {
    margin: -5rem !important;
  }
  .mt-lg-n11,
  .my-lg-n11 {
    margin-top: -5rem !important;
  }
  .mr-lg-n11,
  .mx-lg-n11 {
    margin-right: -5rem !important;
  }
  .mb-lg-n11,
  .my-lg-n11 {
    margin-bottom: -5rem !important;
  }
  .ml-lg-n11,
  .mx-lg-n11 {
    margin-left: -5rem !important;
  }
  .m-lg-n12 {
    margin: -5.5rem !important;
  }
  .mt-lg-n12,
  .my-lg-n12 {
    margin-top: -5.5rem !important;
  }
  .mr-lg-n12,
  .mx-lg-n12 {
    margin-right: -5.5rem !important;
  }
  .mb-lg-n12,
  .my-lg-n12 {
    margin-bottom: -5.5rem !important;
  }
  .ml-lg-n12,
  .mx-lg-n12 {
    margin-left: -5.5rem !important;
  }
  .m-lg-n13 {
    margin: -6rem !important;
  }
  .mt-lg-n13,
  .my-lg-n13 {
    margin-top: -6rem !important;
  }
  .mr-lg-n13,
  .mx-lg-n13 {
    margin-right: -6rem !important;
  }
  .mb-lg-n13,
  .my-lg-n13 {
    margin-bottom: -6rem !important;
  }
  .ml-lg-n13,
  .mx-lg-n13 {
    margin-left: -6rem !important;
  }
  .m-lg-n14 {
    margin: -6.5rem !important;
  }
  .mt-lg-n14,
  .my-lg-n14 {
    margin-top: -6.5rem !important;
  }
  .mr-lg-n14,
  .mx-lg-n14 {
    margin-right: -6.5rem !important;
  }
  .mb-lg-n14,
  .my-lg-n14 {
    margin-bottom: -6.5rem !important;
  }
  .ml-lg-n14,
  .mx-lg-n14 {
    margin-left: -6.5rem !important;
  }
  .m-lg-n15 {
    margin: -7rem !important;
  }
  .mt-lg-n15,
  .my-lg-n15 {
    margin-top: -7rem !important;
  }
  .mr-lg-n15,
  .mx-lg-n15 {
    margin-right: -7rem !important;
  }
  .mb-lg-n15,
  .my-lg-n15 {
    margin-bottom: -7rem !important;
  }
  .ml-lg-n15,
  .mx-lg-n15 {
    margin-left: -7rem !important;
  }
  .m-lg-n16 {
    margin: -7.5rem !important;
  }
  .mt-lg-n16,
  .my-lg-n16 {
    margin-top: -7.5rem !important;
  }
  .mr-lg-n16,
  .mx-lg-n16 {
    margin-right: -7.5rem !important;
  }
  .mb-lg-n16,
  .my-lg-n16 {
    margin-bottom: -7.5rem !important;
  }
  .ml-lg-n16,
  .mx-lg-n16 {
    margin-left: -7.5rem !important;
  }
  .m-lg-n17 {
    margin: -8rem !important;
  }
  .mt-lg-n17,
  .my-lg-n17 {
    margin-top: -8rem !important;
  }
  .mr-lg-n17,
  .mx-lg-n17 {
    margin-right: -8rem !important;
  }
  .mb-lg-n17,
  .my-lg-n17 {
    margin-bottom: -8rem !important;
  }
  .ml-lg-n17,
  .mx-lg-n17 {
    margin-left: -8rem !important;
  }
  .m-lg-n18 {
    margin: -8.5rem !important;
  }
  .mt-lg-n18,
  .my-lg-n18 {
    margin-top: -8.5rem !important;
  }
  .mr-lg-n18,
  .mx-lg-n18 {
    margin-right: -8.5rem !important;
  }
  .mb-lg-n18,
  .my-lg-n18 {
    margin-bottom: -8.5rem !important;
  }
  .ml-lg-n18,
  .mx-lg-n18 {
    margin-left: -8.5rem !important;
  }
  .m-lg-n19 {
    margin: -9rem !important;
  }
  .mt-lg-n19,
  .my-lg-n19 {
    margin-top: -9rem !important;
  }
  .mr-lg-n19,
  .mx-lg-n19 {
    margin-right: -9rem !important;
  }
  .mb-lg-n19,
  .my-lg-n19 {
    margin-bottom: -9rem !important;
  }
  .ml-lg-n19,
  .mx-lg-n19 {
    margin-left: -9rem !important;
  }
  .m-lg-n20 {
    margin: -9.5rem !important;
  }
  .mt-lg-n20,
  .my-lg-n20 {
    margin-top: -9.5rem !important;
  }
  .mr-lg-n20,
  .mx-lg-n20 {
    margin-right: -9.5rem !important;
  }
  .mb-lg-n20,
  .my-lg-n20 {
    margin-bottom: -9.5rem !important;
  }
  .ml-lg-n20,
  .mx-lg-n20 {
    margin-left: -9.5rem !important;
  }
  .m-lg-n21 {
    margin: -10rem !important;
  }
  .mt-lg-n21,
  .my-lg-n21 {
    margin-top: -10rem !important;
  }
  .mr-lg-n21,
  .mx-lg-n21 {
    margin-right: -10rem !important;
  }
  .mb-lg-n21,
  .my-lg-n21 {
    margin-bottom: -10rem !important;
  }
  .ml-lg-n21,
  .mx-lg-n21 {
    margin-left: -10rem !important;
  }
  .m-lg-n22 {
    margin: -11rem !important;
  }
  .mt-lg-n22,
  .my-lg-n22 {
    margin-top: -11rem !important;
  }
  .mr-lg-n22,
  .mx-lg-n22 {
    margin-right: -11rem !important;
  }
  .mb-lg-n22,
  .my-lg-n22 {
    margin-bottom: -11rem !important;
  }
  .ml-lg-n22,
  .mx-lg-n22 {
    margin-left: -11rem !important;
  }
  .m-lg-n23 {
    margin: -12rem !important;
  }
  .mt-lg-n23,
  .my-lg-n23 {
    margin-top: -12rem !important;
  }
  .mr-lg-n23,
  .mx-lg-n23 {
    margin-right: -12rem !important;
  }
  .mb-lg-n23,
  .my-lg-n23 {
    margin-bottom: -12rem !important;
  }
  .ml-lg-n23,
  .mx-lg-n23 {
    margin-left: -12rem !important;
  }
  .m-lg-n24 {
    margin: -13rem !important;
  }
  .mt-lg-n24,
  .my-lg-n24 {
    margin-top: -13rem !important;
  }
  .mr-lg-n24,
  .mx-lg-n24 {
    margin-right: -13rem !important;
  }
  .mb-lg-n24,
  .my-lg-n24 {
    margin-bottom: -13rem !important;
  }
  .ml-lg-n24,
  .mx-lg-n24 {
    margin-left: -13rem !important;
  }
  .m-lg-n25 {
    margin: -13.5rem !important;
  }
  .mt-lg-n25,
  .my-lg-n25 {
    margin-top: -13.5rem !important;
  }
  .mr-lg-n25,
  .mx-lg-n25 {
    margin-right: -13.5rem !important;
  }
  .mb-lg-n25,
  .my-lg-n25 {
    margin-bottom: -13.5rem !important;
  }
  .ml-lg-n25,
  .mx-lg-n25 {
    margin-left: -13.5rem !important;
  }
  .m-lg-n26 {
    margin: -14rem !important;
  }
  .mt-lg-n26,
  .my-lg-n26 {
    margin-top: -14rem !important;
  }
  .mr-lg-n26,
  .mx-lg-n26 {
    margin-right: -14rem !important;
  }
  .mb-lg-n26,
  .my-lg-n26 {
    margin-bottom: -14rem !important;
  }
  .ml-lg-n26,
  .mx-lg-n26 {
    margin-left: -14rem !important;
  }
  .m-lg-n27 {
    margin: -15rem !important;
  }
  .mt-lg-n27,
  .my-lg-n27 {
    margin-top: -15rem !important;
  }
  .mr-lg-n27,
  .mx-lg-n27 {
    margin-right: -15rem !important;
  }
  .mb-lg-n27,
  .my-lg-n27 {
    margin-bottom: -15rem !important;
  }
  .ml-lg-n27,
  .mx-lg-n27 {
    margin-left: -15rem !important;
  }
  .m-lg-n28 {
    margin: -16rem !important;
  }
  .mt-lg-n28,
  .my-lg-n28 {
    margin-top: -16rem !important;
  }
  .mr-lg-n28,
  .mx-lg-n28 {
    margin-right: -16rem !important;
  }
  .mb-lg-n28,
  .my-lg-n28 {
    margin-bottom: -16rem !important;
  }
  .ml-lg-n28,
  .mx-lg-n28 {
    margin-left: -16rem !important;
  }
  .m-lg-n29 {
    margin: -17rem !important;
  }
  .mt-lg-n29,
  .my-lg-n29 {
    margin-top: -17rem !important;
  }
  .mr-lg-n29,
  .mx-lg-n29 {
    margin-right: -17rem !important;
  }
  .mb-lg-n29,
  .my-lg-n29 {
    margin-bottom: -17rem !important;
  }
  .ml-lg-n29,
  .mx-lg-n29 {
    margin-left: -17rem !important;
  }
  .m-lg-n30 {
    margin: -18rem !important;
  }
  .mt-lg-n30,
  .my-lg-n30 {
    margin-top: -18rem !important;
  }
  .mr-lg-n30,
  .mx-lg-n30 {
    margin-right: -18rem !important;
  }
  .mb-lg-n30,
  .my-lg-n30 {
    margin-bottom: -18rem !important;
  }
  .ml-lg-n30,
  .mx-lg-n30 {
    margin-left: -18rem !important;
  }
  .m-lg-n31 {
    margin: -19rem !important;
  }
  .mt-lg-n31,
  .my-lg-n31 {
    margin-top: -19rem !important;
  }
  .mr-lg-n31,
  .mx-lg-n31 {
    margin-right: -19rem !important;
  }
  .mb-lg-n31,
  .my-lg-n31 {
    margin-bottom: -19rem !important;
  }
  .ml-lg-n31,
  .mx-lg-n31 {
    margin-left: -19rem !important;
  }
  .m-lg-n32 {
    margin: -20rem !important;
  }
  .mt-lg-n32,
  .my-lg-n32 {
    margin-top: -20rem !important;
  }
  .mr-lg-n32,
  .mx-lg-n32 {
    margin-right: -20rem !important;
  }
  .mb-lg-n32,
  .my-lg-n32 {
    margin-bottom: -20rem !important;
  }
  .ml-lg-n32,
  .mx-lg-n32 {
    margin-left: -20rem !important;
  }
  .m-lg-n37 {
    margin: -22.5rem !important;
  }
  .mt-lg-n37,
  .my-lg-n37 {
    margin-top: -22.5rem !important;
  }
  .mr-lg-n37,
  .mx-lg-n37 {
    margin-right: -22.5rem !important;
  }
  .mb-lg-n37,
  .my-lg-n37 {
    margin-bottom: -22.5rem !important;
  }
  .ml-lg-n37,
  .mx-lg-n37 {
    margin-left: -22.5rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 2rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 2rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 2rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 2rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 2rem !important;
  }
  .m-xl-6 {
    margin: 2.5rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 2.5rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 2.5rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 2.5rem !important;
  }
  .m-xl-7 {
    margin: 3rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 3rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 3rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 3rem !important;
  }
  .m-xl-8 {
    margin: 3.5rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 3.5rem !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 3.5rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 3.5rem !important;
  }
  .m-xl-9 {
    margin: 4rem !important;
  }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 4rem !important;
  }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 4rem !important;
  }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 4rem !important;
  }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 4rem !important;
  }
  .m-xl-10 {
    margin: 4.5rem !important;
  }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 4.5rem !important;
  }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 4.5rem !important;
  }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 4.5rem !important;
  }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 4.5rem !important;
  }
  .m-xl-11 {
    margin: 5rem !important;
  }
  .mt-xl-11,
  .my-xl-11 {
    margin-top: 5rem !important;
  }
  .mr-xl-11,
  .mx-xl-11 {
    margin-right: 5rem !important;
  }
  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 5rem !important;
  }
  .ml-xl-11,
  .mx-xl-11 {
    margin-left: 5rem !important;
  }
  .m-xl-12 {
    margin: 5.5rem !important;
  }
  .mt-xl-12,
  .my-xl-12 {
    margin-top: 5.5rem !important;
  }
  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 5.5rem !important;
  }
  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 5.5rem !important;
  }
  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 5.5rem !important;
  }
  .m-xl-13 {
    margin: 6rem !important;
  }
  .mt-xl-13,
  .my-xl-13 {
    margin-top: 6rem !important;
  }
  .mr-xl-13,
  .mx-xl-13 {
    margin-right: 6rem !important;
  }
  .mb-xl-13,
  .my-xl-13 {
    margin-bottom: 6rem !important;
  }
  .ml-xl-13,
  .mx-xl-13 {
    margin-left: 6rem !important;
  }
  .m-xl-14 {
    margin: 6.5rem !important;
  }
  .mt-xl-14,
  .my-xl-14 {
    margin-top: 6.5rem !important;
  }
  .mr-xl-14,
  .mx-xl-14 {
    margin-right: 6.5rem !important;
  }
  .mb-xl-14,
  .my-xl-14 {
    margin-bottom: 6.5rem !important;
  }
  .ml-xl-14,
  .mx-xl-14 {
    margin-left: 6.5rem !important;
  }
  .m-xl-15 {
    margin: 7rem !important;
  }
  .mt-xl-15,
  .my-xl-15 {
    margin-top: 7rem !important;
  }
  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 7rem !important;
  }
  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 7rem !important;
  }
  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 7rem !important;
  }
  .m-xl-16 {
    margin: 7.5rem !important;
  }
  .mt-xl-16,
  .my-xl-16 {
    margin-top: 7.5rem !important;
  }
  .mr-xl-16,
  .mx-xl-16 {
    margin-right: 7.5rem !important;
  }
  .mb-xl-16,
  .my-xl-16 {
    margin-bottom: 7.5rem !important;
  }
  .ml-xl-16,
  .mx-xl-16 {
    margin-left: 7.5rem !important;
  }
  .m-xl-17 {
    margin: 8rem !important;
  }
  .mt-xl-17,
  .my-xl-17 {
    margin-top: 8rem !important;
  }
  .mr-xl-17,
  .mx-xl-17 {
    margin-right: 8rem !important;
  }
  .mb-xl-17,
  .my-xl-17 {
    margin-bottom: 8rem !important;
  }
  .ml-xl-17,
  .mx-xl-17 {
    margin-left: 8rem !important;
  }
  .m-xl-18 {
    margin: 8.5rem !important;
  }
  .mt-xl-18,
  .my-xl-18 {
    margin-top: 8.5rem !important;
  }
  .mr-xl-18,
  .mx-xl-18 {
    margin-right: 8.5rem !important;
  }
  .mb-xl-18,
  .my-xl-18 {
    margin-bottom: 8.5rem !important;
  }
  .ml-xl-18,
  .mx-xl-18 {
    margin-left: 8.5rem !important;
  }
  .m-xl-19 {
    margin: 9rem !important;
  }
  .mt-xl-19,
  .my-xl-19 {
    margin-top: 9rem !important;
  }
  .mr-xl-19,
  .mx-xl-19 {
    margin-right: 9rem !important;
  }
  .mb-xl-19,
  .my-xl-19 {
    margin-bottom: 9rem !important;
  }
  .ml-xl-19,
  .mx-xl-19 {
    margin-left: 9rem !important;
  }
  .m-xl-20 {
    margin: 9.5rem !important;
  }
  .mt-xl-20,
  .my-xl-20 {
    margin-top: 9.5rem !important;
  }
  .mr-xl-20,
  .mx-xl-20 {
    margin-right: 9.5rem !important;
  }
  .mb-xl-20,
  .my-xl-20 {
    margin-bottom: 9.5rem !important;
  }
  .ml-xl-20,
  .mx-xl-20 {
    margin-left: 9.5rem !important;
  }
  .m-xl-21 {
    margin: 10rem !important;
  }
  .mt-xl-21,
  .my-xl-21 {
    margin-top: 10rem !important;
  }
  .mr-xl-21,
  .mx-xl-21 {
    margin-right: 10rem !important;
  }
  .mb-xl-21,
  .my-xl-21 {
    margin-bottom: 10rem !important;
  }
  .ml-xl-21,
  .mx-xl-21 {
    margin-left: 10rem !important;
  }
  .m-xl-22 {
    margin: 11rem !important;
  }
  .mt-xl-22,
  .my-xl-22 {
    margin-top: 11rem !important;
  }
  .mr-xl-22,
  .mx-xl-22 {
    margin-right: 11rem !important;
  }
  .mb-xl-22,
  .my-xl-22 {
    margin-bottom: 11rem !important;
  }
  .ml-xl-22,
  .mx-xl-22 {
    margin-left: 11rem !important;
  }
  .m-xl-23 {
    margin: 12rem !important;
  }
  .mt-xl-23,
  .my-xl-23 {
    margin-top: 12rem !important;
  }
  .mr-xl-23,
  .mx-xl-23 {
    margin-right: 12rem !important;
  }
  .mb-xl-23,
  .my-xl-23 {
    margin-bottom: 12rem !important;
  }
  .ml-xl-23,
  .mx-xl-23 {
    margin-left: 12rem !important;
  }
  .m-xl-24 {
    margin: 13rem !important;
  }
  .mt-xl-24,
  .my-xl-24 {
    margin-top: 13rem !important;
  }
  .mr-xl-24,
  .mx-xl-24 {
    margin-right: 13rem !important;
  }
  .mb-xl-24,
  .my-xl-24 {
    margin-bottom: 13rem !important;
  }
  .ml-xl-24,
  .mx-xl-24 {
    margin-left: 13rem !important;
  }
  .m-xl-25 {
    margin: 13.5rem !important;
  }
  .mt-xl-25,
  .my-xl-25 {
    margin-top: 13.5rem !important;
  }
  .mr-xl-25,
  .mx-xl-25 {
    margin-right: 13.5rem !important;
  }
  .mb-xl-25,
  .my-xl-25 {
    margin-bottom: 13.5rem !important;
  }
  .ml-xl-25,
  .mx-xl-25 {
    margin-left: 13.5rem !important;
  }
  .m-xl-26 {
    margin: 14rem !important;
  }
  .mt-xl-26,
  .my-xl-26 {
    margin-top: 14rem !important;
  }
  .mr-xl-26,
  .mx-xl-26 {
    margin-right: 14rem !important;
  }
  .mb-xl-26,
  .my-xl-26 {
    margin-bottom: 14rem !important;
  }
  .ml-xl-26,
  .mx-xl-26 {
    margin-left: 14rem !important;
  }
  .m-xl-27 {
    margin: 15rem !important;
  }
  .mt-xl-27,
  .my-xl-27 {
    margin-top: 15rem !important;
  }
  .mr-xl-27,
  .mx-xl-27 {
    margin-right: 15rem !important;
  }
  .mb-xl-27,
  .my-xl-27 {
    margin-bottom: 15rem !important;
  }
  .ml-xl-27,
  .mx-xl-27 {
    margin-left: 15rem !important;
  }
  .m-xl-28 {
    margin: 16rem !important;
  }
  .mt-xl-28,
  .my-xl-28 {
    margin-top: 16rem !important;
  }
  .mr-xl-28,
  .mx-xl-28 {
    margin-right: 16rem !important;
  }
  .mb-xl-28,
  .my-xl-28 {
    margin-bottom: 16rem !important;
  }
  .ml-xl-28,
  .mx-xl-28 {
    margin-left: 16rem !important;
  }
  .m-xl-29 {
    margin: 17rem !important;
  }
  .mt-xl-29,
  .my-xl-29 {
    margin-top: 17rem !important;
  }
  .mr-xl-29,
  .mx-xl-29 {
    margin-right: 17rem !important;
  }
  .mb-xl-29,
  .my-xl-29 {
    margin-bottom: 17rem !important;
  }
  .ml-xl-29,
  .mx-xl-29 {
    margin-left: 17rem !important;
  }
  .m-xl-30 {
    margin: 18rem !important;
  }
  .mt-xl-30,
  .my-xl-30 {
    margin-top: 18rem !important;
  }
  .mr-xl-30,
  .mx-xl-30 {
    margin-right: 18rem !important;
  }
  .mb-xl-30,
  .my-xl-30 {
    margin-bottom: 18rem !important;
  }
  .ml-xl-30,
  .mx-xl-30 {
    margin-left: 18rem !important;
  }
  .m-xl-31 {
    margin: 19rem !important;
  }
  .mt-xl-31,
  .my-xl-31 {
    margin-top: 19rem !important;
  }
  .mr-xl-31,
  .mx-xl-31 {
    margin-right: 19rem !important;
  }
  .mb-xl-31,
  .my-xl-31 {
    margin-bottom: 19rem !important;
  }
  .ml-xl-31,
  .mx-xl-31 {
    margin-left: 19rem !important;
  }
  .m-xl-32 {
    margin: 20rem !important;
  }
  .mt-xl-32,
  .my-xl-32 {
    margin-top: 20rem !important;
  }
  .mr-xl-32,
  .mx-xl-32 {
    margin-right: 20rem !important;
  }
  .mb-xl-32,
  .my-xl-32 {
    margin-bottom: 20rem !important;
  }
  .ml-xl-32,
  .mx-xl-32 {
    margin-left: 20rem !important;
  }
  .m-xl-37 {
    margin: 22.5rem !important;
  }
  .mt-xl-37,
  .my-xl-37 {
    margin-top: 22.5rem !important;
  }
  .mr-xl-37,
  .mx-xl-37 {
    margin-right: 22.5rem !important;
  }
  .mb-xl-37,
  .my-xl-37 {
    margin-bottom: 22.5rem !important;
  }
  .ml-xl-37,
  .mx-xl-37 {
    margin-left: 22.5rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 2rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 2rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 2rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 2rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 2rem !important;
  }
  .p-xl-6 {
    padding: 2.5rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 2.5rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 2.5rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 2.5rem !important;
  }
  .p-xl-7 {
    padding: 3rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 3rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 3rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 3rem !important;
  }
  .p-xl-8 {
    padding: 3.5rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 3.5rem !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 3.5rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 3.5rem !important;
  }
  .p-xl-9 {
    padding: 4rem !important;
  }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 4rem !important;
  }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 4rem !important;
  }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 4rem !important;
  }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 4rem !important;
  }
  .p-xl-10 {
    padding: 4.5rem !important;
  }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 4.5rem !important;
  }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 4.5rem !important;
  }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 4.5rem !important;
  }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 4.5rem !important;
  }
  .p-xl-11 {
    padding: 5rem !important;
  }
  .pt-xl-11,
  .py-xl-11 {
    padding-top: 5rem !important;
  }
  .pr-xl-11,
  .px-xl-11 {
    padding-right: 5rem !important;
  }
  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 5rem !important;
  }
  .pl-xl-11,
  .px-xl-11 {
    padding-left: 5rem !important;
  }
  .p-xl-12 {
    padding: 5.5rem !important;
  }
  .pt-xl-12,
  .py-xl-12 {
    padding-top: 5.5rem !important;
  }
  .pr-xl-12,
  .px-xl-12 {
    padding-right: 5.5rem !important;
  }
  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 5.5rem !important;
  }
  .pl-xl-12,
  .px-xl-12 {
    padding-left: 5.5rem !important;
  }
  .p-xl-13 {
    padding: 6rem !important;
  }
  .pt-xl-13,
  .py-xl-13 {
    padding-top: 6rem !important;
  }
  .pr-xl-13,
  .px-xl-13 {
    padding-right: 6rem !important;
  }
  .pb-xl-13,
  .py-xl-13 {
    padding-bottom: 6rem !important;
  }
  .pl-xl-13,
  .px-xl-13 {
    padding-left: 6rem !important;
  }
  .p-xl-14 {
    padding: 6.5rem !important;
  }
  .pt-xl-14,
  .py-xl-14 {
    padding-top: 6.5rem !important;
  }
  .pr-xl-14,
  .px-xl-14 {
    padding-right: 6.5rem !important;
  }
  .pb-xl-14,
  .py-xl-14 {
    padding-bottom: 6.5rem !important;
  }
  .pl-xl-14,
  .px-xl-14 {
    padding-left: 6.5rem !important;
  }
  .p-xl-15 {
    padding: 7rem !important;
  }
  .pt-xl-15,
  .py-xl-15 {
    padding-top: 7rem !important;
  }
  .pr-xl-15,
  .px-xl-15 {
    padding-right: 7rem !important;
  }
  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 7rem !important;
  }
  .pl-xl-15,
  .px-xl-15 {
    padding-left: 7rem !important;
  }
  .p-xl-16 {
    padding: 7.5rem !important;
  }
  .pt-xl-16,
  .py-xl-16 {
    padding-top: 7.5rem !important;
  }
  .pr-xl-16,
  .px-xl-16 {
    padding-right: 7.5rem !important;
  }
  .pb-xl-16,
  .py-xl-16 {
    padding-bottom: 7.5rem !important;
  }
  .pl-xl-16,
  .px-xl-16 {
    padding-left: 7.5rem !important;
  }
  .p-xl-17 {
    padding: 8rem !important;
  }
  .pt-xl-17,
  .py-xl-17 {
    padding-top: 8rem !important;
  }
  .pr-xl-17,
  .px-xl-17 {
    padding-right: 8rem !important;
  }
  .pb-xl-17,
  .py-xl-17 {
    padding-bottom: 8rem !important;
  }
  .pl-xl-17,
  .px-xl-17 {
    padding-left: 8rem !important;
  }
  .p-xl-18 {
    padding: 8.5rem !important;
  }
  .pt-xl-18,
  .py-xl-18 {
    padding-top: 8.5rem !important;
  }
  .pr-xl-18,
  .px-xl-18 {
    padding-right: 8.5rem !important;
  }
  .pb-xl-18,
  .py-xl-18 {
    padding-bottom: 8.5rem !important;
  }
  .pl-xl-18,
  .px-xl-18 {
    padding-left: 8.5rem !important;
  }
  .p-xl-19 {
    padding: 9rem !important;
  }
  .pt-xl-19,
  .py-xl-19 {
    padding-top: 9rem !important;
  }
  .pr-xl-19,
  .px-xl-19 {
    padding-right: 9rem !important;
  }
  .pb-xl-19,
  .py-xl-19 {
    padding-bottom: 9rem !important;
  }
  .pl-xl-19,
  .px-xl-19 {
    padding-left: 9rem !important;
  }
  .p-xl-20 {
    padding: 9.5rem !important;
  }
  .pt-xl-20,
  .py-xl-20 {
    padding-top: 9.5rem !important;
  }
  .pr-xl-20,
  .px-xl-20 {
    padding-right: 9.5rem !important;
  }
  .pb-xl-20,
  .py-xl-20 {
    padding-bottom: 9.5rem !important;
  }
  .pl-xl-20,
  .px-xl-20 {
    padding-left: 9.5rem !important;
  }
  .p-xl-21 {
    padding: 10rem !important;
  }
  .pt-xl-21,
  .py-xl-21 {
    padding-top: 10rem !important;
  }
  .pr-xl-21,
  .px-xl-21 {
    padding-right: 10rem !important;
  }
  .pb-xl-21,
  .py-xl-21 {
    padding-bottom: 10rem !important;
  }
  .pl-xl-21,
  .px-xl-21 {
    padding-left: 10rem !important;
  }
  .p-xl-22 {
    padding: 11rem !important;
  }
  .pt-xl-22,
  .py-xl-22 {
    padding-top: 11rem !important;
  }
  .pr-xl-22,
  .px-xl-22 {
    padding-right: 11rem !important;
  }
  .pb-xl-22,
  .py-xl-22 {
    padding-bottom: 11rem !important;
  }
  .pl-xl-22,
  .px-xl-22 {
    padding-left: 11rem !important;
  }
  .p-xl-23 {
    padding: 12rem !important;
  }
  .pt-xl-23,
  .py-xl-23 {
    padding-top: 12rem !important;
  }
  .pr-xl-23,
  .px-xl-23 {
    padding-right: 12rem !important;
  }
  .pb-xl-23,
  .py-xl-23 {
    padding-bottom: 12rem !important;
  }
  .pl-xl-23,
  .px-xl-23 {
    padding-left: 12rem !important;
  }
  .p-xl-24 {
    padding: 13rem !important;
  }
  .pt-xl-24,
  .py-xl-24 {
    padding-top: 13rem !important;
  }
  .pr-xl-24,
  .px-xl-24 {
    padding-right: 13rem !important;
  }
  .pb-xl-24,
  .py-xl-24 {
    padding-bottom: 13rem !important;
  }
  .pl-xl-24,
  .px-xl-24 {
    padding-left: 13rem !important;
  }
  .p-xl-25 {
    padding: 13.5rem !important;
  }
  .pt-xl-25,
  .py-xl-25 {
    padding-top: 13.5rem !important;
  }
  .pr-xl-25,
  .px-xl-25 {
    padding-right: 13.5rem !important;
  }
  .pb-xl-25,
  .py-xl-25 {
    padding-bottom: 13.5rem !important;
  }
  .pl-xl-25,
  .px-xl-25 {
    padding-left: 13.5rem !important;
  }
  .p-xl-26 {
    padding: 14rem !important;
  }
  .pt-xl-26,
  .py-xl-26 {
    padding-top: 14rem !important;
  }
  .pr-xl-26,
  .px-xl-26 {
    padding-right: 14rem !important;
  }
  .pb-xl-26,
  .py-xl-26 {
    padding-bottom: 14rem !important;
  }
  .pl-xl-26,
  .px-xl-26 {
    padding-left: 14rem !important;
  }
  .p-xl-27 {
    padding: 15rem !important;
  }
  .pt-xl-27,
  .py-xl-27 {
    padding-top: 15rem !important;
  }
  .pr-xl-27,
  .px-xl-27 {
    padding-right: 15rem !important;
  }
  .pb-xl-27,
  .py-xl-27 {
    padding-bottom: 15rem !important;
  }
  .pl-xl-27,
  .px-xl-27 {
    padding-left: 15rem !important;
  }
  .p-xl-28 {
    padding: 16rem !important;
  }
  .pt-xl-28,
  .py-xl-28 {
    padding-top: 16rem !important;
  }
  .pr-xl-28,
  .px-xl-28 {
    padding-right: 16rem !important;
  }
  .pb-xl-28,
  .py-xl-28 {
    padding-bottom: 16rem !important;
  }
  .pl-xl-28,
  .px-xl-28 {
    padding-left: 16rem !important;
  }
  .p-xl-29 {
    padding: 17rem !important;
  }
  .pt-xl-29,
  .py-xl-29 {
    padding-top: 17rem !important;
  }
  .pr-xl-29,
  .px-xl-29 {
    padding-right: 17rem !important;
  }
  .pb-xl-29,
  .py-xl-29 {
    padding-bottom: 17rem !important;
  }
  .pl-xl-29,
  .px-xl-29 {
    padding-left: 17rem !important;
  }
  .p-xl-30 {
    padding: 18rem !important;
  }
  .pt-xl-30,
  .py-xl-30 {
    padding-top: 18rem !important;
  }
  .pr-xl-30,
  .px-xl-30 {
    padding-right: 18rem !important;
  }
  .pb-xl-30,
  .py-xl-30 {
    padding-bottom: 18rem !important;
  }
  .pl-xl-30,
  .px-xl-30 {
    padding-left: 18rem !important;
  }
  .p-xl-31 {
    padding: 19rem !important;
  }
  .pt-xl-31,
  .py-xl-31 {
    padding-top: 19rem !important;
  }
  .pr-xl-31,
  .px-xl-31 {
    padding-right: 19rem !important;
  }
  .pb-xl-31,
  .py-xl-31 {
    padding-bottom: 19rem !important;
  }
  .pl-xl-31,
  .px-xl-31 {
    padding-left: 19rem !important;
  }
  .p-xl-32 {
    padding: 20rem !important;
  }
  .pt-xl-32,
  .py-xl-32 {
    padding-top: 20rem !important;
  }
  .pr-xl-32,
  .px-xl-32 {
    padding-right: 20rem !important;
  }
  .pb-xl-32,
  .py-xl-32 {
    padding-bottom: 20rem !important;
  }
  .pl-xl-32,
  .px-xl-32 {
    padding-left: 20rem !important;
  }
  .p-xl-37 {
    padding: 22.5rem !important;
  }
  .pt-xl-37,
  .py-xl-37 {
    padding-top: 22.5rem !important;
  }
  .pr-xl-37,
  .px-xl-37 {
    padding-right: 22.5rem !important;
  }
  .pb-xl-37,
  .py-xl-37 {
    padding-bottom: 22.5rem !important;
  }
  .pl-xl-37,
  .px-xl-37 {
    padding-left: 22.5rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -2rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -2rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -2rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -2rem !important;
  }
  .m-xl-n6 {
    margin: -2.5rem !important;
  }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -2.5rem !important;
  }
  .m-xl-n7 {
    margin: -3rem !important;
  }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -3rem !important;
  }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -3rem !important;
  }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -3rem !important;
  }
  .m-xl-n8 {
    margin: -3.5rem !important;
  }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -3.5rem !important;
  }
  .m-xl-n9 {
    margin: -4rem !important;
  }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -4rem !important;
  }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -4rem !important;
  }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -4rem !important;
  }
  .m-xl-n10 {
    margin: -4.5rem !important;
  }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -4.5rem !important;
  }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -4.5rem !important;
  }
  .m-xl-n11 {
    margin: -5rem !important;
  }
  .mt-xl-n11,
  .my-xl-n11 {
    margin-top: -5rem !important;
  }
  .mr-xl-n11,
  .mx-xl-n11 {
    margin-right: -5rem !important;
  }
  .mb-xl-n11,
  .my-xl-n11 {
    margin-bottom: -5rem !important;
  }
  .ml-xl-n11,
  .mx-xl-n11 {
    margin-left: -5rem !important;
  }
  .m-xl-n12 {
    margin: -5.5rem !important;
  }
  .mt-xl-n12,
  .my-xl-n12 {
    margin-top: -5.5rem !important;
  }
  .mr-xl-n12,
  .mx-xl-n12 {
    margin-right: -5.5rem !important;
  }
  .mb-xl-n12,
  .my-xl-n12 {
    margin-bottom: -5.5rem !important;
  }
  .ml-xl-n12,
  .mx-xl-n12 {
    margin-left: -5.5rem !important;
  }
  .m-xl-n13 {
    margin: -6rem !important;
  }
  .mt-xl-n13,
  .my-xl-n13 {
    margin-top: -6rem !important;
  }
  .mr-xl-n13,
  .mx-xl-n13 {
    margin-right: -6rem !important;
  }
  .mb-xl-n13,
  .my-xl-n13 {
    margin-bottom: -6rem !important;
  }
  .ml-xl-n13,
  .mx-xl-n13 {
    margin-left: -6rem !important;
  }
  .m-xl-n14 {
    margin: -6.5rem !important;
  }
  .mt-xl-n14,
  .my-xl-n14 {
    margin-top: -6.5rem !important;
  }
  .mr-xl-n14,
  .mx-xl-n14 {
    margin-right: -6.5rem !important;
  }
  .mb-xl-n14,
  .my-xl-n14 {
    margin-bottom: -6.5rem !important;
  }
  .ml-xl-n14,
  .mx-xl-n14 {
    margin-left: -6.5rem !important;
  }
  .m-xl-n15 {
    margin: -7rem !important;
  }
  .mt-xl-n15,
  .my-xl-n15 {
    margin-top: -7rem !important;
  }
  .mr-xl-n15,
  .mx-xl-n15 {
    margin-right: -7rem !important;
  }
  .mb-xl-n15,
  .my-xl-n15 {
    margin-bottom: -7rem !important;
  }
  .ml-xl-n15,
  .mx-xl-n15 {
    margin-left: -7rem !important;
  }
  .m-xl-n16 {
    margin: -7.5rem !important;
  }
  .mt-xl-n16,
  .my-xl-n16 {
    margin-top: -7.5rem !important;
  }
  .mr-xl-n16,
  .mx-xl-n16 {
    margin-right: -7.5rem !important;
  }
  .mb-xl-n16,
  .my-xl-n16 {
    margin-bottom: -7.5rem !important;
  }
  .ml-xl-n16,
  .mx-xl-n16 {
    margin-left: -7.5rem !important;
  }
  .m-xl-n17 {
    margin: -8rem !important;
  }
  .mt-xl-n17,
  .my-xl-n17 {
    margin-top: -8rem !important;
  }
  .mr-xl-n17,
  .mx-xl-n17 {
    margin-right: -8rem !important;
  }
  .mb-xl-n17,
  .my-xl-n17 {
    margin-bottom: -8rem !important;
  }
  .ml-xl-n17,
  .mx-xl-n17 {
    margin-left: -8rem !important;
  }
  .m-xl-n18 {
    margin: -8.5rem !important;
  }
  .mt-xl-n18,
  .my-xl-n18 {
    margin-top: -8.5rem !important;
  }
  .mr-xl-n18,
  .mx-xl-n18 {
    margin-right: -8.5rem !important;
  }
  .mb-xl-n18,
  .my-xl-n18 {
    margin-bottom: -8.5rem !important;
  }
  .ml-xl-n18,
  .mx-xl-n18 {
    margin-left: -8.5rem !important;
  }
  .m-xl-n19 {
    margin: -9rem !important;
  }
  .mt-xl-n19,
  .my-xl-n19 {
    margin-top: -9rem !important;
  }
  .mr-xl-n19,
  .mx-xl-n19 {
    margin-right: -9rem !important;
  }
  .mb-xl-n19,
  .my-xl-n19 {
    margin-bottom: -9rem !important;
  }
  .ml-xl-n19,
  .mx-xl-n19 {
    margin-left: -9rem !important;
  }
  .m-xl-n20 {
    margin: -9.5rem !important;
  }
  .mt-xl-n20,
  .my-xl-n20 {
    margin-top: -9.5rem !important;
  }
  .mr-xl-n20,
  .mx-xl-n20 {
    margin-right: -9.5rem !important;
  }
  .mb-xl-n20,
  .my-xl-n20 {
    margin-bottom: -9.5rem !important;
  }
  .ml-xl-n20,
  .mx-xl-n20 {
    margin-left: -9.5rem !important;
  }
  .m-xl-n21 {
    margin: -10rem !important;
  }
  .mt-xl-n21,
  .my-xl-n21 {
    margin-top: -10rem !important;
  }
  .mr-xl-n21,
  .mx-xl-n21 {
    margin-right: -10rem !important;
  }
  .mb-xl-n21,
  .my-xl-n21 {
    margin-bottom: -10rem !important;
  }
  .ml-xl-n21,
  .mx-xl-n21 {
    margin-left: -10rem !important;
  }
  .m-xl-n22 {
    margin: -11rem !important;
  }
  .mt-xl-n22,
  .my-xl-n22 {
    margin-top: -11rem !important;
  }
  .mr-xl-n22,
  .mx-xl-n22 {
    margin-right: -11rem !important;
  }
  .mb-xl-n22,
  .my-xl-n22 {
    margin-bottom: -11rem !important;
  }
  .ml-xl-n22,
  .mx-xl-n22 {
    margin-left: -11rem !important;
  }
  .m-xl-n23 {
    margin: -12rem !important;
  }
  .mt-xl-n23,
  .my-xl-n23 {
    margin-top: -12rem !important;
  }
  .mr-xl-n23,
  .mx-xl-n23 {
    margin-right: -12rem !important;
  }
  .mb-xl-n23,
  .my-xl-n23 {
    margin-bottom: -12rem !important;
  }
  .ml-xl-n23,
  .mx-xl-n23 {
    margin-left: -12rem !important;
  }
  .m-xl-n24 {
    margin: -13rem !important;
  }
  .mt-xl-n24,
  .my-xl-n24 {
    margin-top: -13rem !important;
  }
  .mr-xl-n24,
  .mx-xl-n24 {
    margin-right: -13rem !important;
  }
  .mb-xl-n24,
  .my-xl-n24 {
    margin-bottom: -13rem !important;
  }
  .ml-xl-n24,
  .mx-xl-n24 {
    margin-left: -13rem !important;
  }
  .m-xl-n25 {
    margin: -13.5rem !important;
  }
  .mt-xl-n25,
  .my-xl-n25 {
    margin-top: -13.5rem !important;
  }
  .mr-xl-n25,
  .mx-xl-n25 {
    margin-right: -13.5rem !important;
  }
  .mb-xl-n25,
  .my-xl-n25 {
    margin-bottom: -13.5rem !important;
  }
  .ml-xl-n25,
  .mx-xl-n25 {
    margin-left: -13.5rem !important;
  }
  .m-xl-n26 {
    margin: -14rem !important;
  }
  .mt-xl-n26,
  .my-xl-n26 {
    margin-top: -14rem !important;
  }
  .mr-xl-n26,
  .mx-xl-n26 {
    margin-right: -14rem !important;
  }
  .mb-xl-n26,
  .my-xl-n26 {
    margin-bottom: -14rem !important;
  }
  .ml-xl-n26,
  .mx-xl-n26 {
    margin-left: -14rem !important;
  }
  .m-xl-n27 {
    margin: -15rem !important;
  }
  .mt-xl-n27,
  .my-xl-n27 {
    margin-top: -15rem !important;
  }
  .mr-xl-n27,
  .mx-xl-n27 {
    margin-right: -15rem !important;
  }
  .mb-xl-n27,
  .my-xl-n27 {
    margin-bottom: -15rem !important;
  }
  .ml-xl-n27,
  .mx-xl-n27 {
    margin-left: -15rem !important;
  }
  .m-xl-n28 {
    margin: -16rem !important;
  }
  .mt-xl-n28,
  .my-xl-n28 {
    margin-top: -16rem !important;
  }
  .mr-xl-n28,
  .mx-xl-n28 {
    margin-right: -16rem !important;
  }
  .mb-xl-n28,
  .my-xl-n28 {
    margin-bottom: -16rem !important;
  }
  .ml-xl-n28,
  .mx-xl-n28 {
    margin-left: -16rem !important;
  }
  .m-xl-n29 {
    margin: -17rem !important;
  }
  .mt-xl-n29,
  .my-xl-n29 {
    margin-top: -17rem !important;
  }
  .mr-xl-n29,
  .mx-xl-n29 {
    margin-right: -17rem !important;
  }
  .mb-xl-n29,
  .my-xl-n29 {
    margin-bottom: -17rem !important;
  }
  .ml-xl-n29,
  .mx-xl-n29 {
    margin-left: -17rem !important;
  }
  .m-xl-n30 {
    margin: -18rem !important;
  }
  .mt-xl-n30,
  .my-xl-n30 {
    margin-top: -18rem !important;
  }
  .mr-xl-n30,
  .mx-xl-n30 {
    margin-right: -18rem !important;
  }
  .mb-xl-n30,
  .my-xl-n30 {
    margin-bottom: -18rem !important;
  }
  .ml-xl-n30,
  .mx-xl-n30 {
    margin-left: -18rem !important;
  }
  .m-xl-n31 {
    margin: -19rem !important;
  }
  .mt-xl-n31,
  .my-xl-n31 {
    margin-top: -19rem !important;
  }
  .mr-xl-n31,
  .mx-xl-n31 {
    margin-right: -19rem !important;
  }
  .mb-xl-n31,
  .my-xl-n31 {
    margin-bottom: -19rem !important;
  }
  .ml-xl-n31,
  .mx-xl-n31 {
    margin-left: -19rem !important;
  }
  .m-xl-n32 {
    margin: -20rem !important;
  }
  .mt-xl-n32,
  .my-xl-n32 {
    margin-top: -20rem !important;
  }
  .mr-xl-n32,
  .mx-xl-n32 {
    margin-right: -20rem !important;
  }
  .mb-xl-n32,
  .my-xl-n32 {
    margin-bottom: -20rem !important;
  }
  .ml-xl-n32,
  .mx-xl-n32 {
    margin-left: -20rem !important;
  }
  .m-xl-n37 {
    margin: -22.5rem !important;
  }
  .mt-xl-n37,
  .my-xl-n37 {
    margin-top: -22.5rem !important;
  }
  .mr-xl-n37,
  .mx-xl-n37 {
    margin-right: -22.5rem !important;
  }
  .mb-xl-n37,
  .my-xl-n37 {
    margin-bottom: -22.5rem !important;
  }
  .ml-xl-n37,
  .mx-xl-n37 {
    margin-left: -22.5rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 400 !important;
}

.font-weight-lighter {
  font-weight: 100 !important;
}

.font-weight-normal {
  font-weight: normal !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.font-weight-bolder {
  font-weight: 900 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #ef353d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #c81018 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #ef353d !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #c81018 !important;
}

.text-light {
  color: #f2f2f2 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cccccc !important;
}

.text-dark {
  color: #4d4d4d !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #272727 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
header {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  transition: 300ms;
}
header .header-container.active .open-search {
  fill: white !important;
}
header .header-container.active .open-search path {
  fill: white !important;
}
header .fa-search {
  width: 20px;
}
header .select {
  position: relative;
  background: transparent;
  /* .fa-chevron-down {
      position: absolute;
      right: 6px;
      top: 4px;
      font-size: 14px;
      z-index: -1;
  } */
}
header .select select {
  font-size: 14px;
  border: none;
  box-shadow: none;
  border-radius: 0;
  background: transparent;
  height: 100%;
  width: 100%;
  cursor: pointer;
  outline: none;
  padding-right: 24px;
  padding-left: 15px;
  -moz-appearance: none;
  -webkit-appearance: none;
  color: white;
}
header .select select:-moz-focusring {
  color: transparent;
}
header .select select::-ms-expand {
  display: none;
}
header .select select option {
  color: white;
}
@media all and (min-width: 0\0 ) and (min-resolution: 0.001dpcm) {
  header .select select {
    padding-right: 0;
  }
  header .select:after, header .select:before {
    display: none;
  }
}
header .toggle {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
}
header .toggle .line {
  transition: 200ms;
  background-color: white;
  height: 2px;
}
header .toggle .line.line1 {
  width: 42px;
}
header .toggle .line.line2 {
  width: 52px;
  margin: 0.5rem 0;
}
header .toggle .line.line3 {
  width: 30px;
}
header .toggle:hover .line {
  width: 52px;
}
header .toggle.on-off .line {
  width: 40px;
}
header .toggle.on-off .line1 {
  transform: rotate(45deg) translate(7.5px, 7.5px);
}
header .toggle.on-off .line2 {
  transform: rotate(90deg) translateX(10px) translateY(8.5px);
  opacity: 0;
}
header .toggle.on-off .line3 {
  transform: rotate(-45deg) translate(6.5px, -8px);
}
header .menu-language {
  min-width: 2rem;
  background: transparent;
}
header .menu-language li:first-child {
  display: none;
}

.header-logo {
  width: calc(10vw + 15px);
}

.header-logo-mobile img {
  width: 100%;
  width: calc(10vw + 15px);
}

.header .select {
  min-width: 52px;
}
.header.active .menu-btn,
.header.active .fa-search {
  color: #fff !important;
}
.header.active .toggle .line {
  background: #fff !important;
}
.header.active .select .fa-chevron-down,
.header.active .select select {
  color: #fff !important;
}
.header.active .language-link {
  color: white !important;
}

.dropdown .language-ul-list {
  will-change: none;
}

.navbar-logo {
  visibility: hidden;
}
.navbar-logo.active {
  visibility: visible;
}

.close-navbar.active {
  visibility: hidden;
  display: none;
}

.navbar-screen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: #ef353d;
  opacity: 0;
  overflow: hidden;
  z-index: 15;
  pointer-events: none;
  transform: translateY(-100%);
  transition: 300ms;
}
.navbar-screen.active {
  pointer-events: visible;
  opacity: 1;
  transform: translateY(0);
}
.navbar-screen .container {
  padding-top: calc(7vw + 15px);
}
.navbar-screen .nav-list li {
  position: relative;
}
.navbar-screen .nav-list li a {
  font-weight: normal;
  font-size: 32px;
  line-height: 2;
  letter-spacing: -0.02em;
  color: #ffffff;
  transition: 400ms;
  border-right: 3px solid transparent;
}
.navbar-screen .nav-list li a .hover {
  font-weight: 300;
  font-size: 72px;
  line-height: 100%;
  letter-spacing: 0.2em;
  color: rgba(255, 255, 255, 0.0823529412);
  position: absolute;
  top: 55%;
  transform: translateY(-50%) scale(0.9);
  text-transform: uppercase;
  left: -13%;
  opacity: 0;
  transition: 500ms;
  z-index: -1;
}
.navbar-screen .nav-list li a:hover {
  border-right: 3px solid white;
  font-weight: bold;
}
.navbar-screen .nav-list li a:hover .hover {
  opacity: 1;
  transform: translateY(-50%) scale(1);
}
.navbar-screen .social-media li a i {
  font-size: 24px;
}

.search-screen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: #ef353d;
  opacity: 0;
  overflow: hidden;
  z-index: 999;
  pointer-events: none;
  transform: translateY(-100%);
  transition: 300ms;
  /*   .close-search {
      position: absolute;
      top: 16px;
      right: 16px;
      font-size: 32px;
      width: 64px;
      height: 64px;
      background: white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $secondary;
  } */
}
.search-screen.active {
  pointer-events: visible;
  opacity: 1;
  transform: translateY(0);
}
.search-screen .search-wrapper {
  max-width: 720px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.search-screen .search-wrapper input {
  font-size: 24px;
  border: none;
  padding: 0.75rem 4rem 0.75rem 1rem;
  border-bottom: 3px solid white;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  outline-width: 0 !important;
  -webkit-box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  outline-style: none;
}
.search-screen .search-wrapper input:focus-visible {
  outline: none;
  border-bottom: 3px solid white !important;
}
.search-screen .search-wrapper input::placeholder {
  font-size: 24px;
  color: white;
}
.search-screen .search-wrapper .fa-search {
  font-size: 32px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
  right: 16px;
}
.search-screen .close-search {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 40px;
  height: 40px;
  opacity: 0.8;
}
.search-screen .close-search:hover {
  opacity: 1;
}
.search-screen .close-search:before,
.search-screen .close-search:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 40px;
  width: 2px;
  background-color: rgb(255, 255, 255);
}
.search-screen .close-search:before {
  transform: rotate(45deg);
}
.search-screen .close-search:after {
  transform: rotate(-45deg);
}
.search-screen .search-logo {
  width: calc(10vw + 15px);
}
.search-screen .fa-search {
  max-width: 24px;
}

@media (min-width: 992px) {
  .navbar-screen .container::after {
    content: "";
    position: absolute;
    height: 95vw;
    top: 0vw;
    left: calc(10vw + 14px);
    width: 2px;
    opacity: 0.2;
    background: white;
  }
  .navbar-screen .container::before {
    content: "";
    position: absolute;
    height: 8px;
    width: 8px;
    top: 50%;
    left: calc(10vw + 14px);
    background: white;
  }
  .line-custom {
    position: relative;
  }
  .line-custom::after {
    content: "";
    position: absolute;
    height: 78vw;
    top: -50vw;
    right: 15px;
    width: 2px;
    opacity: 0.2;
    background: white;
  }
}
.general-search-input:focus {
  outline-width: 0 !important;
  box-shadow: none !important;
  border-color: transparent !important;
}

[contenteditable=true]:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: transparent !important;
}

header .general-search-input:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: transparent !important;
}

@media (max-width: 1680px) {
  .navbar-screen .nav-list li a {
    font-size: 18px;
  }
  .navbar-screen .nav-list li a .hover {
    font-size: 48px;
  }
}
@media (max-width: 767.98px) {
  .select .fa-chevron-down {
    font-size: 12px !important;
    top: 5px !important;
  }
  .select select {
    padding-left: 0 !important;
    padding-right: 20px !important;
    font-size: 12px !important;
  }
  .select::before {
    width: 20px !important;
  }
}
@media (max-width: 575.98px) {
  .navbar-logo {
    visibility: hidden;
    max-width: 0 !important;
    min-width: 0 !important;
  }
  .navbar-logo.active {
    visibility: visible;
    max-width: 40vw !important;
    min-width: 40vw !important;
  }
  .header .menu-btn {
    font-size: 14px;
  }
  .header .toggle.on-off .line {
    width: 35px;
  }
  .header-logo-mobile img {
    max-width: 40vw;
    min-width: 40vw;
    position: relative;
    left: -30px;
  }
  .search-logo {
    width: 24vw !important;
  }
  .select,
  .open-search {
    display: none;
  }
  .close-search {
    display: block !important;
  }
  .open-search {
    max-width: 25px;
    width: 100%;
  }
  .header-container.active .select,
  .header-container.active .open-search {
    display: block;
  }
  .header-container.active .open-search {
    fill: white !important;
  }
  .header-container.active .open-search path {
    fill: white !important;
  }
  .header-container.active .menu-btn {
    display: none !important;
  }
  .navbar-screen .nav-list {
    margin-top: 28vw;
    margin-left: calc(40vw - 30px);
    border-bottom: 1px solid white;
  }
  .navbar-screen .nav-list li a {
    font-size: 18px;
    line-height: 2.2;
  }
  .navbar-screen .nav-list li a .hover {
    font-size: 28px !important;
    left: -3%;
  }
  .navbar-screen .wrapper-social {
    margin-left: calc(40vw - 30px);
  }
  .search-screen .search-wrapper input {
    padding: 1.2rem 4rem 1.2rem 1rem;
    font-size: 16px;
  }
  .search-screen .search-wrapper input::placeholder {
    font-size: 16px;
  }
  .search-screen .search-wrapper .fa-search {
    right: 16px;
    font-size: 24px;
  }
  .social-media li a i {
    font-size: 16px !important;
  }
}
@media (min-width: 992px) {
  .header-logo-mobile {
    opacity: 0;
    appearance: none;
    pointer-events: none;
  }
}
.header.header-height-style {
  background: rgba(255, 255, 255, 0.95);
}
.header.header-height-style .menu-btn,
.header.header-height-style .fa-search {
  color: #ef353d !important;
  fill: #ef353d !important;
}
.header.header-height-style .menu-btn path,
.header.header-height-style .fa-search path {
  fill: #ef353d !important;
}
.header.header-height-style .toggle .line {
  background: #ef353d !important;
}
.header.header-height-style .select .fa-chevron-down,
.header.header-height-style .select select {
  color: #ef353d !important;
}
.header.header-height-style .selected-language {
  color: #ef353d;
}
.header.active {
  background: none;
}
.header.active .menu-btn,
.header.active .fa-search {
  color: white !important;
  fill: white !important;
}
.header.active .menu-btn path,
.header.active .fa-search path {
  fill: white !important;
}
.header.active .toggle .line {
  background: white !important;
}
.header.active .select .fa-chevron-down,
.header.active .select select {
  color: white !important;
}
.header.active .selected-language {
  color: white !important;
}

@media (max-height: 630px) {
  .navbar-screen .nav-list li a {
    line-height: 1.5;
  }
}
@media (max-height: 578px) {
  .navbar-screen .nav-list li a {
    line-height: 1.3;
  }
}
footer {
  margin-top: 68px;
}
footer .footer-logo {
  position: absolute;
  width: calc(10vw + 15px);
  top: -68px;
  left: 0;
}
footer .footer-list-content {
  border-bottom: 1px solid #dddddd;
}

.footer-link {
  transition: 300ms;
}
.footer-link:hover {
  transform: scale(1.1);
}

@media (max-width: 991.98px) {
  footer {
    margin-top: 80px;
  }
  footer .footer-logo {
    position: relative !important;
    top: -6rem;
    left: 0;
  }
}
@media (max-width: 767.98px) {
  footer {
    margin-top: 40px;
  }
  footer .footer-logo {
    top: -3rem;
  }
}
@media (max-width: 575.98px) {
  footer .footer-logo {
    width: calc(40vw + 15px);
    margin-top: -40px;
  }
  footer .footer-list-content {
    padding-left: 24px;
    margin-top: -1rem;
    border-bottom: none;
  }
  footer .footer-bottom-row {
    margin-left: 24px;
  }
  footer .footer-bottom-row .copyright {
    max-width: 180px;
    padding-left: 0 !important;
    margin-top: 32px;
  }
  footer .footer-bottom-row .col-lg-5 {
    padding-left: 0 !important;
    border-bottom: 1px solid #dddddd;
  }
  footer .footer-bottom-row .col-lg-5 ul {
    margin-bottom: 32px;
  }
}
.home-slider .swiper-container {
  width: 100%;
  height: 100%;
}
.home-slider .swiper-wrapper {
  background: #000000;
}
.home-slider .swiper-slide {
  font-size: 18px;
  background-size: cover;
  width: 100%;
  background-repeat: no-repeat;
  height: 94vh;
}
.home-slider .swiper-slide .text-white h1 {
  font-size: 3.375rem;
}
.home-slider .swiper-slide .text-white p {
  font-size: 1.25rem;
}
.home-slider .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home-slider .slider-content {
  margin-top: calc(10vw + 15px);
}
.home-slider .slider-index-no {
  font-family: Kursk;
  font-style: normal;
  font-weight: 500;
  font-size: 160px;
  line-height: 1.2;
  color: #ffffff;
  opacity: 0.15;
  position: absolute;
  top: -40px;
  left: -80px;
}
.home-slider .swiper-pagination {
  bottom: initial;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
}
.home-slider .swiper-pagination .swiper-pagination-bullet {
  background: transparent;
  color: white;
  font-style: normal;
  font-weight: bolder;
  font-size: 12px;
  opacity: 0.8;
  margin-right: 32px;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
}
.home-slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  color: #ef353d;
}
.home-slider .swiper-slide .slider-index-no {
  opacity: 0;
  transition: 1s;
  transform: translateX(-70px);
}
.home-slider .swiper-slide .swiper-pagination-bullet {
  opacity: 0;
  transition: 1s;
  transform: translateY(-100px);
}
.home-slider .swiper-slide .swiper-pagination-bullet:nth-child(1) {
  transform: translateY(-100px);
}
.home-slider .swiper-slide .swiper-pagination-bullet:nth-child(2) {
  transform: translateY(-120px);
}
.home-slider .swiper-slide .swiper-pagination-bullet:nth-child(3) {
  transform: translateY(-140px);
}
.home-slider .swiper-slide .swiper-pagination-bullet:nth-child(4) {
  transform: translateY(-160px);
}
.home-slider .swiper-slide .swiper-pagination-bullet:nth-child(5) {
  transform: translateY(-180px);
}
.home-slider .swiper-slide .text-white h1 {
  opacity: 0;
  transition: 1s;
  transform: translateY(100px);
}
.home-slider .swiper-slide .text-white p {
  opacity: 0;
  transition: 1s;
  transform: translateY(150px);
}
.home-slider .swiper-slide .text-white a {
  opacity: 0;
  transition: 1s;
  transform: translateY(200px);
}
.home-slider .swiper-slide.swiper-slide-active .slider-index-no {
  opacity: 0.15;
  transition: 1s;
  transform: translateX(0);
}
.home-slider .swiper-slide.swiper-slide-active .swiper-pagination-bullet {
  opacity: 1;
  transition: 1s;
  transform: translateY(0);
}
.home-slider .swiper-slide.swiper-slide-active .text-white h1 {
  opacity: 1;
  transition: 1s;
  transform: translateY(0);
}
.home-slider .swiper-slide.swiper-slide-active .text-white p {
  opacity: 1;
  transition: 1s;
  transform: translateY(0);
}
.home-slider .swiper-slide.swiper-slide-active .text-white a {
  opacity: 1;
  transition: 1s;
  transform: translateY(0);
}

.home-about-section .card-img-overlayy {
  padding: 230px 72px 72px 72px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  background-image: url(/assets/dokumhaneYeni.jpg);
  background-size: cover;
}
.home-about-section .card-img-overlayy.two {
  background-image: url(/assets/talasli-imalat-2.jpeg);
}
.home-about-section .card-img-overlayy .more-text p {
  height: 0;
  overflow: hidden;
  transition: 300ms;
  font-size: 20px;
  line-height: 1.5;
}
.home-about-section .card-img-overlayy .more-text.active p {
  height: auto;
}
.home-about-section .card-img-overlayy .more-text.active .outline-btn {
  display: none !important;
}
.home-about-section .about-card .card-no {
  font-family: Kursk;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 100%;
  color: #ffffff;
  mix-blend-mode: screen;
  opacity: 0.2;
}
.home-about-section .about-card h2 {
  font-size: 72px;
  line-height: 100%;
  letter-spacing: -0.03em;
}

.home-products-section .section-secondary-title {
  position: relative;
}
.home-products-section .section-secondary-title::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  background: #ef353d;
  right: 20vw;
}
.home-products-section .card {
  border: none;
}
.home-products-section .card .card-body {
  border: 1px solid #e5e5e5;
  border-top: none;
}
.home-products-section .home-products-slider {
  padding-bottom: 106px;
}
.home-products-section .home-products-slider .swiper-button-next,
.home-products-section .home-products-slider .swiper-button-prev {
  top: initial;
  bottom: 0;
  width: 56px;
  height: 56px;
  background-color: #dddddd;
}
.home-products-section .home-products-slider .swiper-button-next::after,
.home-products-section .home-products-slider .swiper-button-prev::after {
  font-size: 20px;
  color: #4d4d4d;
}
.home-products-section .home-products-slider .swiper-button-prev {
  left: 0;
}
.home-products-section .home-products-slider .swiper-button-next {
  left: 56px;
}
.home-products-section .home-products-slider .view-all-btn {
  position: absolute;
  bottom: 2px;
  right: 0;
}
.home-products-section .swiper-button-next.next-custom {
  bottom: 65%;
  left: initial;
  right: -30px;
  background: transparent;
}
.home-products-section .swiper-button-next.next-custom::after {
  color: #ef353d;
  font-size: 28px;
}

.home-catalogue-section {
  z-index: 2;
}

.home-references-section {
  z-index: 2;
}
.home-references-section .references-box {
  width: 13vw;
  height: 9vw;
  margin-right: 2vw;
  margin-bottom: 2vw;
  border: 1px solid #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-references-section .references-box figure {
  overflow: hidden;
}

.home-news-section .home-news-slider {
  margin-top: -230px;
}
.home-news-section .position-relative .swiper-button-next,
.home-news-section .position-relative .swiper-button-prev {
  top: 20%;
}
.home-news-section .position-relative .swiper-button-next::after,
.home-news-section .position-relative .swiper-button-prev::after {
  font-size: 28px;
  color: #fff;
}
.home-news-section .position-relative .swiper-button-prev {
  left: -45px;
}
.home-news-section .position-relative .swiper-button-next {
  right: -45px;
}
.home-news-section .news-wrapper {
  padding-top: 7.125rem;
  padding-bottom: 18.75rem;
  padding-left: 2rem;
  position: relative;
}
.home-news-section .news-wrapper::after {
  content: "";
  position: absolute;
  width: 150vw;
  height: 100%;
  left: -125px;
  top: 0;
  right: 0;
  bottom: 0;
  background: #ef353d;
}
.home-news-section .news-wrapper .col-lg-8 {
  position: relative;
  z-index: 4;
}
.home-news-section .news-wrapper h1 {
  font-weight: normal;
  font-size: 48px;
  line-height: 1.1;
  letter-spacing: -0.02em;
}

.join-team-section h1 {
  font-size: 40px;
  line-height: 1.1;
  letter-spacing: -0.02em;
  border-left: 3px solid #ef353d;
}
.join-team-section .wrapper-team {
  position: relative;
}
.join-team-section .wrapper-team::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: -125px;
  top: 0;
  right: 0;
  bottom: 0;
  background: #f2f2f2;
  z-index: -1;
}

.home-follow-us-section ul li i {
  font-size: 32px;
  color: #ef353d;
}

.join-card-img-overlayy-mobile {
  padding: 300px 32px 72px 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  background-size: cover;
  width: 100%;
  background-repeat: no-repeat;
  height: 654px;
  position: relative;
}
.join-card-img-overlayy-mobile .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 0.8;
}
.join-card-img-overlayy-mobile .outline-btn {
  color: white !important;
}
.join-card-img-overlayy-mobile .outline-btn::after {
  background: white !important;
}

@media (max-width: 1680px) {
  .home-about-section .about-card .card-img-overlayy h2 {
    font-size: 56px;
  }
  .home-about-section .about-card .card-img-overlayy h4 {
    font-size: 1.25rem;
  }
  .home-about-section .about-card .card-img-overlayy .outline-btn {
    font-size: 0.875rem;
  }
  .home-about-section .about-card .card-img-overlayy .more-text .read-more-description {
    font-size: 16px;
  }
  .home-news-section .news-wrapper h1 {
    font-size: 40px;
  }
  .home-news-section .home-news-slider .swiper-wrapper .swiper-slide .card .card-body a {
    font-size: 0.675rem;
  }
  .home-news-section .home-news-slider .swiper-wrapper .swiper-slide .card .card-body .card-title {
    font-size: 0.875rem;
  }
  .home-news-section .home-news-slider .swiper-wrapper .swiper-slide .card .card-body p {
    font-size: 0.625rem;
  }
  .home-news-section .home-news-slider .swiper-wrapper .swiper-slide .card .card-body .outline-btn {
    font-size: 0.625rem;
  }
  .join-team-section .section-title-custom {
    font-size: 40px;
  }
}
@media (max-width: 1440px) {
  .home-slider .text-white h1 {
    font-size: 2.5rem;
  }
  .home-slider .text-white p {
    font-size: 1.1rem;
  }
  .home-about-section .about-card .card-img-overlayy h2 {
    font-size: 40px;
  }
  .home-about-section .about-card .card-img-overlayy h4 {
    font-size: 1rem;
  }
  .home-about-section .about-card .card-img-overlayy .outline-btn {
    font-size: 0.775rem;
  }
  .home-about-section .about-card .card-img-overlayy .more-text .read-more-description {
    font-size: 14px;
  }
  .home-products-section .swiper-slide .card .card-body .card-title {
    font-size: 1rem;
  }
  .home-products-section .swiper-slide .card .card-body .card-text {
    font-size: 0.625rem;
  }
  .home-products-section .swiper-slide .card .card-body a {
    font-size: 0.625rem;
  }
  .home-products-section .swiper-slide .card .card-body a h3 {
    font-size: 1.5rem;
  }
  .home-catalogue-section .col-md-4 .icon-file {
    font-size: 1.5rem;
  }
  .home-catalogue-section .col-md-4 .content h2 {
    font-size: 1.625rem;
  }
  .home-news-section .news-wrapper h1 {
    font-size: 40px;
  }
  .home-news-section .home-news-slider .swiper-wrapper .swiper-slide .card .card-img-top {
    max-height: 276px;
    object-fit: cover;
  }
  .home-news-section .home-news-slider .swiper-wrapper .swiper-slide .card .card-body a {
    font-size: 0.675rem;
  }
  .home-news-section .home-news-slider .swiper-wrapper .swiper-slide .card .card-body .card-title {
    font-size: 0.725rem;
  }
  .home-news-section .home-news-slider .swiper-wrapper .swiper-slide .card .card-body p {
    font-size: 0.575rem;
  }
  .home-news-section .home-news-slider .swiper-wrapper .swiper-slide .card .card-body .outline-btn {
    font-size: 0.575rem;
  }
  .home-news-section .home-news-slider .swiper-wrapper .swiper-slide .card .card-body .more-text .read-more-description {
    font-size: 12px;
  }
  .join-team-section .section-title-custom {
    font-size: 32px;
    padding: 1.2rem 0 1.2rem 2rem;
  }
}
@media (max-width: 1280px) {
  .home-about-section .about-card .card-img-overlayy {
    display: flex;
    justify-content: center;
  }
  .home-about-section .about-card .card-img-overlayy .card-no {
    font-size: 24px;
  }
  .home-about-section .about-card .card-img-overlayy h2 {
    font-size: 32px;
  }
  .home-about-section .about-card .card-img-overlayy h4 {
    font-size: 0.875rem;
  }
  .home-about-section .about-card .card-img-overlayy .outline-btn {
    font-size: 0.675rem;
  }
  .join-team-section .section-title-custom {
    padding: 1.125rem 0 1.125rem 2rem;
    font-size: 28px;
  }
}
@media (max-width: 1080px) {
  .home-references-section .references-box {
    width: 11vw;
    height: 7vw;
    margin-right: 2vw;
    margin-bottom: 2vw;
  }
}
@media (max-width: 991.98px) {
  .home-slider .slider-content {
    margin-top: calc(10vw + 55px);
  }
  .home-slider .swiper-slide {
    height: 680px;
  }
  .home-slider .text-white h1 {
    font-size: 2rem;
  }
  .home-about-section .about-card .card-img-overlayy {
    display: flex;
    justify-content: center;
    padding: 230px 40px 40px 40px;
  }
  .home-about-section .about-card .card-img-overlayy .card-no {
    font-size: 18px;
  }
  .home-about-section .about-card .card-img-overlayy h2 {
    font-size: 24px;
  }
  .home-about-section .about-card .card-img-overlayy h4 {
    font-size: 0.775rem;
  }
  .home-about-section .about-card .card-img-overlayy .outline-btn {
    font-size: 0.5rem;
  }
  .home-about-section .about-card .card-img-overlayy .more-text .read-more-description {
    font-size: 10px;
  }
  .home-references-section .col-lg-2 {
    min-width: 280px;
  }
  .join-team-section .section-title-custom {
    font-size: 28px;
    padding: 1rem 0 1rem 2rem;
  }
  .join-team-section .wrapper-team::after {
    width: 150vw;
  }
  .home-references-section .references-box {
    width: 17vw;
    height: 12vw;
    margin-right: 2vw;
    margin-bottom: 2vw;
  }
}
@media (max-width: 767.98px) {
  .home-slider .swiper-slide {
    height: 580px;
  }
  .home-slider .swiper-slide .text-white h1 {
    font-size: 2.5rem;
  }
  .home-slider .swiper-slide .text-white p {
    font-size: 1rem;
  }
  .home-about-section .about-card .card-img-overlayy {
    display: flex;
    justify-content: center;
    padding: 230px 40px 40px 40px;
  }
  .home-about-section .about-card .card-img-overlayy .card-no {
    font-size: 16px;
  }
  .home-about-section .about-card .card-img-overlayy h2 {
    font-size: 20px;
  }
  .home-about-section .about-card .card-img-overlayy h4 {
    font-size: 0.625rem;
  }
  .home-about-section .about-card .card-img-overlayy .outline-btn {
    font-size: 0.5rem;
  }
  .home-products-section .home-products-slider {
    padding-bottom: 60px;
  }
  .home-products-section .home-products-slider .view-all-btn {
    font-size: 0.875rem;
  }
  .home-news-section .news-wrapper {
    padding-top: 4.125rem;
    padding-bottom: 15.75rem;
  }
  .join-team-section .section-title-custom {
    font-size: 22px;
    padding: 0.875rem 0 0.875rem 2rem;
    margin-bottom: 1rem;
  }
  .home-references-section .references-box {
    width: 27vw;
    height: 19vw;
    margin-right: 2vw;
    margin-bottom: 2vw;
  }
}
@media (max-width: 575.98px) {
  .home-slider .swiper-slide {
    height: 735px;
    align-items: flex-start;
    position: relative;
  }
  .home-slider .swiper-slide::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.8;
    z-index: -1;
  }
  .home-slider .swiper-slide:nth-child(1) {
    background-position-x: 58%;
  }
  .home-slider .swiper-slide:nth-child(2) {
    background-position-x: 55%;
  }
  .home-slider .swiper-slide:nth-child(4) {
    background-position-x: 50%;
  }
  .home-slider .text-white h1 {
    font-size: 40px;
    line-height: 44px;
  }
  .home-slider .text-white p {
    font-size: 16px;
  }
  .home-slider .text-white a {
    font-size: 20px;
    padding-bottom: 2px;
  }
  .home-slider .slider-content {
    margin-top: calc(40vw + 80px) !important;
  }
  .home-slider .slider-content .slider-index-no {
    font-weight: bolder;
    top: -52px;
    left: -32px;
  }
  .home-slider .slider-content .swiper-pagination .swiper-pagination-bullet {
    margin-right: 28px;
  }
  .home-about-section .container {
    padding: 0;
  }
  .home-about-section .about-card {
    padding: 0 12px;
    margin-bottom: 12px;
  }
  .home-about-section .about-card .card-img-overlayy {
    padding: 216px 32px 72px 32px;
    border-radius: 8px;
  }
  .home-about-section .about-card .card-img-overlayy .card-no {
    font-size: 24px;
    line-height: 1;
    margin-bottom: 3px;
  }
  .home-about-section .about-card .card-img-overlayy .head-top-title {
    font-size: 20px;
    line-height: 1;
    margin-bottom: 1rem;
  }
  .home-about-section .about-card .card-img-overlayy .head-mid-title {
    font-size: 48px;
    line-height: 1;
  }
  .home-about-section .about-card .card-img-overlayy .head-bottom-title {
    font-size: 1rem;
    margin-bottom: 2rem !important;
  }
  .home-about-section .about-card .card-img-overlayy .more-text .read-more-description {
    font-size: 16px;
  }
  .home-about-section .about-card .card-img-overlayy.two {
    background-position-x: 24%;
  }
  .home-products-section .swiper-slide .card .card-body .card-title {
    font-size: 1.25rem;
    line-height: 1.1;
  }
  .home-products-section .swiper-slide .card .card-body .card-text {
    font-size: 1rem;
  }
  .home-products-section .swiper-slide .card .card-body a {
    font-size: 1rem;
  }
  .home-products-section .home-products-slider .swiper-button-next,
  .home-products-section .home-products-slider .swiper-button-prev {
    top: 30%;
    background: transparent;
    width: auto;
    height: 28px;
  }
  .home-products-section .home-products-slider .swiper-button-next::after,
  .home-products-section .home-products-slider .swiper-button-prev::after {
    font-size: 28px;
    color: #ef353d;
  }
  .home-products-section .home-products-slider .swiper-button-prev {
    left: 16px;
  }
  .home-products-section .home-products-slider .swiper-button-next {
    left: initial;
    right: 16px;
  }
  .home-products-section .home-products-slider .view-all-btn {
    position: inherit;
    margin-top: 2rem;
    margin-left: 2rem;
  }
  .home-catalogue-section .multiply {
    transform: scale(1.25) translateX(3px);
    margin-bottom: 1rem;
  }
  .home-catalogue-section .catalogue-mobile-title {
    font-size: 24px;
    line-height: 1.1;
  }
  .home-news-section .home-news-slider {
    margin-top: -210px;
  }
  .home-news-section .home-news-slider .swiper-wrapper .swiper-slide .card {
    position: relative;
  }
  .home-news-section .home-news-slider .swiper-wrapper .swiper-slide .card .card-img-top {
    max-height: 360px;
    height: 100%;
  }
  .home-news-section .home-news-slider .swiper-wrapper .swiper-slide .card::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    max-height: 360px;
    background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.8;
  }
  .home-news-section .home-news-slider .swiper-wrapper .swiper-slide .card .card-body a {
    font-size: 0.75rem;
  }
  .home-news-section .home-news-slider .swiper-wrapper .swiper-slide .card .card-body .card-title {
    font-size: 1.25rem;
    line-height: 1.3;
  }
  .home-news-section .home-news-slider .swiper-wrapper .swiper-slide .card .card-body .card-text {
    font-size: 1rem;
  }
  .home-news-section .home-news-slider .swiper-wrapper .swiper-slide .card .card-body .outline-btn {
    font-size: 1rem;
  }
  .home-news-section .news-wrapper {
    padding-top: 4rem;
    padding-bottom: 12rem;
    padding-left: 0;
  }
  .home-news-section .news-wrapper h1 {
    font-size: 48px;
  }
  .home-news-section .position-relative .swiper-button-next,
  .home-news-section .position-relative .swiper-button-prev {
    top: 24%;
  }
  .home-news-section .position-relative .swiper-button-next::after,
  .home-news-section .position-relative .swiper-button-prev::after {
    min-width: 36px;
    min-height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 28px;
  }
  .home-news-section .position-relative .swiper-button-prev {
    left: 15px !important;
  }
  .home-news-section .position-relative .swiper-button-next {
    right: 15px !important;
  }
  .home-references-section .col-lg-2 {
    min-width: initial;
    max-width: 163px;
    height: 104px;
    width: 100%;
    min-width: 44%;
  }
  .home-references-section .col-lg-2 figure {
    width: 100%;
  }
  .home-references-section .col-lg-2.logo-1 figure {
    max-width: 93px;
  }
  .home-references-section .col-lg-2.logo-2 {
    margin-right: 0 !important;
  }
  .home-references-section .col-lg-2.logo-2 figure {
    max-width: 76px;
  }
  .home-references-section .col-lg-2.logo-3 figure {
    max-width: 91px;
  }
  .home-references-section .col-lg-2.logo-4 {
    margin-right: 0 !important;
  }
  .home-references-section .col-lg-2.logo-4 figure {
    max-width: 85px;
  }
  .home-references-section .col-lg-2.logo-5 figure {
    max-width: 88px;
  }
  .home-references-section .col-lg-2.logo-6 {
    margin-right: 0 !important;
  }
  .home-references-section .col-lg-2.logo-6 figure {
    max-width: 99px;
  }
  .home-references-section .col-lg-2.logo-7 figure {
    max-width: 72px;
  }
  .home-references-section .references-box {
    width: 35vw;
    height: 26vw;
    margin-right: 2vw;
    margin-bottom: 2vw;
  }
  .home-follow-us-section ul li i {
    font-size: 20px;
  }
}
@media (max-width: 1280px) {
  .home-slider .swiper-slide .text-white h1 {
    font-size: 2.5rem;
  }
  .home-slider .swiper-slide .text-white p {
    font-size: 1.15rem;
  }
}
@media (max-height: 578px) {
  .home-slider .swiper-slide {
    height: 578px;
  }
  .home-slider .swiper-slide .text-white h1 {
    font-size: 2rem;
  }
  .home-slider .swiper-slide .text-white p {
    font-size: 1rem;
  }
}
@media (max-width: 375px) {
  .home-slider .swiper-slide .text-white h1 {
    font-size: 2rem;
  }
  .home-slider .swiper-slide .text-white p {
    font-size: 1rem;
  }
}
.about-eku .movie-wrapper .movie-figure:hover .movie-img {
  filter: brightness(1);
}
.about-eku .movie-wrapper .movie-figure:hover .play-icon {
  transform: scale(1.1);
}
.about-eku .movie-wrapper a {
  font-size: 12px;
  letter-spacing: 0.2em;
}
.about-eku .movie-wrapper .movie-img {
  filter: brightness(0.5);
  transition: 300ms;
}
.about-eku .movie-wrapper .play-content {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.about-eku .movie-wrapper .play-content span {
  font-size: 10px;
}
.about-eku .movie-wrapper .play-theme {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}
.about-eku .movie-wrapper .play-theme .play-content {
  pointer-events: none;
}
.about-eku .movie-wrapper .play-icon {
  transition: 300ms;
  max-width: 60px;
}

.company-doing .wrapper-company-doing {
  padding-top: 165px;
  padding-bottom: 20px;
}
.company-doing .wrapper-company-doing .doing-icon {
  max-width: 94px;
}

.company-doing-mobile .swiper-button-next,
.company-doing-mobile .swiper-button-prev {
  top: 15%;
}
.company-doing-mobile .swiper-button-next::after,
.company-doing-mobile .swiper-button-prev::after {
  font-size: 1rem;
  color: #ef353d;
  font-weight: bolder;
}

.our-history .swiper-container {
  width: 83vw;
  height: 100%;
}
.our-history .swiper-button-next,
.our-history .swiper-button-prev {
  background: rgba(255, 255, 255, 0.74);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: none;
  align-items: center;
  justify-content: center;
}
.our-history .swiper-button-next::after,
.our-history .swiper-button-prev::after {
  color: #ef353d;
  font-size: 16px;
}
.our-history .swiper-wrapper::after {
  content: "";
  position: absolute;
  width: 2500vw;
  height: 1px;
  background: #ef353d;
  opacity: 0.2;
  top: 60px;
}
.our-history .swiper-slide {
  display: flex;
  justify-content: flex-start;
}
.our-history .swiper-slide figure {
  min-height: 218px;
  max-height: 218px;
}
.our-history .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.our-history .swiper-scrollbar {
  width: 100%;
  left: 0 !important;
  right: 0;
  background: transparent !important;
}
.our-history .swiper-scrollbar .swiper-scrollbar-drag {
  height: 4px;
  border-radius: 0;
  background: #ef353d;
}
.our-history .swiper-scrollbar::after {
  content: "";
  position: absolute;
  width: 500vw;
  height: 1px;
  background: #ef353d;
  opacity: 0.2;
  bottom: 0px;
}
.our-history .swiper-date {
  font-size: 24px;
  letter-spacing: -0.02em;
  position: relative;
}
.our-history .swiper-date::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background: #ef353d;
  left: 0;
  bottom: -32px;
}

.your-partner .line {
  position: relative;
}
.your-partner .line::after {
  content: "";
  position: absolute;
  width: 3px;
  height: 146px;
  background: #ef353d;
  top: -40px;
  left: 15px;
}
.your-partner .your-partner-card {
  padding-top: 1.9rem;
  padding-left: 2rem;
}
.your-partner .your-partner-card .no {
  top: 8px;
  left: 4px;
  font-family: Kursk;
  font-size: 48px;
  line-height: 1.2;
  color: #ef353d;
  opacity: 0.15;
}

.wellcome-to-eku .section-secondary-title-v2 p {
  font-size: 2rem;
  line-height: 1.1;
  letter-spacing: -0.02em;
  color: #4d4d4d;
  font-weight: normal;
}

.modal-dialog {
  max-width: 70vw !important;
  margin: 30px auto;
}
.modal-dialog .modal-body span {
  font-size: 32px;
  font-weight: 100;
  color: #4d4d4d;
}

@media (min-width: 992px) {
  .company-doing .wrapper-company-doing {
    position: relative;
  }
  .company-doing .wrapper-company-doing::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 130vw;
    height: 100%;
    background: #f2f2f2;
    z-index: -1;
  }
  .our-history .row {
    position: relative;
  }
  .our-history .row::after {
    content: "";
    position: absolute;
    width: 3px;
    height: 146px;
    background: #ef353d;
    top: 0;
    left: 15px;
  }
  .our-history::after {
    content: "";
    position: absolute;
    top: 6rem;
    bottom: 0;
    left: calc(10vw + 16px);
    width: 1px;
    height: calc(100% - 6rem - 20px);
    background: #ef353d;
    z-index: 1;
    opacity: 0.2;
  }
  .your-partner::after {
    content: "";
    position: absolute;
    top: 0rem;
    bottom: 0;
    left: calc(10vw + 16px);
    width: 1px;
    height: 100%;
    background: #ef353d;
    z-index: 1;
    opacity: 0.2;
  }
  .wellcome-to-eku::after {
    content: "";
    position: absolute;
    top: 0rem;
    bottom: 0;
    left: calc(10vw + 16px);
    width: 1px;
    height: 100%;
    background: #ef353d;
    z-index: 1;
    opacity: 0.2;
  }
}
@media (max-width: 1280px) {
  .our-history .swiper-scrollbar {
    bottom: 10% !important;
  }
}
@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 100vw !important;
  }
  .our-history .swiper-button-next,
  .our-history .swiper-button-prev {
    display: flex;
  }
}
@media (max-width: 575.98px) {
  .about-eku {
    position: relative;
  }
  .about-eku::after {
    content: "";
    position: absolute;
    top: 0;
    left: 30px;
    width: 1px;
    height: calc(100% + 10rem);
    background: #ef353d;
    opacity: 0.2;
    z-index: -1;
  }
  .about-eku .company-head-description {
    font-size: 1.5rem;
    line-height: 1.2 !important;
  }
  .about-eku .section-secondary-title-v2 {
    margin-bottom: 1rem !important;
  }
  .about-eku .movie-wrapper {
    margin-left: -50px;
    margin-right: -30px;
  }
  .about-eku figure .movie-img {
    height: 412px;
    object-fit: cover;
    object-position: -20px 0px;
  }
  .our-history .swiper-container {
    width: 100% !important;
  }
  .our-history .swiper-container .swiper-button-next,
  .our-history .swiper-container .swiper-button-prev {
    top: 38%;
    border-radius: 0;
    width: 30px;
    height: 30px;
  }
  .our-history .swiper-container .swiper-button-next {
    right: 0;
  }
  .our-history .swiper-container .swiper-button-prev {
    left: 0;
  }
  .our-history .swiper-container .swiper-scrollbar {
    bottom: 15% !important;
  }
  .our-history .section-secondary-title-v2 {
    margin-bottom: 2rem !important;
  }
  .your-partner .line::after {
    display: none;
  }
  .your-partner .head .section-secondary-title-v2 {
    font-size: 1.875rem;
  }
  .your-partner .head p {
    font-size: 0.938;
  }
  .your-partner .your-partner-card no {
    font-size: 2.5rem;
  }
  .your-partner .your-partner-card h4 {
    font-size: 1.2rem;
  }
  .company-doing-mobile-slider .swiper-button-next {
    right: 32px;
  }
  .company-doing-mobile-slider .swiper-button-prev {
    left: 32px;
  }
  .wellcome-to-eku #section-secondary-title-v2 {
    position: relative;
    font-size: 2rem !important;
    border-left: none;
  }
  .wellcome-to-eku #section-secondary-title-v2 p {
    font-size: 0.938rem !important;
  }
  .wellcome-to-eku #section-secondary-title-v2::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    background: #ef353d;
    top: 1.5rem;
    left: -23px;
  }
}
.videoIframe {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.videoPoster {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: url("/assets/page-company-video-img.jpg") rgba(0, 0, 0, 0.5);
  background-size: 100%;
  border: none;
  text-indent: -999em;
  overflow: hidden;
  opacity: 1;
  -webkit-transition: opacity 800ms, height 0s;
  -moz-transition: opacity 800ms, height 0s;
  transition: opacity 800ms, height 0s;
  -webkit-transition-delay: 0s, 0s;
  -moz-transition-delay: 0s, 0s;
  transition-delay: 0s, 0s;
}

.videoWrapperActive .videoPoster {
  opacity: 0;
  height: 0;
  -webkit-transition-delay: 0s, 800ms;
  -moz-transition-delay: 0s, 800ms;
  transition-delay: 0s, 800ms;
}

.manufacture-mobile-wrapper {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.manufacture-mobile-gr {
  background-color: rgba(0, 0, 0, 0.3764705882);
  padding: 158px 32px 64px 32px;
  height: 100%;
  width: 100%;
}

.manufacture-next {
  color: #EF353D !important;
}

.manufacture-prev {
  color: #EF353D !important;
}

@media (max-width: 992px) {
  .manufacture-next {
    color: white !important;
    right: 64px !important;
    top: 13% !important;
  }
  .manufacture-prev {
    color: white !important;
    top: 13% !important;
  }
  .manufacture-mobile-gr .secondary-bold-title {
    font-size: 2.25rem;
  }
  .manufacture-mobile-gr h2 {
    font-size: 1.5rem;
  }
}
#manifacturing-detail-section .content-wrapper h1 {
  margin-bottom: 2rem;
}
#manifacturing-detail-section .content-wrapper p {
  margin-bottom: 4rem;
}
.manifacturing-detail-section-mobile {
  padding: 0 12px;
}
.manifacturing-detail-section-mobile .manifacturing-mobile-card {
  margin-bottom: 12px;
}
.manifacturing-detail-section-mobile .manifacturing-mobile-card .card-img-overlayy {
  position: relative;
  padding: 158px 32px 64px 32px;
  width: 100%;
  background-size: cover;
  height: 100%;
  background-repeat: no-repeat;
  border-radius: 8px;
}
.manifacturing-detail-section-mobile .manifacturing-mobile-card .card-img-overlayy .content-wrapper h1 {
  line-height: 1.1;
  letter-spacing: -0.02em;
}
.manifacturing-detail-section-mobile .manifacturing-mobile-card .card-img-overlayy .content-wrapper h2 {
  font-weight: 800;
  line-height: 1.2;
  letter-spacing: -0.02em;
}
.manifacturing-detail-section-mobile .manifacturing-mobile-card .card-img-overlayy .content-wrapper .outline-btn::after {
  background: white;
}
.manifacturing-detail-section-mobile .manifacturing-mobile-card .card-img-overlayy.one {
  height: 620px;
  background-image: url(../../public/assets/manufacturing-section-img/sec1.jpg);
  padding: 158px 32px 64px 32px;
  background-position-x: 100%;
}
.manifacturing-detail-section-mobile .manifacturing-mobile-card .card-img-overlayy.two {
  height: 620px;
  background-image: url(../../public/assets/manufacturing-section-img/sec2.jpg);
  padding: 136px 32px 64px 32px;
  background-position-x: 70%;
}
.manifacturing-detail-section-mobile .manifacturing-mobile-card .card-img-overlayy.three {
  height: 620px;
  background-image: url(../../public/assets/manufacturing-section-img/sec3.jpg);
  padding: 158px 32px 64px 32px;
  background-position-x: 64%;
}
.manifacturing-detail-section-mobile .manifacturing-mobile-card .card-img-overlayy.four {
  height: 620px;
  background-image: url(../../public/assets/manufacturing-section-img/sec4.jpg);
  padding: 158px 32px 64px 32px;
  background-position-x: 10%;
}
.manifacturing-detail-section-mobile .manifacturing-mobile-card .card-img-overlayy .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 0.8;
  border-radius: 8px;
}

.manifacturing-quality-systems .for-card {
  position: relative;
}
.manifacturing-quality-systems .for-card figure {
  max-width: 100px;
}

.quality-systems-mobile-slider .swiper-button-prev,
.quality-systems-mobile-slider .swiper-button-next {
  top: 40%;
}
.quality-systems-mobile-slider .swiper-button-prev::after,
.quality-systems-mobile-slider .swiper-button-next::after {
  color: #ef353d;
  font-size: 1rem;
  font-weight: bolder;
}
.quality-systems-mobile-slider .swiper-button-next {
  right: 32px;
}
.quality-systems-mobile-slider .swiper-button-prev {
  left: 32px;
}

.manifacturing-documents-and-certificates .card,
.manifacturing-documents-and-certificates-mobile .card {
  max-width: 252px;
  min-width: 252px;
}
.manifacturing-documents-and-certificates .wrapper-content,
.manifacturing-documents-and-certificates-mobile .wrapper-content {
  position: relative;
}
.manifacturing-documents-and-certificates .wrapper-content::after,
.manifacturing-documents-and-certificates-mobile .wrapper-content::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 150vw;
  height: 100%;
  background: #f2f2f2;
  z-index: -1;
}
.manifacturing-documents-and-certificates .certificates,
.manifacturing-documents-and-certificates-mobile .certificates {
  width: 47px;
  height: 80px;
  background: #ef353d;
}
.manifacturing-documents-and-certificates .certificates .certificates-svg,
.manifacturing-documents-and-certificates-mobile .certificates .certificates-svg {
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
}

.manifacturing-documents-and-certificates-mobile .certificates-mobile-slider {
  transform: translateX(-40px);
  padding-left: 40px;
}
.manifacturing-documents-and-certificates-mobile .swiper-button-next::after,
.manifacturing-documents-and-certificates-mobile .swiper-button-prev::after {
  font-size: 1rem;
  color: #ef353d;
  font-weight: bolder;
}

.manufacturing-slider .swiper-button-next,
.manufacturing-slider .swiper-button-prev {
  color: white;
}
.manufacturing-slider .swiper-button-next::after,
.manufacturing-slider .swiper-button-prev::after {
  font-size: 28px;
}
.manufacturing-slider .swiper-button-prev {
  left: 64px;
}
.manufacturing-slider .swiper-button-next {
  right: 64px;
}

.min-secondary-title {
  font-size: 1.5rem;
  font-weight: 700;
}

.quality-card-desc {
  font-size: 1.3rem;
}

@media (max-width: 1680px) {
  .swiper-slide .swiper-text-animation h2 {
    font-size: 1.875rem;
    word-break: break-word;
  }
  .manifacturing h2 {
    font-size: 1.5rem;
  }
  #manifacturing-detail-section .content-wrapper h1 {
    font-size: 40px;
    margin-bottom: 1.5rem;
  }
  #manifacturing-detail-section .content-wrapper h2 {
    font-size: 1.5rem;
  }
  #manifacturing-detail-section .content-wrapper p {
    font-size: 0.875rem;
    margin-bottom: 2.5rem;
  }
  #manifacturing-detail-section .content-wrapper .outline-btn {
    font-size: 0.875rem;
  }
}
@media (max-width: 1440px) {
  #manifacturing-detail-section .content-wrapper h1 {
    font-size: 32px;
    margin-bottom: 1.2rem;
  }
  #manifacturing-detail-section .content-wrapper h2 {
    font-size: 1.2rem;
  }
  #manifacturing-detail-section .content-wrapper p {
    font-size: 0.725rem;
    margin-bottom: 2rem;
  }
  #manifacturing-detail-section .content-wrapper .outline-btn {
    font-size: 0.725rem;
  }
}
@media (max-width: 1280px) {
  #manifacturing-detail-section .content-wrapper h1 {
    font-size: 24px;
    margin-bottom: 1rem;
  }
  #manifacturing-detail-section .content-wrapper h2 {
    font-size: 1rem;
  }
  #manifacturing-detail-section .content-wrapper p {
    font-size: 0.7rem;
    margin-bottom: 1.865rem;
  }
  #manifacturing-detail-section .content-wrapper .outline-btn {
    font-size: 0.7em;
  }
}
@media (max-width: 991.98px) {
  #manifacturing-detail-section .col-lg-7 img {
    max-height: 320px;
  }
  .manifacturing-quality-systems h2 {
    font-size: 1.6rem;
  }
  .manifacturing-documents-and-certificates .wrapper-content::after {
    left: -10%;
  }
}
@media (max-width: 767.98px) {
  #manifacturing-detail-section .col-lg-7 img {
    max-height: 240px;
  }
  #manifacturing-detail-section .content-wrapper h1 {
    font-size: 24px;
    margin-bottom: 1rem;
  }
  #manifacturing-detail-section .content-wrapper h2 {
    font-size: 1rem;
  }
  #manifacturing-detail-section .content-wrapper p {
    font-size: 0.7rem;
    margin-bottom: 1.865rem;
  }
  #manifacturing-detail-section .content-wrapper .outline-btn {
    font-size: 0.7em;
  }
  .manifacturing-quality-systems h2 {
    font-size: 1.5rem;
  }
}
@media (max-width: 575.98px) {
  .manufacturing-page-hero img {
    object-position: initial !important;
  }
  .manifacturing-quality-systems {
    position: relative;
  }
  .manifacturing-quality-systems::before {
    content: "";
    position: absolute;
    height: 358px;
    width: 1px;
    left: 30px;
    top: 0;
    opacity: 0.2;
    border: 1px solid #ef353d;
  }
  .manifacturing-quality-systems .manifacturing-quality-systems-description {
    font-size: 1rem;
  }
  .manifacturing .max-w-1228px {
    font-size: 1rem;
  }
  .manifacturing-documents-and-certificates .card {
    max-width: 100% !important;
    width: 100%;
  }
  .manifacturing-documents-and-certificates .section-secondary-title-v2 {
    border-color: transparent;
    position: relative;
  }
  .manifacturing-documents-and-certificates .section-secondary-title-v2::after {
    content: "";
    position: absolute;
    left: 0;
    width: 3px;
    height: 86px;
    top: -8px;
    background: #ef353d;
  }
  .manifacturing-documents-and-certificates .section-secondary-title-v2::before {
    content: "";
    position: absolute;
    height: 155px;
    width: 1px;
    left: 0px;
    top: -3rem;
    opacity: 0.2;
    border: 1px solid #ef353d;
  }
  .manifacturing-documents-and-certificates .wrapper-content {
    position: relative;
  }
  .manifacturing-documents-and-certificates .wrapper-content::after {
    display: none;
  }
}
.products-general .offer-list-dropdown .btn::after {
  border: solid #ef353d;
  border-width: 0 3px 3px 0 !important;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
}
.products-general .offer-list-dropdown .btn .pieces {
  line-height: 1.7;
}
.products-general .offer-list-dropdown .offer-list-dropdown-wrapper {
  transition: 300ms;
  margin-right: 0;
}
.products-general .offer-list-dropdown.active .offer-list-dropdown-wrapper {
  margin-right: 48px;
}
.products-general .add-btn {
  border: 1px solid #ef353d;
  background: transparent;
  width: 40px;
  height: 40px;
  border-radius: 5px;
}
.products-general .add-btn .tooltip-custom {
  transition: 200ms;
  opacity: 0;
  position: absolute;
  left: 14px;
  bottom: -20px;
  font-weight: bold;
  font-size: 10px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #808080;
  padding: 8px 10px;
  background: #f2f2f2;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  white-space: nowrap;
}
.products-general .add-btn svg {
  fill: #4d4d4d;
}
.products-general .add-btn:hover {
  background: #ef353d;
}
.products-general .add-btn:hover svg {
  color: white;
  fill: white;
}
.products-general .add-btn:hover .tooltip-custom {
  opacity: 1;
}
.products-general .fa-info {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4d4d4d;
  width: 20px;
  height: 20px;
  border: 1px solid #4d4d4d;
  border-radius: 5px;
  font-size: 10px !important;
  cursor: pointer;
}
.products-general .fa-info:hover {
  border-color: #ef353d;
  color: #ef353d;
}
.products-general .pagination .page-item {
  border-radius: 5px;
}
.products-general .pagination .page-item .page-link {
  color: #808080;
}
.products-general .pagination .page-item.actived .page-link {
  background: #f2f2f2;
  color: white;
  color: black;
}

.product-banner img {
  object-position: initial !important;
}

.product-categories-wrapper {
  margin-top: calc(10vw + 15px);
}

.products {
  position: relative;
}
.products .border-filter {
  position: relative;
}
.products .border-filter::after {
  content: "";
  top: 0;
  left: 15px;
  height: 120px;
  width: 3px;
  background: #ef353d;
  position: absolute;
}
.products .filter {
  padding: 12px 1rem 12px 2rem;
  position: relative;
}
.products .filter .filter-group-img {
  max-height: 470px;
  object-fit: cover;
}
.products .filter .filter-header {
  border-radius: 5px;
}
.products .filter .filter-header .filter-button {
  cursor: pointer;
  min-width: 162px;
}
.products .filter .filter-header .hamburger {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.products .filter .filter-header .hamburger .line {
  width: 24px;
  height: 2px;
  margin-bottom: 4px;
  background: white;
  transition: 300ms;
}
.products .filter .filter-header .hamburger .line:last-child {
  margin-bottom: 6px;
}
.products .filter .filter-header .hamburger.active .line1 {
  transform: rotate(45deg) translate(3.5px, 5.5px);
}
.products .filter .filter-header .hamburger.active .line2 {
  opacity: 0;
  transform: translateX(-80px);
}
.products .filter .filter-header .hamburger.active .line3 {
  transform: rotate(-45deg) translate(3.5px, -5.5px);
}
.products .filter .filter-header span {
  font-weight: 800;
  font-size: 18px;
  letter-spacing: 0.2em;
}
.products .filter .filter-header .search-filter {
  max-width: 545px;
  width: 100%;
  position: relative;
}
.products .filter .filter-header .search-filter::before {
  content: "";
  position: absolute;
  height: 94px;
  width: 16px;
  background: transparent;
  left: -30px;
  top: -13px;
}
.products .filter .filter-header .search-filter::after {
  content: "";
  position: absolute;
  height: 16px;
  width: calc(100% + 46px);
  background: transparent;
  right: -16px;
  bottom: -1.8rem;
}
.products .filter .filter-header .search-filter .search-button {
  display: none !important;
}
.products .filter .filter-header .search-filter .search-box i {
  font-size: 24px;
}
.products .filter .filter-header .search-filter .search-box .search-input {
  border-radius: 3px;
  border: none;
  padding: 14px 1.5rem;
  font-size: 18px;
  outline: none;
}
.products .filter .filter-header .search-filter .search-box .search-input::placeholder {
  font-size: 18px;
  line-height: 3;
  letter-spacing: -0.02em;
  color: #808080;
  display: flex;
  align-items: center;
  padding-top: 14px !important;
  margin-top: 14px !important;
}
.products .filter .filter-header .search-filter .search-box .search-input {
  background: #ef353d;
  box-shadow: #ef353d;
  pointer-events: none;
  color: #ef353d;
  user-select: none;
}
.products .filter .filter-header .search-filter .search-box .search-input::placeholder {
  color: #ef353d;
}
.products .filter .filter-header .search-filter.active .search-input {
  color: initial;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.2);
  background: white;
  pointer-events: initial;
  -webkit-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
}
.products .filter .filter-header .search-filter.active .search-input::placeholder {
  color: #808080;
}
.products .filter .filter-header .search-filter.active .search-button {
  display: flex !important;
}
.products .filter .filter-header .search-filter.active::before, .products .filter .filter-header .search-filter.active::after {
  background: white;
}
.products .filter .filter-body-mobile.active {
  margin-top: 2rem;
  display: block;
}
.products .filter .filter-body {
  overflow-y: hidden;
  display: none;
  padding-bottom: 24px;
}
.products .filter .filter-body.active {
  margin-top: 2rem;
  display: block;
}
.products .filter .filter-body .filter-group h6 {
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.2em;
  color: #ef353d;
  border-bottom: 1px solid #ef353d;
}
.products .filter .filter-body .filter-group .filter-list {
  max-height: 240px;
  overflow-y: auto;
  position: relative;
  border-right: 1px solid #cccccc;
}
.products .filter .filter-body .filter-group .filter-list li {
  text-transform: uppercase;
  color: #4d4d4d;
  padding: 24px 32px 20px;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: -0.02em;
  cursor: pointer;
  color: #4d4d4d;
}
.products .filter .filter-body .filter-group .filter-list li.active {
  background: #e0e0e0;
  border-radius: 5px;
}
.products .filter .filter-body .filter-group .filter-list::-webkit-scrollbar-thumb {
  background-color: #ef353d;
  border-radius: 3px;
}
.products .filter .filter-body .filter-group .filter-list::-webkit-scrollbar {
  width: 3px;
}
.products .product-list-item {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-top: 1px solid #ccc;
  transition: 200ms;
}
.products .product-list-item:hover {
  background: #f7f7f7;
}
.products .product-list-item:last-child {
  border-bottom: 1px solid #ccc;
}
.products .product-list-item .no {
  font-weight: bold;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: -0.02em;
}
.products .product-list-item .info {
  font-weight: bold;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #808080;
}
.products .product-list-item .product-name {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #4d4d4d;
}
.products .product-list-item .product-img {
  max-width: 120px;
}
.products .product-list-item.active .product-info-content {
  display: flex !important;
}
.products .product-info-content {
  max-width: 760px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  border-top: 6px solid #ef353d;
  z-index: 7;
}
.products .product-info-content .closed {
  position: absolute;
  right: -18px;
  top: -18px;
  width: 62px;
  height: 62px;
  background: #e5e5e5;
  border-radius: 50%;
  cursor: pointer;
}
.products .product-info-content .closed:hover {
  opacity: 1;
}
.products .product-info-content .closed:before,
.products .product-info-content .closed:after {
  position: absolute;
  line-height: 62px;
  content: " ";
  height: 25px;
  width: 2px;
  background-color: #ef353d;
  left: 50%;
  top: 18px;
}
.products .product-info-content .closed:before {
  transform: rotate(45deg);
}
.products .product-info-content .closed:after {
  transform: rotate(-45deg);
}
.products .product-info-content figure {
  max-width: 276px;
}
.products .product-info-content .product-info-name {
  font-size: 24px;
  letter-spacing: -0.02em;
}
.products .hero-wrapper {
  padding: 64px 64px 240px 64px;
}
.products .hero-wrapper::before {
  content: "";
  position: absolute;
  top: -5px;
  left: 15px;
  height: 5px;
  width: 65%;
  background: #ef353d;
  border-top-left-radius: 20px;
}
.products .hero-content {
  padding: 0 64px;
  margin-top: -195px;
}

.line-product::before {
  content: "";
  position: absolute;
  top: 0;
  left: calc(10vw + 14px);
  background: #ef353d;
  opacity: 0.2;
  width: 1px;
  height: 100%;
}

.products-mobile {
  margin-top: calc(40vw + 15px);
}
.products-mobile .filter-button {
  cursor: pointer;
}
.products-mobile .hamburger {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.products-mobile .hamburger .line {
  width: 24px;
  height: 2px;
  margin-bottom: 4px;
  background: white;
  transition: 300ms;
}
.products-mobile .hamburger .line:last-child {
  margin-bottom: 6px;
}
.products-mobile .hamburger.active .line1 {
  transform: rotate(45deg) translate(3.5px, 5.5px);
}
.products-mobile .hamburger.active .line2 {
  opacity: 0;
  transform: translateX(-80px);
}
.products-mobile .hamburger.active .line3 {
  transform: rotate(-45deg) translate(3.5px, -5.5px);
}
.products-mobile .filter-group h6 {
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.2em;
  color: #ef353d;
  border-bottom: 1px solid #ef353d;
}
.products-mobile .filter-group .filter-list {
  max-height: 240px;
  overflow-y: auto;
  position: relative;
  border-right: 1px solid #cccccc;
}
.products-mobile .filter-group .filter-list li {
  text-transform: uppercase;
  color: #4d4d4d;
  padding: 20px 32px;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: -0.02em;
  cursor: pointer;
  color: #4d4d4d;
}
.products-mobile .filter-group .filter-list li.active {
  background: #e0e0e0;
  border-radius: 5px;
}
.products-mobile .filter-group .filter-list::-webkit-scrollbar-thumb {
  background-color: #ef353d;
  border-radius: 3px;
}
.products-mobile .filter-group .filter-list::-webkit-scrollbar {
  width: 3px;
}
.products-mobile .mobile-filter-wrapper {
  border: 1px solid #ef353d;
  border-radius: 5px;
}
.products-mobile .product-search {
  border: 1px solid #ef353d;
  border-radius: 5px;
}
.products-mobile .product-search .search_btn {
  background: #ef353d;
  padding: 18px 24px;
}
.products-mobile .product-search input {
  padding: 18px 24px;
  border: none;
  border-radius: 5px;
}

.mobile-product-list .product-item {
  border-bottom: 1px solid #cccccc;
}
.mobile-product-list .product-item.active .mobile-product-info-content {
  display: block !important;
}
.mobile-product-list .product-item .product-names {
  max-width: 127px;
}
.mobile-product-list .product-item .product-names h6 {
  font-weight: bold;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #4d4d4d;
}
.mobile-product-list .product-item .product-names p {
  line-height: 1;
  letter-spacing: -0.02em;
}
.mobile-product-list .product-item .product-names span {
  font-weight: bold;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #808080;
}
.mobile-product-list .product-item .product-img {
  max-width: 90px;
}

.offer-list-dropdown {
  border: 1px solid transparent;
  position: absolute !important;
  top: -24px;
  right: 0;
  background: white;
  z-index: 1;
  max-width: 470px;
  width: 100%;
  height: 0;
}
.offer-list-dropdown .offer-back-btn {
  font-weight: normal;
  font-size: 16px;
  line-height: 1;
  letter-spacing: -0.02em;
  color: #4d4d4d;
  display: none;
  pointer-events: none;
  cursor: pointer;
}
.offer-list-dropdown .offer-back-btn i {
  color: #ef353d;
  margin-right: 10px;
  font-size: 12px;
}
.offer-list-dropdown .offer-back-btn.active {
  display: block;
  pointer-events: visible;
}
.offer-list-dropdown .custom-dropdown-pages {
  display: none;
  overflow-x: hidden;
}
.offer-list-dropdown.active {
  height: 766px;
  border: 1px solid #ef353d;
}
.offer-list-dropdown.active .custom-dropdown-pages {
  display: block;
}
.offer-list-dropdown.active .list-toggle::after {
  transform: rotate(-135deg);
}
.offer-list-dropdown.active.height {
  height: 350px;
  overflow: hidden;
}

.custom-dropdown-pages {
  opacity: 0.95;
  border-radius: 5px;
}
.custom-dropdown-pages .offer-list .list-all {
  overflow: auto;
  max-height: 480px;
}
.custom-dropdown-pages .offer-list .head-title {
  font-weight: bold;
  font-size: 10px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #808080;
}
.custom-dropdown-pages .offer-list .head-title:nth-of-type(1) {
  width: 86px;
}
.custom-dropdown-pages .offer-list .head-title:nth-of-type(2) {
  width: 190px;
}
.custom-dropdown-pages .offer-list .offer-list-item {
  border-bottom: 1px solid #cccccc;
  padding: 24px 0;
}
.custom-dropdown-pages .offer-list .offer-list-item .product-no {
  font-weight: bold;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #ef353d;
  width: 86px;
}
.custom-dropdown-pages .offer-list .offer-list-item .product-names {
  width: 190px;
}
.custom-dropdown-pages .offer-list .offer-list-item .product-names .product-name {
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
  text-align: right;
  letter-spacing: -0.02em;
  color: #4d4d4d;
}
.custom-dropdown-pages .offer-list .offer-list-item .product-names .product-ref {
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #808080;
}
.custom-dropdown-pages .offer-list .offer-list-item .pieces input {
  width: 30px;
  height: 30px;
  border: 1px solid #ef353d;
  border-radius: 5px;
  letter-spacing: -0.02em;
  color: #4d4d4d;
  line-height: 2;
  vertical-align: middle;
  text-align: center;
  margin: 0 5px;
  font-weight: bold;
}
.custom-dropdown-pages .offer-list .offer-list-item .pieces span {
  color: #4d4d4d;
  cursor: pointer;
}
.custom-dropdown-pages .send-my-offer-list {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  transition: 200ms;
}
.custom-dropdown-pages .send-my-offer-list.active {
  transform: translateX(0%);
}
.custom-dropdown-pages .send-my-offer-list .sen-my-offer-form input {
  border: 1px solid #ef353d;
  height: 56px;
  padding: 20px 16px;
}
.custom-dropdown-pages .offer-list-success {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  transition: 200ms;
}
.custom-dropdown-pages .offer-list-success.active {
  transform: translateX(0);
}

.mobile-filter-wrapper .filter-body-mobile {
  height: 0;
  overflow: hidden;
}
.mobile-filter-wrapper .filter-body-mobile.active {
  height: auto;
}
.mobile-filter-wrapper .filter-group {
  border-radius: 0 !important;
}
.mobile-filter-wrapper .filter-group .mobile-filter-list {
  position: initial !important;
}
.mobile-filter-wrapper .filter-group .mobile-filter-item {
  border-radius: 0 !important;
}
.mobile-filter-wrapper .filter-group .mobile-filter-item i {
  display: none;
  font-size: 20px;
}
.mobile-filter-wrapper .filter-group.active {
  height: 56px;
  overflow: hidden;
}
.mobile-filter-wrapper .filter-group.active .mobile-filter-list .mobile-filter-item.active {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background: #ef353d;
  color: #fff;
  padding-left: 46px;
  border-top: 1px solid white;
  height: 100%;
}
.mobile-filter-wrapper .filter-group.active .mobile-filter-list .mobile-filter-item.active i {
  display: block;
}

.mobile-product-info-content {
  position: fixed;
  top: 17vw;
  left: 0;
  right: 0;
  background: orange;
  z-index: 99999;
  border-top: 6px solid #ef353d;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
  display: none !important;
}
.mobile-product-info-content .col figure {
  max-width: 140px;
}
.mobile-product-info-content .closed {
  position: absolute;
  right: -18px;
  top: -18px;
  width: 40px;
  height: 40px;
  background: #e5e5e5;
  border-radius: 50%;
  cursor: pointer;
}
.mobile-product-info-content .closed:hover {
  opacity: 1;
}
.mobile-product-info-content .closed:before,
.mobile-product-info-content .closed:after {
  position: absolute;
  line-height: 40px;
  content: " ";
  height: 20px;
  width: 1px;
  background-color: #ef353d;
  left: 50%;
  top: 10px;
}
.mobile-product-info-content .closed:before {
  transform: rotate(45deg);
}
.mobile-product-info-content .closed:after {
  transform: rotate(-45deg);
}

.control-checkbox {
  font-family: "Nexa";
}

@media (max-width: 1680px) {
  .products .filter-body .filter-group h6 {
    font-size: 9px !important;
  }
  .products .filter-body .filter-group .filter-list li {
    font-size: 11px !important;
    padding: 18px 24px !important;
  }
  .products .filter-body .clear-all-filters,
  .products .filter-body .filter-show {
    font-size: 14px !important;
  }
  .offer-list-dropdown.active {
    height: 635px;
  }
  .offer-list-dropdown .custom-dropdown-pages .offer-list .list-all {
    max-height: 375px;
  }
  .offer-list-dropdown .custom-dropdown-pages .offer-list ul .offer-list-item .product-no {
    font-size: 0.875rem;
  }
  .offer-list-dropdown .custom-dropdown-pages .offer-list ul .offer-list-item .product-names .product-name,
  .offer-list-dropdown .custom-dropdown-pages .offer-list ul .offer-list-item .product-names .product-ref {
    font-size: 0.875rem;
  }
  .send-my-offer-list .sen-my-offer-form label {
    font-size: 0.875rem;
    margin-bottom: 0;
  }
  .send-my-offer-list .sen-my-offer-form input {
    height: 40px !important;
    padding: 20px 16px;
  }
  .send-my-offer-list .sen-my-offer-form input::placeholder {
    font-size: 0.875rem;
  }
  .send-my-offer-list .control-checkbox {
    font-size: 0.725rem;
  }
}
@media (max-width: 1440px) {
  .products .filter-body .filter-group h6 {
    font-size: 7px !important;
  }
  .products .filter-body .filter-group .filter-list li {
    font-size: 9px !important;
    padding: 16px 18px !important;
  }
  .products .filter-body .clear-all-filters,
  .products .filter-body .filter-show {
    font-size: 12px !important;
  }
}
@media (max-width: 1280px) {
  .products .filter-body .filter-group {
    padding: 1rem !important;
  }
  .products .filter-body .filter-group h6 {
    font-size: 7px !important;
  }
  .products .filter-body .filter-group .filter-list li {
    font-size: 8px !important;
    padding: 14px 14px !important;
  }
  .products .filter-body .clear-all-filters,
  .products .filter-body .filter-show {
    font-size: 12px !important;
  }
}
@media (max-width: 991.98px) {
  .offer-list-dropdown {
    top: 0;
  }
  .add-btn .tooltip-custom {
    display: none;
  }
  .line-product::before {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .mobile-filter-wrapper .filter-header {
    padding: 20px 24px !important;
  }
  .mobile-filter-wrapper .product-search input {
    padding: 20px 24px !important;
  }
  .offer-list-dropdown {
    top: 0;
  }
  .offer-list-dropdown.active {
    height: 635px;
  }
  .offer-list-dropdown .custom-dropdown-pages .offer-list .head-title {
    font-size: 8px;
  }
  .offer-list-dropdown .custom-dropdown-pages .offer-list .list-all {
    max-height: 375px;
  }
  .offer-list-dropdown .custom-dropdown-pages .offer-list ul .offer-list-item .product-no {
    font-size: 0.725rem;
  }
  .offer-list-dropdown .custom-dropdown-pages .offer-list ul .offer-list-item .product-names .product-name,
  .offer-list-dropdown .custom-dropdown-pages .offer-list ul .offer-list-item .product-names .product-ref {
    font-size: 0.725rem;
  }
  .products-filter .hero-wrapper {
    padding: 32px 32px 240px 32px !important;
  }
  .products-filter .hero-content {
    padding: 0 32px !important;
  }
}
@media (max-width: 575.98px) {
  .filters-hero-description h2 {
    font-size: 1.5rem;
  }
  .f-1.active {
    border-bottom: 1px solid white;
    border-top: 1px solid white;
  }
  .products-mobile .section-secondary-title-v2 {
    border: none;
    padding: 1rem 0 1rem 0rem;
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  .products-mobile .dropdown-toggle::after {
    margin-bottom: 8px;
  }
  .products-mobile .filter-group .filter-list .mobile-filter-item {
    padding: 20px 16px !important;
  }
  .products-mobile .filter-group .filter-list .mobile-filter-item.active {
    padding-left: 46px !important;
    padding-right: 32px !important;
  }
  .products-filter {
    margin-top: 40vw !important;
  }
  .products-filter .section-secondary-title-v2 {
    padding: 1rem 0 1rem 0rem;
    border: none;
    font-size: 2rem;
    margin-bottom: 66px;
  }
  .products-filter .list-toggle .outline-btn {
    display: none;
    visibility: hidden;
  }
  .products-filter .hero-wrapper {
    padding: 32px 32px 210px 32px !important;
    background: linear-gradient(180deg, #f7f7f7 0%, rgba(230, 230, 230, 0.1) 100%) !important;
  }
  .products-filter .hero-wrapper h6 {
    font-size: 20px;
  }
  .products-filter .hero-content {
    padding: 0 0 0 16px !important;
  }
  .products-filter .hero-content .mobile-img {
    transform: scale(1.15) translateX(-8px);
  }
  .mobile-product-info-content {
    flex-direction: column;
  }
  .products {
    margin-top: 15vw;
  }
  .product-categories-wrapper {
    margin-top: 40vw !important;
  }
  /* .offer-list-dropdown {
      .list-toggle {
          padding-right: 0 !important;
      }
  } */
}
@media (max-width: 575.98px) {
  .careers-head-title {
    position: relative;
  }
  .careers-head-title::after {
    content: "";
    position: absolute;
    left: 30px;
    top: 0;
    background: #ef353d;
    opacity: 0.2;
    height: 100%;
    width: 1px;
  }
  .contact-form {
    position: relative;
  }
  .contact-form::after {
    content: "";
    position: absolute;
    left: 30px;
    top: 0rem;
    background: #ef353d;
    opacity: 0.2;
    height: calc(1rem + 200px);
    width: 1px;
  }
}
.careers-page-banner img {
  object-position: initial;
}

.wrapper-contact-form,
.wrapper-careers-form {
  position: relative;
}
.wrapper-contact-form::after,
.wrapper-careers-form::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: #f2f2f2;
  z-index: -1;
}
.wrapper-contact-form.none-after::after,
.wrapper-careers-form.none-after::after {
  display: none;
}
.wrapper-contact-form .contact-form-content label,
.wrapper-careers-form .contact-form-content label {
  font-weight: normal;
  font-size: 12px;
  letter-spacing: -0.02em;
  color: #4d4d4d;
  margin-bottom: 0;
}
.wrapper-contact-form .contact-form-content input,
.wrapper-careers-form .contact-form-content input {
  padding: 26px 16px;
  border: 1px solid #ef353d;
  height: 56px;
}
.wrapper-contact-form .contact-form-content input.form-check-input,
.wrapper-careers-form .contact-form-content input.form-check-input {
  height: auto;
}
.wrapper-contact-form .contact-form-content .form-check-label,
.wrapper-careers-form .contact-form-content .form-check-label {
  font-size: 16px;
}
.wrapper-contact-form .contact-form-content .custom-select,
.wrapper-careers-form .contact-form-content .custom-select {
  border: 1px solid #ef353d !important;
  height: 56px;
  border-color: #ef353d !important;
}
.wrapper-contact-form .contact-form-content .form-control,
.wrapper-careers-form .contact-form-content .form-control {
  border: 1px solid #ef353d !important;
}
.wrapper-contact-form .contact-form-content textarea,
.wrapper-careers-form .contact-form-content textarea {
  border: 1px solid #ef353d !important;
  max-height: 120px;
  min-height: 56px;
  border-color: #ef353d !important;
}

.control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  padding-top: 3px;
  cursor: pointer;
  font-size: 16px;
}

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control_indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #ffffff;
  border: 1px solid #ef353d;
  border-radius: 0px;
}

.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
  background: #fff;
}

.control input:checked ~ .control_indicator {
  background: #ef353d;
}

.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
  background: #ef353d;
}

.control input:disabled ~ .control_indicator {
  background: #fff;
  opacity: 0.6;
  pointer-events: none;
}

.control_indicator:after {
  box-sizing: unset;
  content: "";
  position: absolute;
  display: none;
}

.control input:checked ~ .control_indicator:after {
  display: block;
}

.control-checkbox .control_indicator:after {
  left: 8px;
  top: 4px;
  width: 3px;
  height: 8px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.control-checkbox input:disabled ~ .control_indicator:after {
  border-color: #fff;
}

.control-checkbox .control_indicator::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 4.5rem;
  height: 4.5rem;
  margin-left: -1.7rem;
  margin-top: -1.7rem;
  background: #ef353d;
  border-radius: 3rem;
  opacity: 0.6;
  z-index: 99999;
  transform: scale(0);
}

@keyframes s-ripple {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
@keyframes s-ripple-dup {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(1);
  }
  60% {
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
.control-checkbox input + .control_indicator::before {
  animation: s-ripple 250ms ease-out;
}

.control-checkbox input:checked + .control_indicator::before {
  animation-name: s-ripple-dup;
}

@media (max-width: 1680px) {
  .careers-head-title h2 {
    font-size: 1.6rem;
  }
  .meet h1 {
    font-size: 2rem;
  }
  .meet .contact-info-row h2 {
    font-size: 1.765rem;
  }
}
@media (max-width: 1440px) {
  .careers-head-title h2 {
    font-size: 1.365rem;
  }
  .meet h1 {
    font-size: 1.8rem;
  }
  .meet .contact-info-row h2 {
    font-size: 1.625rem;
  }
  .meet .contact-info-row p {
    font-size: 0.875rem;
  }
}
@media (max-width: 1280px) {
  .careers-head-title h2 {
    font-size: 1.125rem;
  }
  .careers-head-title .contact-info-row h2 {
    font-size: 1.5rem;
  }
  .careers-head-title .contact-info-row p {
    font-size: 0.725rem;
  }
  .contact-form .wrapper-careers-form .contact-form-content input {
    font-size: 0.875rem;
  }
  .contact-form .wrapper-careers-form .contact-form-content input::placeholder {
    font-size: 0.875rem;
  }
  .contact-form .wrapper-careers-form .contact-form-content .form-check-label {
    font-size: 0.875rem;
  }
  .contact-form .wrapper-careers-form .contact-form-content .custom-select {
    font-size: 0.875rem;
  }
  .contact-form .wrapper-careers-form .contact-form-content textarea {
    font-size: 0.875rem;
  }
  .contact-form .wrapper-careers-form .contact-form-content textarea::placeholder {
    font-size: 0.875rem;
  }
  .meet h1 {
    font-size: 1.6rem;
  }
  .meet p {
    font-size: 0.675rem;
  }
}
@media (max-width: 991.98px) {
  .careers-head-title h2 {
    font-size: 1rem;
  }
  .meet h1 {
    font-size: 1.5rem;
  }
  .wrapper-contact-form::after {
    left: -10%;
    width: 130vw;
  }
}
@media (max-width: 575.98px) {
  .contact-us .page-hero-image figure img {
    object-position: -112px 0px;
  }
  .meet {
    position: relative;
  }
  .meet::after {
    content: "";
    position: absolute;
    left: 30px;
    top: 0rem;
    background: #ef353d;
    opacity: 0.2;
    height: 98%;
    width: 1px;
  }
  .meet h1 {
    font-size: 2.5rem;
    border-left: 3px solid #ef353d;
    padding-left: 1rem;
  }
  .meet p {
    font-size: 1rem;
  }
  .contact-form::after {
    display: none;
  }
  .home-follow-us-section {
    position: relative;
  }
  .home-follow-us-section::after {
    content: "";
    position: absolute;
    left: 30px;
    top: 0rem;
    background: #ef353d;
    opacity: 0.2;
    height: 100%;
    width: 1px;
  }
}
.news-all {
  margin-top: calc(10vw + 15px);
}
.news-all .card .card-img-top {
  max-height: 455px;
  object-fit: cover;
}

@media (max-width: 1680px) {
  .news-all .card .card-body a {
    font-size: 10px !important;
  }
  .news-all .card .card-body h2 {
    font-size: 1.3rem;
  }
  .news-all .card .card-body p {
    font-size: 0.725rem;
  }
  .news-head-title {
    font-size: 24px;
  }
}
@media (max-width: 1440px) {
  .news-all .card .card-body a {
    font-size: 9px !important;
  }
  .news-all .card .card-body h2 {
    font-size: 1rem;
  }
  .news-all .card .card-body p {
    font-size: 0.625rem;
  }
  .news-head-title {
    font-size: 18px;
  }
}
@media (max-width: 1280px) {
  .news-head-title {
    font-size: 16px;
  }
}
@media (max-width: 991.98px) {
  .news-head-title {
    font-size: 14px;
  }
}
@media (max-width: 767.98px) {
  .news-all .card .card-img-top {
    max-height: 280px;
  }
  .news-all .card .card-body a {
    font-size: 16px !important;
  }
  .news-all .card .card-body h2 {
    font-size: 1rem;
  }
  .news-all .card .card-body p {
    font-size: 0.625rem;
  }
}
@media (max-width: 575.98px) {
  .news-all {
    margin-top: 40vw;
  }
  .news-all .section-secondary-title-v2 {
    padding-left: 0 !important;
    border-left: none;
  }
  .news-all .card {
    position: relative;
  }
  .news-all .card .card-img-top {
    max-height: 360px;
  }
  .news-all .card .card-body {
    position: relative;
  }
  .news-all .card .card-body::after {
    content: "";
    position: absolute;
    width: 1px;
    height: calc(100% + 4.5rem);
    background: #ef353d;
    opacity: 0.2;
    top: 0;
    left: 0;
  }
  .news-all .card .card-body a {
    font-size: 16px !important;
  }
  .news-all .card .card-body h2 {
    font-size: 2rem;
  }
  .news-all .card .card-body p {
    font-size: 1rem;
  }
  .news-all .card .card-body .outline-btm {
    font-size: 1rem;
  }
  .news-all .card::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    top: 85px;
    right: 0;
    width: 100%;
    height: 276px;
    background: linear-gradient(1.06deg, #0a0a0a 0.94%, rgba(196, 196, 196, 0) 99.12%);
  }
  .news-all .mid-news {
    max-height: 360px;
    height: 360px;
    object-fit: cover;
  }
  .news-all .load-more::before {
    content: "";
    position: absolute;
    width: 1px;
    height: calc(100% + 6.5rem);
    background: #ef353d;
    opacity: 0.2;
    top: -1.5rem;
    left: -32px;
  }
}
.news-detail {
  margin-top: calc(10vw + 15px);
}
.news-detail .card .card-img-top {
  max-height: 455px;
  object-fit: cover;
}
.news-detail .news-title {
  font-size: 64px;
}

@media (max-width: 1680px) {
  .news-detail .card .card-body a {
    font-size: 10px !important;
  }
  .news-detail .card .card-body h2 {
    font-size: 1.3rem;
  }
  .news-detail .card .card-body p {
    font-size: 0.725rem;
  }
  .news-detail .news-title {
    font-size: 40px;
  }
  .news-detail .head-description {
    font-size: 1.625rem;
  }
}
@media (max-width: 1440px) {
  .news-detail .card .card-body a {
    font-size: 9px !important;
  }
  .news-detail .card .card-body h2 {
    font-size: 1rem;
  }
  .news-detail .card .card-body p {
    font-size: 0.625rem;
  }
  .news-detail .news-title {
    font-size: 28px;
  }
  .news-detail .head-description {
    font-size: 1.5rem;
  }
  .news-detail .body-description {
    font-size: 0.875rem;
  }
}
@media (max-width: 767.98px) {
  .news-detail .card .card-img-top {
    max-height: 280px;
  }
  .news-detail .card .card-body a {
    font-size: 9px !important;
  }
  .news-detail .card .card-body h2 {
    font-size: 1rem;
  }
  .news-detail .card .card-body p {
    font-size: 0.625rem;
  }
  .news-detail .head-description {
    font-size: 1rem;
  }
  .news-detail .body-description {
    font-size: 0.625rem;
  }
}
@media (max-width: 575.98px) {
  .news-detail {
    margin-top: 40vw;
  }
  .news-detail .card-body {
    position: relative;
  }
  .news-detail .card-body::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: calc(100% + 2rem);
    background: #ef353d;
    opacity: 0.2;
  }
  .news-detail .news-title {
    font-size: 2.5rem;
  }
  .news-detail .card-img-top {
    height: 180px;
    max-height: 360px !important;
    object-fit: cover;
  }
  .news-detail .head-description {
    font-size: 1.5rem;
  }
  .news-detail .body-description {
    font-size: 1rem;
  }
  .news-detail .social-media li a i {
    font-size: 1.5rem;
  }
  .news-detail .also-like-title .section-secondary-title-v2 {
    font-size: 2rem;
  }
  .news-detail .also-like-wrapper .card .card-body a {
    font-size: 0.75rem !important;
  }
  .news-detail .also-like-wrapper .card .card-body h2 {
    font-size: 2rem;
  }
  .news-detail .also-like-wrapper .card .card-body p {
    font-size: 1rem;
  }
  .news-detail .also-like-wrapper .card .card-body .outline-btn {
    font-size: 1rem !important;
  }
  .news-detail .all-news-btn {
    position: relative;
  }
  .news-detail .all-news-btn::before {
    content: "";
    position: absolute;
    top: -3rem;
    left: -2rem;
    width: 1px;
    height: calc(100% + 10rem);
    background: #ef353d;
    opacity: 0.2;
  }
  .news-detail .dot8px {
    position: relative;
  }
  .news-detail .dot8px::before {
    content: "";
    position: absolute;
    left: 15px;
    top: 4px;
    width: 8px;
    height: 8px;
    background: #ef353d;
  }
}
.faq {
  margin-top: calc(10vw + 15px);
}
.faq .card-img-top {
  height: 414px;
  background: #f2f2f2;
  object-fit: contain;
}

.faq-models,
.principles {
  margin-top: calc(10vw + 15px);
}
.faq-models .head-wrapper,
.principles .head-wrapper {
  border-bottom: 2px solid #ef353d;
  padding-bottom: 2rem;
}
.faq-models .head-wrapper .section-secondary-title-v2,
.principles .head-wrapper .section-secondary-title-v2 {
  margin-bottom: 4rem !important;
}
.faq-models .head-wrapper .faq-models-head-description,
.principles .head-wrapper .faq-models-head-description {
  font-size: 64px;
}
.faq-models .content .head-secondary,
.principles .content .head-secondary {
  font-size: 3rem;
}
.faq-models .models-img img,
.principles .models-img img {
  height: 32vw;
  object-fit: cover;
}
.faq-models .all-faqs,
.principles .all-faqs {
  position: relative;
}
.faq-models .all-faqs::after,
.principles .all-faqs::after {
  position: absolute;
  top: 0;
  left: 14px;
  content: "";
  width: 8px;
  height: 8px;
  background: #ef353d;
}

@media (min-width: 1440px) {
  .faq .col-lg-6 {
    max-width: 46% !important;
  }
}
@media (min-width: 992px) {
  .faq,
  .faq-models,
  .principles {
    position: relative;
  }
  .faq::before,
  .faq-models::before,
  .principles::before {
    content: "";
    position: absolute;
    top: 0;
    left: calc(10vw + 14px);
    background: #ef353d;
    opacity: 0.2;
    width: 1px;
    height: 100%;
  }
  .faq::after,
  .faq-models::after,
  .principles::after {
    content: "";
    position: absolute;
    width: 3px;
    height: 86px;
    background: #ef353d;
    top: 121px;
    left: calc(10vw + 14px);
  }
}
@media (max-width: 575.98px) {
  .faq,
  .faq-models,
  .principles {
    margin-top: 40vw;
  }
  .faq .section-secondary-title-v2 {
    border: none;
    padding: 1rem 0 1rem 0rem;
  }
  .faq .faq-head-description {
    font-size: 1rem;
  }
  .faq .card figure img {
    width: 125%;
    transform: translateX(-10%);
  }
  .faq .card .card-body .card-title {
    font-size: 1.5rem;
  }
  .faq-models,
  .principles {
    margin-top: 40vw;
  }
  .faq-models .head-wrapper .section-secondary-title-v2,
  .principles .head-wrapper .section-secondary-title-v2 {
    border: none;
    padding: 1rem 0 1rem 0rem;
    margin-top: 1rem;
    margin-bottom: 1.5rem !important;
  }
  .faq-models .head-wrapper .faq-models-head-description,
  .principles .head-wrapper .faq-models-head-description {
    font-size: 2.5rem;
  }
  .faq-models .section-secondary-title-v2,
  .principles .section-secondary-title-v2 {
    border: none;
    padding: 1rem 0 1rem 0rem;
    margin-top: 1rem;
    margin-bottom: 1.5rem !important;
  }
  .faq-models .head-secondary,
  .principles .head-secondary {
    font-size: 2.5rem !important;
  }
  .faq-models .models-img,
  .principles .models-img {
    margin: 0 -30px;
  }
  .faq-models .models-img img,
  .principles .models-img img {
    height: 415px;
  }
  .faq-models .all-faqs::after,
  .principles .all-faqs::after {
    display: none;
  }
  .faq-models .all-faqs h4,
  .principles .all-faqs h4 {
    font-size: 1.25rem;
  }
}
.anim,
.loader {
  position: relative;
}
.anim.image-anim,
.loader.image-anim {
  overflow: hidden;
}
.anim.image-anim::before,
.loader.image-anim::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  -webkit-transition: left 1500ms cubic-bezier(0.835, 0.015, 0.245, 0.995) 0s;
  transition: left 1500ms cubic-bezier(0.835, 0.015, 0.245, 0.995) 0s;
}
.anim.image-anim::after,
.loader.image-anim::after {
  content: "";
  height: 100%;
  width: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(247, 247, 249, 0.95);
}
.anim.active::before,
.loader.active::before {
  left: 100%;
}
.anim.z-index-4::before, .anim.z-index-4::after,
.loader.z-index-4::before,
.loader.z-index-4::after {
  z-index: 4;
}

.loader.up-anim {
  opacity: 0;
  transform: translateY(70px);
  transition: 300ms;
  transition-delay: 800ms;
}
.loader.active {
  opacity: 1;
  transform: translateY(0);
}

.header-logo.loader {
  transform: scale(1.1);
  transition: 500ms;
}
.header-logo.active {
  transform: scale(1);
}

.error-page {
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  font-family: "Chakra Petch";
  overflow: hidden;
}
.error-page img {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  max-width: 1200px;
  z-index: -1;
  opacity: 0.3;
}
.error-page h1 {
  font-size: 148px;
  font-weight: bold;
  color: #ef353d;
  line-height: 1;
}

.accordion-team-section .accordion-item {
  position: relative;
}
.accordion-team-section .accordion-item .person-name {
  font-size: 1.5rem;
  font-weight: 600;
  color: #000;
  letter-spacing: 0.2px;
  text-align: start;
}
.accordion-team-section .accordion-item .person-title {
  font-size: 1rem;
  color: rgb(66, 66, 66);
  margin-bottom: 0.5rem;
  letter-spacing: 0.2px;
  font-weight: 400;
  text-align: start;
}
.accordion-team-section .accordion-header {
  transform: translateX(24px);
}
.accordion-team-section .accordion-btn-img {
  width: 120px;
  height: 120px;
  margin-right: 1.5rem;
  transition: 300ms;
  opacity: 1;
}
.accordion-team-section .accordion-button::after {
  background-image: url(/assets/our-management-team/plus.svg);
  margin-left: 0;
  margin-right: 40px;
}
.accordion-team-section .accordion-button:not(.collapsed)::after {
  background-image: url(/assets/our-management-team/plus.svg);
  transform: rotate(135deg);
}
.accordion-team-section .accordion-button:not(.collapsed) {
  background: transparent;
}
.accordion-team-section .accordion-col-custom {
  transition: 300ms;
  min-width: 50%;
}
.accordion-team-section .accordion-col-custom.wide {
  min-width: 100% !important;
}
.accordion-team-section .accordion-col-custom {
  transition-delay: 350ms;
}
.accordion-team-section .accordion-col-custom::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  right: 0;
  width: 1740px;
  min-width: 1740px;
  height: 100%;
  background: transparent;
  margin: 0 auto;
  transition: 1500ms;
  transition-delay: 550ms;
  z-index: -1;
  opacity: 0;
}
.accordion-team-section .accordion-col-custom .card-body img {
  opacity: 0;
  transform: scale(0.7);
  transition-delay: 600s !important;
  transition: 500ms !important;
}
.accordion-team-section .accordion-col-custom .card-body .content {
  opacity: 0;
  transform: translateY(70px);
  transition-delay: 700s !important;
  transition: 500ms !important;
}
.accordion-team-section .accordion-col-custom.wide {
  position: relative;
}
.accordion-team-section .accordion-col-custom.wide .card-body img {
  opacity: 1;
  transform: scale(1);
}
.accordion-team-section .accordion-col-custom.wide .card-body .content {
  opacity: 1;
  transform: translateY(0);
}
.accordion-team-section .accordion-col-custom.wide.accordion-col-custom::before {
  height: 100%;
  background: #f7f7f7;
  margin: 0 auto;
  opacity: 1;
}
.accordion-team-section .accordion-col-custom.wide .person-accordion-icon {
  transform: rotate(90deg);
}
.accordion-team-section .accordion-col-custom.wide .accordion-item {
  padding-bottom: 6.5rem;
}
.accordion-team-section .accordion-col-custom.wide .accordion-btn-img {
  width: 0;
  margin-right: 0;
  opacity: 0;
}

@media (max-width: 991.98px) {
  .accordion-team-section .accordion-item.border-bottom-line::before {
    bottom: -24px;
    right: 1.5rem;
  }
}
/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #ef353d;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #3a3a3a;
  --primary: #007bff;
  --secondary: #ef353d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #ef353d;
  --light: #f2f2f2;
  --dark: #4d4d4d;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Nexa";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Nexa";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 900;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: none;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.5;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.5;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.5;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.5;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.5;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: normal;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.75rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: bold;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 820px;
  }
}
@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 80vw;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 0 solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 0 solid #dee2e6;
}
.table tbody + tbody {
  border-top: 0 solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 0 solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 0 solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 0;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #fbc6c9;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #f7969a;
}

.table-hover .table-secondary:hover {
  background-color: #f9aeb2;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #f9aeb2;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fbc6c9;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f7969a;
}

.table-hover .table-danger:hover {
  background-color: #f9aeb2;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f9aeb2;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fbfbfb;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f8f8f8;
}

.table-hover .table-light:hover {
  background-color: #eeeeee;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #eeeeee;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #cdcdcd;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #a2a2a2;
}

.table-hover .table-dark:hover {
  background-color: silver;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: silver;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #3a3a3a;
  border-color: #4d4d4d;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #3a3a3a;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #4d4d4d;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.75rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 1rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 5px;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%233a3a3a' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #ef353d;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(239, 53, 61, 0.9);
  border-radius: 5px;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ef353d;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ef353d' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ef353d' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #ef353d;
  box-shadow: 0 0 0 0.2rem rgba(239, 53, 61, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #ef353d;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%233a3a3a' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ef353d' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ef353d' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #ef353d;
  box-shadow: 0 0 0 0.2rem rgba(239, 53, 61, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ef353d;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #ef353d;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #ef353d;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f3646a;
  background-color: #f3646a;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(239, 53, 61, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ef353d;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ef353d;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #ef353d;
  box-shadow: 0 0 0 0.2rem rgba(239, 53, 61, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: normal;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #ef353d;
  border-color: #ef353d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #eb131c;
  border-color: #df121b;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #eb131c;
  border-color: #df121b;
  box-shadow: 0 0 0 0.2rem rgba(241, 83, 90, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #ef353d;
  border-color: #ef353d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #df121b;
  border-color: #d31119;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 83, 90, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #ef353d;
  border-color: #ef353d;
}
.btn-danger:hover {
  color: #fff;
  background-color: #eb131c;
  border-color: #df121b;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #eb131c;
  border-color: #df121b;
  box-shadow: 0 0 0 0.2rem rgba(241, 83, 90, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #ef353d;
  border-color: #ef353d;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #df121b;
  border-color: #d31119;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 83, 90, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-light:hover {
  color: #212529;
  background-color: #dfdfdf;
  border-color: #d9d9d9;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #dfdfdf;
  border-color: #d9d9d9;
  box-shadow: 0 0 0 0.2rem rgba(211, 211, 212, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #d9d9d9;
  border-color: #d2d2d2;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 211, 212, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #4d4d4d;
  border-color: #4d4d4d;
}
.btn-dark:hover {
  color: #fff;
  background-color: #3a3a3a;
  border-color: #343434;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #3a3a3a;
  border-color: #343434;
  box-shadow: 0 0 0 0.2rem rgba(104, 104, 104, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #4d4d4d;
  border-color: #4d4d4d;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #343434;
  border-color: #2d2d2d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(104, 104, 104, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #ef353d;
  border-color: #ef353d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #ef353d;
  border-color: #ef353d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 53, 61, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #ef353d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #ef353d;
  border-color: #ef353d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 53, 61, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #ef353d;
  border-color: #ef353d;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #ef353d;
  border-color: #ef353d;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 53, 61, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #ef353d;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #ef353d;
  border-color: #ef353d;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 53, 61, 0.5);
}

.btn-outline-light {
  color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 242, 242, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f2f2f2;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 242, 242, 0.5);
}

.btn-outline-dark {
  color: #4d4d4d;
  border-color: #4d4d4d;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #4d4d4d;
  border-color: #4d4d4d;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 77, 77, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #4d4d4d;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #4d4d4d;
  border-color: #4d4d4d;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 77, 77, 0.5);
}

.btn-link {
  font-weight: normal;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: none;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 1rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.75rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: normal;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 5px;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 1rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.75rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 5px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%233a3a3a' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 5px;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 5px;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 5px 5px 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 5px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 5px;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: 4px 4px 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 4px 4px;
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 4px;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 5px;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 5px;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.page-item:last-child .page-link {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #ef353d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #df121b;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(239, 53, 61, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #ef353d;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #df121b;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(239, 53, 61, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f2f2f2;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #d9d9d9;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(242, 242, 242, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #4d4d4d;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #343434;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(77, 77, 77, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 1rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 5px;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: bold;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #7c1c20;
  background-color: #fcd7d8;
  border-color: #fbc6c9;
}
.alert-secondary hr {
  border-top-color: #f9aeb2;
}
.alert-secondary .alert-link {
  color: #521315;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #7c1c20;
  background-color: #fcd7d8;
  border-color: #fbc6c9;
}
.alert-danger hr {
  border-top-color: #f9aeb2;
}
.alert-danger .alert-link {
  color: #521315;
}

.alert-light {
  color: #7e7e7e;
  background-color: #fcfcfc;
  border-color: #fbfbfb;
}
.alert-light hr {
  border-top-color: #eeeeee;
}
.alert-light .alert-link {
  color: #656565;
}

.alert-dark {
  color: #282828;
  background-color: #dbdbdb;
  border-color: #cdcdcd;
}
.alert-dark hr {
  border-top-color: silver;
}
.alert-dark .alert-link {
  color: #0f0f0f;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 5px;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 5px;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f5f5f5;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 5px;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #7c1c20;
  background-color: #fbc6c9;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #7c1c20;
  background-color: #f9aeb2;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #7c1c20;
  border-color: #7c1c20;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #7c1c20;
  background-color: #fbc6c9;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #7c1c20;
  background-color: #f9aeb2;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #7c1c20;
  border-color: #7c1c20;
}

.list-group-item-light {
  color: #7e7e7e;
  background-color: #fbfbfb;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #7e7e7e;
  background-color: #eeeeee;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #7e7e7e;
  border-color: #7e7e7e;
}

.list-group-item-dark {
  color: #282828;
  background-color: #cdcdcd;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #282828;
  background-color: silver;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #282828;
  border-color: #282828;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(1rem - 1px);
  border-top-right-radius: calc(1rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(1rem - 1px);
  border-bottom-left-radius: calc(1rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Nexa";
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 5px;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Nexa";
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 1rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 1rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 1rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(1rem - 1px);
  border-top-right-radius: calc(1rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #ef353d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #df121b !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #ef353d !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #df121b !important;
}

.bg-light {
  background-color: #f2f2f2 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #d9d9d9 !important;
}

.bg-dark {
  background-color: #4d4d4d !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #343434 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #ef353d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #ef353d !important;
}

.border-light {
  border-color: #f2f2f2 !important;
}

.border-dark {
  border-color: #4d4d4d !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.75rem !important;
}

.rounded {
  border-radius: 5px !important;
}

.rounded-top {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.rounded-right {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.rounded-bottom {
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.rounded-left {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.rounded-lg {
  border-radius: 1rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.mt-5,
.my-5 {
  margin-top: 2rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 2rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 2rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 2rem !important;
}

.m-6 {
  margin: 2.5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 2.5rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 2.5rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 2.5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 2.5rem !important;
}

.m-7 {
  margin: 3rem !important;
}

.mt-7,
.my-7 {
  margin-top: 3rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 3rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 3rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 3rem !important;
}

.m-8 {
  margin: 3.5rem !important;
}

.mt-8,
.my-8 {
  margin-top: 3.5rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 3.5rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 3.5rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 3.5rem !important;
}

.m-9 {
  margin: 4rem !important;
}

.mt-9,
.my-9 {
  margin-top: 4rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 4rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 4rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 4rem !important;
}

.m-10 {
  margin: 4.5rem !important;
}

.mt-10,
.my-10 {
  margin-top: 4.5rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 4.5rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 4.5rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 4.5rem !important;
}

.m-11 {
  margin: 5rem !important;
}

.mt-11,
.my-11 {
  margin-top: 5rem !important;
}

.mr-11,
.mx-11 {
  margin-right: 5rem !important;
}

.mb-11,
.my-11 {
  margin-bottom: 5rem !important;
}

.ml-11,
.mx-11 {
  margin-left: 5rem !important;
}

.m-12 {
  margin: 5.5rem !important;
}

.mt-12,
.my-12 {
  margin-top: 5.5rem !important;
}

.mr-12,
.mx-12 {
  margin-right: 5.5rem !important;
}

.mb-12,
.my-12 {
  margin-bottom: 5.5rem !important;
}

.ml-12,
.mx-12 {
  margin-left: 5.5rem !important;
}

.m-13 {
  margin: 6rem !important;
}

.mt-13,
.my-13 {
  margin-top: 6rem !important;
}

.mr-13,
.mx-13 {
  margin-right: 6rem !important;
}

.mb-13,
.my-13 {
  margin-bottom: 6rem !important;
}

.ml-13,
.mx-13 {
  margin-left: 6rem !important;
}

.m-14 {
  margin: 6.5rem !important;
}

.mt-14,
.my-14 {
  margin-top: 6.5rem !important;
}

.mr-14,
.mx-14 {
  margin-right: 6.5rem !important;
}

.mb-14,
.my-14 {
  margin-bottom: 6.5rem !important;
}

.ml-14,
.mx-14 {
  margin-left: 6.5rem !important;
}

.m-15 {
  margin: 7rem !important;
}

.mt-15,
.my-15 {
  margin-top: 7rem !important;
}

.mr-15,
.mx-15 {
  margin-right: 7rem !important;
}

.mb-15,
.my-15 {
  margin-bottom: 7rem !important;
}

.ml-15,
.mx-15 {
  margin-left: 7rem !important;
}

.m-16 {
  margin: 7.5rem !important;
}

.mt-16,
.my-16 {
  margin-top: 7.5rem !important;
}

.mr-16,
.mx-16 {
  margin-right: 7.5rem !important;
}

.mb-16,
.my-16 {
  margin-bottom: 7.5rem !important;
}

.ml-16,
.mx-16 {
  margin-left: 7.5rem !important;
}

.m-17 {
  margin: 8rem !important;
}

.mt-17,
.my-17 {
  margin-top: 8rem !important;
}

.mr-17,
.mx-17 {
  margin-right: 8rem !important;
}

.mb-17,
.my-17 {
  margin-bottom: 8rem !important;
}

.ml-17,
.mx-17 {
  margin-left: 8rem !important;
}

.m-18 {
  margin: 8.5rem !important;
}

.mt-18,
.my-18 {
  margin-top: 8.5rem !important;
}

.mr-18,
.mx-18 {
  margin-right: 8.5rem !important;
}

.mb-18,
.my-18 {
  margin-bottom: 8.5rem !important;
}

.ml-18,
.mx-18 {
  margin-left: 8.5rem !important;
}

.m-19 {
  margin: 9rem !important;
}

.mt-19,
.my-19 {
  margin-top: 9rem !important;
}

.mr-19,
.mx-19 {
  margin-right: 9rem !important;
}

.mb-19,
.my-19 {
  margin-bottom: 9rem !important;
}

.ml-19,
.mx-19 {
  margin-left: 9rem !important;
}

.m-20 {
  margin: 9.5rem !important;
}

.mt-20,
.my-20 {
  margin-top: 9.5rem !important;
}

.mr-20,
.mx-20 {
  margin-right: 9.5rem !important;
}

.mb-20,
.my-20 {
  margin-bottom: 9.5rem !important;
}

.ml-20,
.mx-20 {
  margin-left: 9.5rem !important;
}

.m-21 {
  margin: 10rem !important;
}

.mt-21,
.my-21 {
  margin-top: 10rem !important;
}

.mr-21,
.mx-21 {
  margin-right: 10rem !important;
}

.mb-21,
.my-21 {
  margin-bottom: 10rem !important;
}

.ml-21,
.mx-21 {
  margin-left: 10rem !important;
}

.m-22 {
  margin: 11rem !important;
}

.mt-22,
.my-22 {
  margin-top: 11rem !important;
}

.mr-22,
.mx-22 {
  margin-right: 11rem !important;
}

.mb-22,
.my-22 {
  margin-bottom: 11rem !important;
}

.ml-22,
.mx-22 {
  margin-left: 11rem !important;
}

.m-23 {
  margin: 12rem !important;
}

.mt-23,
.my-23 {
  margin-top: 12rem !important;
}

.mr-23,
.mx-23 {
  margin-right: 12rem !important;
}

.mb-23,
.my-23 {
  margin-bottom: 12rem !important;
}

.ml-23,
.mx-23 {
  margin-left: 12rem !important;
}

.m-24 {
  margin: 13rem !important;
}

.mt-24,
.my-24 {
  margin-top: 13rem !important;
}

.mr-24,
.mx-24 {
  margin-right: 13rem !important;
}

.mb-24,
.my-24 {
  margin-bottom: 13rem !important;
}

.ml-24,
.mx-24 {
  margin-left: 13rem !important;
}

.m-25 {
  margin: 13.5rem !important;
}

.mt-25,
.my-25 {
  margin-top: 13.5rem !important;
}

.mr-25,
.mx-25 {
  margin-right: 13.5rem !important;
}

.mb-25,
.my-25 {
  margin-bottom: 13.5rem !important;
}

.ml-25,
.mx-25 {
  margin-left: 13.5rem !important;
}

.m-26 {
  margin: 14rem !important;
}

.mt-26,
.my-26 {
  margin-top: 14rem !important;
}

.mr-26,
.mx-26 {
  margin-right: 14rem !important;
}

.mb-26,
.my-26 {
  margin-bottom: 14rem !important;
}

.ml-26,
.mx-26 {
  margin-left: 14rem !important;
}

.m-27 {
  margin: 15rem !important;
}

.mt-27,
.my-27 {
  margin-top: 15rem !important;
}

.mr-27,
.mx-27 {
  margin-right: 15rem !important;
}

.mb-27,
.my-27 {
  margin-bottom: 15rem !important;
}

.ml-27,
.mx-27 {
  margin-left: 15rem !important;
}

.m-28 {
  margin: 16rem !important;
}

.mt-28,
.my-28 {
  margin-top: 16rem !important;
}

.mr-28,
.mx-28 {
  margin-right: 16rem !important;
}

.mb-28,
.my-28 {
  margin-bottom: 16rem !important;
}

.ml-28,
.mx-28 {
  margin-left: 16rem !important;
}

.m-29 {
  margin: 17rem !important;
}

.mt-29,
.my-29 {
  margin-top: 17rem !important;
}

.mr-29,
.mx-29 {
  margin-right: 17rem !important;
}

.mb-29,
.my-29 {
  margin-bottom: 17rem !important;
}

.ml-29,
.mx-29 {
  margin-left: 17rem !important;
}

.m-30 {
  margin: 18rem !important;
}

.mt-30,
.my-30 {
  margin-top: 18rem !important;
}

.mr-30,
.mx-30 {
  margin-right: 18rem !important;
}

.mb-30,
.my-30 {
  margin-bottom: 18rem !important;
}

.ml-30,
.mx-30 {
  margin-left: 18rem !important;
}

.m-31 {
  margin: 19rem !important;
}

.mt-31,
.my-31 {
  margin-top: 19rem !important;
}

.mr-31,
.mx-31 {
  margin-right: 19rem !important;
}

.mb-31,
.my-31 {
  margin-bottom: 19rem !important;
}

.ml-31,
.mx-31 {
  margin-left: 19rem !important;
}

.m-32 {
  margin: 20rem !important;
}

.mt-32,
.my-32 {
  margin-top: 20rem !important;
}

.mr-32,
.mx-32 {
  margin-right: 20rem !important;
}

.mb-32,
.my-32 {
  margin-bottom: 20rem !important;
}

.ml-32,
.mx-32 {
  margin-left: 20rem !important;
}

.m-37 {
  margin: 22.5rem !important;
}

.mt-37,
.my-37 {
  margin-top: 22.5rem !important;
}

.mr-37,
.mx-37 {
  margin-right: 22.5rem !important;
}

.mb-37,
.my-37 {
  margin-bottom: 22.5rem !important;
}

.ml-37,
.mx-37 {
  margin-left: 22.5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.pt-5,
.py-5 {
  padding-top: 2rem !important;
}

.pr-5,
.px-5 {
  padding-right: 2rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 2rem !important;
}

.pl-5,
.px-5 {
  padding-left: 2rem !important;
}

.p-6 {
  padding: 2.5rem !important;
}

.pt-6,
.py-6 {
  padding-top: 2.5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 2.5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 2.5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 2.5rem !important;
}

.p-7 {
  padding: 3rem !important;
}

.pt-7,
.py-7 {
  padding-top: 3rem !important;
}

.pr-7,
.px-7 {
  padding-right: 3rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 3rem !important;
}

.pl-7,
.px-7 {
  padding-left: 3rem !important;
}

.p-8 {
  padding: 3.5rem !important;
}

.pt-8,
.py-8 {
  padding-top: 3.5rem !important;
}

.pr-8,
.px-8 {
  padding-right: 3.5rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 3.5rem !important;
}

.pl-8,
.px-8 {
  padding-left: 3.5rem !important;
}

.p-9 {
  padding: 4rem !important;
}

.pt-9,
.py-9 {
  padding-top: 4rem !important;
}

.pr-9,
.px-9 {
  padding-right: 4rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 4rem !important;
}

.pl-9,
.px-9 {
  padding-left: 4rem !important;
}

.p-10 {
  padding: 4.5rem !important;
}

.pt-10,
.py-10 {
  padding-top: 4.5rem !important;
}

.pr-10,
.px-10 {
  padding-right: 4.5rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 4.5rem !important;
}

.pl-10,
.px-10 {
  padding-left: 4.5rem !important;
}

.p-11 {
  padding: 5rem !important;
}

.pt-11,
.py-11 {
  padding-top: 5rem !important;
}

.pr-11,
.px-11 {
  padding-right: 5rem !important;
}

.pb-11,
.py-11 {
  padding-bottom: 5rem !important;
}

.pl-11,
.px-11 {
  padding-left: 5rem !important;
}

.p-12 {
  padding: 5.5rem !important;
}

.pt-12,
.py-12 {
  padding-top: 5.5rem !important;
}

.pr-12,
.px-12 {
  padding-right: 5.5rem !important;
}

.pb-12,
.py-12 {
  padding-bottom: 5.5rem !important;
}

.pl-12,
.px-12 {
  padding-left: 5.5rem !important;
}

.p-13 {
  padding: 6rem !important;
}

.pt-13,
.py-13 {
  padding-top: 6rem !important;
}

.pr-13,
.px-13 {
  padding-right: 6rem !important;
}

.pb-13,
.py-13 {
  padding-bottom: 6rem !important;
}

.pl-13,
.px-13 {
  padding-left: 6rem !important;
}

.p-14 {
  padding: 6.5rem !important;
}

.pt-14,
.py-14 {
  padding-top: 6.5rem !important;
}

.pr-14,
.px-14 {
  padding-right: 6.5rem !important;
}

.pb-14,
.py-14 {
  padding-bottom: 6.5rem !important;
}

.pl-14,
.px-14 {
  padding-left: 6.5rem !important;
}

.p-15 {
  padding: 7rem !important;
}

.pt-15,
.py-15 {
  padding-top: 7rem !important;
}

.pr-15,
.px-15 {
  padding-right: 7rem !important;
}

.pb-15,
.py-15 {
  padding-bottom: 7rem !important;
}

.pl-15,
.px-15 {
  padding-left: 7rem !important;
}

.p-16 {
  padding: 7.5rem !important;
}

.pt-16,
.py-16 {
  padding-top: 7.5rem !important;
}

.pr-16,
.px-16 {
  padding-right: 7.5rem !important;
}

.pb-16,
.py-16 {
  padding-bottom: 7.5rem !important;
}

.pl-16,
.px-16 {
  padding-left: 7.5rem !important;
}

.p-17 {
  padding: 8rem !important;
}

.pt-17,
.py-17 {
  padding-top: 8rem !important;
}

.pr-17,
.px-17 {
  padding-right: 8rem !important;
}

.pb-17,
.py-17 {
  padding-bottom: 8rem !important;
}

.pl-17,
.px-17 {
  padding-left: 8rem !important;
}

.p-18 {
  padding: 8.5rem !important;
}

.pt-18,
.py-18 {
  padding-top: 8.5rem !important;
}

.pr-18,
.px-18 {
  padding-right: 8.5rem !important;
}

.pb-18,
.py-18 {
  padding-bottom: 8.5rem !important;
}

.pl-18,
.px-18 {
  padding-left: 8.5rem !important;
}

.p-19 {
  padding: 9rem !important;
}

.pt-19,
.py-19 {
  padding-top: 9rem !important;
}

.pr-19,
.px-19 {
  padding-right: 9rem !important;
}

.pb-19,
.py-19 {
  padding-bottom: 9rem !important;
}

.pl-19,
.px-19 {
  padding-left: 9rem !important;
}

.p-20 {
  padding: 9.5rem !important;
}

.pt-20,
.py-20 {
  padding-top: 9.5rem !important;
}

.pr-20,
.px-20 {
  padding-right: 9.5rem !important;
}

.pb-20,
.py-20 {
  padding-bottom: 9.5rem !important;
}

.pl-20,
.px-20 {
  padding-left: 9.5rem !important;
}

.p-21 {
  padding: 10rem !important;
}

.pt-21,
.py-21 {
  padding-top: 10rem !important;
}

.pr-21,
.px-21 {
  padding-right: 10rem !important;
}

.pb-21,
.py-21 {
  padding-bottom: 10rem !important;
}

.pl-21,
.px-21 {
  padding-left: 10rem !important;
}

.p-22 {
  padding: 11rem !important;
}

.pt-22,
.py-22 {
  padding-top: 11rem !important;
}

.pr-22,
.px-22 {
  padding-right: 11rem !important;
}

.pb-22,
.py-22 {
  padding-bottom: 11rem !important;
}

.pl-22,
.px-22 {
  padding-left: 11rem !important;
}

.p-23 {
  padding: 12rem !important;
}

.pt-23,
.py-23 {
  padding-top: 12rem !important;
}

.pr-23,
.px-23 {
  padding-right: 12rem !important;
}

.pb-23,
.py-23 {
  padding-bottom: 12rem !important;
}

.pl-23,
.px-23 {
  padding-left: 12rem !important;
}

.p-24 {
  padding: 13rem !important;
}

.pt-24,
.py-24 {
  padding-top: 13rem !important;
}

.pr-24,
.px-24 {
  padding-right: 13rem !important;
}

.pb-24,
.py-24 {
  padding-bottom: 13rem !important;
}

.pl-24,
.px-24 {
  padding-left: 13rem !important;
}

.p-25 {
  padding: 13.5rem !important;
}

.pt-25,
.py-25 {
  padding-top: 13.5rem !important;
}

.pr-25,
.px-25 {
  padding-right: 13.5rem !important;
}

.pb-25,
.py-25 {
  padding-bottom: 13.5rem !important;
}

.pl-25,
.px-25 {
  padding-left: 13.5rem !important;
}

.p-26 {
  padding: 14rem !important;
}

.pt-26,
.py-26 {
  padding-top: 14rem !important;
}

.pr-26,
.px-26 {
  padding-right: 14rem !important;
}

.pb-26,
.py-26 {
  padding-bottom: 14rem !important;
}

.pl-26,
.px-26 {
  padding-left: 14rem !important;
}

.p-27 {
  padding: 15rem !important;
}

.pt-27,
.py-27 {
  padding-top: 15rem !important;
}

.pr-27,
.px-27 {
  padding-right: 15rem !important;
}

.pb-27,
.py-27 {
  padding-bottom: 15rem !important;
}

.pl-27,
.px-27 {
  padding-left: 15rem !important;
}

.p-28 {
  padding: 16rem !important;
}

.pt-28,
.py-28 {
  padding-top: 16rem !important;
}

.pr-28,
.px-28 {
  padding-right: 16rem !important;
}

.pb-28,
.py-28 {
  padding-bottom: 16rem !important;
}

.pl-28,
.px-28 {
  padding-left: 16rem !important;
}

.p-29 {
  padding: 17rem !important;
}

.pt-29,
.py-29 {
  padding-top: 17rem !important;
}

.pr-29,
.px-29 {
  padding-right: 17rem !important;
}

.pb-29,
.py-29 {
  padding-bottom: 17rem !important;
}

.pl-29,
.px-29 {
  padding-left: 17rem !important;
}

.p-30 {
  padding: 18rem !important;
}

.pt-30,
.py-30 {
  padding-top: 18rem !important;
}

.pr-30,
.px-30 {
  padding-right: 18rem !important;
}

.pb-30,
.py-30 {
  padding-bottom: 18rem !important;
}

.pl-30,
.px-30 {
  padding-left: 18rem !important;
}

.p-31 {
  padding: 19rem !important;
}

.pt-31,
.py-31 {
  padding-top: 19rem !important;
}

.pr-31,
.px-31 {
  padding-right: 19rem !important;
}

.pb-31,
.py-31 {
  padding-bottom: 19rem !important;
}

.pl-31,
.px-31 {
  padding-left: 19rem !important;
}

.p-32 {
  padding: 20rem !important;
}

.pt-32,
.py-32 {
  padding-top: 20rem !important;
}

.pr-32,
.px-32 {
  padding-right: 20rem !important;
}

.pb-32,
.py-32 {
  padding-bottom: 20rem !important;
}

.pl-32,
.px-32 {
  padding-left: 20rem !important;
}

.p-37 {
  padding: 22.5rem !important;
}

.pt-37,
.py-37 {
  padding-top: 22.5rem !important;
}

.pr-37,
.px-37 {
  padding-right: 22.5rem !important;
}

.pb-37,
.py-37 {
  padding-bottom: 22.5rem !important;
}

.pl-37,
.px-37 {
  padding-left: 22.5rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -2rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -2rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -2rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -2rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -2rem !important;
}

.m-n6 {
  margin: -2.5rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -2.5rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -2.5rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -2.5rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -2.5rem !important;
}

.m-n7 {
  margin: -3rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -3rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -3rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -3rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -3rem !important;
}

.m-n8 {
  margin: -3.5rem !important;
}

.mt-n8,
.my-n8 {
  margin-top: -3.5rem !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -3.5rem !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -3.5rem !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -3.5rem !important;
}

.m-n9 {
  margin: -4rem !important;
}

.mt-n9,
.my-n9 {
  margin-top: -4rem !important;
}

.mr-n9,
.mx-n9 {
  margin-right: -4rem !important;
}

.mb-n9,
.my-n9 {
  margin-bottom: -4rem !important;
}

.ml-n9,
.mx-n9 {
  margin-left: -4rem !important;
}

.m-n10 {
  margin: -4.5rem !important;
}

.mt-n10,
.my-n10 {
  margin-top: -4.5rem !important;
}

.mr-n10,
.mx-n10 {
  margin-right: -4.5rem !important;
}

.mb-n10,
.my-n10 {
  margin-bottom: -4.5rem !important;
}

.ml-n10,
.mx-n10 {
  margin-left: -4.5rem !important;
}

.m-n11 {
  margin: -5rem !important;
}

.mt-n11,
.my-n11 {
  margin-top: -5rem !important;
}

.mr-n11,
.mx-n11 {
  margin-right: -5rem !important;
}

.mb-n11,
.my-n11 {
  margin-bottom: -5rem !important;
}

.ml-n11,
.mx-n11 {
  margin-left: -5rem !important;
}

.m-n12 {
  margin: -5.5rem !important;
}

.mt-n12,
.my-n12 {
  margin-top: -5.5rem !important;
}

.mr-n12,
.mx-n12 {
  margin-right: -5.5rem !important;
}

.mb-n12,
.my-n12 {
  margin-bottom: -5.5rem !important;
}

.ml-n12,
.mx-n12 {
  margin-left: -5.5rem !important;
}

.m-n13 {
  margin: -6rem !important;
}

.mt-n13,
.my-n13 {
  margin-top: -6rem !important;
}

.mr-n13,
.mx-n13 {
  margin-right: -6rem !important;
}

.mb-n13,
.my-n13 {
  margin-bottom: -6rem !important;
}

.ml-n13,
.mx-n13 {
  margin-left: -6rem !important;
}

.m-n14 {
  margin: -6.5rem !important;
}

.mt-n14,
.my-n14 {
  margin-top: -6.5rem !important;
}

.mr-n14,
.mx-n14 {
  margin-right: -6.5rem !important;
}

.mb-n14,
.my-n14 {
  margin-bottom: -6.5rem !important;
}

.ml-n14,
.mx-n14 {
  margin-left: -6.5rem !important;
}

.m-n15 {
  margin: -7rem !important;
}

.mt-n15,
.my-n15 {
  margin-top: -7rem !important;
}

.mr-n15,
.mx-n15 {
  margin-right: -7rem !important;
}

.mb-n15,
.my-n15 {
  margin-bottom: -7rem !important;
}

.ml-n15,
.mx-n15 {
  margin-left: -7rem !important;
}

.m-n16 {
  margin: -7.5rem !important;
}

.mt-n16,
.my-n16 {
  margin-top: -7.5rem !important;
}

.mr-n16,
.mx-n16 {
  margin-right: -7.5rem !important;
}

.mb-n16,
.my-n16 {
  margin-bottom: -7.5rem !important;
}

.ml-n16,
.mx-n16 {
  margin-left: -7.5rem !important;
}

.m-n17 {
  margin: -8rem !important;
}

.mt-n17,
.my-n17 {
  margin-top: -8rem !important;
}

.mr-n17,
.mx-n17 {
  margin-right: -8rem !important;
}

.mb-n17,
.my-n17 {
  margin-bottom: -8rem !important;
}

.ml-n17,
.mx-n17 {
  margin-left: -8rem !important;
}

.m-n18 {
  margin: -8.5rem !important;
}

.mt-n18,
.my-n18 {
  margin-top: -8.5rem !important;
}

.mr-n18,
.mx-n18 {
  margin-right: -8.5rem !important;
}

.mb-n18,
.my-n18 {
  margin-bottom: -8.5rem !important;
}

.ml-n18,
.mx-n18 {
  margin-left: -8.5rem !important;
}

.m-n19 {
  margin: -9rem !important;
}

.mt-n19,
.my-n19 {
  margin-top: -9rem !important;
}

.mr-n19,
.mx-n19 {
  margin-right: -9rem !important;
}

.mb-n19,
.my-n19 {
  margin-bottom: -9rem !important;
}

.ml-n19,
.mx-n19 {
  margin-left: -9rem !important;
}

.m-n20 {
  margin: -9.5rem !important;
}

.mt-n20,
.my-n20 {
  margin-top: -9.5rem !important;
}

.mr-n20,
.mx-n20 {
  margin-right: -9.5rem !important;
}

.mb-n20,
.my-n20 {
  margin-bottom: -9.5rem !important;
}

.ml-n20,
.mx-n20 {
  margin-left: -9.5rem !important;
}

.m-n21 {
  margin: -10rem !important;
}

.mt-n21,
.my-n21 {
  margin-top: -10rem !important;
}

.mr-n21,
.mx-n21 {
  margin-right: -10rem !important;
}

.mb-n21,
.my-n21 {
  margin-bottom: -10rem !important;
}

.ml-n21,
.mx-n21 {
  margin-left: -10rem !important;
}

.m-n22 {
  margin: -11rem !important;
}

.mt-n22,
.my-n22 {
  margin-top: -11rem !important;
}

.mr-n22,
.mx-n22 {
  margin-right: -11rem !important;
}

.mb-n22,
.my-n22 {
  margin-bottom: -11rem !important;
}

.ml-n22,
.mx-n22 {
  margin-left: -11rem !important;
}

.m-n23 {
  margin: -12rem !important;
}

.mt-n23,
.my-n23 {
  margin-top: -12rem !important;
}

.mr-n23,
.mx-n23 {
  margin-right: -12rem !important;
}

.mb-n23,
.my-n23 {
  margin-bottom: -12rem !important;
}

.ml-n23,
.mx-n23 {
  margin-left: -12rem !important;
}

.m-n24 {
  margin: -13rem !important;
}

.mt-n24,
.my-n24 {
  margin-top: -13rem !important;
}

.mr-n24,
.mx-n24 {
  margin-right: -13rem !important;
}

.mb-n24,
.my-n24 {
  margin-bottom: -13rem !important;
}

.ml-n24,
.mx-n24 {
  margin-left: -13rem !important;
}

.m-n25 {
  margin: -13.5rem !important;
}

.mt-n25,
.my-n25 {
  margin-top: -13.5rem !important;
}

.mr-n25,
.mx-n25 {
  margin-right: -13.5rem !important;
}

.mb-n25,
.my-n25 {
  margin-bottom: -13.5rem !important;
}

.ml-n25,
.mx-n25 {
  margin-left: -13.5rem !important;
}

.m-n26 {
  margin: -14rem !important;
}

.mt-n26,
.my-n26 {
  margin-top: -14rem !important;
}

.mr-n26,
.mx-n26 {
  margin-right: -14rem !important;
}

.mb-n26,
.my-n26 {
  margin-bottom: -14rem !important;
}

.ml-n26,
.mx-n26 {
  margin-left: -14rem !important;
}

.m-n27 {
  margin: -15rem !important;
}

.mt-n27,
.my-n27 {
  margin-top: -15rem !important;
}

.mr-n27,
.mx-n27 {
  margin-right: -15rem !important;
}

.mb-n27,
.my-n27 {
  margin-bottom: -15rem !important;
}

.ml-n27,
.mx-n27 {
  margin-left: -15rem !important;
}

.m-n28 {
  margin: -16rem !important;
}

.mt-n28,
.my-n28 {
  margin-top: -16rem !important;
}

.mr-n28,
.mx-n28 {
  margin-right: -16rem !important;
}

.mb-n28,
.my-n28 {
  margin-bottom: -16rem !important;
}

.ml-n28,
.mx-n28 {
  margin-left: -16rem !important;
}

.m-n29 {
  margin: -17rem !important;
}

.mt-n29,
.my-n29 {
  margin-top: -17rem !important;
}

.mr-n29,
.mx-n29 {
  margin-right: -17rem !important;
}

.mb-n29,
.my-n29 {
  margin-bottom: -17rem !important;
}

.ml-n29,
.mx-n29 {
  margin-left: -17rem !important;
}

.m-n30 {
  margin: -18rem !important;
}

.mt-n30,
.my-n30 {
  margin-top: -18rem !important;
}

.mr-n30,
.mx-n30 {
  margin-right: -18rem !important;
}

.mb-n30,
.my-n30 {
  margin-bottom: -18rem !important;
}

.ml-n30,
.mx-n30 {
  margin-left: -18rem !important;
}

.m-n31 {
  margin: -19rem !important;
}

.mt-n31,
.my-n31 {
  margin-top: -19rem !important;
}

.mr-n31,
.mx-n31 {
  margin-right: -19rem !important;
}

.mb-n31,
.my-n31 {
  margin-bottom: -19rem !important;
}

.ml-n31,
.mx-n31 {
  margin-left: -19rem !important;
}

.m-n32 {
  margin: -20rem !important;
}

.mt-n32,
.my-n32 {
  margin-top: -20rem !important;
}

.mr-n32,
.mx-n32 {
  margin-right: -20rem !important;
}

.mb-n32,
.my-n32 {
  margin-bottom: -20rem !important;
}

.ml-n32,
.mx-n32 {
  margin-left: -20rem !important;
}

.m-n37 {
  margin: -22.5rem !important;
}

.mt-n37,
.my-n37 {
  margin-top: -22.5rem !important;
}

.mr-n37,
.mx-n37 {
  margin-right: -22.5rem !important;
}

.mb-n37,
.my-n37 {
  margin-bottom: -22.5rem !important;
}

.ml-n37,
.mx-n37 {
  margin-left: -22.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 2rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 2rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 2rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 2rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 2rem !important;
  }
  .m-sm-6 {
    margin: 2.5rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 2.5rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 2.5rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 2.5rem !important;
  }
  .m-sm-7 {
    margin: 3rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 3rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 3rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 3rem !important;
  }
  .m-sm-8 {
    margin: 3.5rem !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 3.5rem !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 3.5rem !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 3.5rem !important;
  }
  .m-sm-9 {
    margin: 4rem !important;
  }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 4rem !important;
  }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 4rem !important;
  }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 4rem !important;
  }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 4rem !important;
  }
  .m-sm-10 {
    margin: 4.5rem !important;
  }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 4.5rem !important;
  }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 4.5rem !important;
  }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 4.5rem !important;
  }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 4.5rem !important;
  }
  .m-sm-11 {
    margin: 5rem !important;
  }
  .mt-sm-11,
  .my-sm-11 {
    margin-top: 5rem !important;
  }
  .mr-sm-11,
  .mx-sm-11 {
    margin-right: 5rem !important;
  }
  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 5rem !important;
  }
  .ml-sm-11,
  .mx-sm-11 {
    margin-left: 5rem !important;
  }
  .m-sm-12 {
    margin: 5.5rem !important;
  }
  .mt-sm-12,
  .my-sm-12 {
    margin-top: 5.5rem !important;
  }
  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 5.5rem !important;
  }
  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 5.5rem !important;
  }
  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 5.5rem !important;
  }
  .m-sm-13 {
    margin: 6rem !important;
  }
  .mt-sm-13,
  .my-sm-13 {
    margin-top: 6rem !important;
  }
  .mr-sm-13,
  .mx-sm-13 {
    margin-right: 6rem !important;
  }
  .mb-sm-13,
  .my-sm-13 {
    margin-bottom: 6rem !important;
  }
  .ml-sm-13,
  .mx-sm-13 {
    margin-left: 6rem !important;
  }
  .m-sm-14 {
    margin: 6.5rem !important;
  }
  .mt-sm-14,
  .my-sm-14 {
    margin-top: 6.5rem !important;
  }
  .mr-sm-14,
  .mx-sm-14 {
    margin-right: 6.5rem !important;
  }
  .mb-sm-14,
  .my-sm-14 {
    margin-bottom: 6.5rem !important;
  }
  .ml-sm-14,
  .mx-sm-14 {
    margin-left: 6.5rem !important;
  }
  .m-sm-15 {
    margin: 7rem !important;
  }
  .mt-sm-15,
  .my-sm-15 {
    margin-top: 7rem !important;
  }
  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 7rem !important;
  }
  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 7rem !important;
  }
  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 7rem !important;
  }
  .m-sm-16 {
    margin: 7.5rem !important;
  }
  .mt-sm-16,
  .my-sm-16 {
    margin-top: 7.5rem !important;
  }
  .mr-sm-16,
  .mx-sm-16 {
    margin-right: 7.5rem !important;
  }
  .mb-sm-16,
  .my-sm-16 {
    margin-bottom: 7.5rem !important;
  }
  .ml-sm-16,
  .mx-sm-16 {
    margin-left: 7.5rem !important;
  }
  .m-sm-17 {
    margin: 8rem !important;
  }
  .mt-sm-17,
  .my-sm-17 {
    margin-top: 8rem !important;
  }
  .mr-sm-17,
  .mx-sm-17 {
    margin-right: 8rem !important;
  }
  .mb-sm-17,
  .my-sm-17 {
    margin-bottom: 8rem !important;
  }
  .ml-sm-17,
  .mx-sm-17 {
    margin-left: 8rem !important;
  }
  .m-sm-18 {
    margin: 8.5rem !important;
  }
  .mt-sm-18,
  .my-sm-18 {
    margin-top: 8.5rem !important;
  }
  .mr-sm-18,
  .mx-sm-18 {
    margin-right: 8.5rem !important;
  }
  .mb-sm-18,
  .my-sm-18 {
    margin-bottom: 8.5rem !important;
  }
  .ml-sm-18,
  .mx-sm-18 {
    margin-left: 8.5rem !important;
  }
  .m-sm-19 {
    margin: 9rem !important;
  }
  .mt-sm-19,
  .my-sm-19 {
    margin-top: 9rem !important;
  }
  .mr-sm-19,
  .mx-sm-19 {
    margin-right: 9rem !important;
  }
  .mb-sm-19,
  .my-sm-19 {
    margin-bottom: 9rem !important;
  }
  .ml-sm-19,
  .mx-sm-19 {
    margin-left: 9rem !important;
  }
  .m-sm-20 {
    margin: 9.5rem !important;
  }
  .mt-sm-20,
  .my-sm-20 {
    margin-top: 9.5rem !important;
  }
  .mr-sm-20,
  .mx-sm-20 {
    margin-right: 9.5rem !important;
  }
  .mb-sm-20,
  .my-sm-20 {
    margin-bottom: 9.5rem !important;
  }
  .ml-sm-20,
  .mx-sm-20 {
    margin-left: 9.5rem !important;
  }
  .m-sm-21 {
    margin: 10rem !important;
  }
  .mt-sm-21,
  .my-sm-21 {
    margin-top: 10rem !important;
  }
  .mr-sm-21,
  .mx-sm-21 {
    margin-right: 10rem !important;
  }
  .mb-sm-21,
  .my-sm-21 {
    margin-bottom: 10rem !important;
  }
  .ml-sm-21,
  .mx-sm-21 {
    margin-left: 10rem !important;
  }
  .m-sm-22 {
    margin: 11rem !important;
  }
  .mt-sm-22,
  .my-sm-22 {
    margin-top: 11rem !important;
  }
  .mr-sm-22,
  .mx-sm-22 {
    margin-right: 11rem !important;
  }
  .mb-sm-22,
  .my-sm-22 {
    margin-bottom: 11rem !important;
  }
  .ml-sm-22,
  .mx-sm-22 {
    margin-left: 11rem !important;
  }
  .m-sm-23 {
    margin: 12rem !important;
  }
  .mt-sm-23,
  .my-sm-23 {
    margin-top: 12rem !important;
  }
  .mr-sm-23,
  .mx-sm-23 {
    margin-right: 12rem !important;
  }
  .mb-sm-23,
  .my-sm-23 {
    margin-bottom: 12rem !important;
  }
  .ml-sm-23,
  .mx-sm-23 {
    margin-left: 12rem !important;
  }
  .m-sm-24 {
    margin: 13rem !important;
  }
  .mt-sm-24,
  .my-sm-24 {
    margin-top: 13rem !important;
  }
  .mr-sm-24,
  .mx-sm-24 {
    margin-right: 13rem !important;
  }
  .mb-sm-24,
  .my-sm-24 {
    margin-bottom: 13rem !important;
  }
  .ml-sm-24,
  .mx-sm-24 {
    margin-left: 13rem !important;
  }
  .m-sm-25 {
    margin: 13.5rem !important;
  }
  .mt-sm-25,
  .my-sm-25 {
    margin-top: 13.5rem !important;
  }
  .mr-sm-25,
  .mx-sm-25 {
    margin-right: 13.5rem !important;
  }
  .mb-sm-25,
  .my-sm-25 {
    margin-bottom: 13.5rem !important;
  }
  .ml-sm-25,
  .mx-sm-25 {
    margin-left: 13.5rem !important;
  }
  .m-sm-26 {
    margin: 14rem !important;
  }
  .mt-sm-26,
  .my-sm-26 {
    margin-top: 14rem !important;
  }
  .mr-sm-26,
  .mx-sm-26 {
    margin-right: 14rem !important;
  }
  .mb-sm-26,
  .my-sm-26 {
    margin-bottom: 14rem !important;
  }
  .ml-sm-26,
  .mx-sm-26 {
    margin-left: 14rem !important;
  }
  .m-sm-27 {
    margin: 15rem !important;
  }
  .mt-sm-27,
  .my-sm-27 {
    margin-top: 15rem !important;
  }
  .mr-sm-27,
  .mx-sm-27 {
    margin-right: 15rem !important;
  }
  .mb-sm-27,
  .my-sm-27 {
    margin-bottom: 15rem !important;
  }
  .ml-sm-27,
  .mx-sm-27 {
    margin-left: 15rem !important;
  }
  .m-sm-28 {
    margin: 16rem !important;
  }
  .mt-sm-28,
  .my-sm-28 {
    margin-top: 16rem !important;
  }
  .mr-sm-28,
  .mx-sm-28 {
    margin-right: 16rem !important;
  }
  .mb-sm-28,
  .my-sm-28 {
    margin-bottom: 16rem !important;
  }
  .ml-sm-28,
  .mx-sm-28 {
    margin-left: 16rem !important;
  }
  .m-sm-29 {
    margin: 17rem !important;
  }
  .mt-sm-29,
  .my-sm-29 {
    margin-top: 17rem !important;
  }
  .mr-sm-29,
  .mx-sm-29 {
    margin-right: 17rem !important;
  }
  .mb-sm-29,
  .my-sm-29 {
    margin-bottom: 17rem !important;
  }
  .ml-sm-29,
  .mx-sm-29 {
    margin-left: 17rem !important;
  }
  .m-sm-30 {
    margin: 18rem !important;
  }
  .mt-sm-30,
  .my-sm-30 {
    margin-top: 18rem !important;
  }
  .mr-sm-30,
  .mx-sm-30 {
    margin-right: 18rem !important;
  }
  .mb-sm-30,
  .my-sm-30 {
    margin-bottom: 18rem !important;
  }
  .ml-sm-30,
  .mx-sm-30 {
    margin-left: 18rem !important;
  }
  .m-sm-31 {
    margin: 19rem !important;
  }
  .mt-sm-31,
  .my-sm-31 {
    margin-top: 19rem !important;
  }
  .mr-sm-31,
  .mx-sm-31 {
    margin-right: 19rem !important;
  }
  .mb-sm-31,
  .my-sm-31 {
    margin-bottom: 19rem !important;
  }
  .ml-sm-31,
  .mx-sm-31 {
    margin-left: 19rem !important;
  }
  .m-sm-32 {
    margin: 20rem !important;
  }
  .mt-sm-32,
  .my-sm-32 {
    margin-top: 20rem !important;
  }
  .mr-sm-32,
  .mx-sm-32 {
    margin-right: 20rem !important;
  }
  .mb-sm-32,
  .my-sm-32 {
    margin-bottom: 20rem !important;
  }
  .ml-sm-32,
  .mx-sm-32 {
    margin-left: 20rem !important;
  }
  .m-sm-37 {
    margin: 22.5rem !important;
  }
  .mt-sm-37,
  .my-sm-37 {
    margin-top: 22.5rem !important;
  }
  .mr-sm-37,
  .mx-sm-37 {
    margin-right: 22.5rem !important;
  }
  .mb-sm-37,
  .my-sm-37 {
    margin-bottom: 22.5rem !important;
  }
  .ml-sm-37,
  .mx-sm-37 {
    margin-left: 22.5rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 2rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 2rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 2rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 2rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 2rem !important;
  }
  .p-sm-6 {
    padding: 2.5rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 2.5rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 2.5rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 2.5rem !important;
  }
  .p-sm-7 {
    padding: 3rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 3rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 3rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 3rem !important;
  }
  .p-sm-8 {
    padding: 3.5rem !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 3.5rem !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 3.5rem !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 3.5rem !important;
  }
  .p-sm-9 {
    padding: 4rem !important;
  }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 4rem !important;
  }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 4rem !important;
  }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 4rem !important;
  }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 4rem !important;
  }
  .p-sm-10 {
    padding: 4.5rem !important;
  }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 4.5rem !important;
  }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 4.5rem !important;
  }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 4.5rem !important;
  }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 4.5rem !important;
  }
  .p-sm-11 {
    padding: 5rem !important;
  }
  .pt-sm-11,
  .py-sm-11 {
    padding-top: 5rem !important;
  }
  .pr-sm-11,
  .px-sm-11 {
    padding-right: 5rem !important;
  }
  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 5rem !important;
  }
  .pl-sm-11,
  .px-sm-11 {
    padding-left: 5rem !important;
  }
  .p-sm-12 {
    padding: 5.5rem !important;
  }
  .pt-sm-12,
  .py-sm-12 {
    padding-top: 5.5rem !important;
  }
  .pr-sm-12,
  .px-sm-12 {
    padding-right: 5.5rem !important;
  }
  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 5.5rem !important;
  }
  .pl-sm-12,
  .px-sm-12 {
    padding-left: 5.5rem !important;
  }
  .p-sm-13 {
    padding: 6rem !important;
  }
  .pt-sm-13,
  .py-sm-13 {
    padding-top: 6rem !important;
  }
  .pr-sm-13,
  .px-sm-13 {
    padding-right: 6rem !important;
  }
  .pb-sm-13,
  .py-sm-13 {
    padding-bottom: 6rem !important;
  }
  .pl-sm-13,
  .px-sm-13 {
    padding-left: 6rem !important;
  }
  .p-sm-14 {
    padding: 6.5rem !important;
  }
  .pt-sm-14,
  .py-sm-14 {
    padding-top: 6.5rem !important;
  }
  .pr-sm-14,
  .px-sm-14 {
    padding-right: 6.5rem !important;
  }
  .pb-sm-14,
  .py-sm-14 {
    padding-bottom: 6.5rem !important;
  }
  .pl-sm-14,
  .px-sm-14 {
    padding-left: 6.5rem !important;
  }
  .p-sm-15 {
    padding: 7rem !important;
  }
  .pt-sm-15,
  .py-sm-15 {
    padding-top: 7rem !important;
  }
  .pr-sm-15,
  .px-sm-15 {
    padding-right: 7rem !important;
  }
  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 7rem !important;
  }
  .pl-sm-15,
  .px-sm-15 {
    padding-left: 7rem !important;
  }
  .p-sm-16 {
    padding: 7.5rem !important;
  }
  .pt-sm-16,
  .py-sm-16 {
    padding-top: 7.5rem !important;
  }
  .pr-sm-16,
  .px-sm-16 {
    padding-right: 7.5rem !important;
  }
  .pb-sm-16,
  .py-sm-16 {
    padding-bottom: 7.5rem !important;
  }
  .pl-sm-16,
  .px-sm-16 {
    padding-left: 7.5rem !important;
  }
  .p-sm-17 {
    padding: 8rem !important;
  }
  .pt-sm-17,
  .py-sm-17 {
    padding-top: 8rem !important;
  }
  .pr-sm-17,
  .px-sm-17 {
    padding-right: 8rem !important;
  }
  .pb-sm-17,
  .py-sm-17 {
    padding-bottom: 8rem !important;
  }
  .pl-sm-17,
  .px-sm-17 {
    padding-left: 8rem !important;
  }
  .p-sm-18 {
    padding: 8.5rem !important;
  }
  .pt-sm-18,
  .py-sm-18 {
    padding-top: 8.5rem !important;
  }
  .pr-sm-18,
  .px-sm-18 {
    padding-right: 8.5rem !important;
  }
  .pb-sm-18,
  .py-sm-18 {
    padding-bottom: 8.5rem !important;
  }
  .pl-sm-18,
  .px-sm-18 {
    padding-left: 8.5rem !important;
  }
  .p-sm-19 {
    padding: 9rem !important;
  }
  .pt-sm-19,
  .py-sm-19 {
    padding-top: 9rem !important;
  }
  .pr-sm-19,
  .px-sm-19 {
    padding-right: 9rem !important;
  }
  .pb-sm-19,
  .py-sm-19 {
    padding-bottom: 9rem !important;
  }
  .pl-sm-19,
  .px-sm-19 {
    padding-left: 9rem !important;
  }
  .p-sm-20 {
    padding: 9.5rem !important;
  }
  .pt-sm-20,
  .py-sm-20 {
    padding-top: 9.5rem !important;
  }
  .pr-sm-20,
  .px-sm-20 {
    padding-right: 9.5rem !important;
  }
  .pb-sm-20,
  .py-sm-20 {
    padding-bottom: 9.5rem !important;
  }
  .pl-sm-20,
  .px-sm-20 {
    padding-left: 9.5rem !important;
  }
  .p-sm-21 {
    padding: 10rem !important;
  }
  .pt-sm-21,
  .py-sm-21 {
    padding-top: 10rem !important;
  }
  .pr-sm-21,
  .px-sm-21 {
    padding-right: 10rem !important;
  }
  .pb-sm-21,
  .py-sm-21 {
    padding-bottom: 10rem !important;
  }
  .pl-sm-21,
  .px-sm-21 {
    padding-left: 10rem !important;
  }
  .p-sm-22 {
    padding: 11rem !important;
  }
  .pt-sm-22,
  .py-sm-22 {
    padding-top: 11rem !important;
  }
  .pr-sm-22,
  .px-sm-22 {
    padding-right: 11rem !important;
  }
  .pb-sm-22,
  .py-sm-22 {
    padding-bottom: 11rem !important;
  }
  .pl-sm-22,
  .px-sm-22 {
    padding-left: 11rem !important;
  }
  .p-sm-23 {
    padding: 12rem !important;
  }
  .pt-sm-23,
  .py-sm-23 {
    padding-top: 12rem !important;
  }
  .pr-sm-23,
  .px-sm-23 {
    padding-right: 12rem !important;
  }
  .pb-sm-23,
  .py-sm-23 {
    padding-bottom: 12rem !important;
  }
  .pl-sm-23,
  .px-sm-23 {
    padding-left: 12rem !important;
  }
  .p-sm-24 {
    padding: 13rem !important;
  }
  .pt-sm-24,
  .py-sm-24 {
    padding-top: 13rem !important;
  }
  .pr-sm-24,
  .px-sm-24 {
    padding-right: 13rem !important;
  }
  .pb-sm-24,
  .py-sm-24 {
    padding-bottom: 13rem !important;
  }
  .pl-sm-24,
  .px-sm-24 {
    padding-left: 13rem !important;
  }
  .p-sm-25 {
    padding: 13.5rem !important;
  }
  .pt-sm-25,
  .py-sm-25 {
    padding-top: 13.5rem !important;
  }
  .pr-sm-25,
  .px-sm-25 {
    padding-right: 13.5rem !important;
  }
  .pb-sm-25,
  .py-sm-25 {
    padding-bottom: 13.5rem !important;
  }
  .pl-sm-25,
  .px-sm-25 {
    padding-left: 13.5rem !important;
  }
  .p-sm-26 {
    padding: 14rem !important;
  }
  .pt-sm-26,
  .py-sm-26 {
    padding-top: 14rem !important;
  }
  .pr-sm-26,
  .px-sm-26 {
    padding-right: 14rem !important;
  }
  .pb-sm-26,
  .py-sm-26 {
    padding-bottom: 14rem !important;
  }
  .pl-sm-26,
  .px-sm-26 {
    padding-left: 14rem !important;
  }
  .p-sm-27 {
    padding: 15rem !important;
  }
  .pt-sm-27,
  .py-sm-27 {
    padding-top: 15rem !important;
  }
  .pr-sm-27,
  .px-sm-27 {
    padding-right: 15rem !important;
  }
  .pb-sm-27,
  .py-sm-27 {
    padding-bottom: 15rem !important;
  }
  .pl-sm-27,
  .px-sm-27 {
    padding-left: 15rem !important;
  }
  .p-sm-28 {
    padding: 16rem !important;
  }
  .pt-sm-28,
  .py-sm-28 {
    padding-top: 16rem !important;
  }
  .pr-sm-28,
  .px-sm-28 {
    padding-right: 16rem !important;
  }
  .pb-sm-28,
  .py-sm-28 {
    padding-bottom: 16rem !important;
  }
  .pl-sm-28,
  .px-sm-28 {
    padding-left: 16rem !important;
  }
  .p-sm-29 {
    padding: 17rem !important;
  }
  .pt-sm-29,
  .py-sm-29 {
    padding-top: 17rem !important;
  }
  .pr-sm-29,
  .px-sm-29 {
    padding-right: 17rem !important;
  }
  .pb-sm-29,
  .py-sm-29 {
    padding-bottom: 17rem !important;
  }
  .pl-sm-29,
  .px-sm-29 {
    padding-left: 17rem !important;
  }
  .p-sm-30 {
    padding: 18rem !important;
  }
  .pt-sm-30,
  .py-sm-30 {
    padding-top: 18rem !important;
  }
  .pr-sm-30,
  .px-sm-30 {
    padding-right: 18rem !important;
  }
  .pb-sm-30,
  .py-sm-30 {
    padding-bottom: 18rem !important;
  }
  .pl-sm-30,
  .px-sm-30 {
    padding-left: 18rem !important;
  }
  .p-sm-31 {
    padding: 19rem !important;
  }
  .pt-sm-31,
  .py-sm-31 {
    padding-top: 19rem !important;
  }
  .pr-sm-31,
  .px-sm-31 {
    padding-right: 19rem !important;
  }
  .pb-sm-31,
  .py-sm-31 {
    padding-bottom: 19rem !important;
  }
  .pl-sm-31,
  .px-sm-31 {
    padding-left: 19rem !important;
  }
  .p-sm-32 {
    padding: 20rem !important;
  }
  .pt-sm-32,
  .py-sm-32 {
    padding-top: 20rem !important;
  }
  .pr-sm-32,
  .px-sm-32 {
    padding-right: 20rem !important;
  }
  .pb-sm-32,
  .py-sm-32 {
    padding-bottom: 20rem !important;
  }
  .pl-sm-32,
  .px-sm-32 {
    padding-left: 20rem !important;
  }
  .p-sm-37 {
    padding: 22.5rem !important;
  }
  .pt-sm-37,
  .py-sm-37 {
    padding-top: 22.5rem !important;
  }
  .pr-sm-37,
  .px-sm-37 {
    padding-right: 22.5rem !important;
  }
  .pb-sm-37,
  .py-sm-37 {
    padding-bottom: 22.5rem !important;
  }
  .pl-sm-37,
  .px-sm-37 {
    padding-left: 22.5rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -2rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -2rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -2rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -2rem !important;
  }
  .m-sm-n6 {
    margin: -2.5rem !important;
  }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -2.5rem !important;
  }
  .m-sm-n7 {
    margin: -3rem !important;
  }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -3rem !important;
  }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -3rem !important;
  }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -3rem !important;
  }
  .m-sm-n8 {
    margin: -3.5rem !important;
  }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -3.5rem !important;
  }
  .m-sm-n9 {
    margin: -4rem !important;
  }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -4rem !important;
  }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -4rem !important;
  }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -4rem !important;
  }
  .m-sm-n10 {
    margin: -4.5rem !important;
  }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -4.5rem !important;
  }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -4.5rem !important;
  }
  .m-sm-n11 {
    margin: -5rem !important;
  }
  .mt-sm-n11,
  .my-sm-n11 {
    margin-top: -5rem !important;
  }
  .mr-sm-n11,
  .mx-sm-n11 {
    margin-right: -5rem !important;
  }
  .mb-sm-n11,
  .my-sm-n11 {
    margin-bottom: -5rem !important;
  }
  .ml-sm-n11,
  .mx-sm-n11 {
    margin-left: -5rem !important;
  }
  .m-sm-n12 {
    margin: -5.5rem !important;
  }
  .mt-sm-n12,
  .my-sm-n12 {
    margin-top: -5.5rem !important;
  }
  .mr-sm-n12,
  .mx-sm-n12 {
    margin-right: -5.5rem !important;
  }
  .mb-sm-n12,
  .my-sm-n12 {
    margin-bottom: -5.5rem !important;
  }
  .ml-sm-n12,
  .mx-sm-n12 {
    margin-left: -5.5rem !important;
  }
  .m-sm-n13 {
    margin: -6rem !important;
  }
  .mt-sm-n13,
  .my-sm-n13 {
    margin-top: -6rem !important;
  }
  .mr-sm-n13,
  .mx-sm-n13 {
    margin-right: -6rem !important;
  }
  .mb-sm-n13,
  .my-sm-n13 {
    margin-bottom: -6rem !important;
  }
  .ml-sm-n13,
  .mx-sm-n13 {
    margin-left: -6rem !important;
  }
  .m-sm-n14 {
    margin: -6.5rem !important;
  }
  .mt-sm-n14,
  .my-sm-n14 {
    margin-top: -6.5rem !important;
  }
  .mr-sm-n14,
  .mx-sm-n14 {
    margin-right: -6.5rem !important;
  }
  .mb-sm-n14,
  .my-sm-n14 {
    margin-bottom: -6.5rem !important;
  }
  .ml-sm-n14,
  .mx-sm-n14 {
    margin-left: -6.5rem !important;
  }
  .m-sm-n15 {
    margin: -7rem !important;
  }
  .mt-sm-n15,
  .my-sm-n15 {
    margin-top: -7rem !important;
  }
  .mr-sm-n15,
  .mx-sm-n15 {
    margin-right: -7rem !important;
  }
  .mb-sm-n15,
  .my-sm-n15 {
    margin-bottom: -7rem !important;
  }
  .ml-sm-n15,
  .mx-sm-n15 {
    margin-left: -7rem !important;
  }
  .m-sm-n16 {
    margin: -7.5rem !important;
  }
  .mt-sm-n16,
  .my-sm-n16 {
    margin-top: -7.5rem !important;
  }
  .mr-sm-n16,
  .mx-sm-n16 {
    margin-right: -7.5rem !important;
  }
  .mb-sm-n16,
  .my-sm-n16 {
    margin-bottom: -7.5rem !important;
  }
  .ml-sm-n16,
  .mx-sm-n16 {
    margin-left: -7.5rem !important;
  }
  .m-sm-n17 {
    margin: -8rem !important;
  }
  .mt-sm-n17,
  .my-sm-n17 {
    margin-top: -8rem !important;
  }
  .mr-sm-n17,
  .mx-sm-n17 {
    margin-right: -8rem !important;
  }
  .mb-sm-n17,
  .my-sm-n17 {
    margin-bottom: -8rem !important;
  }
  .ml-sm-n17,
  .mx-sm-n17 {
    margin-left: -8rem !important;
  }
  .m-sm-n18 {
    margin: -8.5rem !important;
  }
  .mt-sm-n18,
  .my-sm-n18 {
    margin-top: -8.5rem !important;
  }
  .mr-sm-n18,
  .mx-sm-n18 {
    margin-right: -8.5rem !important;
  }
  .mb-sm-n18,
  .my-sm-n18 {
    margin-bottom: -8.5rem !important;
  }
  .ml-sm-n18,
  .mx-sm-n18 {
    margin-left: -8.5rem !important;
  }
  .m-sm-n19 {
    margin: -9rem !important;
  }
  .mt-sm-n19,
  .my-sm-n19 {
    margin-top: -9rem !important;
  }
  .mr-sm-n19,
  .mx-sm-n19 {
    margin-right: -9rem !important;
  }
  .mb-sm-n19,
  .my-sm-n19 {
    margin-bottom: -9rem !important;
  }
  .ml-sm-n19,
  .mx-sm-n19 {
    margin-left: -9rem !important;
  }
  .m-sm-n20 {
    margin: -9.5rem !important;
  }
  .mt-sm-n20,
  .my-sm-n20 {
    margin-top: -9.5rem !important;
  }
  .mr-sm-n20,
  .mx-sm-n20 {
    margin-right: -9.5rem !important;
  }
  .mb-sm-n20,
  .my-sm-n20 {
    margin-bottom: -9.5rem !important;
  }
  .ml-sm-n20,
  .mx-sm-n20 {
    margin-left: -9.5rem !important;
  }
  .m-sm-n21 {
    margin: -10rem !important;
  }
  .mt-sm-n21,
  .my-sm-n21 {
    margin-top: -10rem !important;
  }
  .mr-sm-n21,
  .mx-sm-n21 {
    margin-right: -10rem !important;
  }
  .mb-sm-n21,
  .my-sm-n21 {
    margin-bottom: -10rem !important;
  }
  .ml-sm-n21,
  .mx-sm-n21 {
    margin-left: -10rem !important;
  }
  .m-sm-n22 {
    margin: -11rem !important;
  }
  .mt-sm-n22,
  .my-sm-n22 {
    margin-top: -11rem !important;
  }
  .mr-sm-n22,
  .mx-sm-n22 {
    margin-right: -11rem !important;
  }
  .mb-sm-n22,
  .my-sm-n22 {
    margin-bottom: -11rem !important;
  }
  .ml-sm-n22,
  .mx-sm-n22 {
    margin-left: -11rem !important;
  }
  .m-sm-n23 {
    margin: -12rem !important;
  }
  .mt-sm-n23,
  .my-sm-n23 {
    margin-top: -12rem !important;
  }
  .mr-sm-n23,
  .mx-sm-n23 {
    margin-right: -12rem !important;
  }
  .mb-sm-n23,
  .my-sm-n23 {
    margin-bottom: -12rem !important;
  }
  .ml-sm-n23,
  .mx-sm-n23 {
    margin-left: -12rem !important;
  }
  .m-sm-n24 {
    margin: -13rem !important;
  }
  .mt-sm-n24,
  .my-sm-n24 {
    margin-top: -13rem !important;
  }
  .mr-sm-n24,
  .mx-sm-n24 {
    margin-right: -13rem !important;
  }
  .mb-sm-n24,
  .my-sm-n24 {
    margin-bottom: -13rem !important;
  }
  .ml-sm-n24,
  .mx-sm-n24 {
    margin-left: -13rem !important;
  }
  .m-sm-n25 {
    margin: -13.5rem !important;
  }
  .mt-sm-n25,
  .my-sm-n25 {
    margin-top: -13.5rem !important;
  }
  .mr-sm-n25,
  .mx-sm-n25 {
    margin-right: -13.5rem !important;
  }
  .mb-sm-n25,
  .my-sm-n25 {
    margin-bottom: -13.5rem !important;
  }
  .ml-sm-n25,
  .mx-sm-n25 {
    margin-left: -13.5rem !important;
  }
  .m-sm-n26 {
    margin: -14rem !important;
  }
  .mt-sm-n26,
  .my-sm-n26 {
    margin-top: -14rem !important;
  }
  .mr-sm-n26,
  .mx-sm-n26 {
    margin-right: -14rem !important;
  }
  .mb-sm-n26,
  .my-sm-n26 {
    margin-bottom: -14rem !important;
  }
  .ml-sm-n26,
  .mx-sm-n26 {
    margin-left: -14rem !important;
  }
  .m-sm-n27 {
    margin: -15rem !important;
  }
  .mt-sm-n27,
  .my-sm-n27 {
    margin-top: -15rem !important;
  }
  .mr-sm-n27,
  .mx-sm-n27 {
    margin-right: -15rem !important;
  }
  .mb-sm-n27,
  .my-sm-n27 {
    margin-bottom: -15rem !important;
  }
  .ml-sm-n27,
  .mx-sm-n27 {
    margin-left: -15rem !important;
  }
  .m-sm-n28 {
    margin: -16rem !important;
  }
  .mt-sm-n28,
  .my-sm-n28 {
    margin-top: -16rem !important;
  }
  .mr-sm-n28,
  .mx-sm-n28 {
    margin-right: -16rem !important;
  }
  .mb-sm-n28,
  .my-sm-n28 {
    margin-bottom: -16rem !important;
  }
  .ml-sm-n28,
  .mx-sm-n28 {
    margin-left: -16rem !important;
  }
  .m-sm-n29 {
    margin: -17rem !important;
  }
  .mt-sm-n29,
  .my-sm-n29 {
    margin-top: -17rem !important;
  }
  .mr-sm-n29,
  .mx-sm-n29 {
    margin-right: -17rem !important;
  }
  .mb-sm-n29,
  .my-sm-n29 {
    margin-bottom: -17rem !important;
  }
  .ml-sm-n29,
  .mx-sm-n29 {
    margin-left: -17rem !important;
  }
  .m-sm-n30 {
    margin: -18rem !important;
  }
  .mt-sm-n30,
  .my-sm-n30 {
    margin-top: -18rem !important;
  }
  .mr-sm-n30,
  .mx-sm-n30 {
    margin-right: -18rem !important;
  }
  .mb-sm-n30,
  .my-sm-n30 {
    margin-bottom: -18rem !important;
  }
  .ml-sm-n30,
  .mx-sm-n30 {
    margin-left: -18rem !important;
  }
  .m-sm-n31 {
    margin: -19rem !important;
  }
  .mt-sm-n31,
  .my-sm-n31 {
    margin-top: -19rem !important;
  }
  .mr-sm-n31,
  .mx-sm-n31 {
    margin-right: -19rem !important;
  }
  .mb-sm-n31,
  .my-sm-n31 {
    margin-bottom: -19rem !important;
  }
  .ml-sm-n31,
  .mx-sm-n31 {
    margin-left: -19rem !important;
  }
  .m-sm-n32 {
    margin: -20rem !important;
  }
  .mt-sm-n32,
  .my-sm-n32 {
    margin-top: -20rem !important;
  }
  .mr-sm-n32,
  .mx-sm-n32 {
    margin-right: -20rem !important;
  }
  .mb-sm-n32,
  .my-sm-n32 {
    margin-bottom: -20rem !important;
  }
  .ml-sm-n32,
  .mx-sm-n32 {
    margin-left: -20rem !important;
  }
  .m-sm-n37 {
    margin: -22.5rem !important;
  }
  .mt-sm-n37,
  .my-sm-n37 {
    margin-top: -22.5rem !important;
  }
  .mr-sm-n37,
  .mx-sm-n37 {
    margin-right: -22.5rem !important;
  }
  .mb-sm-n37,
  .my-sm-n37 {
    margin-bottom: -22.5rem !important;
  }
  .ml-sm-n37,
  .mx-sm-n37 {
    margin-left: -22.5rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 2rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 2rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 2rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 2rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 2rem !important;
  }
  .m-md-6 {
    margin: 2.5rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 2.5rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 2.5rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 2.5rem !important;
  }
  .m-md-7 {
    margin: 3rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 3rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 3rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 3rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 3rem !important;
  }
  .m-md-8 {
    margin: 3.5rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 3.5rem !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 3.5rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 3.5rem !important;
  }
  .m-md-9 {
    margin: 4rem !important;
  }
  .mt-md-9,
  .my-md-9 {
    margin-top: 4rem !important;
  }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 4rem !important;
  }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 4rem !important;
  }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 4rem !important;
  }
  .m-md-10 {
    margin: 4.5rem !important;
  }
  .mt-md-10,
  .my-md-10 {
    margin-top: 4.5rem !important;
  }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 4.5rem !important;
  }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 4.5rem !important;
  }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 4.5rem !important;
  }
  .m-md-11 {
    margin: 5rem !important;
  }
  .mt-md-11,
  .my-md-11 {
    margin-top: 5rem !important;
  }
  .mr-md-11,
  .mx-md-11 {
    margin-right: 5rem !important;
  }
  .mb-md-11,
  .my-md-11 {
    margin-bottom: 5rem !important;
  }
  .ml-md-11,
  .mx-md-11 {
    margin-left: 5rem !important;
  }
  .m-md-12 {
    margin: 5.5rem !important;
  }
  .mt-md-12,
  .my-md-12 {
    margin-top: 5.5rem !important;
  }
  .mr-md-12,
  .mx-md-12 {
    margin-right: 5.5rem !important;
  }
  .mb-md-12,
  .my-md-12 {
    margin-bottom: 5.5rem !important;
  }
  .ml-md-12,
  .mx-md-12 {
    margin-left: 5.5rem !important;
  }
  .m-md-13 {
    margin: 6rem !important;
  }
  .mt-md-13,
  .my-md-13 {
    margin-top: 6rem !important;
  }
  .mr-md-13,
  .mx-md-13 {
    margin-right: 6rem !important;
  }
  .mb-md-13,
  .my-md-13 {
    margin-bottom: 6rem !important;
  }
  .ml-md-13,
  .mx-md-13 {
    margin-left: 6rem !important;
  }
  .m-md-14 {
    margin: 6.5rem !important;
  }
  .mt-md-14,
  .my-md-14 {
    margin-top: 6.5rem !important;
  }
  .mr-md-14,
  .mx-md-14 {
    margin-right: 6.5rem !important;
  }
  .mb-md-14,
  .my-md-14 {
    margin-bottom: 6.5rem !important;
  }
  .ml-md-14,
  .mx-md-14 {
    margin-left: 6.5rem !important;
  }
  .m-md-15 {
    margin: 7rem !important;
  }
  .mt-md-15,
  .my-md-15 {
    margin-top: 7rem !important;
  }
  .mr-md-15,
  .mx-md-15 {
    margin-right: 7rem !important;
  }
  .mb-md-15,
  .my-md-15 {
    margin-bottom: 7rem !important;
  }
  .ml-md-15,
  .mx-md-15 {
    margin-left: 7rem !important;
  }
  .m-md-16 {
    margin: 7.5rem !important;
  }
  .mt-md-16,
  .my-md-16 {
    margin-top: 7.5rem !important;
  }
  .mr-md-16,
  .mx-md-16 {
    margin-right: 7.5rem !important;
  }
  .mb-md-16,
  .my-md-16 {
    margin-bottom: 7.5rem !important;
  }
  .ml-md-16,
  .mx-md-16 {
    margin-left: 7.5rem !important;
  }
  .m-md-17 {
    margin: 8rem !important;
  }
  .mt-md-17,
  .my-md-17 {
    margin-top: 8rem !important;
  }
  .mr-md-17,
  .mx-md-17 {
    margin-right: 8rem !important;
  }
  .mb-md-17,
  .my-md-17 {
    margin-bottom: 8rem !important;
  }
  .ml-md-17,
  .mx-md-17 {
    margin-left: 8rem !important;
  }
  .m-md-18 {
    margin: 8.5rem !important;
  }
  .mt-md-18,
  .my-md-18 {
    margin-top: 8.5rem !important;
  }
  .mr-md-18,
  .mx-md-18 {
    margin-right: 8.5rem !important;
  }
  .mb-md-18,
  .my-md-18 {
    margin-bottom: 8.5rem !important;
  }
  .ml-md-18,
  .mx-md-18 {
    margin-left: 8.5rem !important;
  }
  .m-md-19 {
    margin: 9rem !important;
  }
  .mt-md-19,
  .my-md-19 {
    margin-top: 9rem !important;
  }
  .mr-md-19,
  .mx-md-19 {
    margin-right: 9rem !important;
  }
  .mb-md-19,
  .my-md-19 {
    margin-bottom: 9rem !important;
  }
  .ml-md-19,
  .mx-md-19 {
    margin-left: 9rem !important;
  }
  .m-md-20 {
    margin: 9.5rem !important;
  }
  .mt-md-20,
  .my-md-20 {
    margin-top: 9.5rem !important;
  }
  .mr-md-20,
  .mx-md-20 {
    margin-right: 9.5rem !important;
  }
  .mb-md-20,
  .my-md-20 {
    margin-bottom: 9.5rem !important;
  }
  .ml-md-20,
  .mx-md-20 {
    margin-left: 9.5rem !important;
  }
  .m-md-21 {
    margin: 10rem !important;
  }
  .mt-md-21,
  .my-md-21 {
    margin-top: 10rem !important;
  }
  .mr-md-21,
  .mx-md-21 {
    margin-right: 10rem !important;
  }
  .mb-md-21,
  .my-md-21 {
    margin-bottom: 10rem !important;
  }
  .ml-md-21,
  .mx-md-21 {
    margin-left: 10rem !important;
  }
  .m-md-22 {
    margin: 11rem !important;
  }
  .mt-md-22,
  .my-md-22 {
    margin-top: 11rem !important;
  }
  .mr-md-22,
  .mx-md-22 {
    margin-right: 11rem !important;
  }
  .mb-md-22,
  .my-md-22 {
    margin-bottom: 11rem !important;
  }
  .ml-md-22,
  .mx-md-22 {
    margin-left: 11rem !important;
  }
  .m-md-23 {
    margin: 12rem !important;
  }
  .mt-md-23,
  .my-md-23 {
    margin-top: 12rem !important;
  }
  .mr-md-23,
  .mx-md-23 {
    margin-right: 12rem !important;
  }
  .mb-md-23,
  .my-md-23 {
    margin-bottom: 12rem !important;
  }
  .ml-md-23,
  .mx-md-23 {
    margin-left: 12rem !important;
  }
  .m-md-24 {
    margin: 13rem !important;
  }
  .mt-md-24,
  .my-md-24 {
    margin-top: 13rem !important;
  }
  .mr-md-24,
  .mx-md-24 {
    margin-right: 13rem !important;
  }
  .mb-md-24,
  .my-md-24 {
    margin-bottom: 13rem !important;
  }
  .ml-md-24,
  .mx-md-24 {
    margin-left: 13rem !important;
  }
  .m-md-25 {
    margin: 13.5rem !important;
  }
  .mt-md-25,
  .my-md-25 {
    margin-top: 13.5rem !important;
  }
  .mr-md-25,
  .mx-md-25 {
    margin-right: 13.5rem !important;
  }
  .mb-md-25,
  .my-md-25 {
    margin-bottom: 13.5rem !important;
  }
  .ml-md-25,
  .mx-md-25 {
    margin-left: 13.5rem !important;
  }
  .m-md-26 {
    margin: 14rem !important;
  }
  .mt-md-26,
  .my-md-26 {
    margin-top: 14rem !important;
  }
  .mr-md-26,
  .mx-md-26 {
    margin-right: 14rem !important;
  }
  .mb-md-26,
  .my-md-26 {
    margin-bottom: 14rem !important;
  }
  .ml-md-26,
  .mx-md-26 {
    margin-left: 14rem !important;
  }
  .m-md-27 {
    margin: 15rem !important;
  }
  .mt-md-27,
  .my-md-27 {
    margin-top: 15rem !important;
  }
  .mr-md-27,
  .mx-md-27 {
    margin-right: 15rem !important;
  }
  .mb-md-27,
  .my-md-27 {
    margin-bottom: 15rem !important;
  }
  .ml-md-27,
  .mx-md-27 {
    margin-left: 15rem !important;
  }
  .m-md-28 {
    margin: 16rem !important;
  }
  .mt-md-28,
  .my-md-28 {
    margin-top: 16rem !important;
  }
  .mr-md-28,
  .mx-md-28 {
    margin-right: 16rem !important;
  }
  .mb-md-28,
  .my-md-28 {
    margin-bottom: 16rem !important;
  }
  .ml-md-28,
  .mx-md-28 {
    margin-left: 16rem !important;
  }
  .m-md-29 {
    margin: 17rem !important;
  }
  .mt-md-29,
  .my-md-29 {
    margin-top: 17rem !important;
  }
  .mr-md-29,
  .mx-md-29 {
    margin-right: 17rem !important;
  }
  .mb-md-29,
  .my-md-29 {
    margin-bottom: 17rem !important;
  }
  .ml-md-29,
  .mx-md-29 {
    margin-left: 17rem !important;
  }
  .m-md-30 {
    margin: 18rem !important;
  }
  .mt-md-30,
  .my-md-30 {
    margin-top: 18rem !important;
  }
  .mr-md-30,
  .mx-md-30 {
    margin-right: 18rem !important;
  }
  .mb-md-30,
  .my-md-30 {
    margin-bottom: 18rem !important;
  }
  .ml-md-30,
  .mx-md-30 {
    margin-left: 18rem !important;
  }
  .m-md-31 {
    margin: 19rem !important;
  }
  .mt-md-31,
  .my-md-31 {
    margin-top: 19rem !important;
  }
  .mr-md-31,
  .mx-md-31 {
    margin-right: 19rem !important;
  }
  .mb-md-31,
  .my-md-31 {
    margin-bottom: 19rem !important;
  }
  .ml-md-31,
  .mx-md-31 {
    margin-left: 19rem !important;
  }
  .m-md-32 {
    margin: 20rem !important;
  }
  .mt-md-32,
  .my-md-32 {
    margin-top: 20rem !important;
  }
  .mr-md-32,
  .mx-md-32 {
    margin-right: 20rem !important;
  }
  .mb-md-32,
  .my-md-32 {
    margin-bottom: 20rem !important;
  }
  .ml-md-32,
  .mx-md-32 {
    margin-left: 20rem !important;
  }
  .m-md-37 {
    margin: 22.5rem !important;
  }
  .mt-md-37,
  .my-md-37 {
    margin-top: 22.5rem !important;
  }
  .mr-md-37,
  .mx-md-37 {
    margin-right: 22.5rem !important;
  }
  .mb-md-37,
  .my-md-37 {
    margin-bottom: 22.5rem !important;
  }
  .ml-md-37,
  .mx-md-37 {
    margin-left: 22.5rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 2rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 2rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 2rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 2rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 2rem !important;
  }
  .p-md-6 {
    padding: 2.5rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 2.5rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 2.5rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 2.5rem !important;
  }
  .p-md-7 {
    padding: 3rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 3rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 3rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 3rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 3rem !important;
  }
  .p-md-8 {
    padding: 3.5rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 3.5rem !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 3.5rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 3.5rem !important;
  }
  .p-md-9 {
    padding: 4rem !important;
  }
  .pt-md-9,
  .py-md-9 {
    padding-top: 4rem !important;
  }
  .pr-md-9,
  .px-md-9 {
    padding-right: 4rem !important;
  }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 4rem !important;
  }
  .pl-md-9,
  .px-md-9 {
    padding-left: 4rem !important;
  }
  .p-md-10 {
    padding: 4.5rem !important;
  }
  .pt-md-10,
  .py-md-10 {
    padding-top: 4.5rem !important;
  }
  .pr-md-10,
  .px-md-10 {
    padding-right: 4.5rem !important;
  }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 4.5rem !important;
  }
  .pl-md-10,
  .px-md-10 {
    padding-left: 4.5rem !important;
  }
  .p-md-11 {
    padding: 5rem !important;
  }
  .pt-md-11,
  .py-md-11 {
    padding-top: 5rem !important;
  }
  .pr-md-11,
  .px-md-11 {
    padding-right: 5rem !important;
  }
  .pb-md-11,
  .py-md-11 {
    padding-bottom: 5rem !important;
  }
  .pl-md-11,
  .px-md-11 {
    padding-left: 5rem !important;
  }
  .p-md-12 {
    padding: 5.5rem !important;
  }
  .pt-md-12,
  .py-md-12 {
    padding-top: 5.5rem !important;
  }
  .pr-md-12,
  .px-md-12 {
    padding-right: 5.5rem !important;
  }
  .pb-md-12,
  .py-md-12 {
    padding-bottom: 5.5rem !important;
  }
  .pl-md-12,
  .px-md-12 {
    padding-left: 5.5rem !important;
  }
  .p-md-13 {
    padding: 6rem !important;
  }
  .pt-md-13,
  .py-md-13 {
    padding-top: 6rem !important;
  }
  .pr-md-13,
  .px-md-13 {
    padding-right: 6rem !important;
  }
  .pb-md-13,
  .py-md-13 {
    padding-bottom: 6rem !important;
  }
  .pl-md-13,
  .px-md-13 {
    padding-left: 6rem !important;
  }
  .p-md-14 {
    padding: 6.5rem !important;
  }
  .pt-md-14,
  .py-md-14 {
    padding-top: 6.5rem !important;
  }
  .pr-md-14,
  .px-md-14 {
    padding-right: 6.5rem !important;
  }
  .pb-md-14,
  .py-md-14 {
    padding-bottom: 6.5rem !important;
  }
  .pl-md-14,
  .px-md-14 {
    padding-left: 6.5rem !important;
  }
  .p-md-15 {
    padding: 7rem !important;
  }
  .pt-md-15,
  .py-md-15 {
    padding-top: 7rem !important;
  }
  .pr-md-15,
  .px-md-15 {
    padding-right: 7rem !important;
  }
  .pb-md-15,
  .py-md-15 {
    padding-bottom: 7rem !important;
  }
  .pl-md-15,
  .px-md-15 {
    padding-left: 7rem !important;
  }
  .p-md-16 {
    padding: 7.5rem !important;
  }
  .pt-md-16,
  .py-md-16 {
    padding-top: 7.5rem !important;
  }
  .pr-md-16,
  .px-md-16 {
    padding-right: 7.5rem !important;
  }
  .pb-md-16,
  .py-md-16 {
    padding-bottom: 7.5rem !important;
  }
  .pl-md-16,
  .px-md-16 {
    padding-left: 7.5rem !important;
  }
  .p-md-17 {
    padding: 8rem !important;
  }
  .pt-md-17,
  .py-md-17 {
    padding-top: 8rem !important;
  }
  .pr-md-17,
  .px-md-17 {
    padding-right: 8rem !important;
  }
  .pb-md-17,
  .py-md-17 {
    padding-bottom: 8rem !important;
  }
  .pl-md-17,
  .px-md-17 {
    padding-left: 8rem !important;
  }
  .p-md-18 {
    padding: 8.5rem !important;
  }
  .pt-md-18,
  .py-md-18 {
    padding-top: 8.5rem !important;
  }
  .pr-md-18,
  .px-md-18 {
    padding-right: 8.5rem !important;
  }
  .pb-md-18,
  .py-md-18 {
    padding-bottom: 8.5rem !important;
  }
  .pl-md-18,
  .px-md-18 {
    padding-left: 8.5rem !important;
  }
  .p-md-19 {
    padding: 9rem !important;
  }
  .pt-md-19,
  .py-md-19 {
    padding-top: 9rem !important;
  }
  .pr-md-19,
  .px-md-19 {
    padding-right: 9rem !important;
  }
  .pb-md-19,
  .py-md-19 {
    padding-bottom: 9rem !important;
  }
  .pl-md-19,
  .px-md-19 {
    padding-left: 9rem !important;
  }
  .p-md-20 {
    padding: 9.5rem !important;
  }
  .pt-md-20,
  .py-md-20 {
    padding-top: 9.5rem !important;
  }
  .pr-md-20,
  .px-md-20 {
    padding-right: 9.5rem !important;
  }
  .pb-md-20,
  .py-md-20 {
    padding-bottom: 9.5rem !important;
  }
  .pl-md-20,
  .px-md-20 {
    padding-left: 9.5rem !important;
  }
  .p-md-21 {
    padding: 10rem !important;
  }
  .pt-md-21,
  .py-md-21 {
    padding-top: 10rem !important;
  }
  .pr-md-21,
  .px-md-21 {
    padding-right: 10rem !important;
  }
  .pb-md-21,
  .py-md-21 {
    padding-bottom: 10rem !important;
  }
  .pl-md-21,
  .px-md-21 {
    padding-left: 10rem !important;
  }
  .p-md-22 {
    padding: 11rem !important;
  }
  .pt-md-22,
  .py-md-22 {
    padding-top: 11rem !important;
  }
  .pr-md-22,
  .px-md-22 {
    padding-right: 11rem !important;
  }
  .pb-md-22,
  .py-md-22 {
    padding-bottom: 11rem !important;
  }
  .pl-md-22,
  .px-md-22 {
    padding-left: 11rem !important;
  }
  .p-md-23 {
    padding: 12rem !important;
  }
  .pt-md-23,
  .py-md-23 {
    padding-top: 12rem !important;
  }
  .pr-md-23,
  .px-md-23 {
    padding-right: 12rem !important;
  }
  .pb-md-23,
  .py-md-23 {
    padding-bottom: 12rem !important;
  }
  .pl-md-23,
  .px-md-23 {
    padding-left: 12rem !important;
  }
  .p-md-24 {
    padding: 13rem !important;
  }
  .pt-md-24,
  .py-md-24 {
    padding-top: 13rem !important;
  }
  .pr-md-24,
  .px-md-24 {
    padding-right: 13rem !important;
  }
  .pb-md-24,
  .py-md-24 {
    padding-bottom: 13rem !important;
  }
  .pl-md-24,
  .px-md-24 {
    padding-left: 13rem !important;
  }
  .p-md-25 {
    padding: 13.5rem !important;
  }
  .pt-md-25,
  .py-md-25 {
    padding-top: 13.5rem !important;
  }
  .pr-md-25,
  .px-md-25 {
    padding-right: 13.5rem !important;
  }
  .pb-md-25,
  .py-md-25 {
    padding-bottom: 13.5rem !important;
  }
  .pl-md-25,
  .px-md-25 {
    padding-left: 13.5rem !important;
  }
  .p-md-26 {
    padding: 14rem !important;
  }
  .pt-md-26,
  .py-md-26 {
    padding-top: 14rem !important;
  }
  .pr-md-26,
  .px-md-26 {
    padding-right: 14rem !important;
  }
  .pb-md-26,
  .py-md-26 {
    padding-bottom: 14rem !important;
  }
  .pl-md-26,
  .px-md-26 {
    padding-left: 14rem !important;
  }
  .p-md-27 {
    padding: 15rem !important;
  }
  .pt-md-27,
  .py-md-27 {
    padding-top: 15rem !important;
  }
  .pr-md-27,
  .px-md-27 {
    padding-right: 15rem !important;
  }
  .pb-md-27,
  .py-md-27 {
    padding-bottom: 15rem !important;
  }
  .pl-md-27,
  .px-md-27 {
    padding-left: 15rem !important;
  }
  .p-md-28 {
    padding: 16rem !important;
  }
  .pt-md-28,
  .py-md-28 {
    padding-top: 16rem !important;
  }
  .pr-md-28,
  .px-md-28 {
    padding-right: 16rem !important;
  }
  .pb-md-28,
  .py-md-28 {
    padding-bottom: 16rem !important;
  }
  .pl-md-28,
  .px-md-28 {
    padding-left: 16rem !important;
  }
  .p-md-29 {
    padding: 17rem !important;
  }
  .pt-md-29,
  .py-md-29 {
    padding-top: 17rem !important;
  }
  .pr-md-29,
  .px-md-29 {
    padding-right: 17rem !important;
  }
  .pb-md-29,
  .py-md-29 {
    padding-bottom: 17rem !important;
  }
  .pl-md-29,
  .px-md-29 {
    padding-left: 17rem !important;
  }
  .p-md-30 {
    padding: 18rem !important;
  }
  .pt-md-30,
  .py-md-30 {
    padding-top: 18rem !important;
  }
  .pr-md-30,
  .px-md-30 {
    padding-right: 18rem !important;
  }
  .pb-md-30,
  .py-md-30 {
    padding-bottom: 18rem !important;
  }
  .pl-md-30,
  .px-md-30 {
    padding-left: 18rem !important;
  }
  .p-md-31 {
    padding: 19rem !important;
  }
  .pt-md-31,
  .py-md-31 {
    padding-top: 19rem !important;
  }
  .pr-md-31,
  .px-md-31 {
    padding-right: 19rem !important;
  }
  .pb-md-31,
  .py-md-31 {
    padding-bottom: 19rem !important;
  }
  .pl-md-31,
  .px-md-31 {
    padding-left: 19rem !important;
  }
  .p-md-32 {
    padding: 20rem !important;
  }
  .pt-md-32,
  .py-md-32 {
    padding-top: 20rem !important;
  }
  .pr-md-32,
  .px-md-32 {
    padding-right: 20rem !important;
  }
  .pb-md-32,
  .py-md-32 {
    padding-bottom: 20rem !important;
  }
  .pl-md-32,
  .px-md-32 {
    padding-left: 20rem !important;
  }
  .p-md-37 {
    padding: 22.5rem !important;
  }
  .pt-md-37,
  .py-md-37 {
    padding-top: 22.5rem !important;
  }
  .pr-md-37,
  .px-md-37 {
    padding-right: 22.5rem !important;
  }
  .pb-md-37,
  .py-md-37 {
    padding-bottom: 22.5rem !important;
  }
  .pl-md-37,
  .px-md-37 {
    padding-left: 22.5rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -2rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -2rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -2rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -2rem !important;
  }
  .m-md-n6 {
    margin: -2.5rem !important;
  }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -2.5rem !important;
  }
  .m-md-n7 {
    margin: -3rem !important;
  }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -3rem !important;
  }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -3rem !important;
  }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -3rem !important;
  }
  .m-md-n8 {
    margin: -3.5rem !important;
  }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -3.5rem !important;
  }
  .m-md-n9 {
    margin: -4rem !important;
  }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -4rem !important;
  }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -4rem !important;
  }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -4rem !important;
  }
  .m-md-n10 {
    margin: -4.5rem !important;
  }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -4.5rem !important;
  }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -4.5rem !important;
  }
  .m-md-n11 {
    margin: -5rem !important;
  }
  .mt-md-n11,
  .my-md-n11 {
    margin-top: -5rem !important;
  }
  .mr-md-n11,
  .mx-md-n11 {
    margin-right: -5rem !important;
  }
  .mb-md-n11,
  .my-md-n11 {
    margin-bottom: -5rem !important;
  }
  .ml-md-n11,
  .mx-md-n11 {
    margin-left: -5rem !important;
  }
  .m-md-n12 {
    margin: -5.5rem !important;
  }
  .mt-md-n12,
  .my-md-n12 {
    margin-top: -5.5rem !important;
  }
  .mr-md-n12,
  .mx-md-n12 {
    margin-right: -5.5rem !important;
  }
  .mb-md-n12,
  .my-md-n12 {
    margin-bottom: -5.5rem !important;
  }
  .ml-md-n12,
  .mx-md-n12 {
    margin-left: -5.5rem !important;
  }
  .m-md-n13 {
    margin: -6rem !important;
  }
  .mt-md-n13,
  .my-md-n13 {
    margin-top: -6rem !important;
  }
  .mr-md-n13,
  .mx-md-n13 {
    margin-right: -6rem !important;
  }
  .mb-md-n13,
  .my-md-n13 {
    margin-bottom: -6rem !important;
  }
  .ml-md-n13,
  .mx-md-n13 {
    margin-left: -6rem !important;
  }
  .m-md-n14 {
    margin: -6.5rem !important;
  }
  .mt-md-n14,
  .my-md-n14 {
    margin-top: -6.5rem !important;
  }
  .mr-md-n14,
  .mx-md-n14 {
    margin-right: -6.5rem !important;
  }
  .mb-md-n14,
  .my-md-n14 {
    margin-bottom: -6.5rem !important;
  }
  .ml-md-n14,
  .mx-md-n14 {
    margin-left: -6.5rem !important;
  }
  .m-md-n15 {
    margin: -7rem !important;
  }
  .mt-md-n15,
  .my-md-n15 {
    margin-top: -7rem !important;
  }
  .mr-md-n15,
  .mx-md-n15 {
    margin-right: -7rem !important;
  }
  .mb-md-n15,
  .my-md-n15 {
    margin-bottom: -7rem !important;
  }
  .ml-md-n15,
  .mx-md-n15 {
    margin-left: -7rem !important;
  }
  .m-md-n16 {
    margin: -7.5rem !important;
  }
  .mt-md-n16,
  .my-md-n16 {
    margin-top: -7.5rem !important;
  }
  .mr-md-n16,
  .mx-md-n16 {
    margin-right: -7.5rem !important;
  }
  .mb-md-n16,
  .my-md-n16 {
    margin-bottom: -7.5rem !important;
  }
  .ml-md-n16,
  .mx-md-n16 {
    margin-left: -7.5rem !important;
  }
  .m-md-n17 {
    margin: -8rem !important;
  }
  .mt-md-n17,
  .my-md-n17 {
    margin-top: -8rem !important;
  }
  .mr-md-n17,
  .mx-md-n17 {
    margin-right: -8rem !important;
  }
  .mb-md-n17,
  .my-md-n17 {
    margin-bottom: -8rem !important;
  }
  .ml-md-n17,
  .mx-md-n17 {
    margin-left: -8rem !important;
  }
  .m-md-n18 {
    margin: -8.5rem !important;
  }
  .mt-md-n18,
  .my-md-n18 {
    margin-top: -8.5rem !important;
  }
  .mr-md-n18,
  .mx-md-n18 {
    margin-right: -8.5rem !important;
  }
  .mb-md-n18,
  .my-md-n18 {
    margin-bottom: -8.5rem !important;
  }
  .ml-md-n18,
  .mx-md-n18 {
    margin-left: -8.5rem !important;
  }
  .m-md-n19 {
    margin: -9rem !important;
  }
  .mt-md-n19,
  .my-md-n19 {
    margin-top: -9rem !important;
  }
  .mr-md-n19,
  .mx-md-n19 {
    margin-right: -9rem !important;
  }
  .mb-md-n19,
  .my-md-n19 {
    margin-bottom: -9rem !important;
  }
  .ml-md-n19,
  .mx-md-n19 {
    margin-left: -9rem !important;
  }
  .m-md-n20 {
    margin: -9.5rem !important;
  }
  .mt-md-n20,
  .my-md-n20 {
    margin-top: -9.5rem !important;
  }
  .mr-md-n20,
  .mx-md-n20 {
    margin-right: -9.5rem !important;
  }
  .mb-md-n20,
  .my-md-n20 {
    margin-bottom: -9.5rem !important;
  }
  .ml-md-n20,
  .mx-md-n20 {
    margin-left: -9.5rem !important;
  }
  .m-md-n21 {
    margin: -10rem !important;
  }
  .mt-md-n21,
  .my-md-n21 {
    margin-top: -10rem !important;
  }
  .mr-md-n21,
  .mx-md-n21 {
    margin-right: -10rem !important;
  }
  .mb-md-n21,
  .my-md-n21 {
    margin-bottom: -10rem !important;
  }
  .ml-md-n21,
  .mx-md-n21 {
    margin-left: -10rem !important;
  }
  .m-md-n22 {
    margin: -11rem !important;
  }
  .mt-md-n22,
  .my-md-n22 {
    margin-top: -11rem !important;
  }
  .mr-md-n22,
  .mx-md-n22 {
    margin-right: -11rem !important;
  }
  .mb-md-n22,
  .my-md-n22 {
    margin-bottom: -11rem !important;
  }
  .ml-md-n22,
  .mx-md-n22 {
    margin-left: -11rem !important;
  }
  .m-md-n23 {
    margin: -12rem !important;
  }
  .mt-md-n23,
  .my-md-n23 {
    margin-top: -12rem !important;
  }
  .mr-md-n23,
  .mx-md-n23 {
    margin-right: -12rem !important;
  }
  .mb-md-n23,
  .my-md-n23 {
    margin-bottom: -12rem !important;
  }
  .ml-md-n23,
  .mx-md-n23 {
    margin-left: -12rem !important;
  }
  .m-md-n24 {
    margin: -13rem !important;
  }
  .mt-md-n24,
  .my-md-n24 {
    margin-top: -13rem !important;
  }
  .mr-md-n24,
  .mx-md-n24 {
    margin-right: -13rem !important;
  }
  .mb-md-n24,
  .my-md-n24 {
    margin-bottom: -13rem !important;
  }
  .ml-md-n24,
  .mx-md-n24 {
    margin-left: -13rem !important;
  }
  .m-md-n25 {
    margin: -13.5rem !important;
  }
  .mt-md-n25,
  .my-md-n25 {
    margin-top: -13.5rem !important;
  }
  .mr-md-n25,
  .mx-md-n25 {
    margin-right: -13.5rem !important;
  }
  .mb-md-n25,
  .my-md-n25 {
    margin-bottom: -13.5rem !important;
  }
  .ml-md-n25,
  .mx-md-n25 {
    margin-left: -13.5rem !important;
  }
  .m-md-n26 {
    margin: -14rem !important;
  }
  .mt-md-n26,
  .my-md-n26 {
    margin-top: -14rem !important;
  }
  .mr-md-n26,
  .mx-md-n26 {
    margin-right: -14rem !important;
  }
  .mb-md-n26,
  .my-md-n26 {
    margin-bottom: -14rem !important;
  }
  .ml-md-n26,
  .mx-md-n26 {
    margin-left: -14rem !important;
  }
  .m-md-n27 {
    margin: -15rem !important;
  }
  .mt-md-n27,
  .my-md-n27 {
    margin-top: -15rem !important;
  }
  .mr-md-n27,
  .mx-md-n27 {
    margin-right: -15rem !important;
  }
  .mb-md-n27,
  .my-md-n27 {
    margin-bottom: -15rem !important;
  }
  .ml-md-n27,
  .mx-md-n27 {
    margin-left: -15rem !important;
  }
  .m-md-n28 {
    margin: -16rem !important;
  }
  .mt-md-n28,
  .my-md-n28 {
    margin-top: -16rem !important;
  }
  .mr-md-n28,
  .mx-md-n28 {
    margin-right: -16rem !important;
  }
  .mb-md-n28,
  .my-md-n28 {
    margin-bottom: -16rem !important;
  }
  .ml-md-n28,
  .mx-md-n28 {
    margin-left: -16rem !important;
  }
  .m-md-n29 {
    margin: -17rem !important;
  }
  .mt-md-n29,
  .my-md-n29 {
    margin-top: -17rem !important;
  }
  .mr-md-n29,
  .mx-md-n29 {
    margin-right: -17rem !important;
  }
  .mb-md-n29,
  .my-md-n29 {
    margin-bottom: -17rem !important;
  }
  .ml-md-n29,
  .mx-md-n29 {
    margin-left: -17rem !important;
  }
  .m-md-n30 {
    margin: -18rem !important;
  }
  .mt-md-n30,
  .my-md-n30 {
    margin-top: -18rem !important;
  }
  .mr-md-n30,
  .mx-md-n30 {
    margin-right: -18rem !important;
  }
  .mb-md-n30,
  .my-md-n30 {
    margin-bottom: -18rem !important;
  }
  .ml-md-n30,
  .mx-md-n30 {
    margin-left: -18rem !important;
  }
  .m-md-n31 {
    margin: -19rem !important;
  }
  .mt-md-n31,
  .my-md-n31 {
    margin-top: -19rem !important;
  }
  .mr-md-n31,
  .mx-md-n31 {
    margin-right: -19rem !important;
  }
  .mb-md-n31,
  .my-md-n31 {
    margin-bottom: -19rem !important;
  }
  .ml-md-n31,
  .mx-md-n31 {
    margin-left: -19rem !important;
  }
  .m-md-n32 {
    margin: -20rem !important;
  }
  .mt-md-n32,
  .my-md-n32 {
    margin-top: -20rem !important;
  }
  .mr-md-n32,
  .mx-md-n32 {
    margin-right: -20rem !important;
  }
  .mb-md-n32,
  .my-md-n32 {
    margin-bottom: -20rem !important;
  }
  .ml-md-n32,
  .mx-md-n32 {
    margin-left: -20rem !important;
  }
  .m-md-n37 {
    margin: -22.5rem !important;
  }
  .mt-md-n37,
  .my-md-n37 {
    margin-top: -22.5rem !important;
  }
  .mr-md-n37,
  .mx-md-n37 {
    margin-right: -22.5rem !important;
  }
  .mb-md-n37,
  .my-md-n37 {
    margin-bottom: -22.5rem !important;
  }
  .ml-md-n37,
  .mx-md-n37 {
    margin-left: -22.5rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 2rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 2rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 2rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 2rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 2rem !important;
  }
  .m-lg-6 {
    margin: 2.5rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 2.5rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 2.5rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 2.5rem !important;
  }
  .m-lg-7 {
    margin: 3rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 3rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 3rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 3rem !important;
  }
  .m-lg-8 {
    margin: 3.5rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 3.5rem !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 3.5rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 3.5rem !important;
  }
  .m-lg-9 {
    margin: 4rem !important;
  }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 4rem !important;
  }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 4rem !important;
  }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 4rem !important;
  }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 4rem !important;
  }
  .m-lg-10 {
    margin: 4.5rem !important;
  }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 4.5rem !important;
  }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 4.5rem !important;
  }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 4.5rem !important;
  }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 4.5rem !important;
  }
  .m-lg-11 {
    margin: 5rem !important;
  }
  .mt-lg-11,
  .my-lg-11 {
    margin-top: 5rem !important;
  }
  .mr-lg-11,
  .mx-lg-11 {
    margin-right: 5rem !important;
  }
  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 5rem !important;
  }
  .ml-lg-11,
  .mx-lg-11 {
    margin-left: 5rem !important;
  }
  .m-lg-12 {
    margin: 5.5rem !important;
  }
  .mt-lg-12,
  .my-lg-12 {
    margin-top: 5.5rem !important;
  }
  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 5.5rem !important;
  }
  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 5.5rem !important;
  }
  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 5.5rem !important;
  }
  .m-lg-13 {
    margin: 6rem !important;
  }
  .mt-lg-13,
  .my-lg-13 {
    margin-top: 6rem !important;
  }
  .mr-lg-13,
  .mx-lg-13 {
    margin-right: 6rem !important;
  }
  .mb-lg-13,
  .my-lg-13 {
    margin-bottom: 6rem !important;
  }
  .ml-lg-13,
  .mx-lg-13 {
    margin-left: 6rem !important;
  }
  .m-lg-14 {
    margin: 6.5rem !important;
  }
  .mt-lg-14,
  .my-lg-14 {
    margin-top: 6.5rem !important;
  }
  .mr-lg-14,
  .mx-lg-14 {
    margin-right: 6.5rem !important;
  }
  .mb-lg-14,
  .my-lg-14 {
    margin-bottom: 6.5rem !important;
  }
  .ml-lg-14,
  .mx-lg-14 {
    margin-left: 6.5rem !important;
  }
  .m-lg-15 {
    margin: 7rem !important;
  }
  .mt-lg-15,
  .my-lg-15 {
    margin-top: 7rem !important;
  }
  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 7rem !important;
  }
  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 7rem !important;
  }
  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 7rem !important;
  }
  .m-lg-16 {
    margin: 7.5rem !important;
  }
  .mt-lg-16,
  .my-lg-16 {
    margin-top: 7.5rem !important;
  }
  .mr-lg-16,
  .mx-lg-16 {
    margin-right: 7.5rem !important;
  }
  .mb-lg-16,
  .my-lg-16 {
    margin-bottom: 7.5rem !important;
  }
  .ml-lg-16,
  .mx-lg-16 {
    margin-left: 7.5rem !important;
  }
  .m-lg-17 {
    margin: 8rem !important;
  }
  .mt-lg-17,
  .my-lg-17 {
    margin-top: 8rem !important;
  }
  .mr-lg-17,
  .mx-lg-17 {
    margin-right: 8rem !important;
  }
  .mb-lg-17,
  .my-lg-17 {
    margin-bottom: 8rem !important;
  }
  .ml-lg-17,
  .mx-lg-17 {
    margin-left: 8rem !important;
  }
  .m-lg-18 {
    margin: 8.5rem !important;
  }
  .mt-lg-18,
  .my-lg-18 {
    margin-top: 8.5rem !important;
  }
  .mr-lg-18,
  .mx-lg-18 {
    margin-right: 8.5rem !important;
  }
  .mb-lg-18,
  .my-lg-18 {
    margin-bottom: 8.5rem !important;
  }
  .ml-lg-18,
  .mx-lg-18 {
    margin-left: 8.5rem !important;
  }
  .m-lg-19 {
    margin: 9rem !important;
  }
  .mt-lg-19,
  .my-lg-19 {
    margin-top: 9rem !important;
  }
  .mr-lg-19,
  .mx-lg-19 {
    margin-right: 9rem !important;
  }
  .mb-lg-19,
  .my-lg-19 {
    margin-bottom: 9rem !important;
  }
  .ml-lg-19,
  .mx-lg-19 {
    margin-left: 9rem !important;
  }
  .m-lg-20 {
    margin: 9.5rem !important;
  }
  .mt-lg-20,
  .my-lg-20 {
    margin-top: 9.5rem !important;
  }
  .mr-lg-20,
  .mx-lg-20 {
    margin-right: 9.5rem !important;
  }
  .mb-lg-20,
  .my-lg-20 {
    margin-bottom: 9.5rem !important;
  }
  .ml-lg-20,
  .mx-lg-20 {
    margin-left: 9.5rem !important;
  }
  .m-lg-21 {
    margin: 10rem !important;
  }
  .mt-lg-21,
  .my-lg-21 {
    margin-top: 10rem !important;
  }
  .mr-lg-21,
  .mx-lg-21 {
    margin-right: 10rem !important;
  }
  .mb-lg-21,
  .my-lg-21 {
    margin-bottom: 10rem !important;
  }
  .ml-lg-21,
  .mx-lg-21 {
    margin-left: 10rem !important;
  }
  .m-lg-22 {
    margin: 11rem !important;
  }
  .mt-lg-22,
  .my-lg-22 {
    margin-top: 11rem !important;
  }
  .mr-lg-22,
  .mx-lg-22 {
    margin-right: 11rem !important;
  }
  .mb-lg-22,
  .my-lg-22 {
    margin-bottom: 11rem !important;
  }
  .ml-lg-22,
  .mx-lg-22 {
    margin-left: 11rem !important;
  }
  .m-lg-23 {
    margin: 12rem !important;
  }
  .mt-lg-23,
  .my-lg-23 {
    margin-top: 12rem !important;
  }
  .mr-lg-23,
  .mx-lg-23 {
    margin-right: 12rem !important;
  }
  .mb-lg-23,
  .my-lg-23 {
    margin-bottom: 12rem !important;
  }
  .ml-lg-23,
  .mx-lg-23 {
    margin-left: 12rem !important;
  }
  .m-lg-24 {
    margin: 13rem !important;
  }
  .mt-lg-24,
  .my-lg-24 {
    margin-top: 13rem !important;
  }
  .mr-lg-24,
  .mx-lg-24 {
    margin-right: 13rem !important;
  }
  .mb-lg-24,
  .my-lg-24 {
    margin-bottom: 13rem !important;
  }
  .ml-lg-24,
  .mx-lg-24 {
    margin-left: 13rem !important;
  }
  .m-lg-25 {
    margin: 13.5rem !important;
  }
  .mt-lg-25,
  .my-lg-25 {
    margin-top: 13.5rem !important;
  }
  .mr-lg-25,
  .mx-lg-25 {
    margin-right: 13.5rem !important;
  }
  .mb-lg-25,
  .my-lg-25 {
    margin-bottom: 13.5rem !important;
  }
  .ml-lg-25,
  .mx-lg-25 {
    margin-left: 13.5rem !important;
  }
  .m-lg-26 {
    margin: 14rem !important;
  }
  .mt-lg-26,
  .my-lg-26 {
    margin-top: 14rem !important;
  }
  .mr-lg-26,
  .mx-lg-26 {
    margin-right: 14rem !important;
  }
  .mb-lg-26,
  .my-lg-26 {
    margin-bottom: 14rem !important;
  }
  .ml-lg-26,
  .mx-lg-26 {
    margin-left: 14rem !important;
  }
  .m-lg-27 {
    margin: 15rem !important;
  }
  .mt-lg-27,
  .my-lg-27 {
    margin-top: 15rem !important;
  }
  .mr-lg-27,
  .mx-lg-27 {
    margin-right: 15rem !important;
  }
  .mb-lg-27,
  .my-lg-27 {
    margin-bottom: 15rem !important;
  }
  .ml-lg-27,
  .mx-lg-27 {
    margin-left: 15rem !important;
  }
  .m-lg-28 {
    margin: 16rem !important;
  }
  .mt-lg-28,
  .my-lg-28 {
    margin-top: 16rem !important;
  }
  .mr-lg-28,
  .mx-lg-28 {
    margin-right: 16rem !important;
  }
  .mb-lg-28,
  .my-lg-28 {
    margin-bottom: 16rem !important;
  }
  .ml-lg-28,
  .mx-lg-28 {
    margin-left: 16rem !important;
  }
  .m-lg-29 {
    margin: 17rem !important;
  }
  .mt-lg-29,
  .my-lg-29 {
    margin-top: 17rem !important;
  }
  .mr-lg-29,
  .mx-lg-29 {
    margin-right: 17rem !important;
  }
  .mb-lg-29,
  .my-lg-29 {
    margin-bottom: 17rem !important;
  }
  .ml-lg-29,
  .mx-lg-29 {
    margin-left: 17rem !important;
  }
  .m-lg-30 {
    margin: 18rem !important;
  }
  .mt-lg-30,
  .my-lg-30 {
    margin-top: 18rem !important;
  }
  .mr-lg-30,
  .mx-lg-30 {
    margin-right: 18rem !important;
  }
  .mb-lg-30,
  .my-lg-30 {
    margin-bottom: 18rem !important;
  }
  .ml-lg-30,
  .mx-lg-30 {
    margin-left: 18rem !important;
  }
  .m-lg-31 {
    margin: 19rem !important;
  }
  .mt-lg-31,
  .my-lg-31 {
    margin-top: 19rem !important;
  }
  .mr-lg-31,
  .mx-lg-31 {
    margin-right: 19rem !important;
  }
  .mb-lg-31,
  .my-lg-31 {
    margin-bottom: 19rem !important;
  }
  .ml-lg-31,
  .mx-lg-31 {
    margin-left: 19rem !important;
  }
  .m-lg-32 {
    margin: 20rem !important;
  }
  .mt-lg-32,
  .my-lg-32 {
    margin-top: 20rem !important;
  }
  .mr-lg-32,
  .mx-lg-32 {
    margin-right: 20rem !important;
  }
  .mb-lg-32,
  .my-lg-32 {
    margin-bottom: 20rem !important;
  }
  .ml-lg-32,
  .mx-lg-32 {
    margin-left: 20rem !important;
  }
  .m-lg-37 {
    margin: 22.5rem !important;
  }
  .mt-lg-37,
  .my-lg-37 {
    margin-top: 22.5rem !important;
  }
  .mr-lg-37,
  .mx-lg-37 {
    margin-right: 22.5rem !important;
  }
  .mb-lg-37,
  .my-lg-37 {
    margin-bottom: 22.5rem !important;
  }
  .ml-lg-37,
  .mx-lg-37 {
    margin-left: 22.5rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 2rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 2rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 2rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 2rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 2rem !important;
  }
  .p-lg-6 {
    padding: 2.5rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 2.5rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 2.5rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 2.5rem !important;
  }
  .p-lg-7 {
    padding: 3rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 3rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 3rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 3rem !important;
  }
  .p-lg-8 {
    padding: 3.5rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 3.5rem !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 3.5rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 3.5rem !important;
  }
  .p-lg-9 {
    padding: 4rem !important;
  }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 4rem !important;
  }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 4rem !important;
  }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 4rem !important;
  }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 4rem !important;
  }
  .p-lg-10 {
    padding: 4.5rem !important;
  }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 4.5rem !important;
  }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 4.5rem !important;
  }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 4.5rem !important;
  }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 4.5rem !important;
  }
  .p-lg-11 {
    padding: 5rem !important;
  }
  .pt-lg-11,
  .py-lg-11 {
    padding-top: 5rem !important;
  }
  .pr-lg-11,
  .px-lg-11 {
    padding-right: 5rem !important;
  }
  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 5rem !important;
  }
  .pl-lg-11,
  .px-lg-11 {
    padding-left: 5rem !important;
  }
  .p-lg-12 {
    padding: 5.5rem !important;
  }
  .pt-lg-12,
  .py-lg-12 {
    padding-top: 5.5rem !important;
  }
  .pr-lg-12,
  .px-lg-12 {
    padding-right: 5.5rem !important;
  }
  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 5.5rem !important;
  }
  .pl-lg-12,
  .px-lg-12 {
    padding-left: 5.5rem !important;
  }
  .p-lg-13 {
    padding: 6rem !important;
  }
  .pt-lg-13,
  .py-lg-13 {
    padding-top: 6rem !important;
  }
  .pr-lg-13,
  .px-lg-13 {
    padding-right: 6rem !important;
  }
  .pb-lg-13,
  .py-lg-13 {
    padding-bottom: 6rem !important;
  }
  .pl-lg-13,
  .px-lg-13 {
    padding-left: 6rem !important;
  }
  .p-lg-14 {
    padding: 6.5rem !important;
  }
  .pt-lg-14,
  .py-lg-14 {
    padding-top: 6.5rem !important;
  }
  .pr-lg-14,
  .px-lg-14 {
    padding-right: 6.5rem !important;
  }
  .pb-lg-14,
  .py-lg-14 {
    padding-bottom: 6.5rem !important;
  }
  .pl-lg-14,
  .px-lg-14 {
    padding-left: 6.5rem !important;
  }
  .p-lg-15 {
    padding: 7rem !important;
  }
  .pt-lg-15,
  .py-lg-15 {
    padding-top: 7rem !important;
  }
  .pr-lg-15,
  .px-lg-15 {
    padding-right: 7rem !important;
  }
  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 7rem !important;
  }
  .pl-lg-15,
  .px-lg-15 {
    padding-left: 7rem !important;
  }
  .p-lg-16 {
    padding: 7.5rem !important;
  }
  .pt-lg-16,
  .py-lg-16 {
    padding-top: 7.5rem !important;
  }
  .pr-lg-16,
  .px-lg-16 {
    padding-right: 7.5rem !important;
  }
  .pb-lg-16,
  .py-lg-16 {
    padding-bottom: 7.5rem !important;
  }
  .pl-lg-16,
  .px-lg-16 {
    padding-left: 7.5rem !important;
  }
  .p-lg-17 {
    padding: 8rem !important;
  }
  .pt-lg-17,
  .py-lg-17 {
    padding-top: 8rem !important;
  }
  .pr-lg-17,
  .px-lg-17 {
    padding-right: 8rem !important;
  }
  .pb-lg-17,
  .py-lg-17 {
    padding-bottom: 8rem !important;
  }
  .pl-lg-17,
  .px-lg-17 {
    padding-left: 8rem !important;
  }
  .p-lg-18 {
    padding: 8.5rem !important;
  }
  .pt-lg-18,
  .py-lg-18 {
    padding-top: 8.5rem !important;
  }
  .pr-lg-18,
  .px-lg-18 {
    padding-right: 8.5rem !important;
  }
  .pb-lg-18,
  .py-lg-18 {
    padding-bottom: 8.5rem !important;
  }
  .pl-lg-18,
  .px-lg-18 {
    padding-left: 8.5rem !important;
  }
  .p-lg-19 {
    padding: 9rem !important;
  }
  .pt-lg-19,
  .py-lg-19 {
    padding-top: 9rem !important;
  }
  .pr-lg-19,
  .px-lg-19 {
    padding-right: 9rem !important;
  }
  .pb-lg-19,
  .py-lg-19 {
    padding-bottom: 9rem !important;
  }
  .pl-lg-19,
  .px-lg-19 {
    padding-left: 9rem !important;
  }
  .p-lg-20 {
    padding: 9.5rem !important;
  }
  .pt-lg-20,
  .py-lg-20 {
    padding-top: 9.5rem !important;
  }
  .pr-lg-20,
  .px-lg-20 {
    padding-right: 9.5rem !important;
  }
  .pb-lg-20,
  .py-lg-20 {
    padding-bottom: 9.5rem !important;
  }
  .pl-lg-20,
  .px-lg-20 {
    padding-left: 9.5rem !important;
  }
  .p-lg-21 {
    padding: 10rem !important;
  }
  .pt-lg-21,
  .py-lg-21 {
    padding-top: 10rem !important;
  }
  .pr-lg-21,
  .px-lg-21 {
    padding-right: 10rem !important;
  }
  .pb-lg-21,
  .py-lg-21 {
    padding-bottom: 10rem !important;
  }
  .pl-lg-21,
  .px-lg-21 {
    padding-left: 10rem !important;
  }
  .p-lg-22 {
    padding: 11rem !important;
  }
  .pt-lg-22,
  .py-lg-22 {
    padding-top: 11rem !important;
  }
  .pr-lg-22,
  .px-lg-22 {
    padding-right: 11rem !important;
  }
  .pb-lg-22,
  .py-lg-22 {
    padding-bottom: 11rem !important;
  }
  .pl-lg-22,
  .px-lg-22 {
    padding-left: 11rem !important;
  }
  .p-lg-23 {
    padding: 12rem !important;
  }
  .pt-lg-23,
  .py-lg-23 {
    padding-top: 12rem !important;
  }
  .pr-lg-23,
  .px-lg-23 {
    padding-right: 12rem !important;
  }
  .pb-lg-23,
  .py-lg-23 {
    padding-bottom: 12rem !important;
  }
  .pl-lg-23,
  .px-lg-23 {
    padding-left: 12rem !important;
  }
  .p-lg-24 {
    padding: 13rem !important;
  }
  .pt-lg-24,
  .py-lg-24 {
    padding-top: 13rem !important;
  }
  .pr-lg-24,
  .px-lg-24 {
    padding-right: 13rem !important;
  }
  .pb-lg-24,
  .py-lg-24 {
    padding-bottom: 13rem !important;
  }
  .pl-lg-24,
  .px-lg-24 {
    padding-left: 13rem !important;
  }
  .p-lg-25 {
    padding: 13.5rem !important;
  }
  .pt-lg-25,
  .py-lg-25 {
    padding-top: 13.5rem !important;
  }
  .pr-lg-25,
  .px-lg-25 {
    padding-right: 13.5rem !important;
  }
  .pb-lg-25,
  .py-lg-25 {
    padding-bottom: 13.5rem !important;
  }
  .pl-lg-25,
  .px-lg-25 {
    padding-left: 13.5rem !important;
  }
  .p-lg-26 {
    padding: 14rem !important;
  }
  .pt-lg-26,
  .py-lg-26 {
    padding-top: 14rem !important;
  }
  .pr-lg-26,
  .px-lg-26 {
    padding-right: 14rem !important;
  }
  .pb-lg-26,
  .py-lg-26 {
    padding-bottom: 14rem !important;
  }
  .pl-lg-26,
  .px-lg-26 {
    padding-left: 14rem !important;
  }
  .p-lg-27 {
    padding: 15rem !important;
  }
  .pt-lg-27,
  .py-lg-27 {
    padding-top: 15rem !important;
  }
  .pr-lg-27,
  .px-lg-27 {
    padding-right: 15rem !important;
  }
  .pb-lg-27,
  .py-lg-27 {
    padding-bottom: 15rem !important;
  }
  .pl-lg-27,
  .px-lg-27 {
    padding-left: 15rem !important;
  }
  .p-lg-28 {
    padding: 16rem !important;
  }
  .pt-lg-28,
  .py-lg-28 {
    padding-top: 16rem !important;
  }
  .pr-lg-28,
  .px-lg-28 {
    padding-right: 16rem !important;
  }
  .pb-lg-28,
  .py-lg-28 {
    padding-bottom: 16rem !important;
  }
  .pl-lg-28,
  .px-lg-28 {
    padding-left: 16rem !important;
  }
  .p-lg-29 {
    padding: 17rem !important;
  }
  .pt-lg-29,
  .py-lg-29 {
    padding-top: 17rem !important;
  }
  .pr-lg-29,
  .px-lg-29 {
    padding-right: 17rem !important;
  }
  .pb-lg-29,
  .py-lg-29 {
    padding-bottom: 17rem !important;
  }
  .pl-lg-29,
  .px-lg-29 {
    padding-left: 17rem !important;
  }
  .p-lg-30 {
    padding: 18rem !important;
  }
  .pt-lg-30,
  .py-lg-30 {
    padding-top: 18rem !important;
  }
  .pr-lg-30,
  .px-lg-30 {
    padding-right: 18rem !important;
  }
  .pb-lg-30,
  .py-lg-30 {
    padding-bottom: 18rem !important;
  }
  .pl-lg-30,
  .px-lg-30 {
    padding-left: 18rem !important;
  }
  .p-lg-31 {
    padding: 19rem !important;
  }
  .pt-lg-31,
  .py-lg-31 {
    padding-top: 19rem !important;
  }
  .pr-lg-31,
  .px-lg-31 {
    padding-right: 19rem !important;
  }
  .pb-lg-31,
  .py-lg-31 {
    padding-bottom: 19rem !important;
  }
  .pl-lg-31,
  .px-lg-31 {
    padding-left: 19rem !important;
  }
  .p-lg-32 {
    padding: 20rem !important;
  }
  .pt-lg-32,
  .py-lg-32 {
    padding-top: 20rem !important;
  }
  .pr-lg-32,
  .px-lg-32 {
    padding-right: 20rem !important;
  }
  .pb-lg-32,
  .py-lg-32 {
    padding-bottom: 20rem !important;
  }
  .pl-lg-32,
  .px-lg-32 {
    padding-left: 20rem !important;
  }
  .p-lg-37 {
    padding: 22.5rem !important;
  }
  .pt-lg-37,
  .py-lg-37 {
    padding-top: 22.5rem !important;
  }
  .pr-lg-37,
  .px-lg-37 {
    padding-right: 22.5rem !important;
  }
  .pb-lg-37,
  .py-lg-37 {
    padding-bottom: 22.5rem !important;
  }
  .pl-lg-37,
  .px-lg-37 {
    padding-left: 22.5rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -2rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -2rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -2rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -2rem !important;
  }
  .m-lg-n6 {
    margin: -2.5rem !important;
  }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -2.5rem !important;
  }
  .m-lg-n7 {
    margin: -3rem !important;
  }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -3rem !important;
  }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -3rem !important;
  }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -3rem !important;
  }
  .m-lg-n8 {
    margin: -3.5rem !important;
  }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -3.5rem !important;
  }
  .m-lg-n9 {
    margin: -4rem !important;
  }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -4rem !important;
  }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -4rem !important;
  }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -4rem !important;
  }
  .m-lg-n10 {
    margin: -4.5rem !important;
  }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -4.5rem !important;
  }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -4.5rem !important;
  }
  .m-lg-n11 {
    margin: -5rem !important;
  }
  .mt-lg-n11,
  .my-lg-n11 {
    margin-top: -5rem !important;
  }
  .mr-lg-n11,
  .mx-lg-n11 {
    margin-right: -5rem !important;
  }
  .mb-lg-n11,
  .my-lg-n11 {
    margin-bottom: -5rem !important;
  }
  .ml-lg-n11,
  .mx-lg-n11 {
    margin-left: -5rem !important;
  }
  .m-lg-n12 {
    margin: -5.5rem !important;
  }
  .mt-lg-n12,
  .my-lg-n12 {
    margin-top: -5.5rem !important;
  }
  .mr-lg-n12,
  .mx-lg-n12 {
    margin-right: -5.5rem !important;
  }
  .mb-lg-n12,
  .my-lg-n12 {
    margin-bottom: -5.5rem !important;
  }
  .ml-lg-n12,
  .mx-lg-n12 {
    margin-left: -5.5rem !important;
  }
  .m-lg-n13 {
    margin: -6rem !important;
  }
  .mt-lg-n13,
  .my-lg-n13 {
    margin-top: -6rem !important;
  }
  .mr-lg-n13,
  .mx-lg-n13 {
    margin-right: -6rem !important;
  }
  .mb-lg-n13,
  .my-lg-n13 {
    margin-bottom: -6rem !important;
  }
  .ml-lg-n13,
  .mx-lg-n13 {
    margin-left: -6rem !important;
  }
  .m-lg-n14 {
    margin: -6.5rem !important;
  }
  .mt-lg-n14,
  .my-lg-n14 {
    margin-top: -6.5rem !important;
  }
  .mr-lg-n14,
  .mx-lg-n14 {
    margin-right: -6.5rem !important;
  }
  .mb-lg-n14,
  .my-lg-n14 {
    margin-bottom: -6.5rem !important;
  }
  .ml-lg-n14,
  .mx-lg-n14 {
    margin-left: -6.5rem !important;
  }
  .m-lg-n15 {
    margin: -7rem !important;
  }
  .mt-lg-n15,
  .my-lg-n15 {
    margin-top: -7rem !important;
  }
  .mr-lg-n15,
  .mx-lg-n15 {
    margin-right: -7rem !important;
  }
  .mb-lg-n15,
  .my-lg-n15 {
    margin-bottom: -7rem !important;
  }
  .ml-lg-n15,
  .mx-lg-n15 {
    margin-left: -7rem !important;
  }
  .m-lg-n16 {
    margin: -7.5rem !important;
  }
  .mt-lg-n16,
  .my-lg-n16 {
    margin-top: -7.5rem !important;
  }
  .mr-lg-n16,
  .mx-lg-n16 {
    margin-right: -7.5rem !important;
  }
  .mb-lg-n16,
  .my-lg-n16 {
    margin-bottom: -7.5rem !important;
  }
  .ml-lg-n16,
  .mx-lg-n16 {
    margin-left: -7.5rem !important;
  }
  .m-lg-n17 {
    margin: -8rem !important;
  }
  .mt-lg-n17,
  .my-lg-n17 {
    margin-top: -8rem !important;
  }
  .mr-lg-n17,
  .mx-lg-n17 {
    margin-right: -8rem !important;
  }
  .mb-lg-n17,
  .my-lg-n17 {
    margin-bottom: -8rem !important;
  }
  .ml-lg-n17,
  .mx-lg-n17 {
    margin-left: -8rem !important;
  }
  .m-lg-n18 {
    margin: -8.5rem !important;
  }
  .mt-lg-n18,
  .my-lg-n18 {
    margin-top: -8.5rem !important;
  }
  .mr-lg-n18,
  .mx-lg-n18 {
    margin-right: -8.5rem !important;
  }
  .mb-lg-n18,
  .my-lg-n18 {
    margin-bottom: -8.5rem !important;
  }
  .ml-lg-n18,
  .mx-lg-n18 {
    margin-left: -8.5rem !important;
  }
  .m-lg-n19 {
    margin: -9rem !important;
  }
  .mt-lg-n19,
  .my-lg-n19 {
    margin-top: -9rem !important;
  }
  .mr-lg-n19,
  .mx-lg-n19 {
    margin-right: -9rem !important;
  }
  .mb-lg-n19,
  .my-lg-n19 {
    margin-bottom: -9rem !important;
  }
  .ml-lg-n19,
  .mx-lg-n19 {
    margin-left: -9rem !important;
  }
  .m-lg-n20 {
    margin: -9.5rem !important;
  }
  .mt-lg-n20,
  .my-lg-n20 {
    margin-top: -9.5rem !important;
  }
  .mr-lg-n20,
  .mx-lg-n20 {
    margin-right: -9.5rem !important;
  }
  .mb-lg-n20,
  .my-lg-n20 {
    margin-bottom: -9.5rem !important;
  }
  .ml-lg-n20,
  .mx-lg-n20 {
    margin-left: -9.5rem !important;
  }
  .m-lg-n21 {
    margin: -10rem !important;
  }
  .mt-lg-n21,
  .my-lg-n21 {
    margin-top: -10rem !important;
  }
  .mr-lg-n21,
  .mx-lg-n21 {
    margin-right: -10rem !important;
  }
  .mb-lg-n21,
  .my-lg-n21 {
    margin-bottom: -10rem !important;
  }
  .ml-lg-n21,
  .mx-lg-n21 {
    margin-left: -10rem !important;
  }
  .m-lg-n22 {
    margin: -11rem !important;
  }
  .mt-lg-n22,
  .my-lg-n22 {
    margin-top: -11rem !important;
  }
  .mr-lg-n22,
  .mx-lg-n22 {
    margin-right: -11rem !important;
  }
  .mb-lg-n22,
  .my-lg-n22 {
    margin-bottom: -11rem !important;
  }
  .ml-lg-n22,
  .mx-lg-n22 {
    margin-left: -11rem !important;
  }
  .m-lg-n23 {
    margin: -12rem !important;
  }
  .mt-lg-n23,
  .my-lg-n23 {
    margin-top: -12rem !important;
  }
  .mr-lg-n23,
  .mx-lg-n23 {
    margin-right: -12rem !important;
  }
  .mb-lg-n23,
  .my-lg-n23 {
    margin-bottom: -12rem !important;
  }
  .ml-lg-n23,
  .mx-lg-n23 {
    margin-left: -12rem !important;
  }
  .m-lg-n24 {
    margin: -13rem !important;
  }
  .mt-lg-n24,
  .my-lg-n24 {
    margin-top: -13rem !important;
  }
  .mr-lg-n24,
  .mx-lg-n24 {
    margin-right: -13rem !important;
  }
  .mb-lg-n24,
  .my-lg-n24 {
    margin-bottom: -13rem !important;
  }
  .ml-lg-n24,
  .mx-lg-n24 {
    margin-left: -13rem !important;
  }
  .m-lg-n25 {
    margin: -13.5rem !important;
  }
  .mt-lg-n25,
  .my-lg-n25 {
    margin-top: -13.5rem !important;
  }
  .mr-lg-n25,
  .mx-lg-n25 {
    margin-right: -13.5rem !important;
  }
  .mb-lg-n25,
  .my-lg-n25 {
    margin-bottom: -13.5rem !important;
  }
  .ml-lg-n25,
  .mx-lg-n25 {
    margin-left: -13.5rem !important;
  }
  .m-lg-n26 {
    margin: -14rem !important;
  }
  .mt-lg-n26,
  .my-lg-n26 {
    margin-top: -14rem !important;
  }
  .mr-lg-n26,
  .mx-lg-n26 {
    margin-right: -14rem !important;
  }
  .mb-lg-n26,
  .my-lg-n26 {
    margin-bottom: -14rem !important;
  }
  .ml-lg-n26,
  .mx-lg-n26 {
    margin-left: -14rem !important;
  }
  .m-lg-n27 {
    margin: -15rem !important;
  }
  .mt-lg-n27,
  .my-lg-n27 {
    margin-top: -15rem !important;
  }
  .mr-lg-n27,
  .mx-lg-n27 {
    margin-right: -15rem !important;
  }
  .mb-lg-n27,
  .my-lg-n27 {
    margin-bottom: -15rem !important;
  }
  .ml-lg-n27,
  .mx-lg-n27 {
    margin-left: -15rem !important;
  }
  .m-lg-n28 {
    margin: -16rem !important;
  }
  .mt-lg-n28,
  .my-lg-n28 {
    margin-top: -16rem !important;
  }
  .mr-lg-n28,
  .mx-lg-n28 {
    margin-right: -16rem !important;
  }
  .mb-lg-n28,
  .my-lg-n28 {
    margin-bottom: -16rem !important;
  }
  .ml-lg-n28,
  .mx-lg-n28 {
    margin-left: -16rem !important;
  }
  .m-lg-n29 {
    margin: -17rem !important;
  }
  .mt-lg-n29,
  .my-lg-n29 {
    margin-top: -17rem !important;
  }
  .mr-lg-n29,
  .mx-lg-n29 {
    margin-right: -17rem !important;
  }
  .mb-lg-n29,
  .my-lg-n29 {
    margin-bottom: -17rem !important;
  }
  .ml-lg-n29,
  .mx-lg-n29 {
    margin-left: -17rem !important;
  }
  .m-lg-n30 {
    margin: -18rem !important;
  }
  .mt-lg-n30,
  .my-lg-n30 {
    margin-top: -18rem !important;
  }
  .mr-lg-n30,
  .mx-lg-n30 {
    margin-right: -18rem !important;
  }
  .mb-lg-n30,
  .my-lg-n30 {
    margin-bottom: -18rem !important;
  }
  .ml-lg-n30,
  .mx-lg-n30 {
    margin-left: -18rem !important;
  }
  .m-lg-n31 {
    margin: -19rem !important;
  }
  .mt-lg-n31,
  .my-lg-n31 {
    margin-top: -19rem !important;
  }
  .mr-lg-n31,
  .mx-lg-n31 {
    margin-right: -19rem !important;
  }
  .mb-lg-n31,
  .my-lg-n31 {
    margin-bottom: -19rem !important;
  }
  .ml-lg-n31,
  .mx-lg-n31 {
    margin-left: -19rem !important;
  }
  .m-lg-n32 {
    margin: -20rem !important;
  }
  .mt-lg-n32,
  .my-lg-n32 {
    margin-top: -20rem !important;
  }
  .mr-lg-n32,
  .mx-lg-n32 {
    margin-right: -20rem !important;
  }
  .mb-lg-n32,
  .my-lg-n32 {
    margin-bottom: -20rem !important;
  }
  .ml-lg-n32,
  .mx-lg-n32 {
    margin-left: -20rem !important;
  }
  .m-lg-n37 {
    margin: -22.5rem !important;
  }
  .mt-lg-n37,
  .my-lg-n37 {
    margin-top: -22.5rem !important;
  }
  .mr-lg-n37,
  .mx-lg-n37 {
    margin-right: -22.5rem !important;
  }
  .mb-lg-n37,
  .my-lg-n37 {
    margin-bottom: -22.5rem !important;
  }
  .ml-lg-n37,
  .mx-lg-n37 {
    margin-left: -22.5rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 2rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 2rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 2rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 2rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 2rem !important;
  }
  .m-xl-6 {
    margin: 2.5rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 2.5rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 2.5rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 2.5rem !important;
  }
  .m-xl-7 {
    margin: 3rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 3rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 3rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 3rem !important;
  }
  .m-xl-8 {
    margin: 3.5rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 3.5rem !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 3.5rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 3.5rem !important;
  }
  .m-xl-9 {
    margin: 4rem !important;
  }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 4rem !important;
  }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 4rem !important;
  }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 4rem !important;
  }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 4rem !important;
  }
  .m-xl-10 {
    margin: 4.5rem !important;
  }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 4.5rem !important;
  }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 4.5rem !important;
  }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 4.5rem !important;
  }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 4.5rem !important;
  }
  .m-xl-11 {
    margin: 5rem !important;
  }
  .mt-xl-11,
  .my-xl-11 {
    margin-top: 5rem !important;
  }
  .mr-xl-11,
  .mx-xl-11 {
    margin-right: 5rem !important;
  }
  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 5rem !important;
  }
  .ml-xl-11,
  .mx-xl-11 {
    margin-left: 5rem !important;
  }
  .m-xl-12 {
    margin: 5.5rem !important;
  }
  .mt-xl-12,
  .my-xl-12 {
    margin-top: 5.5rem !important;
  }
  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 5.5rem !important;
  }
  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 5.5rem !important;
  }
  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 5.5rem !important;
  }
  .m-xl-13 {
    margin: 6rem !important;
  }
  .mt-xl-13,
  .my-xl-13 {
    margin-top: 6rem !important;
  }
  .mr-xl-13,
  .mx-xl-13 {
    margin-right: 6rem !important;
  }
  .mb-xl-13,
  .my-xl-13 {
    margin-bottom: 6rem !important;
  }
  .ml-xl-13,
  .mx-xl-13 {
    margin-left: 6rem !important;
  }
  .m-xl-14 {
    margin: 6.5rem !important;
  }
  .mt-xl-14,
  .my-xl-14 {
    margin-top: 6.5rem !important;
  }
  .mr-xl-14,
  .mx-xl-14 {
    margin-right: 6.5rem !important;
  }
  .mb-xl-14,
  .my-xl-14 {
    margin-bottom: 6.5rem !important;
  }
  .ml-xl-14,
  .mx-xl-14 {
    margin-left: 6.5rem !important;
  }
  .m-xl-15 {
    margin: 7rem !important;
  }
  .mt-xl-15,
  .my-xl-15 {
    margin-top: 7rem !important;
  }
  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 7rem !important;
  }
  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 7rem !important;
  }
  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 7rem !important;
  }
  .m-xl-16 {
    margin: 7.5rem !important;
  }
  .mt-xl-16,
  .my-xl-16 {
    margin-top: 7.5rem !important;
  }
  .mr-xl-16,
  .mx-xl-16 {
    margin-right: 7.5rem !important;
  }
  .mb-xl-16,
  .my-xl-16 {
    margin-bottom: 7.5rem !important;
  }
  .ml-xl-16,
  .mx-xl-16 {
    margin-left: 7.5rem !important;
  }
  .m-xl-17 {
    margin: 8rem !important;
  }
  .mt-xl-17,
  .my-xl-17 {
    margin-top: 8rem !important;
  }
  .mr-xl-17,
  .mx-xl-17 {
    margin-right: 8rem !important;
  }
  .mb-xl-17,
  .my-xl-17 {
    margin-bottom: 8rem !important;
  }
  .ml-xl-17,
  .mx-xl-17 {
    margin-left: 8rem !important;
  }
  .m-xl-18 {
    margin: 8.5rem !important;
  }
  .mt-xl-18,
  .my-xl-18 {
    margin-top: 8.5rem !important;
  }
  .mr-xl-18,
  .mx-xl-18 {
    margin-right: 8.5rem !important;
  }
  .mb-xl-18,
  .my-xl-18 {
    margin-bottom: 8.5rem !important;
  }
  .ml-xl-18,
  .mx-xl-18 {
    margin-left: 8.5rem !important;
  }
  .m-xl-19 {
    margin: 9rem !important;
  }
  .mt-xl-19,
  .my-xl-19 {
    margin-top: 9rem !important;
  }
  .mr-xl-19,
  .mx-xl-19 {
    margin-right: 9rem !important;
  }
  .mb-xl-19,
  .my-xl-19 {
    margin-bottom: 9rem !important;
  }
  .ml-xl-19,
  .mx-xl-19 {
    margin-left: 9rem !important;
  }
  .m-xl-20 {
    margin: 9.5rem !important;
  }
  .mt-xl-20,
  .my-xl-20 {
    margin-top: 9.5rem !important;
  }
  .mr-xl-20,
  .mx-xl-20 {
    margin-right: 9.5rem !important;
  }
  .mb-xl-20,
  .my-xl-20 {
    margin-bottom: 9.5rem !important;
  }
  .ml-xl-20,
  .mx-xl-20 {
    margin-left: 9.5rem !important;
  }
  .m-xl-21 {
    margin: 10rem !important;
  }
  .mt-xl-21,
  .my-xl-21 {
    margin-top: 10rem !important;
  }
  .mr-xl-21,
  .mx-xl-21 {
    margin-right: 10rem !important;
  }
  .mb-xl-21,
  .my-xl-21 {
    margin-bottom: 10rem !important;
  }
  .ml-xl-21,
  .mx-xl-21 {
    margin-left: 10rem !important;
  }
  .m-xl-22 {
    margin: 11rem !important;
  }
  .mt-xl-22,
  .my-xl-22 {
    margin-top: 11rem !important;
  }
  .mr-xl-22,
  .mx-xl-22 {
    margin-right: 11rem !important;
  }
  .mb-xl-22,
  .my-xl-22 {
    margin-bottom: 11rem !important;
  }
  .ml-xl-22,
  .mx-xl-22 {
    margin-left: 11rem !important;
  }
  .m-xl-23 {
    margin: 12rem !important;
  }
  .mt-xl-23,
  .my-xl-23 {
    margin-top: 12rem !important;
  }
  .mr-xl-23,
  .mx-xl-23 {
    margin-right: 12rem !important;
  }
  .mb-xl-23,
  .my-xl-23 {
    margin-bottom: 12rem !important;
  }
  .ml-xl-23,
  .mx-xl-23 {
    margin-left: 12rem !important;
  }
  .m-xl-24 {
    margin: 13rem !important;
  }
  .mt-xl-24,
  .my-xl-24 {
    margin-top: 13rem !important;
  }
  .mr-xl-24,
  .mx-xl-24 {
    margin-right: 13rem !important;
  }
  .mb-xl-24,
  .my-xl-24 {
    margin-bottom: 13rem !important;
  }
  .ml-xl-24,
  .mx-xl-24 {
    margin-left: 13rem !important;
  }
  .m-xl-25 {
    margin: 13.5rem !important;
  }
  .mt-xl-25,
  .my-xl-25 {
    margin-top: 13.5rem !important;
  }
  .mr-xl-25,
  .mx-xl-25 {
    margin-right: 13.5rem !important;
  }
  .mb-xl-25,
  .my-xl-25 {
    margin-bottom: 13.5rem !important;
  }
  .ml-xl-25,
  .mx-xl-25 {
    margin-left: 13.5rem !important;
  }
  .m-xl-26 {
    margin: 14rem !important;
  }
  .mt-xl-26,
  .my-xl-26 {
    margin-top: 14rem !important;
  }
  .mr-xl-26,
  .mx-xl-26 {
    margin-right: 14rem !important;
  }
  .mb-xl-26,
  .my-xl-26 {
    margin-bottom: 14rem !important;
  }
  .ml-xl-26,
  .mx-xl-26 {
    margin-left: 14rem !important;
  }
  .m-xl-27 {
    margin: 15rem !important;
  }
  .mt-xl-27,
  .my-xl-27 {
    margin-top: 15rem !important;
  }
  .mr-xl-27,
  .mx-xl-27 {
    margin-right: 15rem !important;
  }
  .mb-xl-27,
  .my-xl-27 {
    margin-bottom: 15rem !important;
  }
  .ml-xl-27,
  .mx-xl-27 {
    margin-left: 15rem !important;
  }
  .m-xl-28 {
    margin: 16rem !important;
  }
  .mt-xl-28,
  .my-xl-28 {
    margin-top: 16rem !important;
  }
  .mr-xl-28,
  .mx-xl-28 {
    margin-right: 16rem !important;
  }
  .mb-xl-28,
  .my-xl-28 {
    margin-bottom: 16rem !important;
  }
  .ml-xl-28,
  .mx-xl-28 {
    margin-left: 16rem !important;
  }
  .m-xl-29 {
    margin: 17rem !important;
  }
  .mt-xl-29,
  .my-xl-29 {
    margin-top: 17rem !important;
  }
  .mr-xl-29,
  .mx-xl-29 {
    margin-right: 17rem !important;
  }
  .mb-xl-29,
  .my-xl-29 {
    margin-bottom: 17rem !important;
  }
  .ml-xl-29,
  .mx-xl-29 {
    margin-left: 17rem !important;
  }
  .m-xl-30 {
    margin: 18rem !important;
  }
  .mt-xl-30,
  .my-xl-30 {
    margin-top: 18rem !important;
  }
  .mr-xl-30,
  .mx-xl-30 {
    margin-right: 18rem !important;
  }
  .mb-xl-30,
  .my-xl-30 {
    margin-bottom: 18rem !important;
  }
  .ml-xl-30,
  .mx-xl-30 {
    margin-left: 18rem !important;
  }
  .m-xl-31 {
    margin: 19rem !important;
  }
  .mt-xl-31,
  .my-xl-31 {
    margin-top: 19rem !important;
  }
  .mr-xl-31,
  .mx-xl-31 {
    margin-right: 19rem !important;
  }
  .mb-xl-31,
  .my-xl-31 {
    margin-bottom: 19rem !important;
  }
  .ml-xl-31,
  .mx-xl-31 {
    margin-left: 19rem !important;
  }
  .m-xl-32 {
    margin: 20rem !important;
  }
  .mt-xl-32,
  .my-xl-32 {
    margin-top: 20rem !important;
  }
  .mr-xl-32,
  .mx-xl-32 {
    margin-right: 20rem !important;
  }
  .mb-xl-32,
  .my-xl-32 {
    margin-bottom: 20rem !important;
  }
  .ml-xl-32,
  .mx-xl-32 {
    margin-left: 20rem !important;
  }
  .m-xl-37 {
    margin: 22.5rem !important;
  }
  .mt-xl-37,
  .my-xl-37 {
    margin-top: 22.5rem !important;
  }
  .mr-xl-37,
  .mx-xl-37 {
    margin-right: 22.5rem !important;
  }
  .mb-xl-37,
  .my-xl-37 {
    margin-bottom: 22.5rem !important;
  }
  .ml-xl-37,
  .mx-xl-37 {
    margin-left: 22.5rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 2rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 2rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 2rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 2rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 2rem !important;
  }
  .p-xl-6 {
    padding: 2.5rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 2.5rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 2.5rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 2.5rem !important;
  }
  .p-xl-7 {
    padding: 3rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 3rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 3rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 3rem !important;
  }
  .p-xl-8 {
    padding: 3.5rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 3.5rem !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 3.5rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 3.5rem !important;
  }
  .p-xl-9 {
    padding: 4rem !important;
  }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 4rem !important;
  }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 4rem !important;
  }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 4rem !important;
  }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 4rem !important;
  }
  .p-xl-10 {
    padding: 4.5rem !important;
  }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 4.5rem !important;
  }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 4.5rem !important;
  }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 4.5rem !important;
  }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 4.5rem !important;
  }
  .p-xl-11 {
    padding: 5rem !important;
  }
  .pt-xl-11,
  .py-xl-11 {
    padding-top: 5rem !important;
  }
  .pr-xl-11,
  .px-xl-11 {
    padding-right: 5rem !important;
  }
  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 5rem !important;
  }
  .pl-xl-11,
  .px-xl-11 {
    padding-left: 5rem !important;
  }
  .p-xl-12 {
    padding: 5.5rem !important;
  }
  .pt-xl-12,
  .py-xl-12 {
    padding-top: 5.5rem !important;
  }
  .pr-xl-12,
  .px-xl-12 {
    padding-right: 5.5rem !important;
  }
  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 5.5rem !important;
  }
  .pl-xl-12,
  .px-xl-12 {
    padding-left: 5.5rem !important;
  }
  .p-xl-13 {
    padding: 6rem !important;
  }
  .pt-xl-13,
  .py-xl-13 {
    padding-top: 6rem !important;
  }
  .pr-xl-13,
  .px-xl-13 {
    padding-right: 6rem !important;
  }
  .pb-xl-13,
  .py-xl-13 {
    padding-bottom: 6rem !important;
  }
  .pl-xl-13,
  .px-xl-13 {
    padding-left: 6rem !important;
  }
  .p-xl-14 {
    padding: 6.5rem !important;
  }
  .pt-xl-14,
  .py-xl-14 {
    padding-top: 6.5rem !important;
  }
  .pr-xl-14,
  .px-xl-14 {
    padding-right: 6.5rem !important;
  }
  .pb-xl-14,
  .py-xl-14 {
    padding-bottom: 6.5rem !important;
  }
  .pl-xl-14,
  .px-xl-14 {
    padding-left: 6.5rem !important;
  }
  .p-xl-15 {
    padding: 7rem !important;
  }
  .pt-xl-15,
  .py-xl-15 {
    padding-top: 7rem !important;
  }
  .pr-xl-15,
  .px-xl-15 {
    padding-right: 7rem !important;
  }
  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 7rem !important;
  }
  .pl-xl-15,
  .px-xl-15 {
    padding-left: 7rem !important;
  }
  .p-xl-16 {
    padding: 7.5rem !important;
  }
  .pt-xl-16,
  .py-xl-16 {
    padding-top: 7.5rem !important;
  }
  .pr-xl-16,
  .px-xl-16 {
    padding-right: 7.5rem !important;
  }
  .pb-xl-16,
  .py-xl-16 {
    padding-bottom: 7.5rem !important;
  }
  .pl-xl-16,
  .px-xl-16 {
    padding-left: 7.5rem !important;
  }
  .p-xl-17 {
    padding: 8rem !important;
  }
  .pt-xl-17,
  .py-xl-17 {
    padding-top: 8rem !important;
  }
  .pr-xl-17,
  .px-xl-17 {
    padding-right: 8rem !important;
  }
  .pb-xl-17,
  .py-xl-17 {
    padding-bottom: 8rem !important;
  }
  .pl-xl-17,
  .px-xl-17 {
    padding-left: 8rem !important;
  }
  .p-xl-18 {
    padding: 8.5rem !important;
  }
  .pt-xl-18,
  .py-xl-18 {
    padding-top: 8.5rem !important;
  }
  .pr-xl-18,
  .px-xl-18 {
    padding-right: 8.5rem !important;
  }
  .pb-xl-18,
  .py-xl-18 {
    padding-bottom: 8.5rem !important;
  }
  .pl-xl-18,
  .px-xl-18 {
    padding-left: 8.5rem !important;
  }
  .p-xl-19 {
    padding: 9rem !important;
  }
  .pt-xl-19,
  .py-xl-19 {
    padding-top: 9rem !important;
  }
  .pr-xl-19,
  .px-xl-19 {
    padding-right: 9rem !important;
  }
  .pb-xl-19,
  .py-xl-19 {
    padding-bottom: 9rem !important;
  }
  .pl-xl-19,
  .px-xl-19 {
    padding-left: 9rem !important;
  }
  .p-xl-20 {
    padding: 9.5rem !important;
  }
  .pt-xl-20,
  .py-xl-20 {
    padding-top: 9.5rem !important;
  }
  .pr-xl-20,
  .px-xl-20 {
    padding-right: 9.5rem !important;
  }
  .pb-xl-20,
  .py-xl-20 {
    padding-bottom: 9.5rem !important;
  }
  .pl-xl-20,
  .px-xl-20 {
    padding-left: 9.5rem !important;
  }
  .p-xl-21 {
    padding: 10rem !important;
  }
  .pt-xl-21,
  .py-xl-21 {
    padding-top: 10rem !important;
  }
  .pr-xl-21,
  .px-xl-21 {
    padding-right: 10rem !important;
  }
  .pb-xl-21,
  .py-xl-21 {
    padding-bottom: 10rem !important;
  }
  .pl-xl-21,
  .px-xl-21 {
    padding-left: 10rem !important;
  }
  .p-xl-22 {
    padding: 11rem !important;
  }
  .pt-xl-22,
  .py-xl-22 {
    padding-top: 11rem !important;
  }
  .pr-xl-22,
  .px-xl-22 {
    padding-right: 11rem !important;
  }
  .pb-xl-22,
  .py-xl-22 {
    padding-bottom: 11rem !important;
  }
  .pl-xl-22,
  .px-xl-22 {
    padding-left: 11rem !important;
  }
  .p-xl-23 {
    padding: 12rem !important;
  }
  .pt-xl-23,
  .py-xl-23 {
    padding-top: 12rem !important;
  }
  .pr-xl-23,
  .px-xl-23 {
    padding-right: 12rem !important;
  }
  .pb-xl-23,
  .py-xl-23 {
    padding-bottom: 12rem !important;
  }
  .pl-xl-23,
  .px-xl-23 {
    padding-left: 12rem !important;
  }
  .p-xl-24 {
    padding: 13rem !important;
  }
  .pt-xl-24,
  .py-xl-24 {
    padding-top: 13rem !important;
  }
  .pr-xl-24,
  .px-xl-24 {
    padding-right: 13rem !important;
  }
  .pb-xl-24,
  .py-xl-24 {
    padding-bottom: 13rem !important;
  }
  .pl-xl-24,
  .px-xl-24 {
    padding-left: 13rem !important;
  }
  .p-xl-25 {
    padding: 13.5rem !important;
  }
  .pt-xl-25,
  .py-xl-25 {
    padding-top: 13.5rem !important;
  }
  .pr-xl-25,
  .px-xl-25 {
    padding-right: 13.5rem !important;
  }
  .pb-xl-25,
  .py-xl-25 {
    padding-bottom: 13.5rem !important;
  }
  .pl-xl-25,
  .px-xl-25 {
    padding-left: 13.5rem !important;
  }
  .p-xl-26 {
    padding: 14rem !important;
  }
  .pt-xl-26,
  .py-xl-26 {
    padding-top: 14rem !important;
  }
  .pr-xl-26,
  .px-xl-26 {
    padding-right: 14rem !important;
  }
  .pb-xl-26,
  .py-xl-26 {
    padding-bottom: 14rem !important;
  }
  .pl-xl-26,
  .px-xl-26 {
    padding-left: 14rem !important;
  }
  .p-xl-27 {
    padding: 15rem !important;
  }
  .pt-xl-27,
  .py-xl-27 {
    padding-top: 15rem !important;
  }
  .pr-xl-27,
  .px-xl-27 {
    padding-right: 15rem !important;
  }
  .pb-xl-27,
  .py-xl-27 {
    padding-bottom: 15rem !important;
  }
  .pl-xl-27,
  .px-xl-27 {
    padding-left: 15rem !important;
  }
  .p-xl-28 {
    padding: 16rem !important;
  }
  .pt-xl-28,
  .py-xl-28 {
    padding-top: 16rem !important;
  }
  .pr-xl-28,
  .px-xl-28 {
    padding-right: 16rem !important;
  }
  .pb-xl-28,
  .py-xl-28 {
    padding-bottom: 16rem !important;
  }
  .pl-xl-28,
  .px-xl-28 {
    padding-left: 16rem !important;
  }
  .p-xl-29 {
    padding: 17rem !important;
  }
  .pt-xl-29,
  .py-xl-29 {
    padding-top: 17rem !important;
  }
  .pr-xl-29,
  .px-xl-29 {
    padding-right: 17rem !important;
  }
  .pb-xl-29,
  .py-xl-29 {
    padding-bottom: 17rem !important;
  }
  .pl-xl-29,
  .px-xl-29 {
    padding-left: 17rem !important;
  }
  .p-xl-30 {
    padding: 18rem !important;
  }
  .pt-xl-30,
  .py-xl-30 {
    padding-top: 18rem !important;
  }
  .pr-xl-30,
  .px-xl-30 {
    padding-right: 18rem !important;
  }
  .pb-xl-30,
  .py-xl-30 {
    padding-bottom: 18rem !important;
  }
  .pl-xl-30,
  .px-xl-30 {
    padding-left: 18rem !important;
  }
  .p-xl-31 {
    padding: 19rem !important;
  }
  .pt-xl-31,
  .py-xl-31 {
    padding-top: 19rem !important;
  }
  .pr-xl-31,
  .px-xl-31 {
    padding-right: 19rem !important;
  }
  .pb-xl-31,
  .py-xl-31 {
    padding-bottom: 19rem !important;
  }
  .pl-xl-31,
  .px-xl-31 {
    padding-left: 19rem !important;
  }
  .p-xl-32 {
    padding: 20rem !important;
  }
  .pt-xl-32,
  .py-xl-32 {
    padding-top: 20rem !important;
  }
  .pr-xl-32,
  .px-xl-32 {
    padding-right: 20rem !important;
  }
  .pb-xl-32,
  .py-xl-32 {
    padding-bottom: 20rem !important;
  }
  .pl-xl-32,
  .px-xl-32 {
    padding-left: 20rem !important;
  }
  .p-xl-37 {
    padding: 22.5rem !important;
  }
  .pt-xl-37,
  .py-xl-37 {
    padding-top: 22.5rem !important;
  }
  .pr-xl-37,
  .px-xl-37 {
    padding-right: 22.5rem !important;
  }
  .pb-xl-37,
  .py-xl-37 {
    padding-bottom: 22.5rem !important;
  }
  .pl-xl-37,
  .px-xl-37 {
    padding-left: 22.5rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -2rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -2rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -2rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -2rem !important;
  }
  .m-xl-n6 {
    margin: -2.5rem !important;
  }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -2.5rem !important;
  }
  .m-xl-n7 {
    margin: -3rem !important;
  }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -3rem !important;
  }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -3rem !important;
  }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -3rem !important;
  }
  .m-xl-n8 {
    margin: -3.5rem !important;
  }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -3.5rem !important;
  }
  .m-xl-n9 {
    margin: -4rem !important;
  }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -4rem !important;
  }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -4rem !important;
  }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -4rem !important;
  }
  .m-xl-n10 {
    margin: -4.5rem !important;
  }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -4.5rem !important;
  }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -4.5rem !important;
  }
  .m-xl-n11 {
    margin: -5rem !important;
  }
  .mt-xl-n11,
  .my-xl-n11 {
    margin-top: -5rem !important;
  }
  .mr-xl-n11,
  .mx-xl-n11 {
    margin-right: -5rem !important;
  }
  .mb-xl-n11,
  .my-xl-n11 {
    margin-bottom: -5rem !important;
  }
  .ml-xl-n11,
  .mx-xl-n11 {
    margin-left: -5rem !important;
  }
  .m-xl-n12 {
    margin: -5.5rem !important;
  }
  .mt-xl-n12,
  .my-xl-n12 {
    margin-top: -5.5rem !important;
  }
  .mr-xl-n12,
  .mx-xl-n12 {
    margin-right: -5.5rem !important;
  }
  .mb-xl-n12,
  .my-xl-n12 {
    margin-bottom: -5.5rem !important;
  }
  .ml-xl-n12,
  .mx-xl-n12 {
    margin-left: -5.5rem !important;
  }
  .m-xl-n13 {
    margin: -6rem !important;
  }
  .mt-xl-n13,
  .my-xl-n13 {
    margin-top: -6rem !important;
  }
  .mr-xl-n13,
  .mx-xl-n13 {
    margin-right: -6rem !important;
  }
  .mb-xl-n13,
  .my-xl-n13 {
    margin-bottom: -6rem !important;
  }
  .ml-xl-n13,
  .mx-xl-n13 {
    margin-left: -6rem !important;
  }
  .m-xl-n14 {
    margin: -6.5rem !important;
  }
  .mt-xl-n14,
  .my-xl-n14 {
    margin-top: -6.5rem !important;
  }
  .mr-xl-n14,
  .mx-xl-n14 {
    margin-right: -6.5rem !important;
  }
  .mb-xl-n14,
  .my-xl-n14 {
    margin-bottom: -6.5rem !important;
  }
  .ml-xl-n14,
  .mx-xl-n14 {
    margin-left: -6.5rem !important;
  }
  .m-xl-n15 {
    margin: -7rem !important;
  }
  .mt-xl-n15,
  .my-xl-n15 {
    margin-top: -7rem !important;
  }
  .mr-xl-n15,
  .mx-xl-n15 {
    margin-right: -7rem !important;
  }
  .mb-xl-n15,
  .my-xl-n15 {
    margin-bottom: -7rem !important;
  }
  .ml-xl-n15,
  .mx-xl-n15 {
    margin-left: -7rem !important;
  }
  .m-xl-n16 {
    margin: -7.5rem !important;
  }
  .mt-xl-n16,
  .my-xl-n16 {
    margin-top: -7.5rem !important;
  }
  .mr-xl-n16,
  .mx-xl-n16 {
    margin-right: -7.5rem !important;
  }
  .mb-xl-n16,
  .my-xl-n16 {
    margin-bottom: -7.5rem !important;
  }
  .ml-xl-n16,
  .mx-xl-n16 {
    margin-left: -7.5rem !important;
  }
  .m-xl-n17 {
    margin: -8rem !important;
  }
  .mt-xl-n17,
  .my-xl-n17 {
    margin-top: -8rem !important;
  }
  .mr-xl-n17,
  .mx-xl-n17 {
    margin-right: -8rem !important;
  }
  .mb-xl-n17,
  .my-xl-n17 {
    margin-bottom: -8rem !important;
  }
  .ml-xl-n17,
  .mx-xl-n17 {
    margin-left: -8rem !important;
  }
  .m-xl-n18 {
    margin: -8.5rem !important;
  }
  .mt-xl-n18,
  .my-xl-n18 {
    margin-top: -8.5rem !important;
  }
  .mr-xl-n18,
  .mx-xl-n18 {
    margin-right: -8.5rem !important;
  }
  .mb-xl-n18,
  .my-xl-n18 {
    margin-bottom: -8.5rem !important;
  }
  .ml-xl-n18,
  .mx-xl-n18 {
    margin-left: -8.5rem !important;
  }
  .m-xl-n19 {
    margin: -9rem !important;
  }
  .mt-xl-n19,
  .my-xl-n19 {
    margin-top: -9rem !important;
  }
  .mr-xl-n19,
  .mx-xl-n19 {
    margin-right: -9rem !important;
  }
  .mb-xl-n19,
  .my-xl-n19 {
    margin-bottom: -9rem !important;
  }
  .ml-xl-n19,
  .mx-xl-n19 {
    margin-left: -9rem !important;
  }
  .m-xl-n20 {
    margin: -9.5rem !important;
  }
  .mt-xl-n20,
  .my-xl-n20 {
    margin-top: -9.5rem !important;
  }
  .mr-xl-n20,
  .mx-xl-n20 {
    margin-right: -9.5rem !important;
  }
  .mb-xl-n20,
  .my-xl-n20 {
    margin-bottom: -9.5rem !important;
  }
  .ml-xl-n20,
  .mx-xl-n20 {
    margin-left: -9.5rem !important;
  }
  .m-xl-n21 {
    margin: -10rem !important;
  }
  .mt-xl-n21,
  .my-xl-n21 {
    margin-top: -10rem !important;
  }
  .mr-xl-n21,
  .mx-xl-n21 {
    margin-right: -10rem !important;
  }
  .mb-xl-n21,
  .my-xl-n21 {
    margin-bottom: -10rem !important;
  }
  .ml-xl-n21,
  .mx-xl-n21 {
    margin-left: -10rem !important;
  }
  .m-xl-n22 {
    margin: -11rem !important;
  }
  .mt-xl-n22,
  .my-xl-n22 {
    margin-top: -11rem !important;
  }
  .mr-xl-n22,
  .mx-xl-n22 {
    margin-right: -11rem !important;
  }
  .mb-xl-n22,
  .my-xl-n22 {
    margin-bottom: -11rem !important;
  }
  .ml-xl-n22,
  .mx-xl-n22 {
    margin-left: -11rem !important;
  }
  .m-xl-n23 {
    margin: -12rem !important;
  }
  .mt-xl-n23,
  .my-xl-n23 {
    margin-top: -12rem !important;
  }
  .mr-xl-n23,
  .mx-xl-n23 {
    margin-right: -12rem !important;
  }
  .mb-xl-n23,
  .my-xl-n23 {
    margin-bottom: -12rem !important;
  }
  .ml-xl-n23,
  .mx-xl-n23 {
    margin-left: -12rem !important;
  }
  .m-xl-n24 {
    margin: -13rem !important;
  }
  .mt-xl-n24,
  .my-xl-n24 {
    margin-top: -13rem !important;
  }
  .mr-xl-n24,
  .mx-xl-n24 {
    margin-right: -13rem !important;
  }
  .mb-xl-n24,
  .my-xl-n24 {
    margin-bottom: -13rem !important;
  }
  .ml-xl-n24,
  .mx-xl-n24 {
    margin-left: -13rem !important;
  }
  .m-xl-n25 {
    margin: -13.5rem !important;
  }
  .mt-xl-n25,
  .my-xl-n25 {
    margin-top: -13.5rem !important;
  }
  .mr-xl-n25,
  .mx-xl-n25 {
    margin-right: -13.5rem !important;
  }
  .mb-xl-n25,
  .my-xl-n25 {
    margin-bottom: -13.5rem !important;
  }
  .ml-xl-n25,
  .mx-xl-n25 {
    margin-left: -13.5rem !important;
  }
  .m-xl-n26 {
    margin: -14rem !important;
  }
  .mt-xl-n26,
  .my-xl-n26 {
    margin-top: -14rem !important;
  }
  .mr-xl-n26,
  .mx-xl-n26 {
    margin-right: -14rem !important;
  }
  .mb-xl-n26,
  .my-xl-n26 {
    margin-bottom: -14rem !important;
  }
  .ml-xl-n26,
  .mx-xl-n26 {
    margin-left: -14rem !important;
  }
  .m-xl-n27 {
    margin: -15rem !important;
  }
  .mt-xl-n27,
  .my-xl-n27 {
    margin-top: -15rem !important;
  }
  .mr-xl-n27,
  .mx-xl-n27 {
    margin-right: -15rem !important;
  }
  .mb-xl-n27,
  .my-xl-n27 {
    margin-bottom: -15rem !important;
  }
  .ml-xl-n27,
  .mx-xl-n27 {
    margin-left: -15rem !important;
  }
  .m-xl-n28 {
    margin: -16rem !important;
  }
  .mt-xl-n28,
  .my-xl-n28 {
    margin-top: -16rem !important;
  }
  .mr-xl-n28,
  .mx-xl-n28 {
    margin-right: -16rem !important;
  }
  .mb-xl-n28,
  .my-xl-n28 {
    margin-bottom: -16rem !important;
  }
  .ml-xl-n28,
  .mx-xl-n28 {
    margin-left: -16rem !important;
  }
  .m-xl-n29 {
    margin: -17rem !important;
  }
  .mt-xl-n29,
  .my-xl-n29 {
    margin-top: -17rem !important;
  }
  .mr-xl-n29,
  .mx-xl-n29 {
    margin-right: -17rem !important;
  }
  .mb-xl-n29,
  .my-xl-n29 {
    margin-bottom: -17rem !important;
  }
  .ml-xl-n29,
  .mx-xl-n29 {
    margin-left: -17rem !important;
  }
  .m-xl-n30 {
    margin: -18rem !important;
  }
  .mt-xl-n30,
  .my-xl-n30 {
    margin-top: -18rem !important;
  }
  .mr-xl-n30,
  .mx-xl-n30 {
    margin-right: -18rem !important;
  }
  .mb-xl-n30,
  .my-xl-n30 {
    margin-bottom: -18rem !important;
  }
  .ml-xl-n30,
  .mx-xl-n30 {
    margin-left: -18rem !important;
  }
  .m-xl-n31 {
    margin: -19rem !important;
  }
  .mt-xl-n31,
  .my-xl-n31 {
    margin-top: -19rem !important;
  }
  .mr-xl-n31,
  .mx-xl-n31 {
    margin-right: -19rem !important;
  }
  .mb-xl-n31,
  .my-xl-n31 {
    margin-bottom: -19rem !important;
  }
  .ml-xl-n31,
  .mx-xl-n31 {
    margin-left: -19rem !important;
  }
  .m-xl-n32 {
    margin: -20rem !important;
  }
  .mt-xl-n32,
  .my-xl-n32 {
    margin-top: -20rem !important;
  }
  .mr-xl-n32,
  .mx-xl-n32 {
    margin-right: -20rem !important;
  }
  .mb-xl-n32,
  .my-xl-n32 {
    margin-bottom: -20rem !important;
  }
  .ml-xl-n32,
  .mx-xl-n32 {
    margin-left: -20rem !important;
  }
  .m-xl-n37 {
    margin: -22.5rem !important;
  }
  .mt-xl-n37,
  .my-xl-n37 {
    margin-top: -22.5rem !important;
  }
  .mr-xl-n37,
  .mx-xl-n37 {
    margin-right: -22.5rem !important;
  }
  .mb-xl-n37,
  .my-xl-n37 {
    margin-bottom: -22.5rem !important;
  }
  .ml-xl-n37,
  .mx-xl-n37 {
    margin-left: -22.5rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 400 !important;
}

.font-weight-lighter {
  font-weight: 100 !important;
}

.font-weight-normal {
  font-weight: normal !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.font-weight-bolder {
  font-weight: 900 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #ef353d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #c81018 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #ef353d !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #c81018 !important;
}

.text-light {
  color: #f2f2f2 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cccccc !important;
}

.text-dark {
  color: #4d4d4d !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #272727 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
@font-face {
  font-family: "Nexa";
  src: url("../../public/fonts/nexa/Nexa-Bold.woff2") format("woff2"), url("../../public/fonts/nexa/Nexa-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Nexa";
  src: url("../../public/fonts/nexa/Nexa-Bold.woff2") format("woff2"), url("../../public/fonts/nexa/Nexa-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Nexa";
  src: url("../../public/fonts/nexa/Nexa-Regular.woff2") format("woff2"), url("../../public/fonts/nexa/Nexa-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Nexa";
  src: url("../../public/fonts/nexa/Nexa-Regular.woff2") format("woff2"), url("../../public/fonts/nexa/Nexa-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Nexa";
  src: url("../../public/fonts/nexa/Nexa-Thin.woff2") format("woff2"), url("../../public/fonts/nexa/Nexa-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Nexa";
  src: url("../../public/fonts/nexa/Nexa-Thin.woff2") format("woff2"), url("../../public/fonts/nexa/Nexa-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Kursk";
  src: url("../../public/fonts/Kursk-205-W00-Light.woff") format("woff");
  font-style: normal;
}
@font-face {
  font-family: "Chakra Petch";
  src: url("../../public/fonts/ChakraPetch-Regular.woff2") format("woff2"), url("../../public/fonts/ChakraPetch-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Chakra Petch";
  src: url("../../public/fonts/ChakraPetch-Bold.woff2") format("woff2"), url("../../public/fonts/ChakraPetch-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
body {
  overflow: overlay;
}

.btn:focus {
  box-shadow: none;
}

.letter-spacing-002 {
  letter-spacing: -0.02em;
}

.shadow-sm {
  box-shadow: 0px 5px 10px rgba(128, 0, 0, 0.4);
}

.line-h-1 {
  line-height: 1 !important;
}

.line-h-1-1 {
  line-height: 1.1 !important;
}

.line-h-1-2 {
  line-height: 1.2 !important;
}

.line-h-1-3 {
  line-height: 1.3 !important;
}

.z-index-2 {
  z-index: 2;
}

.w-200px {
  width: 200px;
}

.max-w-472px {
  max-width: 472px;
}

.modal-content {
  border: none !important;
}

.bg-light-gradient {
  position: relative;
}
.bg-light-gradient::after {
  display: none;
}
.bg-light-gradient::before {
  content: "";
  position: absolute;
  background: linear-gradient(180deg, #e6e6e6 0%, rgba(230, 230, 230, 0.1) 100%);
  opacity: 0.5;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.secondary-line {
  position: relative;
}
.secondary-line::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  background: #ef353d;
  top: 0;
  left: calc(10vw + 15px);
  opacity: 0.2;
}

.object-cover {
  object-fit: cover;
}

figure {
  margin-bottom: 0;
}

.wrapper {
  overflow: hidden;
}

.multiply {
  mix-blend-mode: multiply;
}

.font-s-12px {
  font-size: 0.75rem;
}

.font-s-14px {
  font-size: 0.875rem;
}

.font-s-16px {
  font-size: 1rem !important;
}

.font-s-18px {
  font-size: 1.125rem !important;
}

.cursor-pointer {
  cursor: pointer;
}

.word-break-all {
  word-break: break-all;
}

.input-style {
  padding: 26px 16px;
  border: 1px solid #ef353d;
  height: 56px;
}

.swiper-button-next.next-custom {
  bottom: 65%;
  left: initial;
  right: 0;
  background: transparent;
  pointer-events: none;
}
.swiper-button-next.next-custom::after {
  color: #ef353d;
  font-size: 28px;
}

.outline-btn {
  position: relative;
  padding-bottom: 3px;
}
.outline-btn::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40%;
  height: 1px;
  background: white;
  transition: 250ms;
}
.outline-btn:hover::after {
  width: 100%;
}
.outline-btn.red {
  color: #ef353d;
}
.outline-btn.red::after {
  background: #ef353d;
}

.section-secondary-title {
  font-style: normal;
  font-weight: 250;
  font-size: 48px;
  line-height: 1.1;
  letter-spacing: -0.02em;
  color: #ef353d;
  border-left: 3px solid #ef353d;
  margin-bottom: 2rem;
  padding-left: 2rem;
  padding: 1.3rem 0 1.3rem 2rem;
}
.section-secondary-title.custom-spacing-20 {
  margin-bottom: 20px;
}
.section-secondary-title.custom-spacing-16 {
  margin-bottom: 16px;
}
.section-secondary-title.custom-spacing-1 {
  margin-bottom: 1px;
}

.section-secondary-title-v2 {
  font-style: normal;
  font-weight: 250;
  font-size: 48px;
  line-height: 1.1;
  letter-spacing: -0.02em;
  color: #ef353d;
  margin-bottom: 2rem;
}

.manifacturing-secondary-title {
  font-style: normal;
  font-size: 48px;
  font-weight: 200;
  line-height: 1;
  color: #ef353d;
  letter-spacing: -0.02em;
}

.secondary-bold-title {
  font-size: 48px;
  line-height: 1.1;
  letter-spacing: -0.02em;
  font-weight: bold;
  color: #ef353d;
}

.privacy-policy-content {
  margin-top: calc(10vw + 15px);
}

@media (max-width: 575.98px) {
  .privacy-policy-content {
    margin-top: 40vw;
  }
}
.page-hero-image {
  margin-top: calc(10vw + 15px);
}
.page-hero-image figure img {
  width: 90vw;
}

.home.figure-left {
  position: absolute;
  left: 0;
  top: 250px;
  max-width: 783px;
  width: 100%;
  z-index: -1;
  pointer-events: none;
}
.home.figure-right {
  position: absolute;
  right: 0;
  top: -150px;
  max-width: 783px;
  width: 100%;
  z-index: -1;
  pointer-events: none;
}

.company-doing {
  position: relative;
}
.company-doing .company.figure-left {
  position: absolute;
  left: -164px;
  top: -530px;
  max-width: 783px;
  width: 100%;
  z-index: -1;
  pointer-events: none;
}

@media (min-width: 992px) {
  .home-slider {
    position: relative;
  }
  .home-slider::after {
    content: "";
    position: absolute;
    top: 0;
    left: calc(10vw + 14px);
    width: 1px;
    height: 100%;
    background: white;
    z-index: 1;
  }
  .page-secondary-line::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(10vw + 16px);
    width: 1px;
    height: 100%;
    background: #ef353d;
    z-index: 1;
    opacity: 0.2;
  }
  .page-secondary-line.percent-25::after {
    height: 25% !important;
  }
  .page-secondary-line.percent-50::after {
    height: 50% !important;
  }
  .page-secondary-line.percent-74::after {
    height: 74% !important;
  }
  .page-secondary-line.percent-150::after {
    height: 150% !important;
    left: calc(10vw + 14px);
  }
  .page-secondary-line.percent-full {
    width: 1px;
    height: 100%;
    background: #ef353d;
    opacity: 0.2;
    border-left: 1px solid #ef353d;
  }
  .page-secondary-line.percent-full::after {
    display: none;
  }
  .page-secondary-line.top-sour-80::after {
    top: -80px;
    height: calc(100% + 80px);
  }
  .section-border {
    position: relative;
  }
  .section-border::after {
    content: "";
    top: 0;
    left: 15px;
    height: 86px;
    width: 3px;
    background: #ef353d;
    position: absolute;
  }
  .section-border-white {
    position: relative;
  }
  .section-border-white::after {
    content: "";
    top: 0;
    left: -142px;
    height: 86px;
    width: 8px;
    background: white;
    position: absolute;
  }
  .section-dot {
    position: relative;
  }
  .section-dot::before {
    content: "";
    top: 0;
    left: -64px;
    height: 8px;
    width: 8px;
    background: #ef353d;
    position: absolute;
  }
  .section-dot.left-15::before {
    top: 10px;
    left: 15px;
  }
  .section-dot.left-110::before {
    top: 4px;
    left: -110px;
  }
  .border-full {
    position: relative;
  }
  .border-full::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(10vw + 16px);
    width: 1px;
    height: 100%;
    background: #ef353d;
    z-index: 1;
    opacity: 0.2;
  }
}
@media (min-width: 1440.98px) {
  .page-secondary-line-right::before {
    content: "";
    position: absolute;
    top: 0;
    right: calc(30vw + 15px);
    width: 1px;
    height: 100%;
    background: #ef353d;
    z-index: 1;
    opacity: 0.2;
    z-index: -1;
  }
  .page-secondary-line-right.percent-11-5vw::before {
    height: 11.5vw !important;
  }
  .page-secondary-line-right.percent-11-5vw .dot::after {
    content: "";
    top: 5vw;
    right: calc(30vw + 15px);
    height: 8px;
    width: 8px;
    background: #ef353d;
    position: absolute;
  }
  .page-secondary-line-right.percent-20vw::before {
    height: 20vw !important;
  }
  .page-secondary-line-right.percent-20vw .dot::before {
    content: "";
    top: 5vw;
    right: calc(30vw + 15px);
    height: 8px;
    width: 8px;
    background: #ef353d;
    position: absolute;
  }
  .page-secondary-line-right.percent-52vw::before {
    height: 52vw !important;
  }
  .page-secondary-line-right.percent-52vw .dot::after {
    content: "";
    top: 5vw;
    right: calc(30vw + 15px);
    height: 8px;
    width: 8px;
    background: #ef353d;
    position: absolute;
  }
  .page-secondary-line-right.percent-52vw .dot::before {
    content: "";
    top: 52vw;
    right: calc(30vw + 15px);
    height: 8px;
    width: 8px;
    background: #ef353d;
    position: absolute;
  }
  .page-secondary-line-right.percent-72vw::before {
    height: 72vw !important;
  }
  .page-secondary-line-right.percent-72vw .dot::after {
    content: "";
    top: 72vw;
    right: calc(30vw + 15px);
    height: 8px;
    width: 8px;
    background: #ef353d;
    position: absolute;
  }
  .page-secondary-line-right.percent-72vw .dot::before {
    content: "";
    top: 5vw;
    right: calc(30vw + 15px);
    height: 8px;
    width: 8px;
    background: #ef353d;
    position: absolute;
  }
  .page-secondary-line-right.percent-5vw::before {
    height: 5vw !important;
  }
  .page-secondary-line-right.percent-5vw .dot::after {
    content: "";
    top: 5vw;
    right: calc(30vw + 15px);
    height: 8px;
    width: 8px;
    background: #ef353d;
    position: absolute;
  }
  .page-secondary-line-right.percent-5vw .dot::before {
    content: "";
    top: 5vw;
    right: calc(30vw + 15px);
    height: 8px;
    width: 8px;
    background: #ef353d;
    position: absolute;
  }
}
.filter-bodys.active .f-1 {
  display: block !important;
  height: auto !important;
}

.social-item {
  transition: 150ms;
}
.social-item:hover {
  transform: scale(1.1);
}

@media (max-width: 1680px) {
  .section-secondary-title,
  .section-secondary-title-v2 {
    font-size: 40px;
  }
}
@media (max-width: 1440px) {
  .section-secondary-title,
  .section-secondary-title-v2 {
    font-size: 32px;
    padding: 1.2rem 0 1.2rem 2rem;
  }
  .section-secondary-title-v2 {
    padding: 1.2rem 0 1.2rem 0rem;
  }
}
@media (max-width: 1280px) {
  .section-secondary-title,
  .section-secondary-title-v2 {
    font-size: 28px;
    padding: 1.125rem 0 1.125rem 2rem;
    margin-bottom: 1.8rem;
  }
  .section-secondary-title-v2 {
    padding: 1.125rem 0 1.125rem 0rem;
  }
}
@media (max-width: 991.98px) {
  .section-secondary-title,
  .section-secondary-title-v2 {
    padding: 1rem 0 1rem 2rem;
    font-size: 24px;
    margin-bottom: 1.3rem;
  }
  .section-secondary-title-v2 {
    padding: 1rem 0 1rem 0rem;
  }
}
@media (max-width: 767.98px) {
  .section-secondary-title,
  .section-secondary-title-v2 {
    padding: 0.875rem 0 0.875rem 2rem;
    font-size: 22px;
    margin-bottom: 1rem;
  }
  .section-secondary-title-v2 {
    border-left: 2px solid #ef353d;
    padding: 0.875rem 0 0.875rem 1rem;
  }
  .outline-btn {
    padding-bottom: 5px;
  }
}
@media (max-width: 575.98px) {
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }
  .pl-xs-20px {
    padding-left: 20px;
  }
  .section-secondary-title,
  .section-secondary-title-v2 {
    padding: 1rem 0 1rem 1rem;
    font-size: 40px;
    margin-bottom: 0;
  }
  .page-hero-image {
    margin-top: 40vw;
  }
  .page-hero-image figure {
    margin-top: -7%;
    margin-bottom: calc(5% - 40px);
    margin-left: calc(-36px - 5%);
    margin-right: calc(-36px - 5%);
  }
  .page-hero-image figure img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    padding: 5%;
    object-position: -60px 0px;
  }
  .home.figure-left, .home.figure-right {
    display: none;
  }
  .mobile-section-secondary-line {
    position: relative;
  }
  .mobile-section-secondary-line::after {
    content: "";
    position: absolute;
    left: 2rem;
    width: 1px;
    height: 100%;
    background: #ef353d;
    opacity: 0.2;
    top: 0;
    z-index: -1;
  }
  .mobile-section-secondary-line.m-line-percent-50::after {
    height: 50%;
  }
  .mobile-section-secondary-line.m-line-percent-37::after {
    height: 37%;
  }
  .mobile-section-secondary-line.m-line-left-0::after {
    left: 0;
    z-index: 2;
  }
  .mobile-title-dot {
    position: relative;
  }
  .mobile-title-dot::before {
    position: absolute;
    content: "";
    top: 4px;
    left: -2rem;
    width: 8px;
    height: 8px;
    background: #ef353d;
  }
}
.flex-table .row:nth-child(odd) {
  background: #f7f7f7;
}
.flex-table .row p {
  margin-bottom: 8px;
}
.flex-table .row p:last-child {
  margin-bottom: 0;
}

.custom-button-padding {
  padding: 20px 16px 16px;
}

.max-w-1240px {
  max-width: 1240px;
}

.max-w-967px {
  max-width: 967px;
}

.max-w-976px {
  max-width: 976px;
}

.max-w-979px {
  max-width: 979px;
}

.max-w-942px {
  max-width: 942px;
}

.max-w-850px {
  max-width: 850px;
}

.max-w-780px {
  max-width: 780px;
}

.max-w-761px {
  max-width: 761px;
}

.max-w-724px {
  max-width: 724px;
}

.max-w-543px {
  max-width: 543px;
}

.max-w-482px {
  max-width: 482px;
}

.max-w-466px {
  max-width: 466px;
}

.max-w-388px {
  max-width: 388px;
}

.max-w-386px {
  max-width: 386px;
}

.max-w-383px {
  max-width: 383px;
}

.max-w-384px {
  max-width: 384px;
}

.max-w-358px {
  max-width: 358px;
}

.max-w-355px {
  max-width: 355px;
}

.max-w-345px {
  max-width: 345px;
}

.max-w-325px {
  max-width: 325px;
}

.max-w-342px {
  max-width: 342px;
}

.max-w-277px {
  max-width: 277px;
}

.max-w-271px {
  max-width: 271px;
}

.max-w-270px {
  max-width: 270px;
}

.max-w-268px {
  max-width: 268px;
}

.max-w-252px {
  max-width: 252px;
}

.max-w-250px {
  max-width: 250px;
}

.max-w-245px {
  max-width: 245px;
}

.max-w-1228px {
  max-width: 1228px;
}

.form-select {
  position: relative;
}
.form-select::after {
  content: "";
  right: 32px;
  top: 48%;
  position: absolute;
  pointer-events: none;
  border: solid #ef353d;
  border-width: 0 3px 3px 0;
  border-radius: 1px;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg) translateY(-50%);
}

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
}

.swiper-button-disabled {
  opacity: 0.5 !important;
}

.swiper-slide .swiper-text-animation h1 {
  transition-delay: 150ms;
  opacity: 0;
  transform: translateY(30px);
  transition: 1s;
}
.swiper-slide .swiper-text-animation h2 {
  transition-delay: 300ms;
  opacity: 0;
  transform: translateY(30px);
  transition: 1s;
}
.swiper-slide .swiper-text-animation p {
  transition-delay: 450ms;
  opacity: 0;
  transform: translateY(30px);
  transition: 1s;
}
.swiper-slide.swiper-slide-active .swiper-text-animation h1 {
  transition-delay: 150ms;
  opacity: 1;
  transform: translateY(0);
}
.swiper-slide.swiper-slide-active .swiper-text-animation h2 {
  transition-delay: 300ms;
  opacity: 1;
  transform: translateY(0);
}
.swiper-slide.swiper-slide-active .swiper-text-animation p {
  transition-delay: 450ms;
  opacity: 1;
  transform: translateY(0);
}

.focus-outline-0:focus {
  outline: 0 !important;
}

input[type=file] {
  display: none;
}

.file-label {
  display: inline-block;
  cursor: pointer;
  border-radius: 4px;
}