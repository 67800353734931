.news-all {
    margin-top: calc(10vw + 15px);

    .card {
        .card-img-top {
            max-height: 455px;
            object-fit: cover;
        }
    }
}

//MEDIA
@media (max-width: 1680px) {
    .news-all {
        .card {
            .card-body {
                a {
                    font-size: 10px !important;
                }
                h2 {
                    font-size: 1.3rem;
                }
                p {
                    font-size: 0.725rem;
                }
            }
        }
    }
    .news-head-title {
        font-size: 24px;
    }
}
@media (max-width: 1440px) {
    .news-all {
        .card {
            .card-body {
                a {
                    font-size: 9px !important;
                }
                h2 {
                    font-size: 1rem;
                }
                p {
                    font-size: 0.625rem;
                }
            }
        }
    }
    .news-head-title {
        font-size: 18px;
    }
}
@media (max-width: 1280px) {
    .news-head-title {
        font-size: 16px;
    }
}
@media (max-width: 991.98px) {
    .news-head-title {
        font-size: 14px;
    }
}
@media (max-width: 767.98px) {
    .news-all {
        .card {
            .card-img-top {
                max-height: 280px;
            }
            .card-body {
                a {
                    font-size: 16px !important;
                }
                h2 {
                    font-size: 1rem;
                }
                p {
                    font-size: 0.625rem;
                }
            }
        }
    }
}
@media (max-width: 575.98px) {
    .news-all {
        margin-top: 40vw;
        .section-secondary-title-v2 {
            padding-left: 0 !important;
            border-left: none;
        }
        .card {
            .card-img-top {
                max-height: 360px;
            }
            .card-body {
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    width: 1px;
                    height: calc(100% + 4.5rem);
                    background: $secondary;
                    opacity: 0.2;
                    top: 0;
                    left: 0;
                }
                a {
                    font-size: 16px !important;
                }
                h2 {
                    font-size: 2rem;
                }
                p {
                    font-size: 1rem;
                }
                .outline-btm {
                    font-size: 1rem;
                }
            }
            position: relative;
            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                top: 85px;
                right: 0;
                width: 100%;
                height: 276px;
                background: linear-gradient(1.06deg, #0a0a0a 0.94%, rgba(196, 196, 196, 0) 99.12%);
            }
        }
        .mid-news {
            max-height: 360px;
            height: 360px;
            object-fit: cover;
        }

        .load-more {
            &::before {
                content: '';
                position: absolute;
                width: 1px;
                height: calc(100% + 6.5rem);
                background: $secondary;
                opacity: 0.2;
                top: -1.5rem;
                left: -32px;
            }
        }
    }
}
