.wrapper-contact-form,
.wrapper-careers-form {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        background: $light;
        z-index: -1;
    }
    &.none-after {
        &::after {
            display: none;
        }
    }

    .contact-form-content {
        label {
            font-weight: normal;
            font-size: 12px;
            letter-spacing: -0.02em;
            color: #4d4d4d;
            margin-bottom: 0;
        }
        input {
            padding: 26px 16px;
            border: 1px solid $secondary;
            height: 56px;
            &.form-check-input {
                height: auto;
            }
        }
        .form-check-label {
            font-size: 16px;
        }
        .custom-select {
            border: 1px solid $secondary !important;
            height: 56px;
            border-color: $secondary !important;
        }
        .form-control{
            border: 1px solid $secondary !important;

        }
        textarea {
            border: 1px solid $secondary !important;
            max-height: 120px;
            min-height: 56px;
            border-color: $secondary !important;

        }
    }
}

//CHECKBOX
.control {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 5px;
    padding-top: 3px;
    cursor: pointer;
    font-size: 16px;
}
.control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.control_indicator {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    background: #ffffff;
    border: 1px solid $secondary;
    border-radius: 0px;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
    background: #fff;
}

.control input:checked ~ .control_indicator {
    background: $secondary;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
    background: $secondary;
}
.control input:disabled ~ .control_indicator {
    background: #fff;
    opacity: 0.6;
    pointer-events: none;
}
.control_indicator:after {
    box-sizing: unset;
    content: '';
    position: absolute;
    display: none;
}
.control input:checked ~ .control_indicator:after {
    display: block;
}
.control-checkbox .control_indicator:after {
    left: 8px;
    top: 4px;
    width: 3px;
    height: 8px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.control-checkbox input:disabled ~ .control_indicator:after {
    border-color: #fff;
}
.control-checkbox .control_indicator::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 4.5rem;
    height: 4.5rem;
    margin-left: -1.7rem;
    margin-top: -1.7rem;
    background: $secondary;
    border-radius: 3rem;
    opacity: 0.6;
    z-index: 99999;
    transform: scale(0);
}
@keyframes s-ripple {
    0% {
        transform: scale(0);
    }
    20% {
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}
@keyframes s-ripple-dup {
    0% {
        transform: scale(0);
    }
    30% {
        transform: scale(1);
    }
    60% {
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}
.control-checkbox input + .control_indicator::before {
    animation: s-ripple 250ms ease-out;
}
.control-checkbox input:checked + .control_indicator::before {
    animation-name: s-ripple-dup;
}


//MEDIA
@media (max-width: 1680px) {
    .careers-head-title {
        h2 {
            font-size: 1.6rem;
        }
    }

    .meet {
        h1 {
            font-size: 2rem;
        }
        .contact-info-row {
            h2 {
                font-size: 1.765rem;
            }
        }
    }
}
@media (max-width: 1440px) {
    .careers-head-title {
        h2 {
            font-size: 1.365rem;
        }
    }

    .meet {
        h1 {
            font-size: 1.8rem;
        }
        .contact-info-row {
            h2 {
                font-size: 1.625rem;
            }
            p {
                font-size: 0.875rem;
            }
        }
    }
}
@media (max-width: 1280px) {
    .careers-head-title {
        h2 {
            font-size: 1.125rem;
        }
        .contact-info-row {
            h2 {
                font-size: 1.5rem;
            }
            p {
                font-size: 0.725rem;
            }
        }
    }

    .contact-form {
        .wrapper-careers-form {
            .contact-form-content {
                label {
                }
                input {
                    font-size: 0.875rem;
                    &::placeholder {
                        font-size: 0.875rem;
                    }
                }

                .form-check-label {
                    font-size: 0.875rem;
                }
                .custom-select {
                    font-size: 0.875rem;
                }
                textarea {
                    font-size: 0.875rem;
                    &::placeholder {
                        font-size: 0.875rem;
                    }
                }
            }
        }
    }

    .meet {
        h1 {
            font-size: 1.6rem;
        }
        p {
            font-size: 0.675rem;
        }
    }
}
@media (max-width: 991.98px) {
    .careers-head-title {
        h2 {
            font-size: 1rem;
        }
    }

    .meet {
        h1 {
            font-size: 1.5rem;
        }
    }

    .wrapper-contact-form {
        &::after {
            left: -10%;
            width: 130vw;
        }
    }
}
@media (max-width: 767.98px) {
}
@media (max-width: 575.98px) {
    .contact-us {
        .page-hero-image {
            figure {
                img {
                    object-position: -112px 0px;
                }
            }
        }
    }
    .meet {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            left: 30px;
            top: 0rem;
            background: $secondary;
            opacity: 0.2;
            height: 98%;
            width: 1px;
        }
        h1 {
            font-size: 2.5rem;
            border-left: 3px solid $secondary;
            padding-left: 1rem;
        }
        p {
            font-size: 1rem;
        }
    }

    .contact-form {
        &::after {
            display: none;
        }
    }

    .home-follow-us-section {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            left: 30px;
            top: 0rem;
            background: $secondary;
            opacity: 0.2;
            height: 100%;
            width: 1px;
        }
    }
}
