// faq
.faq {
    margin-top: calc(10vw + 15px);

    .card-img-top {
        height: 414px;
        background: #f2f2f2;
        object-fit: contain;
    }
}

// faq-models
.faq-models,
.principles {
    margin-top: calc(10vw + 15px);

    .head-wrapper {
        border-bottom: 2px solid $secondary;
        padding-bottom: 2rem;
        .section-secondary-title-v2 {
            margin-bottom: 4rem !important;
        }
        .faq-models-head-description {
            font-size: 64px;
        }
    }
    .content {
        .head-secondary {
            font-size: 3rem;
        }
    }

    .models-img {
        img {
            height: 32vw;
            object-fit: cover;
        }
    }
    .all-faqs {
        position: relative;
        &::after {
            position: absolute;
            top: 0;
            left: 14px;
            content: '';
            width: 8px;
            height: 8px;
            background: $secondary;
        }
    }
}

@media (min-width: 1440px) {
    // faq
    .faq {
        .col-lg-6 {
            max-width: 46% !important;
        }
    }
    // faq-models
}

@media (min-width: 992px) {
    .faq,
    .faq-models,
    .principles {
        position: relative;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: calc(10vw + 14px);
            background: $secondary;
            opacity: 0.2;
            width: 1px;
            height: 100%;
        }
        &::after {
            content: '';
            position: absolute;
            width: 3px;
            height: 86px;
            background: $secondary;
            top: 121px;
            left: calc(10vw + 14px);
        }
    }
}

//MEDIA
@media (max-width: 1680px) {
}
@media (max-width: 1440px) {
}
@media (max-width: 1280px) {
}
@media (max-width: 991.98px) {
}
@media (max-width: 767.98px) {
}
@media (max-width: 575.98px) {
    .faq,
    .faq-models,
    .principles {
        margin-top: 40vw;
    }
    .faq {
        .section-secondary-title-v2 {
            border: none;
            padding: 1rem 0 1rem 0rem;
        }

        .faq-head-description {
            font-size: 1rem;
        }
        .card {
            figure {
                img {
                    width: 125%;
                    transform: translateX(-10%);
                }
            }
            .card-body {
                .card-title {
                    font-size: 1.5rem;
                }
            }
        }
    }

    .faq-models,
    .principles {
        margin-top: 40vw;
        .head-wrapper {
            .section-secondary-title-v2 {
                border: none;
                padding: 1rem 0 1rem 0rem;
                margin-top: 1rem;
                margin-bottom: 1.5rem !important;
            }
            .faq-models-head-description {
                font-size: 2.5rem;
            }
        }
        .section-secondary-title-v2 {
            border: none;
            padding: 1rem 0 1rem 0rem;
            margin-top: 1rem;
            margin-bottom: 1.5rem !important;
        }
        .head-secondary {
            font-size: 2.5rem !important;
        }
        .models-img {
            margin: 0 -30px;
            img {
                height: 415px;
            }
        }
        .all-faqs {
            &::after {
                display: none;
            }
            h4 {
                font-size: 1.25rem;
            }
        }
    }
}
