.home-slider {
    .swiper-container {
        width: 100%;
        height: 100%;
    }
    .swiper-wrapper {
        background: #000000;
    }
    .swiper-slide {
        font-size: 18px;
        // display: flex;
        background-size: cover;
        width: 100%;
        background-repeat: no-repeat;
        height: 94vh;
        .text-white {
            h1 {
                font-size: 3.375rem;
            }
            p {
                font-size: 1.25rem;
            }
        }
    }
    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .slider-content {
        margin-top: calc(10vw + 15px);
    }
    //index-no
    .slider-index-no {
        font-family: Kursk;
        font-style: normal;
        font-weight: 500;
        font-size: 160px;
        line-height: 1.2;
        color: #ffffff;
        opacity: 0.15;
        position: absolute;
        top: -40px;
        left: -80px;
    }
    //pagination
    .swiper-pagination {
        bottom: initial;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        .swiper-pagination-bullet {
            background: transparent;
            color: white;
            font-style: normal;
            font-weight: bolder;
            font-size: 12px;
            opacity: 0.8;
            margin-right: 32px;
            width: 20px;
            height: 20px;
            text-align: center;
            line-height: 20px;
            font-size: 12px;
            &.swiper-pagination-bullet-active {
                color: $secondary;
            }
        }
    }

    // HomeSlider Anim Active
    .swiper-slide {
        .slider-index-no {
            opacity: 0;
            transition: 1s;
            transform: translateX(-70px);
        }
        .swiper-pagination-bullet {
            opacity: 0;
            transition: 1s;
            transform: translateY(-100px);
            &:nth-child(1) {
                transform: translateY(-100px);
            }
            &:nth-child(2) {
                transform: translateY(-120px);
            }
            &:nth-child(3) {
                transform: translateY(-140px);
            }
            &:nth-child(4) {
                transform: translateY(-160px);
            }
            &:nth-child(5) {
                transform: translateY(-180px);
            }
        }
        .text-white {
            h1 {
                opacity: 0;
                transition: 1s;
                transform: translateY(100px);
            }
            p {
                opacity: 0;
                transition: 1s;
                transform: translateY(150px);
            }
            a {
                opacity: 0;
                transition: 1s;
                transform: translateY(200px);
            }
        }

        &.swiper-slide-active {
            .slider-index-no {
                opacity: 0.15;
                transition: 1s;
                transform: translateX(0);
            }
            .swiper-pagination-bullet {
                opacity: 1;
                transition: 1s;
                transform: translateY(0);
            }
            .text-white {
                h1 {
                    opacity: 1;
                    transition: 1s;
                    transform: translateY(0);
                }
                p {
                    opacity: 1;
                    transition: 1s;
                    transform: translateY(0);
                }
                a {
                    opacity: 1;
                    transition: 1s;
                    transform: translateY(0);
                }
            }
        }
    }
}

//HOME ABOUTME{
.home-about-section {
    .card-img-overlayy {
        padding: 230px 72px 72px 72px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
        background-image: url(/assets/dokumhaneYeni.jpg);
        background-size: cover;
        &.one {
        }
        &.two {
            background-image: url(/assets/talasli-imalat-2.jpeg);
        }
        .more-text {
            p {
                height: 0;
                overflow: hidden;
                transition: 300ms;
                font-size: 20px;
                line-height: 1.5;
            }
            &.active {
                p {
                    height: auto;
                }
                .outline-btn {
                    display: none !important;
                }
            }
        }
    }
    .about-card {
        .card-no {
            font-family: Kursk;
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 100%;
            color: #ffffff;
            mix-blend-mode: screen;
            opacity: 0.2;
        }
        h2 {
            font-size: 72px;
            line-height: 100%;
            letter-spacing: -0.03em;
        }
    }
}
//home-products-section
.home-products-section {
    .section-secondary-title {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 8px;
            height: 8px;
            background: $secondary;
            right: 20vw;
        }
    }
    .card {
        border: none;
        .card-body {
            border: 1px solid #e5e5e5;
            border-top: none;
        }
    }

    .home-products-slider {
        padding-bottom: 106px;
        .swiper-button-next,
        .swiper-button-prev {
            top: initial;
            bottom: 0;
            width: 56px;
            height: 56px;
            background-color: #dddddd;
            &::after {
                font-size: 20px;
                color: #4d4d4d;
            }
        }
        .swiper-button-prev {
            left: 0;
        }
        .swiper-button-next {
            left: 56px;
        }

        .view-all-btn {
            position: absolute;
            bottom: 2px;
            right: 0;
        }
    }

    .swiper-button-next {
        &.next-custom {
            bottom: 65%;
            left: initial;
            right: -30px;
            background: transparent;
            &::after {
                color: $secondary;
                font-size: 28px;
            }
        }
    }
}
// home-catalogue-section
.home-catalogue-section {
    z-index: 2;
}
//home-references-section
.home-references-section {
    z-index: 2;
    .references-box {
        width: 13vw;
        height: 9vw;
        margin-right: 2vw;
        margin-bottom: 2vw;
        // min-width: 286px;
        border: 1px solid #e5e5e5;
        display: flex;
        justify-content: center;
        align-items: center;
        figure {
            overflow: hidden;
        }
    }
}
// Home-news-section
.home-news-section {
    .home-news-slider {
        margin-top: -230px;
    }

    .position-relative {
        .swiper-button-next,
        .swiper-button-prev {
            top: 20%;
            &::after {
                font-size: 28px;
                color: $white;
            }
        }

        .swiper-button-prev {
            left: -45px;
        }
        .swiper-button-next {
            right: -45px;
        }
    }

    .news-wrapper {
        padding-top: 7.125rem;
        padding-bottom: 18.75rem;
        padding-left: 2rem;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            width: 150vw;
            height: 100%;
            left: -125px;
            top: 0;
            right: 0;
            bottom: 0;
            background: $secondary;
        }
        .col-lg-8 {
            position: relative;
            z-index: 4;
        }
        h1 {
            font-weight: normal;
            font-size: 48px;
            line-height: 1.1;
            letter-spacing: -0.02em;
        }
    }
}

//home-join-team-section
.join-team-section {
    h1 {
        font-size: 40px;
        line-height: 1.1;
        letter-spacing: -0.02em;
        border-left: 3px solid #ef353d;
    }

    .wrapper-team {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: -125px;
            top: 0;
            right: 0;
            bottom: 0;
            background: #f2f2f2;
            z-index: -1;
        }
    }

}

// home-follow-us-section
.home-follow-us-section {
    ul {
        li {
            i {
                font-size: 32px;
                color: $secondary;
            }
        }
    }
}

.join-card-img-overlayy-mobile {
    padding: 300px 32px 72px 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    background-size: cover;
    width: 100%;
    background-repeat: no-repeat;
    height: 654px;
    position: relative;
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
        opacity: 0.8;
    }
    .outline-btn {
        color: white !important;
        &::after {
            background: white !important;
        }
    }
}

//MEDIA
@media (max-width: 1680px) {
    //home-about-section
    .home-about-section {
        .about-card {
            .card-img-overlayy {
                .card-no {
                }
                h2 {
                    font-size: 56px;
                }
                h4 {
                    font-size: 1.25rem;
                }
                .outline-btn {
                    font-size: 0.875rem;
                }
                .more-text {
                    .read-more-description {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    //home-news-section
    .home-news-section {
        .news-wrapper {
            h1 {
                font-size: 40px;
            }
        }
        .home-news-slider {
            .swiper-wrapper {
                .swiper-slide {
                    .card {
                        .card-body {
                            a {
                                font-size: 0.675rem;
                            }
                            .card-title {
                                font-size: 0.875rem;
                            }
                            p {
                                font-size: 0.625rem;
                            }
                            .outline-btn {
                                font-size: 0.625rem;
                            }
                        }
                    }
                }
            }
        }
    }

    //join-team-section
    .join-team-section {
        .section-title-custom {
            font-size: 40px;
        }
    }
}
@media (max-width: 1440px) {
    .home-slider {
        .text-white {
            h1 {
                font-size: 2.5rem;
            }
            p {
                font-size: 1.1rem;
            }
        }
    }
    //home-about-section
    .home-about-section {
        .about-card {
            .card-img-overlayy {
                .card-no {
                }
                h2 {
                    font-size: 40px;
                }
                h4 {
                    font-size: 1rem;
                }
                .outline-btn {
                    font-size: 0.775rem;
                }

                .more-text {
                    .read-more-description {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    //home-products-section
    .home-products-section {
        .swiper-slide {
            .card {
                .card-body {
                    .card-title {
                        font-size: 1rem;
                    }
                    .card-text {
                        font-size: 0.625rem;
                    }
                    a {
                        font-size: 0.625rem;
                        h3 {
                            font-size: 1.5rem;
                        }
                    }
                }
            }
        }
    }

    // home-catalogue-section
    .home-catalogue-section {
        .col-md-4 {
            .icon-file {
                font-size: 1.5rem;
            }
            .content {
                h2 {
                    font-size: 1.625rem;
                }
            }
        }
    }

    //home-news-section
    .home-news-section {
        .news-wrapper {
            h1 {
                font-size: 40px;
            }
        }
        .home-news-slider {
            .swiper-wrapper {
                .swiper-slide {
                    .card {
                        .card-img-top {
                            max-height: 276px;
                            object-fit: cover;
                        }
                        .card-body {
                            a {
                                font-size: 0.675rem;
                            }
                            .card-title {
                                font-size: 0.725rem;
                            }
                            p {
                                font-size: 0.575rem;
                            }
                            .outline-btn {
                                font-size: 0.575rem;
                            }

                            .more-text {
                                .read-more-description {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    //join-team-section
    .join-team-section {
        .section-title-custom {
            font-size: 32px;
            padding: 1.2rem 0 1.2rem 2rem;
        }
    }
}

@media (max-width: 1280px) {
    //home-about-section
    .home-about-section {
        .about-card {
            .card-img-overlayy {
                display: flex;
                justify-content: center;
                .card-no {
                    font-size: 24px;
                }
                h2 {
                    font-size: 32px;
                }
                h4 {
                    font-size: 0.875rem;
                }
                .outline-btn {
                    font-size: 0.675rem;
                }
            }
        }
    }
    //join-team-section
    .join-team-section {
        .section-title-custom {
            padding: 1.125rem 0 1.125rem 2rem;
            font-size: 28px;
        }
    }

    
}
@media (max-width: 1080px) {
    .home-references-section {
        .references-box {
            width: 11vw;
            height: 7vw;
            margin-right: 2vw;
            margin-bottom: 2vw;
        }
    }
    
}
@media (max-width: 991.98px) {
    //HomeSlider
    .home-slider {
        .slider-content{
            margin-top: calc(10vw + 55px)
        }
        .swiper-slide {
            height: 680px;
        }
        .text-white {
            h1 {
                font-size: 2rem;
            }
        }
    }

    //home-about-section
    .home-about-section {
        .about-card {
            .card-img-overlayy {
                display: flex;
                justify-content: center;
                padding: 230px 40px 40px 40px;

                .card-no {
                    font-size: 18px;
                }
                h2 {
                    font-size: 24px;
                }
                h4 {
                    font-size: 0.775rem;
                }
                .outline-btn {
                    font-size: 0.5rem;
                }

                .more-text {
                    .read-more-description {
                        font-size: 10px;
                    }
                }
            }
        }
    }

    //home-references-section
    .home-references-section {
        .col-lg-2 {
            min-width: 280px;
        }
    }
    //join-team-section

    .join-team-section {
        .section-title-custom {
            font-size: 28px;
            padding: 1rem 0 1rem 2rem;
        }
        .wrapper-team {
            &::after {
                width: 150vw;
            }
        }
    }
    .home-references-section {
        .references-box {
            width: 17vw;
            height: 12vw;
            margin-right: 2vw;
            margin-bottom: 2vw;
        }
    }
}
@media (max-width: 767.98px) {
    //HomeSlider
    .home-slider {
        .swiper-slide {
            height: 580px;
            .text-white {
                h1 {
                    font-size: 2.5rem;
                }
                p {
                    font-size: 1rem;
                }
            }
        }
    }

    //home-about-section
    .home-about-section {
        .about-card {
            .card-img-overlayy {
                display: flex;
                justify-content: center;
                padding: 230px 40px 40px 40px;

                .card-no {
                    font-size: 16px;
                }
                h2 {
                    font-size: 20px;
                }
                h4 {
                    font-size: 0.625rem;
                }
                .outline-btn {
                    font-size: 0.5rem;
                }
            }
        }
    }

    //home-products-section
    .home-products-section {
        .home-products-slider {
            padding-bottom: 60px;
            .view-all-btn {
                font-size: 0.875rem;
            }
        }
    }

    //home-news-section
    .home-news-section {
        .news-wrapper {
            padding-top: 4.125rem;
            padding-bottom: 15.75rem;
        }
    }

    

    //join-team-section
    .join-team-section {
        .section-title-custom {
            font-size: 22px;
            padding: 0.875rem 0 0.875rem 2rem;
            margin-bottom: 1rem;
        }
    }


     .home-references-section {
        .references-box {
            width: 27vw;
            height: 19vw;
            margin-right: 2vw;
            margin-bottom: 2vw;
        }
    }
}
@media (max-width: 575.98px) {
    .home-slider {
        .swiper-slide {
            height: 735px;
            align-items: flex-start;
            position: relative;
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(
                    0deg,
                    #000000 0%,
                    rgba(0, 0, 0, 0) 100%
                );
                opacity: 0.8;
                z-index: -1;
            }
            &:nth-child(1) {
                background-position-x: 58%;
            }
            &:nth-child(2) {
                background-position-x: 55%;
            }
            &:nth-child(4) {
                background-position-x: 50%;
            }
        }
        .text-white {
            h1 {
                font-size: 40px;
                line-height: 44px;
            }
            p {
                font-size: 16px;
            }
            a {
                font-size: 20px;
                padding-bottom: 2px;
            }
        }

        .slider-content {
            margin-top: calc(40vw + 80px) !important;
            .slider-index-no {
                font-weight: bolder;
                top: -52px;
                left: -32px;
            }
            .swiper-pagination {
                .swiper-pagination-bullet {
                    margin-right: 28px;
                }
            }
        }
    }

    //home-about-section
    .home-about-section {
        .container {
            padding: 0;
        }
        .about-card {
            padding: 0 12px;
            margin-bottom: 12px;

            .card-img-overlayy {
                padding: 216px 32px 72px 32px;
                border-radius: 8px;

                .card-no {
                    font-size: 24px;
                    line-height: 1;
                    margin-bottom: 3px;
                }
                .head-top-title {
                    font-size: 20px;
                    line-height: 1;
                    margin-bottom: 1rem;
                }
                .head-mid-title {
                    font-size: 48px;
                    line-height: 1;
                }
                .head-bottom-title {
                    font-size: 1rem;
                    margin-bottom: 2rem !important;
                }
                .more-text {
                    .read-more-description {
                        font-size: 16px;
                    }
                }
                &.two {
                    background-position-x: 24%;
                }
            }
        }
    }

    //home-products-section
    .home-products-section {
        .swiper-slide {
            .card {
                .card-body {
                    .card-title {
                        font-size: 1.25rem;
                        line-height: 1.1;
                    }
                    .card-text {
                        font-size: 1rem;
                    }

                    a {
                        font-size: 1rem;
                    }
                }
            }
        }

        .home-products-slider {
            .swiper-button-next,
            .swiper-button-prev {
                top: 30%;
                background: transparent;
                width: auto;
                height: 28px;
                &::after {
                    font-size: 28px;
                    color: $secondary;
                }
            }
            .swiper-button-prev {
                left: 16px;
            }
            .swiper-button-next {
                left: initial;
                right: 16px;
            }

            .view-all-btn {
                position: inherit;
                margin-top: 2rem;
                margin-left: 2rem;
            }
        }

        .swiper-button-next {
            &.next-custom {
                &::after {
                }
            }
        }
    }

    .home-catalogue-section {
        .multiply {
            transform: scale(1.25) translateX(3px);
            margin-bottom: 1rem;
        }
        .catalogue-mobile-title {
            font-size: 24px;
            line-height: 1.1;
        }
        
    }

    //home-news-section
    .home-news-section {
        .home-news-slider {
            margin-top: -210px;
            .swiper-wrapper {
                .swiper-slide {
                    .card {
                        .card-img-top {
                            max-height: 360px;
                            height: 100%;
                        }
                        position: relative;
                        &::after {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            width: 100%;
                            height: 100%;
                            max-height: 360px;
                            background: linear-gradient(
                                0deg,
                                #000000 0%,
                                rgba(0, 0, 0, 0) 100%
                            );
                            opacity: 0.8;
                        }

                        .card-body {
                            a {
                                font-size: 0.75rem;
                            }
                            .card-title {
                                font-size: 1.25rem;
                                line-height: 1.3;
                            }
                            .card-text {
                                font-size: 1rem;
                            }
                            .outline-btn {
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }
        }
        .news-wrapper {
            padding-top: 4rem;
            padding-bottom: 12rem;
            padding-left: 0;
            h1 {
                font-size: 48px;
            }
        }
        .position-relative {
            .swiper-button-next,
            .swiper-button-prev {
                top: 24%;
                &::after {
                    min-width: 36px;
                    min-height: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    font-size: 28px;
                }
            }

            .swiper-button-prev {
                left: 15px !important;
            }
            .swiper-button-next {
                right: 15px !important;
            }
        }
    }


    
    //home-references-section
    .home-references-section {
        .col-lg-2 {
            min-width: initial;
            max-width: 163px;
            height: 104px;
            width: 100%;
            min-width: 44%;
            figure {
                width: 100%;
            }
            &.logo-1 {
                figure {
                    max-width: 93px;
                }
            }
            &.logo-2 {
                margin-right: 0 !important;
                figure {
                    max-width: 76px;
                }
            }
            &.logo-3 {
                figure {
                    max-width: 91px;
                }
            }
            &.logo-4 {
                margin-right: 0 !important;

                figure {
                    max-width: 85px;
                }
            }
            &.logo-5 {
                figure {
                    max-width: 88px;
                }
            }
            &.logo-6 {
                margin-right: 0 !important;

                figure {
                    max-width: 99px;
                }
            }
            &.logo-7 {
                figure {
                    max-width: 72px;
                }
            }
        }

        .references-box {
            width: 35vw;
            height: 26vw;
            margin-right: 2vw;
            margin-bottom: 2vw;
        }
    }
    

    .home-follow-us-section {
        ul {
            li {
                i {
                    font-size: 20px;
                }
            }
        }
    }
}


@media (max-width: 1280px) {
    .home-slider {
        .swiper-slide {
            .text-white {
                h1{
                    font-size: 2.5rem;
                }
                p{
                    font-size: 1.15rem;
                }
            }
        }
    }
}

@media (max-height: 578px) {
    .home-slider {
        .swiper-slide {
            height: 578px;
            .text-white {
                h1{
                    font-size: 2rem;
                }
                p{
                    font-size: 1rem;
                }
            }
        }
    }
}

@media (max-width: 375px) {
    .home-slider {
        .swiper-slide {
            .text-white {
                h1{
                    font-size: 2rem;
                }
                p{
                    font-size: 1rem;
                }
            }
        }
    }
}

