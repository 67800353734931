.news-detail {
    margin-top: calc(10vw + 15px);
    .card {
        .card-img-top {
            max-height: 455px;
            object-fit: cover;
        }
    }
    .news-title {
        font-size: 64px;
    }
}
//MEDIA
@media (max-width: 1680px) {
    .news-detail {
        .card {
            .card-body {
                a {
                    font-size: 10px !important;
                }
                h2 {
                    font-size: 1.3rem;
                }
                p {
                    font-size: 0.725rem;
                }
            }
        }
        .news-title {
            font-size: 40px;
        }
        .head-description {
            font-size: 1.625rem;
        }
    }
}
@media (max-width: 1440px) {
    .news-detail {
        .card {
            .card-body {
                a {
                    font-size: 9px !important;
                }
                h2 {
                    font-size: 1rem;
                }
                p {
                    font-size: 0.625rem;
                }
            }
        }

        .news-title {
            font-size: 28px;
        }
        .head-description {
            font-size: 1.5rem;
        }
        .body-description {
            font-size: 0.875rem;
        }
    }
}
@media (max-width: 1280px) {
}
@media (max-width: 991.98px) {
}
@media (max-width: 767.98px) {
    .news-detail {
        .card {
            .card-img-top {
                max-height: 280px;
            }
            .card-body {
                a {
                    font-size: 9px !important;
                }
                h2 {
                    font-size: 1rem;
                }
                p {
                    font-size: 0.625rem;
                }
            }
        }
        .head-description {
            font-size: 1rem;
        }
        .body-description {
            font-size: 0.625rem;
        }
    }
}

@media (max-width: 575.98px) {
    .news-detail {
        margin-top: 40vw;
        .card-body {
            position: relative;
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 1px;
                height: calc(100% + 2rem);
                background: $secondary;
                opacity: 0.2;
            }
        }

        .news-title {
            font-size: 2.5rem;
        }
        .card-img-top {
            height: 180px;
            max-height: 360px !important;
            object-fit: cover;
        }
        .head-description {
            font-size: 1.5rem;
        }
        .body-description {
            font-size: 1rem;
        }

        .social-media {
            li {
                a {
                    i {
                        font-size: 1.5rem;
                    }
                }
            }
        }

        .also-like-title {
            .section-secondary-title-v2 {
                font-size: 2rem;
            }
        }
        .also-like-wrapper {
            .card {
                .card-body {
                    a {
                        font-size: 0.75rem !important;
                    }
                    h2 {
                        font-size: 2rem;
                    }
                    p {
                        font-size: 1rem;
                    }
                    .outline-btn {
                        font-size: 1rem !important;
                    }
                }
            }
        }

        .all-news-btn {
            position: relative;
            &::before {
                content: '';
                position: absolute;
                top: -3rem;
                left: -2rem;
                width: 1px;
                height: calc(100% + 10rem);
                background: #ef353d;
                opacity: 0.2;
            }
        }
        .dot8px {
            position: relative;
            &::before {
                content: '';
                position: absolute;
                left: 15px;
                top: 4px;
                width: 8px;
                height: 8px;
                background: $secondary;
            }
        }
    }
}
