// Body
$body-bg: #fff;

// Typography
$font-family-sans-serif: 'Nexa';
$font-size-base: 1rem;
$font-weight-base: 400;

$font-weight-bolder: 900;
$font-weight-bold: bold;
$font-weight-normal: normal;
$font-weight-light: 400;
$font-weight-lighter: 100;

$headings-line-height: 1.5;

$table-border-width: 0;

$border-radius: 5px;
$border-radius-sm: 0.75rem;
$border-radius-lg: 1rem;
$border-radius-pill: 50rem;
$link-hover-decoration: none;

$gray-100: #f5f5f5 !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #3a3a3a !default;
$gray-900: #212529 !default;

$black: #000 !default;
// scss-docs-end gray-color-variables

$white: #fff;
$gray: #f2f2f2;
$gray-dark: #808080;
$red: #ef353d;
$dark: #4d4d4d;

$secondary: $red;
$light: $gray;
$muted: $gray-dark;
$dark: $dark;

$spacer: 1rem;
$spacers: (
    0: 0,
    1: $spacer / 4,
    2: $spacer / 2,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 2,
    6: $spacer * 2.5,
    7: $spacer * 3,
    8: $spacer * 3.5,
    9: $spacer * 4,
    10: $spacer * 4.5,
    11: $spacer * 5,
    12: $spacer * 5.5,
    13: $spacer * 6,
    14: $spacer * 6.5,
    15: $spacer * 7,
    16: $spacer * 7.5,
    17: $spacer * 8,
    18: $spacer * 8.5,
    19: $spacer * 9,
    20: $spacer * 9.5,
    21: $spacer * 10,
    22: $spacer * 11,
    23: $spacer * 12,
    24: $spacer * 13,
    25: $spacer * 13.5,
    26: $spacer * 14,
    27: $spacer * 15,
    28: $spacer * 16,
    29: $spacer * 17,
    30: $spacer * 18,
    31: $spacer * 19,
    32: $spacer * 20,
    37: $spacer * 22.5,
);

$link-decoration: none;
$container-max-widths: (
    sm: 540px,
    md: 820px,
    lg: 80vw,
    /*  */
);
