@media (max-width: 575.98px) {
    .careers-head-title {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            left: 30px;
            top: 0;
            background: $secondary;
            opacity: 0.2;
            height: 100%;
            width: 1px;
        }
    }

    .contact-form {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            left: 30px;
            top: 0rem;
            background: $secondary;
            opacity: 0.2;
            height: calc(1rem + 200px);
            width: 1px;
        }
    }
}
.careers-page-banner{
    img{
        object-position: initial;
    }
}