.error-page{
    height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
    font-family: 'Chakra Petch';
    overflow: hidden;
    img{
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: auto;
        max-width: 1200px;
        z-index: -1;
        opacity: .3;
    }
    h1{
        font-size: 148px;
        font-weight: bold;
        color: $secondary;
        line-height: 1;
    }
}
