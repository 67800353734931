//about-eku-section
.about-eku {
    .movie-wrapper {
        .movie-figure {
            &:hover {
                .movie-img {
                    filter: brightness(1);
                }
                .play-icon {
                    transform: scale(1.1);
                }
            }
        }
        a {
            font-size: 12px;
            letter-spacing: 0.2em;
        }
        .movie-img {
            filter: brightness(0.5);
            transition: 300ms;
        }
        .play-content {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
            span {
                font-size: 10px;
            }
        }
        .play-theme {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            cursor: pointer;

            .play-content {
                pointer-events: none;
            }
        }
        .play-icon {
            transition: 300ms;
            max-width: 60px;
        }
    }
}

//about-doing
.company-doing {
    .wrapper-company-doing {
        padding-top: 165px;
        padding-bottom: 20px;

        .doing-icon {
            max-width: 94px;
        }
    }
}
//company-doing-mobile
.company-doing-mobile {
    .swiper-button-next,
    .swiper-button-prev {
        top: 15%;
        &::after {
            font-size: 1rem;
            color: $secondary;
            font-weight: bolder;
        }
    }
}

//about-our-history
.our-history {
    .swiper-container {
        width: 83vw;
        height: 100%;
    }
    .swiper-button-next,
    .swiper-button-prev {
        background: rgba(255, 255, 255, 0.74);
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: none;
        align-items: center;
        justify-content: center;
        &::after {
            color: $secondary;
            font-size: 16px;
        }
    }
    .swiper-wrapper {
        &::after {
            content: '';
            position: absolute;
            width: 2500vw;
            height: 1px;
            background: $secondary;
            opacity: 0.2;
            top: 60px;
        }
    }
    .swiper-slide {
        display: flex;
        justify-content: flex-start;
        figure {
            min-height: 218px;
            max-height: 218px;
        }
    }
    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .swiper-scrollbar {
        width: 100%;
        left: 0 !important;
        right: 0;
        background: transparent !important;
        .swiper-scrollbar-drag {
            height: 4px;
            border-radius: 0;
            background: $secondary;
        }
        &::after {
            content: '';
            position: absolute;
            width: 500vw;
            height: 1px;
            background: $secondary;
            opacity: 0.2;
            bottom: 0px;
        }
    }
    .swiper-date {
        font-size: 24px;
        letter-spacing: -0.02em;
        position: relative;
        &::after {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            background: #ef353d;
            left: 0;
            bottom: -32px;
        }
    }
}

//your-partner
.your-partner {
    .line {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            width: 3px;
            height: 146px;
            background: $secondary;
            top: -40px;
            left: 15px;
        }
    }
    .your-partner-card {
        padding-top: 1.9rem;
        padding-left: 2rem;
        .no {
            top: 8px;
            left: 4px;
            font-family: Kursk;
            font-size: 48px;
            line-height: 1.2;
            color: $secondary;
            opacity: 0.15;
        }
    }
}

// wellcome-to-eku
.wellcome-to-eku {
    .section-secondary-title-v2 {
        p {
            font-size: 2rem;
            line-height: 1.1;
            letter-spacing: -0.02em;
            color: $dark;
            font-weight: normal;
        }
    }
}

.modal-dialog {
    max-width: 70vw !important;
    margin: 30px auto;
    .modal-body {
        span {
            font-size: 32px;
            font-weight: 100;
            color: $dark;
        }
    }
}
//MEDIA
@media (min-width: 992px) {
    .company-doing {
        .wrapper-company-doing {
            position: relative;
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 130vw;
                height: 100%;
                background: $light;
                z-index: -1;
            }
        }
    }

    .our-history {
        .row {
            position: relative;
            &::after {
                content: '';
                position: absolute;
                width: 3px;
                height: 146px;
                background: $secondary;
                top: 0;
                left: 15px;
            }
        }

        &::after {
            content: '';
            position: absolute;
            top: 6rem;
            bottom: 0;
            left: calc(10vw + 16px);
            width: 1px;
            height: calc(100% - 6rem - 20px);
            background: #ef353d;
            z-index: 1;
            opacity: 0.2;
        }
    }

    .your-partner {
        &::after {
            content: '';
            position: absolute;
            top: 0rem;
            bottom: 0;
            left: calc(10vw + 16px);
            width: 1px;
            height: 100%;
            background: #ef353d;
            z-index: 1;
            opacity: 0.2;
        }
    }

    .wellcome-to-eku {
        &::after {
            content: '';
            position: absolute;
            top: 0rem;
            bottom: 0;
            left: calc(10vw + 16px);
            width: 1px;
            height: 100%;
            background: #ef353d;
            z-index: 1;
            opacity: 0.2;
        }
    }
}

//MEDIA
@media (max-width: 1680px) {
}
@media (max-width: 1440px) {
}
@media (max-width: 1280px) {
    .our-history {
        .swiper-scrollbar {
            bottom: 10% !important  ;
        }
    }
}
@media (max-width: 991.98px) {
    .modal-dialog {
        max-width: 100vw !important;
    }
    .our-history {
        .swiper-button-next,
        .swiper-button-prev {
            display: flex;
        }
    }
}
@media (max-width: 767.98px) {
}
@media (max-width: 575.98px) {
    .about-eku {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 30px;
            width: 1px;
            height: calc(100% + 10rem);
            background: #ef353d;
            opacity: 0.2;
            z-index: -1;
        }
        .company-head-description {
            font-size: 1.5rem;
            line-height: 1.2 !important;
        }
        .section-secondary-title-v2 {
            margin-bottom: 1rem !important;
        }

        .movie-wrapper {
            margin-left: -50px;
            margin-right: -30px;
        }

        figure {
            .movie-img {
                height: 412px;
                object-fit: cover;
                object-position: -20px 0px;
            }
        }
    }

    .our-history {
        .swiper-container {
            width: 100% !important;

            .swiper-button-next,
            .swiper-button-prev {
                top: 38%;
                border-radius: 0;
                width: 30px;
                height: 30px;
            }
            .swiper-button-next {
                right: 0;
            }
            .swiper-button-prev {
                left: 0;
            }
            .swiper-scrollbar {
                bottom: 15% !important;
            }
        }
        .section-secondary-title-v2 {
            margin-bottom: 2rem !important;
        }
    }

    .your-partner {
        .line {
            &::after {
                display: none;
            }
        }
        .head {
            .section-secondary-title-v2 {
                font-size: 1.875rem;
            }
            p {
                font-size: 0.938;
            }
        }
        .your-partner-card {
            no {
                font-size: 2.5rem;
            }
            h4 {
                font-size: 1.2rem;
            }
        }
    }
    .company-doing-mobile-slider {
        .swiper-button-next {
            right: 32px;
        }
        .swiper-button-prev {
            left: 32px;
        }
    }
    .wellcome-to-eku {
        #section-secondary-title-v2 {
            position: relative;
            font-size: 2rem !important;
            p {
                font-size: 0.938rem !important;
            }
            border-left: none;
            &::after {
                content: '';
                position: absolute;
                width: 8px;
                height: 8px;
                background: #ef353d;
                top: 1.5rem;
                left: -23px;
            }
        }
    }
}

.videoIframe {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
}

.videoPoster {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: url('/assets/page-company-video-img.jpg') rgba(0, 0, 0, 0.5);
    background-size: 100%;
    border: none;
    text-indent: -999em;
    overflow: hidden;
    opacity: 1;
    -webkit-transition: opacity 800ms, height 0s;
    -moz-transition: opacity 800ms, height 0s;
    transition: opacity 800ms, height 0s;
    -webkit-transition-delay: 0s, 0s;
    -moz-transition-delay: 0s, 0s;
    transition-delay: 0s, 0s;
}

.videoWrapperActive .videoPoster {
    opacity: 0;
    height: 0;
    -webkit-transition-delay: 0s, 800ms;
    -moz-transition-delay: 0s, 800ms;
    transition-delay: 0s, 800ms;
}
