.anim,
.loader {
    position: relative;
    &.image-anim {
        overflow: hidden;
        &::before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #fff;
            -webkit-transition: left 1500ms cubic-bezier(0.835, 0.015, 0.245, 0.995) 0s;
            transition: left 1500ms cubic-bezier(0.835, 0.015, 0.245, 0.995) 0s;
        }
        &::after {
            content: '';
            height: 100%;
            width: 0;
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: rgba(247, 247, 249, 0.95);
        }
    }
    &.active {
        &::before {
            left: 100%;
        }
    }
    &.z-index-4 {
        &::before,
        &::after {
            z-index: 4;
        }
    }
}

.loader {
    &.up-anim {
        opacity: 0;
        transform: translateY(70px);
        transition: 300ms;
        transition-delay: 800ms;
    }
    &.active {
        opacity: 1;
        transform: translateY(0);
    }
}

.header-logo {
    &.loader {
        transform: scale(1.1);
        transition: 500ms;
    }
    &.active {
        transform: scale(1);
    }
}
