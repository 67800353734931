.products-general {
    .offer-list-dropdown {
        .btn {
            &:focus {
                // outline: none !important;
                // box-shadow: none;
            }
            &::after {
                border: solid $secondary;
                border-width: 0 3px 3px 0 !important;
                display: inline-block;
                padding: 3px;
                transform: rotate(45deg);
            }
            .pieces {
                line-height: 1.7;
            }
        }
        .offer-list-dropdown-wrapper {
            transition: 300ms;
            margin-right: 0;
        }
        &.active {
            .offer-list-dropdown-wrapper {
                margin-right: 48px;
            }
        }
    }
    .add-btn {
        border: 1px solid $secondary;
        background: transparent;
        width: 40px;
        height: 40px;
        border-radius: 5px;

        .tooltip-custom {
            transition: 200ms;
            opacity: 0;
            position: absolute;
            left: 14px;
            bottom: -20px;
            font-weight: bold;
            font-size: 10px;
            line-height: 100%;
            letter-spacing: -0.02em;
            color: $muted;
            padding: 8px 10px;
            background: #f2f2f2;
            box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
            border-radius: 3px;
            white-space: nowrap;
        }

        svg {
            fill: #4d4d4d;
        }
        &:hover {
            background: $secondary;
            svg {
                color: white;
                fill: white;
            }
            .tooltip-custom {
                opacity: 1;
            }
        }
    }

    .fa-info {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $dark;
        width: 20px;
        height: 20px;
        border: 1px solid $dark;
        border-radius: 5px;
        font-size: 10px !important;
        cursor: pointer;
        &:hover {
            border-color: $secondary;
            color: $secondary;
        }
    }

    .pagination {
        .page-item {
            border-radius: 5px;
            .page-link {
                color: $muted;
            }
            &.actived {
                .page-link {
                    background: #f2f2f2;
                    color: white;
                    color: black;
                }
            }
        }
    }
}
.product-banner {
    img {
        object-position: initial !important;
    }
}

.product-categories-wrapper {
    margin-top: calc(10vw + 15px);
}
.products {
    // margin-top: calc(10vw + 15px);
    position: relative;

    .border-filter {
        position: relative;
        &::after {
            content: '';
            top: 0;
            left: 15px;
            height: 120px;
            width: 3px;
            background: #ef353d;
            position: absolute;
        }
    }

    .filter {
        padding: 12px 1rem 12px 2rem;
        position: relative;
        .filter-group-img {
            max-height: 470px;
            object-fit: cover;
        }
        .filter-header {
            border-radius: 5px;
            .filter-button {
                cursor: pointer;
                min-width: 162px;
            }
            .hamburger {
                display: flex;
                flex-direction: column;
                cursor: pointer;
                .line {
                    width: 24px;
                    height: 2px;
                    margin-bottom: 4px;
                    background: white;
                    transition: 300ms;
                    &:last-child {
                        margin-bottom: 6px;
                    }
                }

                &.active {
                    .line1 {
                        transform: rotate(45deg) translate(3.5px, 5.5px);
                    }
                    .line2 {
                        opacity: 0;
                        transform: translateX(-80px);
                    }
                    .line3 {
                        transform: rotate(-45deg) translate(3.5px, -5.5px);
                    }
                }
            }
            span {
                font-weight: 800;
                font-size: 18px;
                letter-spacing: 0.2em;
            }

            .search-filter {
                max-width: 545px;
                width: 100%;
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    height: 94px;
                    width: 16px;
                    background: transparent;
                    left: -30px;
                    top: -13px;
                }
                &::after {
                    content: '';
                    position: absolute;
                    height: 16px;
                    width: calc(100% + 46px);
                    background: transparent;
                    right: -16px;
                    bottom: -1.8rem;
                }
                .search-button {
                    display: none !important;
                }

                .search-box {
                    i {
                        font-size: 24px;
                    }
                    .search-input {
                        border-radius: 3px;
                        border: none;
                        padding: 14px 1.5rem;
                        font-size: 18px;
                        outline: none;
                        &::placeholder {
                            font-size: 18px;
                            line-height: 3;
                            letter-spacing: -0.02em;
                            color: #808080;
                            display: flex;
                            align-items: center;
                            padding-top: 14px !important;
                            margin-top: 14px !important;
                        }
                    }

                    .search-input {
                        background: $secondary;
                        box-shadow: $secondary;
                        pointer-events: none;
                        color: $secondary;
                        user-select: none;
                        &::placeholder {
                            color: $secondary;
                        }
                    }
                }
                &.active {
                    .search-input {
                        color: initial;
                        box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.2);
                        background: white;
                        pointer-events: initial;
                        -webkit-user-select: initial;
                        -moz-user-select: initial;
                        -ms-user-select: initial;
                        &::placeholder {
                            color: #808080;
                        }
                    }
                    .search-button {
                        display: flex !important;
                    }

                    &::before,
                    &::after {
                        background: white;
                    }
                }
            }
        }
        .filter-body-mobile {
            &.active {
                margin-top: 2rem;
                display: block;
            }
        }

        .filter-body {
            overflow-y: hidden;
            display: none;
            padding-bottom: 24px;

            &.active {
                margin-top: 2rem;
                display: block;
            }

            //filter Group
            .filter-group {
                h6 {
                    font-weight: bold;
                    font-size: 12px;
                    letter-spacing: 0.2em;
                    color: $secondary;
                    border-bottom: 1px solid $secondary;
                }
                .filter-list {
                    max-height: 240px;
                    overflow-y: auto;
                    position: relative;
                    border-right: 1px solid #cccccc;

                    li {
                        text-transform: uppercase;
                        color: #4d4d4d;
                        padding: 24px 32px 20px;
                        font-weight: bold;
                        font-size: 16px;
                        letter-spacing: -0.02em;
                        cursor: pointer;

                        color: #4d4d4d;
                        &.active {
                            background: #e0e0e0;
                            border-radius: 5px;
                        }
                    }
                }
                .filter-list::-webkit-scrollbar-thumb {
                    background-color: $secondary;
                    border-radius: 3px;
                }
                .filter-list::-webkit-scrollbar {
                    width: 3px;
                }
            }
        }
    }

    .product-list-item {
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
        border-top: 1px solid #ccc;
        transition: 200ms;
        &:hover {
            background: #f7f7f7;
        }
        &:last-child {
            border-bottom: 1px solid #ccc;
        }
        .no {
            font-weight: bold;
            font-size: 18px;
            line-height: 100%;
            letter-spacing: -0.02em;
        }
        .info {
            font-weight: bold;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: -0.02em;
            color: $muted;
        }
        .product-name {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 100%;
            letter-spacing: -0.02em;
            color: #4d4d4d;
        }
        .product-img {
            max-width: 120px;
        }

        &.active {
            .product-info-content {
                display: flex !important;
            }
        }
    }

    .product-info-content {
        max-width: 760px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #ffffff;
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        border-top: 6px solid $secondary;
        z-index: 7;
        .closed {
            position: absolute;
            right: -18px;
            top: -18px;
            width: 62px;
            height: 62px;
            background: #e5e5e5;
            border-radius: 50%;
            cursor: pointer;
        }
        .closed:hover {
            opacity: 1;
        }
        .closed:before,
        .closed:after {
            position: absolute;
            line-height: 62px;
            content: ' ';
            height: 25px;
            width: 2px;
            background-color: $secondary;
            left: 50%;
            top: 18px;
        }
        .closed:before {
            transform: rotate(45deg);
        }
        .closed:after {
            transform: rotate(-45deg);
        }

        figure {
            max-width: 276px;
        }
        .product-info-name {
            font-size: 24px;
            letter-spacing: -0.02em;
        }
    }

    //FILTER PAGE
    .hero-wrapper {
        padding: 64px 64px 240px 64px;
        &::before {
            content: '';
            position: absolute;
            top: -5px;
            left: 15px;
            height: 5px;
            width: 65%;
            background: $secondary;
            border-top-left-radius: 20px;
        }
    }
    .hero-content {
        padding: 0 64px;
        margin-top: -195px;
    }
}
.line-product {
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: calc(10vw + 14px);
        background: #ef353d;
        opacity: 0.2;
        width: 1px;
        height: 100%;
    }
}
//MOBILE
.products-mobile {
    margin-top: calc(40vw + 15px);

    .filter-button {
        cursor: pointer;
    }
    .hamburger {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        .line {
            width: 24px;
            height: 2px;
            margin-bottom: 4px;
            background: white;
            transition: 300ms;
            &:last-child {
                margin-bottom: 6px;
            }
        }

        &.active {
            .line1 {
                transform: rotate(45deg) translate(3.5px, 5.5px);
            }
            .line2 {
                opacity: 0;
                transform: translateX(-80px);
            }
            .line3 {
                transform: rotate(-45deg) translate(3.5px, -5.5px);
            }
        }
    }

    .filter-group {
        h6 {
            font-weight: bold;
            font-size: 12px;
            letter-spacing: 0.2em;
            color: $secondary;
            border-bottom: 1px solid $secondary;
        }
        .filter-list {
            max-height: 240px;
            overflow-y: auto;
            position: relative;
            border-right: 1px solid #cccccc;

            li {
                text-transform: uppercase;
                color: #4d4d4d;
                padding: 20px 32px;
                font-weight: bold;
                font-size: 16px;
                letter-spacing: -0.02em;
                cursor: pointer;

                color: #4d4d4d;
                &.active {
                    background: #e0e0e0;
                    border-radius: 5px;
                }
            }
        }
        .filter-list::-webkit-scrollbar-thumb {
            background-color: $secondary;
            border-radius: 3px;
        }
        .filter-list::-webkit-scrollbar {
            width: 3px;
        }
    }

    .mobile-filter-wrapper {
        border: 1px solid $secondary;
        border-radius: 5px;
    }

    .product-search {
        border: 1px solid $secondary;
        border-radius: 5px;
        .search_btn {
            background: #ef353d;
            padding: 18px 24px;
        }
        input {
            padding: 18px 24px;
            border: none;
            border-radius: 5px;
        }
    }
}
.mobile-product-list {
    .product-item {
        border-bottom: 1px solid #cccccc;
        &.active {
            .mobile-product-info-content {
                display: block !important;
            }
        }
        .product-names {
            max-width: 127px;
            h6 {
                font-weight: bold;
                font-size: 20px;
                line-height: 100%;
                letter-spacing: -0.02em;
                color: $dark;
            }
            p {
                line-height: 1;
                letter-spacing: -0.02em;
            }
            span {
                font-weight: bold;
                font-size: 12px;
                line-height: 100%;
                letter-spacing: -0.02em;
                color: $muted;
            }
        }
        .product-img {
            max-width: 90px;
        }
    }
}
//DROPDOWN

.offer-list-dropdown {
    border: 1px solid transparent;
    position: absolute !important;
    top: -24px;
    right: 0;
    background: white;
    z-index: 1;
    max-width: 470px;
    width: 100%;
    height: 0;
    .offer-back-btn {
        font-weight: normal;
        font-size: 16px;
        line-height: 1;
        letter-spacing: -0.02em;
        color: #4d4d4d;
        display: none;
        pointer-events: none;
        cursor: pointer;
        i {
            color: $secondary;
            margin-right: 10px;
            font-size: 12px;
        }

        &.active {
            display: block;
            pointer-events: visible;
        }
    }
    .custom-dropdown-pages {
        display: none;
        overflow-x: hidden;
    }

    &.active {
        height: 766px;
        border: 1px solid #ef353d;
        .custom-dropdown-pages {
            display: block;
        }
        .list-toggle {
            &::after {
                transform: rotate(-135deg);
            }
        }
        &.height {
            height: 350px;
            overflow: hidden;
        }
    }
}
.custom-dropdown-pages {
    opacity: 0.95;
    border-radius: 5px;
    // offer-list
    .offer-list {
        .list-all {
            overflow: auto;
            max-height: 480px;
        }
        .head-title {
            font-weight: bold;
            font-size: 10px;
            line-height: 100%;
            letter-spacing: -0.02em;
            color: $muted;
            &:nth-of-type(1) {
                width: 86px;
            }
            &:nth-of-type(2) {
                width: 190px;
            }
        }

        .offer-list-item {
            border-bottom: 1px solid #cccccc;
            padding: 24px 0;
            .product-no {
                font-weight: bold;
                font-size: 18px;
                line-height: 100%;
                letter-spacing: -0.02em;
                color: $secondary;
                width: 86px;
            }

            .product-names {
                width: 190px;
                .product-name {
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 100%;
                    text-align: right;
                    letter-spacing: -0.02em;
                    color: $dark;
                }
                .product-ref {
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 100%;
                    letter-spacing: -0.02em;
                    color: $muted;
                }
            }

            .pieces {
                input {
                    width: 30px;
                    height: 30px;
                    border: 1px solid $secondary;
                    border-radius: 5px;
                    letter-spacing: -0.02em;
                    color: $dark;
                    line-height: 2;
                    vertical-align: middle;
                    text-align: center;
                    margin: 0 5px;
                    font-weight: bold;
                }
                span {
                    color: $dark;
                    cursor: pointer;
                }
            }
        }
    }
    //send-my-offer-list
    .send-my-offer-list {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-100%);
        transition: 200ms;
        &.active {
            transform: translateX(0%);
        }
        .sen-my-offer-form {
            input {
                border: 1px solid $secondary;
                height: 56px;
                padding: 20px 16px;
            }
        }
    }
    //offer-list-success
    .offer-list-success {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-100%);
        transition: 200ms;
        &.active {
            transform: translateX(0);
        }
    }
}
///////PLUSSS

.mobile-filter-wrapper {
    .filter-body-mobile {
        height: 0;
        overflow: hidden;
        &.active {
            height: auto;
        }
    }
    .filter-group {
        border-radius: 0 !important;
        .mobile-filter-list {
            position: initial !important;
        }

        .mobile-filter-item {
            border-radius: 0 !important;

            i {
                display: none;
                font-size: 20px;
            }
        }
        &.active {
            height: 56px;
            overflow: hidden;

            .mobile-filter-list {
                .mobile-filter-item {
                    &.active {
                        position: absolute !important;
                        top: 0;
                        left: 0;
                        right: 0;
                        background: $secondary;
                        color: $white;
                        padding-left: 46px;
                        border-top: 1px solid white;
                        height: 100%;

                        i {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
.mobile-product-info-content {
    position: fixed;
    top: 17vw;
    left: 0;
    right: 0;
    background: orange;
    z-index: 99999;
    border-top: 6px solid $secondary;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    display: none !important;

    .col {
        figure {
            max-width: 140px;
        }
    }

    .closed {
        position: absolute;
        right: -18px;
        top: -18px;
        width: 40px;
        height: 40px;
        background: #e5e5e5;
        border-radius: 50%;
        cursor: pointer;
    }
    .closed:hover {
        opacity: 1;
    }
    .closed:before,
    .closed:after {
        position: absolute;
        line-height: 40px;
        content: ' ';
        height: 20px;
        width: 1px;
        background-color: $secondary;
        left: 50%;
        top: 10px;
    }
    .closed:before {
        transform: rotate(45deg);
    }
    .closed:after {
        transform: rotate(-45deg);
    }
}
.control-checkbox {
    font-family: 'Nexa';
}

// PRODUCT FILTER

//MEDIA
@media (max-width: 1680px) {
    .products {
        .filter-body {
            .filter-group {
                h6 {
                    font-size: 9px !important;
                }
                .filter-list {
                    li {
                        font-size: 11px !important;
                        padding: 18px 24px !important;
                    }
                }
            }
            .clear-all-filters,
            .filter-show {
                font-size: 14px !important;
            }
        }
    }

    .offer-list-dropdown {
        &.active {
            height: 635px;
        }
        .custom-dropdown-pages {
            .offer-list {
                .list-all {
                    max-height: 375px;
                }
                ul {
                    .offer-list-item {
                        .product-no {
                            font-size: 0.875rem;
                        }
                        .product-names {
                            .product-name,
                            .product-ref {
                                font-size: 0.875rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .send-my-offer-list {
        .sen-my-offer-form {
            label {
                font-size: 0.875rem;
                margin-bottom: 0;
            }
            input {
                height: 40px !important;
                padding: 20px 16px;
                &::placeholder {
                    font-size: 0.875rem;
                }
            }
        }
        .control-checkbox {
            font-size: 0.725rem;
        }
    }
}
@media (max-width: 1440px) {
    .products {
        .filter-body {
            .filter-group {
                h6 {
                    font-size: 7px !important;
                }
                .filter-list {
                    li {
                        font-size: 9px !important;
                        padding: 16px 18px !important;
                    }
                }
            }
            .clear-all-filters,
            .filter-show {
                font-size: 12px !important;
            }
        }
    }
}
@media (max-width: 1280px) {
    .products {
        .filter-body {
            .filter-group {
                padding: 1rem !important;
                h6 {
                    font-size: 7px !important;
                }
                .filter-list {
                    li {
                        font-size: 8px !important;
                        padding: 14px 14px !important;
                    }
                }
            }
            .clear-all-filters,
            .filter-show {
                font-size: 12px !important;
            }
        }
    }
}
@media (max-width: 991.98px) {
    .offer-list-dropdown {
        top: 0;
    }

    .add-btn {
        .tooltip-custom {
            display: none;
        }
    }
    .line-product {
        &::before {
            display: none;
        }
    }
}

@media (max-width: 767.98px) {
    .mobile-filter-wrapper {
        .filter-header {
            padding: 20px 24px !important;
        }

        .product-search {
            input {
                padding: 20px 24px !important;
            }
        }
    }
    .offer-list-dropdown {
        top: 0;
        &.active {
            height: 635px;
        }
        .custom-dropdown-pages {
            .offer-list {
                .head-title {
                    font-size: 8px;
                }
                .list-all {
                    max-height: 375px;
                }
                ul {
                    .offer-list-item {
                        .product-no {
                            font-size: 0.725rem;
                        }
                        .product-names {
                            .product-name,
                            .product-ref {
                                font-size: 0.725rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .products-filter {
        .hero-wrapper {
            padding: 32px 32px 240px 32px !important;
        }
        .hero-content {
            padding: 0 32px !important;
        }
    }
}
@media (max-width: 575.98px) {
    .filters-hero-description {
        h2 {
            font-size: 1.5rem;
        }
    }
    .f-1 {
        &.active {
            border-bottom: 1px solid white;
            border-top: 1px solid white;
        }
    }
    .products-mobile {
        .section-secondary-title-v2 {
            border: none;
            padding: 1rem 0 1rem 0rem;
            font-size: 2rem;
            margin-bottom: 2rem;
        }
        .dropdown-toggle {
            &::after {
                margin-bottom: 8px;
            }
        }

        .filter-group {
            .filter-list {
                .mobile-filter-item {
                    padding: 20px 16px !important;
                    &.active {
                        padding-left: 46px !important;
                        padding-right: 32px !important;
                    }
                }
            }
        }
    }
    .products-filter {
        margin-top: 40vw !important;
        .section-secondary-title-v2 {
            padding: 1rem 0 1rem 0rem;
            border: none;
            font-size: 2rem;
            margin-bottom: 66px;
        }
        .list-toggle {
            .outline-btn {
                display: none;
                visibility: hidden;
            }
        }

        .hero-wrapper {
            padding: 32px 32px 210px 32px !important;
            background: linear-gradient(180deg, #f7f7f7 0%, rgba(230, 230, 230, 0.1) 100%) !important;

            h6 {
                font-size: 20px;
            }
        }
        .hero-content {
            padding: 0 0 0 16px !important;
            .mobile-img {
                transform: scale(1.15) translateX(-8px);
            }
        }
    }
    .mobile-product-info-content {
        flex-direction: column;
        .product-info-name {
            // font-size: 1rem;
        }
        .no,
        .info {
            // font-size: 0.725rem;
        }
    }
    .products {
        margin-top: 15vw;
    }
    .product-categories-wrapper {
        margin-top: 40vw !important;
    }

    /* .offer-list-dropdown {
        .list-toggle {
            padding-right: 0 !important;
        }
    } */
}
